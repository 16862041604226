import React from 'react'
import Box from '@mui/material/Box'
import Text from '../../../components/Text'
import UpcomingDates, { PastDates } from '../Home/UpcomingDates'
import { useDatesStore } from '../../../stores/datesStore'
import OpenDateRequestCard from '../OpenDateRequests/components/OpenDateRequestCard'

const ApprovedRequests = () => {
  const { approvedType, setApprovedType, openRequests, currentUser } = useDatesStore()

  const renderOpenDateRequests = (request: any, i: number) => {
    return <OpenDateRequestCard request={request} key={i} currentUser={currentUser} />
  }

  return (
    <Box width="100%">
      <NavLinks 
        setApprovedType={setApprovedType} 
        approvedType={approvedType}
        />
      {approvedType === 'upcoming' && openRequests?.payload.map(renderOpenDateRequests)}
      {approvedType === 'upcoming' ? <UpcomingDates groupByDate /> : <PastDates groupByDate />}
    </Box>
  )
}

type NavLinksProps = {
  setApprovedType: (type: "past" | "upcoming") => void,
  approvedType: string
}

const NavLinks = ({ setApprovedType, approvedType }: NavLinksProps) => {
  return (
    <Box width="100%" mb={2} display="flex" justifyContent="flex-end">
      <Text 
        style={{cursor: 'pointer'}}
        mr={2} 
        onClick={() => setApprovedType('upcoming')}
        weight={approvedType === "upcoming" ? "semibold" : "regular"}>
        Upcoming
      </Text>
      <Text>|</Text>
      <Text 
        style={{cursor: 'pointer'}}
        ml={1} 
        onClick={() => setApprovedType('past')}
        weight={approvedType === "past" ? "semibold" : "regular"}>
        Past
      </Text>
    </Box>
  )
}


export default ApprovedRequests