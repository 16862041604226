import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { colors } from '../../styles/_var'
import Text from '../Text'
import { FooterWrapper } from './Footer.styles'
import { Link } from 'react-router-dom'
import { routes } from '../../utils/constants'

const Footer: FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <FooterWrapper {...props}>
      <Box>
        <Text variant='large' weight={600} color={colors.white}>
          gusiberi
        </Text>
        <Text variant="extra-small" uppercase color={colors.white}>
          for your convenience
        </Text>
      </Box>
      <Box display="flex" alignItems="center" mt={-0.5}>
        <Text color={colors.white} center variant="extra-small">
          &copy; 2023 Gusiberi, Inc
        </Text>
        <strong style={{color: colors.white, marginRight: 5, marginLeft: 5}}>
          &#x2022;
        </strong>
        <Link to={routes.TERMS_OF_USE}>
          <Text variant="extra-small" color={colors.white}>
            Terms
          </Text>
        </Link>
        <strong style={{color: colors.white, marginRight: 5, marginLeft: 5}}>
          &#x2022;
        </strong>
        <Link to={routes.PRIVACY_POLICY}>
          <Text variant="extra-small" color={colors.white}>
            Privacy
          </Text>
        </Link>
      </Box>
    </FooterWrapper>
  )
}

export default Footer