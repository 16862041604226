import { OpenDateRequestsFormValues } from "../pages/Dashboard/OpenDateRequests/OpenDateRequests.hooks"
import { Participant } from "../services/api"

export const DAYS_OF_THE_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
] as const

export interface PublicUser {
  full_name: string,
  username: string, 
  avatar?: string
}

export interface User extends PublicUser {
  twitter?: string,
  instagram?: string,
  dob?: string,
  linkedin?: string,
  bio?: string,
  gender?: string,
  email?: string,
  city: string,
  phone_number?: string
  activated?: boolean
}

export type FriendshipStatus = {
  request_id: number,
  request_sent_by?: PublicUser,
  request_status: 'accepted' | 'pending' | 'declined'
}

export type UserProfile = {
  user: User,
  activities: FavouriteActivity[],
  friendship_status?: FriendshipStatus
}

export type ComponentSpacing = {
  mt?: number,
  mb?: number,
  mr?: number,
  ml?: number,
  mx?: number,
  my?: number,
  m?: number
}

export type PaginationInfo = {
  current_page: number,
  last_page: boolean,
  next_page?: number,
  total_pages: number
}

export type MessageType = {
  id: number,
  message: string,
  user: User,
  message_type?: string,
  created_at: string,
  seen: boolean,
  status?: string,
}

export type DateParticipant = {
  name?: string,
  email?: string,
  decline_reason?: string,
  review?: string,
  user: User,
  social_media?: string,
  friendship_status: FriendshipStatus,
}

export type ParticipantRequest = {
  id: number,
  name: string,
  user?: User,
  contact?: string,
  added_by_id: number,
  approved: 'pending' | 'accepted' | 'declined',
}

export type DateRequest = {
  request_id: string,
  start_date: string,
  end_date: string
  location: string, 
  activity: string,
  created_by: User,
  description?: string,
  cancelled_by?: User,
  backdrop_image?: string,
  updated_at?: string,
  last_sent_message?: MessageType,
  date_participants?: Participant[],
  date_participant_requests?: ParticipantRequest[],
  supplies_suggestions?: SupplySuggestion[],
  supplies?: Supply[],
  buck_breaker_enabled: boolean,
  scoreboard_enabled: boolean,
  slug: string
  date_type: "private_date" | "group_activity"
}

export type DateRequestResponse = {
  pagination_info: PaginationInfo,
  payload: DateRequest[]
}

export type DateTimeslot = {
  id: number,
  recurring: boolean,
  start_time: string,
  end_time: string
}

export type DateSchedule =   {
  id: number
  active?: boolean
  created_at: string
  updated_at: string
  reserved_for?: string
  user_id: number
  day_of_the_week: number
  date_schedule_timeslots: DateTimeslot[]
}

export type DAY_OF_THE_WEEK = typeof DAYS_OF_THE_WEEK[number]

export type DateScheduleResponse = {
  [K in typeof DAYS_OF_THE_WEEK[number]]: DateSchedule
}

export type SupportMessage = {
  id: number,
  sender?: string,
  sender_email?: string,
  subject: string,
  message: string,
  handled_by?: string,
  num_of_messages?: number,
  user_id: number
  created_at: string,
  updated_at: string
}

export type SupportConversation = {
  id: number,
  message: string,
  sender_id: number,
  created_at: string,
  updated_at: string
}

export type FavouriteActivity = {
  id: number,
  description: string,
  favourite_places?: string
}

export type Friend = {
  category_id: number,
  friend_user: User,
  created_at: string
}

export type FriendCategory = {
  id: number, 
  name: string,
  friends_count: number,
  friendships: Friend[]
}

export type FriendRequest = {
  id: number,
  sent_from: User,
  status: string,
  created_at: string
}

export type NotificationGroup = "date_request_received" | "friend_request_received" | "friend_request_accepted" | "message_received" | "date_request_received" | "date_request_declined" | "date_request_accepted" | "date_canceled" | "date_rescheduled" | "upcoming_date_reminder"

export type Notification = {
  id: number,
  sender?: User,
  subject_id_2?: number,
  group: NotificationGroup,
  subject_id: number,
  seen: boolean,
  created_at: string,
  updated_at: string,
}

export type NotificationSettings = {
  invite_response_notification: boolean
  received_requests_notification: boolean
  request_updates: boolean
}

export type UserDetails = {
  user : User,
  notification_settings: NotificationSettings,
  activities: UserActivityTimestamp
}

export type NotificationSettingType = keyof NotificationSettings 

export type Duration = Array<{
  type: 'hour' | 'minute' | 'second' | 'day' | 'd' | 'm' | 'h' |'s',
  value: number
}>

export type OpenDateRequest = {
  id: number,
  slug: string,
  user: User,
  open_date_request_interests?: OpenDateRequestInterest[],
  status: "open" | "closed" | "deleted",
  created_at?: string,
} & OpenDateRequestsFormValues

export type OpenDateRequestInterest = {
  id: number,
  user: User,
  message?: string,
  status: 'accepted' | 'pending' | 'rejected'
}

export type UserActivityTimestamp = {
  demo_viewed_on: null | string
  last_online:  null | string
  last_open_request_notification: null | string
}

export type SupplySuggestion = {
  id?: number,
  is_vital: boolean,
  item: string,
  additional_info: string
}

export type Supply = {
  date_participant_id: number,
} & Omit<SupplySuggestion, 'is_vital'>

export type SupplyWithParticipant = {user?: User, name?: string} & Supply