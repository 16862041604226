import React from 'react'
import Drawer from '../../../components/Drawer/Drawer'
import OpenDateRequests from '../OpenDateRequests/CreateOpenDateRequests'
import { OpenDateRequest } from '../../../utils/types'

type OpenRequestDrawerProps = {
  isOpen: boolean,
  closeDrawer?: () => void,
  updateData?: OpenDateRequest
}

const OpenRequestDrawer = ({
  isOpen,
  closeDrawer=() => {},
  updateData
}: OpenRequestDrawerProps) => {
  return (
    <Drawer
      disableEnforceFocus
      showCloseIcon
      anchor='bottom'
      open={isOpen}
      onOpen={() => {}}
      onClose={closeDrawer}
      header='Open Date Request'
    >
      <OpenDateRequests 
        closeDrawer={closeDrawer} 
        updateData={updateData} 
      />
    </Drawer>
  )
}

export default OpenRequestDrawer
