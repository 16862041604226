import React, { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button/Button'
import { useFriendCategories } from '../Friends.hooks'
import { colors } from '../../../../styles/_var'
import { useUiStore } from '../../../../stores/uiStore'

type EditFriendGroupModalProps = {
  id: number,
  name: string
}

const EditFriendGroupModal: FunctionComponent<EditFriendGroupModalProps> = ({
  id,
  name=''
}) => {
  const [groupName, setGroupName] = useState(name)
  const [error, setError] = useState('')
  const { cancelDialog } = useUiStore()
  const { editFriendGroup, renaming } = useFriendCategories()

  const submitForm = () => {
    if (!groupName.trim()) {
      return setError('this field is required.')
    }

    editFriendGroup(id, groupName)
  }

  return (
    <Box px={2} py={3} width={280}>
      <Text weight="semibold" mb={1}>Rename friend group</Text>
      <Input
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submitForm()
            e.preventDefault()
          }
        }}
        onFocus={() => setError('')}
        error={!!error}
        helperText={error}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        size="small"
        placeholder='New friend group name' />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant='outlined' onClick={cancelDialog} mr={2}>
          <Text>Cancel</Text>
        </Button>
        <Button 
          variant='contained' 
          onClick={submitForm} 
          loading={renaming}
        >
          <Text color={colors.white}>Rename</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default EditFriendGroupModal
