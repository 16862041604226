import styled, { css } from 'styled-components'
import { colors, spacing } from '../../../styles/_var'

const selectedStyle = css`
  background: ${colors.green};
  color: ${colors.white};

  p {
    color: ${colors.white};
  }
`

export const Slot = styled(({ selected, ...rest }) => <div {...rest} />)`
  padding: ${spacing.xs};
  margin-bottom: ${spacing.xxs};
  width: 100%;
  max-width: 20rem;
  border: solid 1px ${colors.green};
  border-radius: 3px;
  text-align: center;
  transition: all ease .2s;
  box-sizing: border-box;
  color: ${colors.green};
  cursor: pointer;
  
  p {
    color: ${colors.green};
  }

  &:hover {
    ${selectedStyle}
  }

  ${({ selected }) => selected && selectedStyle}
`