import React, { FunctionComponent } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { spacing } from '../../../styles/_var'
import Text from '../../../components/Text'
import SimpleForm from '../../../components/SimpleForm'
import { AddScheduleWrapper } from './Availability.styles';
import { DAY_OF_THE_WEEK } from '../../../utils/types';
import { useAddSchedule } from './Availability.hooks';

const AddSchedule: FunctionComponent<{dayOfWeek: DAY_OF_THE_WEEK}> = React.memo(({
  dayOfWeek
}) => {
  const {
    submitForm,
    formFields,
    actionButtons,
    initialFormValues
  } = useAddSchedule(dayOfWeek)

  return (
    <AddScheduleWrapper minWidth={280}>
      <Box py={1.5} px={2}>
        <Text weight="semibold" variant='semi-large'>Add new time slot</Text>
      </Box>
      <Divider />
      <Box p={2}>
        <SimpleForm 
          initialValues={initialFormValues}
          formStyle={{marginTop: spacing.xs}}
          formFields={formFields}
          actionButtons={actionButtons}
          submitForm={submitForm}
        />
      </Box>
    </AddScheduleWrapper>
  )
})

export default AddSchedule