const content = {
  app_name: "gusiberi",
  sign_up_with_email: "Sign up with Email",
  continue_with_google: "Continue with Google",
  welcome_title: "Welcome to gusiberi",
  sign_in: "Sign In",
  sign_up: "Sign Up",
  got_account: "Got an account?",
  no_account: "Don't have an account?",
  intro: "Sign up and begin to plan and schedule your activities or dates with ease and safety.",
  not_your_device: "Not your device? Use a private or incognito window to sign in.",
  signup_instructions: "Fill the information below to update your profile."
}

export default content