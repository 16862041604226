import React from 'react'
import Paper from '@mui/material/Paper'
import styled from 'styled-components'
import MuiAvatar from '@mui/material/Avatar'
import StarIcon from '@mui/icons-material/StarRateRounded';
import { User } from '../../../../../../utils/types';
import Text from '../../../../../../components/Text'
import Box from '@mui/material/Box'
import { colors } from '../../../../../../styles/_var'
import { getFirstName, numToPosition } from '../../../../../../utils/helpers';
import UsersAvatarGroup from '../../../../../../components/UsersAvatarGroup/UsersAvatarGroup';

type ParticipantScoreProps = {
  user?: User | null ,
  team?: {teamName: string, members: User[]},
  totalScore?: number,
  scores: string[]
  isWinner?: boolean,
  index: number,
}

const ParticipantScore = ({ 
  user, 
  totalScore,
  scores, 
  isWinner,
  index,
  team
}: ParticipantScoreProps) => {
  if ((!user && !team) || !totalScore) return null

  const offset = !!team ? 12 : 7
  const widthStyle = {
    width: `calc(270px + ${scores.length * offset}px)`,
    maxWidth: '90%'
  }

  return (
    <ParticipantWrapper elevation={4} sx={{marginBottom: 3, ...widthStyle}}>
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between" 
        sx={{height: 35}}
      >
        <Box display="flex" alignItems="center">
          <Text lighten weight="semibold" ml={2} style={{width: "29px"}}>
            {numToPosition(index)}
          </Text>
          {!!team ? (
            <>
              <UsersAvatarGroup users={team.members} max={3} />
              <Text weight="semibold" lighten ml={1}>
                {team.teamName}
              </Text>
            </>
          ) : (
            <>
              <ParticipantAvatar 
                sx={{width: 45, height: 45, marginLeft: 1}}
                src={user?.avatar} 
              />
              <Text weight="semibold" lighten ml={1}>
                {getFirstName(user?.full_name)}
              </Text>
            </>
          )}
          {isWinner && <StarIcon fontSize="large" sx={{color: "#EB9A30", position: 'relative', top: '-1px'}} />}
        </Box>
        <ScoreSection>
          {scores.map((score, i) => {
            const mr = i === scores.length - 1 ? undefined : (scores.length > 3 ? 1 : 2)
            return (
              <Text 
                weight="semibold" 
                mr={mr} 
                key={i} 
                inline
              >
                {score}
              </Text>
            )
          })}
        </ScoreSection>
      </Box>
    </ParticipantWrapper>
  )
}

const ParticipantAvatar = styled(MuiAvatar)`
  border: solid 3px ${colors.white};
`

const ScoreSection = styled(Box)`
  background: ${colors.lightGreen};
  height: 35px;
  padding: 10px;
  box-sizing: border-box;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`

const ParticipantWrapper = styled(Paper)`
  margin: 0 auto 15px;

  &.MuiPaper-root {
    border-radius: 6px;
  }
`


export default ParticipantScore
