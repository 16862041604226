import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper'
import EditIcon from '@mui/icons-material/EditRounded';
import { spacing } from '../../../../styles/_var'
import Avatar from '../../../../components/Avatar/Avatar'
import Text from '../../../../components/Text'
import TextWithIcon from '../../../../components/TextWithIcon'
import ButtonBase from '@mui/material/ButtonBase';
import { ProfileInfoItem } from './UserProfile.styles';
import { animateComponent } from '../../../../utils/hoc';
import { User } from '../../../../utils/types';
import { formatPhoneNumber } from '../../../../utils/helpers';

type IProfileView = {
  gotoEdit: () => void,
  currentUser: User
}

const ProfileView: FunctionComponent<IProfileView> = React.memo(({
  gotoEdit,
  currentUser
}) => {
  return (
    <Container>
      <Paper sx={{padding: spacing.xs, marginTop: spacing.sm, maxWidth: 330, marginLeft: 'auto', marginRight: 'auto'}}>
        <Box mb={3}position="relative" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Avatar src={currentUser.avatar} sx={{width: 100, height: 100}} />
          <Text variant='medium' weight='semibold'>{currentUser.full_name}</Text>
          <Text weight="semibold" center>@{currentUser.username}</Text>
          <Text center maxWidth={300} mt={3}>{currentUser.bio}</Text>
          <ButtonBase sx={{position: 'absolute', top: 0, right: 0}} onClick={gotoEdit}>
            <TextWithIcon
              weight="semibold"
              variant="subtext"
              before
              icon={<EditIcon />}
            >
              Edit
            </TextWithIcon>
          </ButtonBase>
        </Box>
        <List>
          <ProfileInfoItem>
            <Text>Email:</Text>
            <Text weight="semibold">{currentUser.email}</Text>
          </ProfileInfoItem>
          <Divider />
          <ProfileInfoItem>
            <Text>Phone Number:</Text>
            <Text 
              weight={!currentUser.phone_number ? "regular" : "semibold"}
              lighten = {!currentUser.phone_number}
              variant={!currentUser.phone_number ? 'small' : undefined}
              italic={!currentUser.phone_number}
            >
              {formatPhoneNumber(currentUser.phone_number || '') || 'Please add your phone number'}
            </Text>
          </ProfileInfoItem>
          <Divider />
          <ProfileInfoItem>
            <Text>City:</Text>
            <Text weight="semibold">{currentUser.city}</Text>
          </ProfileInfoItem>
          <Divider />
          <ProfileInfoItem>
            <Text>Twitter:</Text>
            <Text weight="semibold">{currentUser.twitter}</Text>
          </ProfileInfoItem>
          <Divider />
          <ProfileInfoItem>
            <Text>Instagram:</Text>
            <Text weight="semibold">{currentUser.instagram}</Text>
          </ProfileInfoItem>
        </List>
      </Paper>
    </Container>
  )
})

export default animateComponent<IProfileView>(ProfileView, true)
