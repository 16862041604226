import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { 
  DateTimeslot, 
  DateScheduleResponse, 
  DAY_OF_THE_WEEK, 
} from "../utils/types";

export const availabilityStore = createSlice({
  name: 'schedule',
  initialState: {
    availability: {} as DateScheduleResponse
  },
  reducers: {
    setAvailability(state, action) {
      state.availability = action.payload
    },
    enableWeekday(state, action: PayloadAction<DAY_OF_THE_WEEK>) {
      state.availability[action.payload] = { 
        ...state.availability[action.payload],
        active: true 
      } 
    },
    disableWeekday(state, action: PayloadAction<DAY_OF_THE_WEEK>) {
      state.availability[action.payload] = { 
        ...state.availability[action.payload],
        active: false 
      } 
    },
    removeAvailability(state, action: PayloadAction<{day: DAY_OF_THE_WEEK, id: number}>) {
      const { id, day } = action.payload
      state.availability[day].date_schedule_timeslots = state.availability[day].date_schedule_timeslots?.filter(s => s.id !== id) || []
    },
    addAvailability(state, action: PayloadAction<{day: DAY_OF_THE_WEEK, schedule: DateTimeslot}>) {
      const { day, schedule } = action.payload
      state.availability[day].date_schedule_timeslots = [...(state.availability[day].date_schedule_timeslots) || [], schedule]
    }
  }
})

export const {
  setAvailability,
  enableWeekday,
  disableWeekday,
  removeAvailability,
  addAvailability
} = availabilityStore.actions
export const availabilityReducer = availabilityStore.reducer
export const selectAvailability = (state: RootState) => state.schedule.availability 