import React from 'react'
import Divider from '@mui/material/Divider'
import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/CancelRounded';
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import { colors, spacing } from '../../../../styles/_var'
import Button, { ButtonProps } from '../../../../components/Button/Button'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'
import { OpenDateRequest } from '../../../../utils/types'
import CollapsibleCard from '../../../../components/CollapsibleCard/CollapsibleCard';

type OpenRequestInterestsProps = {
  openRequest: OpenDateRequest,
  acceptInterest: (interestId: number) => void,
  rejectInterest: (interestId: number) => void
}
export const OpenRequestInterests = ({ 
  openRequest, 
  acceptInterest,
  rejectInterest
}: OpenRequestInterestsProps) => {
  const interests = openRequest?.open_date_request_interests
  if (!interests?.length) return (
    <Text weight="semibold" color={colors.lightDanger} mt={1}>
      Noone has shown any interests at the moment.
    </Text>
  )

  const rejectButton = (interestId: number, buttonProps?: ButtonProps) => (
    <Button 
      variant="outlined" 
      size="small"
      onClick={() => rejectInterest(interestId)}
      {...buttonProps}
    >
      <Text weight="semibold" variant="small">Decline</Text>
    </Button>
  )
  
  const acceptButton = (interestId: number, buttonProps?: ButtonProps) => (
    <Button 
      variant="contained" 
      size="small" 
      onClick={() => acceptInterest(interestId)}
      {...buttonProps}
    >
      <Text weight="semibold" variant="small" color={colors.white}>Accept</Text>
    </Button>
  )

  const pendingActions = (interestId: number) => (
    <Box>
      {acceptButton(interestId, {mr: 1})}
      {rejectButton(interestId)}
    </Box>
  )

  const acceptedActions = (interestId: number) => (
    <Box display="flex" alignItems="center">
      {rejectButton(interestId)}
      <CheckIcon 
        color="success"
        fontSize="large" 
        sx={{marginLeft: spacing.xxs}} 
      />
    </Box>
  )

  const rejectedActions = (interestId: number) => (
    <Box display="flex" alignItems="center">
      {acceptButton(interestId)}
      <CancelIcon 
        sx={{
          color: colors.lightDanger, 
          marginLeft: spacing.xxs
        }} 
        fontSize="large" 
      />
    </Box>
  )

  return (
    <CollapsibleCard 
      textTitle="Interested Folks"
      headerColor={colors.secondary}
      color={colors.white}
    >
      {interests.map((interest, i) => (
        <Box key={i}>
          <Box 
            key={i} 
            p={2} 
            display="flex" 
            alignItems="center"
            justifyContent="space-between"
          >
            <UserInfo 
              gotoProfileOnClick
              user={interest.user} 
              useSmallAvatar 
            />
            {interest.status === "pending" && pendingActions(interest.id)}
            {interest.status === "accepted" && acceptedActions(interest.id)}
            {interest.status === "rejected" && rejectedActions(interest.id)}
          </Box>
          <Divider />
        </Box>
      ))}
    </CollapsibleCard>
  )
}


export default OpenRequestInterests