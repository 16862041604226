import React, { useState } from 'react'
import Box from '@mui/material/Box'
import SuccessIcon from '@mui/icons-material/TaskAltRounded';
import Text from '../../../components/Text'
import Input from '../../../components/Input'
import Button from '../../../components/Button/Button'
import SimpleFooter from '../../../components/SimpleFooter/SimpleFooter'
import { useNavigate } from 'react-router-dom'
import TextWithIcon from '../../../components/TextWithIcon'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { animateComponent } from '../../../utils/hoc'
import validators from '../../../utils/validators'
import { useResetPasswordRequestMutation } from '../../../services/api'
import { colors } from '../../../styles/_var';
import { AuthContent } from '../AuthIndex/Auth.styles';

const RequestResetPassword = () => {
  const navigate = useNavigate()
  const [sent, setSent] = useState(false)
  const [sendPasswordRequest] = useResetPasswordRequestMutation()

  const resetPassword = (email: string) => {
    sendPasswordRequest({email}).catch(e => {
      // do nothing
    })
    setSent(true)
  }

  return (
    <Box>
      {!sent && (
        <TextWithIcon onClick={() => navigate(-1)} mb={1} before icon={<KeyboardArrowLeft />}>
          Go back
        </TextWithIcon>
      )}
      {!sent && <RequestResetPasswordBody resetPassword={resetPassword} />}
      {sent && (
        <Box p={3} display="flex" alignItems="center" flexDirection="column">
          <SuccessIcon sx={{fontSize: 40, color: colors.green}} />
          <Text mt={1} center color={colors.green}>A password reset link has been sent to this email if its associated with a Gusiberi user.</Text>
        </Box>
      )}
      <SimpleFooter />
    </Box>
  )
}


const RequestResetPasswordBody = animateComponent<{resetPassword: (email: string) => void}>(({
  resetPassword
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [err, setErr] = useState('')

  const onSubmit = () => {
    const error = validators.isEmail(email)
    if (error) return setErr(error)

    setIsLoading(true)
    resetPassword(email)
  }

  return (
    <AuthContent>
      <Text weight="semibold" center mb={1}>Reset Password</Text>
      <Input 
        onFocus={() => setErr('')}
        helperText={err}
        error={!!err}
        size="small" 
        placeholder="Enter your email" 
        value={email} 
        onChange={e => setEmail(e.target.value)} />
      <Button 
        loading={isLoading} 
        fullWidth 
        mt={1} 
        center 
        variant="contained" 
        onClick={onSubmit}
      >
        <Text color={colors.white}>Request password reset</Text>
      </Button>
    </AuthContent>
  )
})


export default RequestResetPassword
