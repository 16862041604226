import styled from 'styled-components'
import { colors } from '../../styles/_var'
import { ResultsMenu } from '../FindUser/FindUser'


export const DashboardHeaderWrapper = styled.div`
  background: ${colors.white};
  position: fixed;
  width: calc(100% - 24rem);
  top: 0;
  left: 24rem;
  box-shadow: 0 0px 4px 1px #EDEDED;
  z-index: 2;
`


export const DashboardHeaderContent = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;


  .MuiBox-root {
    svg {
      color: ${colors.grey};
    }

    input:focus + svg {
      color: ${colors.primary};
    }
  }
`

export const SearchResults = styled(ResultsMenu)`
  left: -28rem;
  z-index: 3;
  width: 28rem;
  top: 2.5rem;
  box-shadow: 1px 1px 3px 1px lightgray;
`