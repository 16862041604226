import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import { DashboardCard } from '../../pages/Dashboard/Home/Home.styles'
import { colors, spacing } from '../../styles/_var'
import { DateRequest, User } from '../../utils/types'
import Text from '../Text'
import { DateCardHeader } from './DateCard.styles'
import DateRequestClass from '../../services/DateRequestClass'
import { groupByDay, unslugify } from '../../utils/helpers'
import TextWithIcon from '../TextWithIcon'
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { PaperProps } from '@mui/material/Paper';
import LocationText from '../LocationText/LocationText';



type IDateCard = {
  currentUser: User,
  date: DateRequest,
  bgColor?: string
} & PaperProps

type IDateCardList = {
  currentUser: User,
  dateList: DateRequest[],
  bgColor?: string,
  groupByDate?: boolean
}


const DateCard: FunctionComponent<IDateCard> = ({
  currentUser,
  date,
  bgColor,
  elevation=3,
  ...rest
}) => {
  const dateObject = new DateRequestClass(date, currentUser)

  return (
    <DashboardCard elevation={elevation} {...rest}>
      <Link to={`/date_requests/${date.request_id}`}>
        <Box padding={spacing.xxs} bgcolor={bgColor || colors.veryLightGreen}>
          <DateCardHeader style={{alignItems: 'center'}}>
            <div>
              <AvatarGroup max={3} spacing={25}>
                {date.date_participants?.map((p, i) => (
                  <Avatar key={i} alt={p.name || p?.user?.full_name} src={p?.user?.avatar} />
                ))}
              </AvatarGroup>
            </div>
            <Box ml={1} flex={1} width={30}>
              <Text weight="bold">{date.activity}</Text>
              {!!date.description && (
                <Text truncate variant='subtext' style={{width: `100%`}}>
                  {date.description}
                </Text>
              )}
            </Box>
          </DateCardHeader>
          <LocationText 
            element="span"
            underline
            location={date.location}
            color={colors.primary}
            textProps={{weight: "semibold", color: colors.primary, truncate: true, mt: 1}}

          />
          <TextWithIcon 
            truncate
            weight="semibold" 
            mt={1} 
            color={colors.darkgrey}
            icon={<ClockIcon style={{color: colors.darkgrey}} />} 
          >
            {dateObject.fullDateTime}.
          </TextWithIcon>
        </Box>
      </Link>
    </DashboardCard>
  )
}

export const DateCardList: FunctionComponent<IDateCardList> = React.memo(({
  dateList,
  groupByDate,
  ...rest
}) => {
  if (!dateList) return null
  const groupedByDate = groupByDay<DateRequest>(dateList, 'start_date')

  if (groupByDate) {
    return (
        <Box>
          {!!groupedByDate && Object.keys(groupedByDate).map((date, i) => (
            <Box key={i} mb={3}>
              <Text weight="semibold" capitalize mb={1}>{unslugify(date)}</Text>
              <Grid container spacing={2}>
                {groupedByDate[date].map((date: any) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={date.request_id}>
                    <DateCard {...rest} date={date} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
      </Box>
    )
  }

  return (
    <Grid container spacing={2}>
      {dateList.map(date => (
        <Grid item xs={12} sm={6} md={4} lg={6} key={date.request_id}>
          <DateCard {...rest} date={date} />
        </Grid>
      ))}
    </Grid>
  )
})

export default DateCard