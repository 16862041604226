import styled from "styled-components";
import Input from "../Input";
import { UserInfo } from "../UserInfo/UserInfo";
import { colors } from '../../styles/_var'

export const UIInput = styled(Input)`
  & .MuiInputBase-root {
    height: 45px;
  }
`

export const ResultItem = styled(UserInfo)`
  transition: all ease .2s;
  cursor: pointer;

  &:hover {
    background: ${colors.verylightPrimary};
  }
`