import React, { FunctionComponent, useRef, useState } from 'react'
import ImageCropper from '../../utils/imageCropper'
import ImagePreviewer from './ImagePreviewer'
import { HiddenFileInput, ImageUploadWrapper, CameraIcon } from './ImageUpload.styles'

type IBaseContentAreaArgs = {
  openFileChooser: () => void
}

type IContentAreaArgsMultipleImages = {
  croppedImages: string[]
  rawImages: string[]
  rawImage?: never
  croppedImage?: never
} & IBaseContentAreaArgs

type IContentAreaArgsSingleImages = {
  croppedImages?: never
  rawImages?: never
  rawImage: string
  croppedImage: string
} & IBaseContentAreaArgs

export type IContentAreaArgs = IContentAreaArgsMultipleImages | IContentAreaArgsSingleImages


type IBaseImageUpload = {
  contentArea: (payload: IContentAreaArgs) => any,
  showIcon?: boolean,
  maxWidth?: number
}

type IImageUploadMultiple = {
  multiple?: boolean
  noPreview?: never
  aspectRatio?: never
  onImagesChange?: (images: string[]) => void
  onImageChange?: never
} & IBaseImageUpload

type IImageUploadSingle = {
  noPreview?: boolean
  aspectRatio?: number
  multiple?: never
  onImageChange?: (image: string) => void,
  onImagesChange?: never
} & IBaseImageUpload

type ImageUploadProps = IImageUploadSingle | IImageUploadMultiple


const ImageUpload: FunctionComponent<ImageUploadProps> = ({
  onImageChange,
  aspectRatio=1,
  maxWidth=500,
  contentArea,
  noPreview=false,
  showIcon,
  multiple=false,
  onImagesChange
}) => {
  const input:any = useRef(null);
  const [croppedImage, setCroppedImage] = useState('')
  const [rawImage, setRawImage] = useState('')
  const [rawImages, setRawImages] = useState<string[]>([])
  const [croppedImages, setCroppedImages] = useState<string[]>([])

  const openFileChooser = () => {
    input.current?.click()
  }

  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = evt.target
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      
      reader.onload = async (e:any) => {
        const rawImage = e.target.result
        setRawImage(rawImage)
        evt.target.value = null as any

        if (noPreview && rawImage) {
          const image = await ImageCropper.createImage(rawImage)
          const croppedImage = await ImageCropper.resizeImage(image, maxWidth)
          if (croppedImage) onComplete(URL.createObjectURL(croppedImage))
        }
      }
      reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
  }

  const onMultipleInputChanges = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const input = evt.target

    if (input.files) {
      const allFiles = Array.from(input.files)
      let fileProcessed = 0
      const filesToProcess = allFiles.length
      const allCroppedImages: string[] = []
      const allRawImages: string[] = []
      
      for (let file of allFiles) {
        const reader = new FileReader();
        // eslint-disable-next-line
        reader.onload = async (e: any) => {
          const rawImage = e.target.result
          allRawImages.push(rawImage)
          evt.target.value = null as any
  
          if (rawImage) {
            const image = await ImageCropper.createImage(rawImage)
            const croppedImage = await ImageCropper.resizeImage(image, maxWidth)

            if (croppedImage) {
              const croppedImageUrl = URL.createObjectURL(croppedImage)
              allCroppedImages.push(croppedImageUrl)
              fileProcessed += 1
              
              if (fileProcessed === filesToProcess) {
                setRawImages(allRawImages)
                setCroppedImages(allCroppedImages)
                onImagesChange?.(allCroppedImages)
              }
            }
          }
        }

        reader.readAsDataURL(file); // convert to base64 string
      }
    }
  }

  const onComplete = (imageUrl: string) => {
    onImageChange?.(imageUrl)
    setCroppedImage(imageUrl)
  }

  const contentAreaArgs: IContentAreaArgs = multiple ? {
    openFileChooser,
    rawImages,
    croppedImages
  } : {
    openFileChooser,
    rawImage,
    croppedImage
  }

  return (
    <>
      <ImageUploadWrapper>
        {contentArea(contentAreaArgs)}
        {showIcon && <CameraIcon fontSize="large" onClick={openFileChooser} />}
        <HiddenFileInput
          multiple={multiple}
          type="file"
          name="myImage"
          accept="image/*"
          ref={input}
          onChange={multiple ? onMultipleInputChanges : onInputChange} />
        {!!rawImage && !noPreview && (
          <ImagePreviewer
            aspectRatio={aspectRatio}
            imageUrl={rawImage}
            maxWidth={maxWidth}
            onClose={() => setRawImage('')}
            onComplete={onComplete} />
        )}
      </ImageUploadWrapper>
    </>
  )
}

export default ImageUpload



