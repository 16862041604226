import React, { useEffect } from "react"
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Wrapper } from './GoogleAuthButton.styles'
import { useGoogleAuthMutation } from "../../services/api";
import { useLoginForm } from "../../pages/Auth/Login/Login.hooks";
import { useProcessError } from "../../hooks/hooks";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useUiStore } from "../../stores/uiStore";
import { useSearchParams } from "react-router-dom";

interface GoogleAuthButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  handleLogin?: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void,
  handleError?: (error: any) => void,
  buttonText?: string
}


const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  handleError,
  handleLogin,
  buttonText='Sign in with Google',
  ...rest
}) => {
  const [params,] = useSearchParams()
  const [authWithGoogle] = useGoogleAuthMutation()
  const { completeLogin } = useLoginForm()
  const processError = useProcessError()
  const {openLoadingScreen, closeLoadingScreen} = useUiStore()

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_KEY,
        scope: 'email',
      });
    }

    try {
      if (navigator.cookieEnabled) gapi.load('client:auth2', start);
    } catch (e) {
      console.log('google auth will not work')
    }
  }, [])

  const defaultHandleLogin = async (response: any) => {
    try {
      openLoadingScreen('Hold on while we log you in...')
      const result = await authWithGoogle({
        id_token: response.tokenId,
        params: {phone_number: params.get('f')}
      }).unwrap()
      completeLogin(result)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    } finally {
      closeLoadingScreen()
    }
  }

  const defaultHandleError = (err: any) => {
    // pass
  }

  return (
    <Wrapper {...rest}>
      <GoogleLogin
        buttonText={buttonText}
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_KEY || ''}
        onSuccess={handleLogin || defaultHandleLogin}
        onFailure={handleError || defaultHandleError}
        responseType='id_token'
      />
    </Wrapper>
  )
}

export default GoogleAuthButton
  