import React, { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import { debounce } from '@mui/material/utils';
import FrownIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import Text from '../../../components/Text'
import { useParams } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import { useFetchOpenDateRequestsQuery, useLazyFetchOpenDateRequestQuery, useMarkOpenRequestAsSeenMutation } from '../../../services/api'
import { CloseLabel, OpenDateRequestWrapper } from './OpenDateRequests.styles'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import OpenRequestActionButtons from './components/OpenRequestActionButtons'
import OpenDateRequestSummary from './components/OpenDateRequestSummary'
import OpenRequestInterests from './components/OpenRequestInterests'
import { useOpenDateRequests } from './OpenDateRequests.hooks'
import { PageWrapper, colors } from '../../../styles/_var'
import TextWithIcon from '../../../components/TextWithIcon'



const useOpenDateRequestView = () => {
  const { slug } = useParams()
  const { data } = useFetchOpenDateRequestsQuery({})
  const [fetchRequest, { data: request, isLoading, isError }] = useLazyFetchOpenDateRequestQuery()
  const [markAsSeen, ] = useMarkOpenRequestAsSeenMutation()
  const openRequest = request?.payload || data?.payload.find(request => request.slug === slug) 
  const markRequestAsSeen = useMemo(() => debounce(markAsSeen, 2000), [markAsSeen])

  return { 
    openRequest, 
    fetchRequest, 
    isLoading, 
    isError, 
    slug, 
    markRequestAsSeen
  }
}

const OpenDateRequestView = () => {
  useSetHeaderTitle("Open Date Requests", true)
  const { 
    openRequest, 
    fetchRequest, 
    isLoading, 
    slug, 
    isError,
    markRequestAsSeen
  } = useOpenDateRequestView()
  const { acceptOpenInterest, rejectOpenInterest } = useOpenDateRequests({})
  

  useEffect(() => {
    if ((openRequest && !isLoading) || isError) return
    if (slug) fetchRequest({slug})
  }, [openRequest, fetchRequest, isLoading, slug, isError])

  useEffect(() => {
    if (!slug) return
    markRequestAsSeen({slug})
  }, [slug, markRequestAsSeen])

  const isClosed = openRequest?.status === "closed"

  const renderBody = () => {
    if (!openRequest && isLoading) {
      return (
        <Text weight="semibold" mt={2} center>
          Loading...
        </Text>
      )
    }

    if (!openRequest) {
      return (
        <TextWithIcon 
          justifyContent="center"
          icon={<FrownIcon sx={{color: colors.danger}} />}
          weight="semibold" 
          mt={2} 
          center 
          color={colors.danger}
        >
          Failed to load request
        </TextWithIcon>
      )
    }

    return (
      <Box p={3}>
        <OpenDateRequestSummary request={openRequest} />
        <Divider />
        <OpenRequestInterests 
          openRequest={openRequest} 
          acceptInterest={acceptOpenInterest(openRequest.slug)}
          rejectInterest={rejectOpenInterest(openRequest.slug)}
        />
        <OpenRequestActionButtons mt={3} mb={2} openRequest={openRequest} />
      </Box>
    )
  }

  const renderClosedLabel = () => (
    <CloseLabel weight="semibold" variant="title" color="danger" mt={2} center>
      Closed
    </CloseLabel>
  )

  return (
    <OpenDateRequestWrapper $closed={isClosed}>
      <MobileHeader />
      <PageWrapper>
        {isClosed && renderClosedLabel()}
        {renderBody()}
      </PageWrapper>
    </OpenDateRequestWrapper>
  )
}

export default OpenDateRequestView
