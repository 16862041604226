import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { spacing } from '../../../../styles/_var'
import Text from '../../../../components/Text'

const LoadingSkeleton = () => {
  return (
    <>
      <Box
        mt={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton variant="circular" width={98} height={98} sx={{marginBottom: spacing.xs}} />
        <Skeleton variant="text" height={40} width={200} />
        <Skeleton variant="text" height={20} width={150} />
        <Box display="flex" mt={1}>
          <Skeleton variant="circular" width={30} height={30} sx={{marginRight: spacing.xs}} />
          <Skeleton variant="circular" height={30} width={30} sx={{marginRight: spacing.xs}} />
          <Skeleton variant="circular" height={30} width={30} />
        </Box>
      </Box>
      <Box mt={2}>
        <Text weight="semibold" variant="medium">Bio</Text>
        <Skeleton variant="rectangular" width="100%" height={60}  />
      </Box>
      <Box mt={2}>
        <Text weight="semibold" variant="medium">Preferred Dating Activities</Text>
        <Skeleton variant="rectangular" width="100%" height={60}  />
      </Box>
    </>
  )
}

export default LoadingSkeleton