import React from 'react'
import { NotificationItem } from './Notifications.styles'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useFetchNotificationsQuery, useMarkAllNotificationAsSeenMutation } from '../../../services/api'
import DefaultNotificationView from './components/DefaultNotificationView'
import Text from '../../../components/Text'
import { PageWrapper, colors } from '../../../styles/_var'
import { groupByDay, unslugify } from '../../../utils/helpers'
import { Notification } from '../../../utils/types'

export const NotificationSkeleton = () => (
  <Box>
    {(new Array(2)).fill(true).map((_, i) => (
      <NotificationItem key={i}>
        <Box display="flex" justifyContent="center" flex={1}>
          <Skeleton variant="circular" height={48} width={48} />
          <Skeleton variant="text" sx={{ml: 1, mr: 2, width: "80%"}} />
        </Box>
        <Skeleton variant="text" sx={{width: 30}} />
      </NotificationItem>
    ))}
  </Box>
)

type MarkAsSeenProps = {
  markAllAsRead: () => void
}

const MarkAsSeen = ({markAllAsRead}: MarkAsSeenProps) => {
  return (
    <Box justifyContent="flex-end" display="flex" mt={2} mr={2}>
      <Text 
        link 
        underline 
        onClick={markAllAsRead} 
        textDecorationStyle="dotted"
      >
        Mark All As Read
      </Text>
    </Box>
  )
}


const Notifications = () => {
  useSetHeaderTitle('Notifications', true)
  const { data, isSuccess, isLoading } = useFetchNotificationsQuery({}, {refetchOnMountOrArgChange: true})
  const [markAllAsRead,] = useMarkAllNotificationAsSeenMutation()
  const groupedByDate = data?.payload && groupByDay<Notification>(data?.payload, 'created_at')

  return (
    <PageWrapper>
      <MobileHeader />
      <MarkAsSeen markAllAsRead={() => markAllAsRead({})} />
      {isLoading && <NotificationSkeleton />}
      {(isSuccess && !data?.payload?.length) && (
        <Text mt={2} color={colors.danger} center weight="semibold">You have no notifications.</Text>
      )}
      {!!groupedByDate && Object.keys(groupedByDate).map((date, i) => (
        <Box key={i} pt={1}>
          <Text ml={2} mb={1} weight="semibold" capitalize>
            {unslugify(date)}
          </Text>
          {groupedByDate[date].map((n: any, i: any) => <DefaultNotificationView key={i} notification={n} />)}
        </Box>
      ))}
    </PageWrapper>
  )
}

export default Notifications