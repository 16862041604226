import React, { FunctionComponent } from 'react'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSelector } from 'react-redux'
import Logo from '../../assets/images/logo.png'
import { selectHeader, selectShowBackButton } from '../../stores/uiStore'
import TextWithIcon from '../TextWithIcon'
import { Link, useNavigate } from 'react-router-dom';
import Text from '../Text';
import { MobileHeaderWrapper, MobileHeaderContainer } from './MobileHeader.styles';
import { routes } from '../../utils/constants';

type MobileHeaderProps = {
  onNavigateBack?: () => void
} & React.HTMLAttributes<HTMLDivElement>

const MobileHeader: FunctionComponent<MobileHeaderProps> = React.memo(({ onNavigateBack, ...props }) => {
  const headerTitle = useSelector(selectHeader)
  const showBackButton = useSelector(selectShowBackButton)
  const navigate = useNavigate()

  return (
    <MobileHeaderWrapper {...props}>
      <MobileHeaderContainer sx={{display: "flex", flexDirection: "row"}}>
        {!showBackButton && (
          <Text 
            variant='semi-large' 
            weight="semibold" 
            mt={2} 
            mb={2} 
            truncate 
            maxWidth={255}
            left
          >
            {headerTitle}
          </Text>
        )}
        {showBackButton && (
          <TextWithIcon
            truncate
            onClick={() => onNavigateBack ? onNavigateBack() : navigate(-1)}
            icon={<LeftIcon fontSize="large" />}
            variant='semi-large'
            weight="semibold"
            mt={2}
            mb={2}
            left
            maxWidth={250}
          >
            {headerTitle}
          </TextWithIcon>
        )}
        <Link to={routes.DASHBOARD}>
          <img src={Logo} alt="gusiberi logo" height={38} />
        </Link>
      </MobileHeaderContainer>
    </MobileHeaderWrapper>
  )
})

export default MobileHeader