import React, { FunctionComponent } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import FriendsIcon from '@mui/icons-material/HowToRegRounded';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddFriendIcon from '@mui/icons-material/PersonAddAltRounded';
import RequestSentIcon from '@mui/icons-material/SendRounded';
import { colors } from '../../styles/_var'
import Text from '../../components/Text'
import { RequestSentLabel } from './FriendshipStatusView.styles';
import Button from '../../components/Button/Button'
import TextWithIcon from '../../components/TextWithIcon'
import { useFriends } from '../../pages/Dashboard/Friends/Friends.hooks';
import { FriendshipStatus } from '../../utils/types';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../stores/authStore';


interface FriendshipStatusViewProps extends BoxProps {
  friendshipStatus?: FriendshipStatus
  user: string,
  hideText?: boolean,
  fullWidth?: boolean
}


const FriendshipStatusView: FunctionComponent<FriendshipStatusViewProps> = ({
  friendshipStatus,
  user,
  hideText,
  fullWidth,
  ...rest
}) => {
  const { request_status, request_sent_by, request_id } = (friendshipStatus || {})
  const {
    sendFriendRequest,
    sendingFriendRequest,
    unfriend,
    unfriending,
    acceptFriendRequest,
    accepting
  } = useFriends()

  const currentUser = useSelector(selectCurrentUser)
  const requestSentByMe = request_sent_by?.username === currentUser.username
  const isFriend = request_status === 'accepted'
  const requestIsPending = request_status === 'pending'
  const awaitingResponse = !isFriend && requestIsPending && requestSentByMe

  if (currentUser.username === user) return null

  return (
    <Box mt={1} display="flex" flexDirection="column" alignItems="center" {...rest}>
      {isFriend && (
        <>
          {!hideText && <TextWithIcon icon={<FriendsIcon />}>
            Friends
          </TextWithIcon>}
          <Button
            fullWidth={fullWidth}
            loading={unfriending}
            variant="outlined"
            mt={1}
            startIcon={<RemoveIcon />}
            onClick={() => unfriend(user)}
          >
            <Text weight="semibold">Remove from friend's list</Text>
          </Button>
        </>
      )}
      {awaitingResponse && (
        <RequestSentLabel icon={<RequestSentIcon />}>
          Friend Request Pending
        </RequestSentLabel>
      )}
      {(!isFriend && !request_status) && (
        <Button
          loading={sendingFriendRequest}
          variant="contained"
          startIcon={<AddFriendIcon />}
          onClick={() => sendFriendRequest(user)}
        >
          <Text color={colors.white}>Add as Friend</Text>
        </Button>
      )}
      {(requestIsPending && !requestSentByMe && !!request_id) && (
        <Button
          mt={0.5}
          loading={accepting}
          variant="contained"
          startIcon={<AddFriendIcon />}
          onClick={() => acceptFriendRequest(request_id)}
        >
          <Text color={colors.white}>Accept Friend Request</Text>
        </Button>
      )}
    </Box>
  )
}

export default FriendshipStatusView