import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Text from '../../components/Text'
import { HeroImage, HeroSeparator, HeroWrapper } from './HomePage.styles'
import Grid from '@mui/material/Grid'
import heroSmallImage from '../../assets/images/hero-img-small.png'
import heroImage from '../../assets/images/hero-img.png'
import content from './content'
import { SystemProps } from '@mui/system'
import { useIsMobile } from '../../hooks/hooks'


const Hero = () => {
  return (
    <>
      <HeroWrapper>
        <Container>
          <HeroContent />
        </Container>
      </HeroWrapper>
      <HeroSeparator />
    </>
  )
}

const HeroContent = () => {
  const desktopGridProps: SystemProps = {}
  const isMobile = useIsMobile()

  if (!isMobile) {
    desktopGridProps.display = "flex"
    desktopGridProps.flexDirection = "column"
    desktopGridProps.justifyContent = "center"
  }

  return (
    <Box textAlign={!isMobile ? undefined : "center"}>
      <Grid container spacing={4}>
        <Grid 
          item
          sm={12}
          md={5}
          {...desktopGridProps}
        >
          <Text 
            left={!isMobile}
            variant={!isMobile ? "large" : "semi-large"}
            weight="bold"
            maxWidth={460}
            center={isMobile}
            mb={!isMobile ? 1 : 4}
          >
            {content.hero_title}
          </Text>
          {isMobile && <HeroImage src={heroSmallImage} />}
          <Text
            left={!isMobile}
            mt={!isMobile ? 3 : 2}
            mb={!isMobile ? 3 : 0}
            maxWidth={440}
            center={isMobile}
          >
            {content.hero_description}
          </Text>
        </Grid>
        {!isMobile && <Grid item md={7} textAlign="right">
          <HeroImage src={heroImage} />
        </Grid>}
      </Grid>
    </Box>
  )
}

export default Hero