import React from 'react'
import Text from '../../../../../components/Text'
import Box from '@mui/material/Box'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime } from 'luxon'
import Drawer from '../../../../../components/Drawer/Drawer'
import { DateRequest } from '../../../../../utils/types'
import validators from '../../../../../utils/validators';
import SimpleForm, { IActionButton } from '../../../../../components/SimpleForm';

type ScheduleAgainDrawerProps = {
  closeDrawer: () => void
  isOpen: boolean
  request: DateRequest
  onSubmit: (payload: {start_date: DateTime, end_date: DateTime}) => void
}
const getNewDefaultDates = (oldStartDate_: string, oldEndDate_: string) => {
  const now = DateTime.now()
  const oldStartDate = DateTime.fromISO(oldStartDate_)
  const oldEndDate = DateTime.fromISO(oldEndDate_)
  
  let newStartDate: DateTime, newEndDate: DateTime;

  if (now.diff(oldStartDate, 'days').days < 7) {
    newStartDate = oldStartDate.plus({days: 7})
    newEndDate = oldEndDate.plus({days: 7})
  } else {
    const daysToAdd = now.weekday < oldStartDate.weekday ? 0 : 7
    newStartDate = DateTime.now().plus({days: daysToAdd}).set({
      hour: oldStartDate.hour,
      minute: oldStartDate.minute,
      weekday: oldStartDate.weekday
    })

    newEndDate = DateTime.now().plus({days: daysToAdd}).set({
      hour: oldEndDate.hour,
      minute: oldEndDate.minute,
      weekday: oldEndDate.weekday
    })
  }

  return [newStartDate, newEndDate]
}

const ScheduleAgainDrawer = ({
  isOpen,
  request,
  closeDrawer,
  onSubmit
}: ScheduleAgainDrawerProps) => {
  const [defaultStartDate, defaultEndDate] = getNewDefaultDates(request.start_date, request.end_date)
  const formFields = [
    {
      name: 'start_date',
      label: 'New start time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%'},
      validate: validators.required
    },
    {
      name: 'end_date',
      label: 'New end time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%'},
      validate: validators.required
    },
  ]

  const actionButtons: IActionButton[] = [
    {
      label: 'Schedule Again',
      isSubmit: true,
      loading: false,
      variant: 'contained',
    },
    {
      label: 'Cancel',
      variant: 'outlined',
      onClick: closeDrawer
    }
  ]

  return (
    <Drawer
      disableEnforceFocus
      showCloseIcon
      anchor='bottom'
      open={isOpen}
      onOpen={() => {}}
      onClose={closeDrawer}
      header='Schedule this Again '
    >
      <Box m={2}>
        <Text center variant="medium" weight="semibold" mb={1}>
          Select new date
        </Text>
        <SimpleForm 
          formFields={formFields}
          actionButtons={actionButtons}
          submitForm={onSubmit} 
          initialValues={{
            start_date: defaultStartDate,
            end_date: defaultEndDate
          }}
        />
      </Box>
    </Drawer>
  )
}

export default ScheduleAgainDrawer
