import React, { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import Paper, { PaperProps } from '@mui/material/Paper'
import TimeIcon from '@mui/icons-material/AccessTimeRounded';
import ChatIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import Skeleton from '@mui/material/Skeleton';
import { animateComponent } from '../../../../utils/hoc'
import { spacing } from '../../../../styles/_var'
import Text from '../../../../components/Text'
import TextWithIcon from '../../../../components/TextWithIcon'
import { useFetchSupportIssuesQuery } from '../../../../services/api';
import { SupportMessage } from '../../../../utils/types';
import { humanize } from '../../../../utils/helpers';
import { ArrowBackIosRounded } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import ConversationList from './ConversationList';

type IssueViewProps = {
  issue: SupportMessage,
  backToIssues: () => void
}

interface PendingIssueProps extends PaperProps {
  issue: SupportMessage
}

const skeletonStyle = { borderRadius: '5px', height: '8rem' }

const PendingIssue: FunctionComponent<PendingIssueProps> = React.memo(({
  issue: { subject, handled_by, updated_at, num_of_messages },
  ...rest
}) => {

  return (
    <Paper sx={{padding: spacing.xs, marginBottom: spacing.sm, cursor: 'pointer'}} {...rest}>
      <Text weight="semibold" variant="medium">{subject}</Text>
      <Box display="flex">
        <TextWithIcon mr={2} icon={<TimeIcon />} variant="subtext">
          {humanize(updated_at)}
        </TextWithIcon>
        <TextWithIcon mr={2} icon={<ChatIcon />}>{num_of_messages}</TextWithIcon>
        {!!handled_by && <TextWithIcon icon={<PersonIcon />}>{handled_by}</TextWithIcon>}
      </Box>
    </Paper>
  )
})

const PendingIssues = React.memo(() => {
  const [selectedIssue, setSelectedIssue] = useState<null | SupportMessage>(null)
  const { data, isLoading } = useFetchSupportIssuesQuery({})
  const backToIssues = () => setSelectedIssue(null)
  
  const renderIssues = (issue: SupportMessage, index: number) => {
    return <PendingIssue onClick={() => setSelectedIssue(issue)} key={index} issue={issue} />
  }

  return (
    <Box mt={3}>
      {isLoading && <Skeleton variant="rectangular" sx={skeletonStyle} />}
      {(!isLoading && !selectedIssue) && data?.payload?.map(renderIssues)}
      {(!isLoading && !!selectedIssue) && <IssueView issue={selectedIssue} backToIssues={backToIssues} />}
    </Box>
  )
})

const IssueView: FunctionComponent<IssueViewProps> = animateComponent<IssueViewProps>(React.memo(({
  issue,
  backToIssues
}) => {
  return (
    <Box mt={3}>
      <TextWithIcon mb={3} icon={<ArrowBackIosRounded />} onClick={backToIssues}>
        Back
      </TextWithIcon>
      <Text variant="semi-large" weight="semibold" mb={2}>{issue.subject}</Text>
      <Text mb={3}>{issue.message}</Text>
      <Divider sx={{marginBottom: spacing.sm}} />
      <ConversationList issue={issue} />
    </Box>
  )
}), true)


export default animateComponent(PendingIssues, true)