import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { 
  useLazyCurrentUserQuery,
  useLazyDateScheduleQuery,
  useLazyFetchUpcomingDatesQuery,
  useLazyPreferredDatingActivityQuery,
} from "../../../services/api"
import { useAuthStore } from "../../../stores/authStore"
import { setAvailability } from "../../../stores/availabilityStore"
import { useUiStore } from "../../../stores/uiStore"
import { useProcessError } from "../../../hooks/hooks"
import content from "../content"
import Auth from "../../../utils/auth"
import { routes } from "../../../utils/constants"
import { useNavigate } from "react-router-dom"
import { User } from "../../../utils/types"


export const useFetchDashboardData = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const [dashboardLoaded, setDashboardLoaded] = useState(false)

  const processError = useProcessError()
  const { 
    currentUser, 
    setUser, 
    setNotificationSettings, 
    setUserActivityTimestamps 
  } = useAuthStore()
  const { openLoadingScreen, closeLoadingScreen } = useUiStore()
  const [loadDashboard, setLoadDashboard] = useState(false)
  const [fetchUserDetails,] = useLazyCurrentUserQuery()
  const [fetchDateSchedules, ] = useLazyDateScheduleQuery()
  const [fetchPrefferedDatingActivity, ] = useLazyPreferredDatingActivityQuery()
  const [fetchUpcomingDates, ] = useLazyFetchUpcomingDatesQuery()

  const fetchDashboardData = useCallback(async () => {
    const [dateScheduleResponse] = await Promise.all([
      fetchDateSchedules({}),
      fetchPrefferedDatingActivity({}),
      fetchUpcomingDates({})
    ])

    const { 
      data: scheduleData, 
      error: scheduleError, 
      isSuccess: scheduleSuccess 
    } = dateScheduleResponse
    processError(scheduleError)

    if (scheduleSuccess) {
      dispatch(setAvailability(scheduleData))
    }
  }, [
    fetchDateSchedules,
    fetchPrefferedDatingActivity,
    fetchUpcomingDates,
    dispatch,
    processError
  ])

  const redirectToAuthIfNotLoggedIn = () => {
    if (Auth.isLoggedIn()) return false 
    window.location.href = routes.AUTH

    return true
  }

  const redirectIfUserNotActivated = (loggedInUser: User) => {
    if (loggedInUser.activated === false) navigate(routes.ACTIVATE)
  }

  const prepareDashboard = async () => {
    const redirecting = redirectToAuthIfNotLoggedIn()
    if (redirecting) return
    openLoadingScreen(content.preparing_dashboard)

    const userDetailsResponse = await fetchUserDetails({})
    const { data, error, isSuccess } = userDetailsResponse
    
    processError(error)

    if (isSuccess && !!data?.payload) {
      if (data?.payload.user?.activated) {
        await fetchDashboardData()
        setLoadDashboard(isSuccess)
        setUser(data.payload?.user)
        setNotificationSettings(data.payload?.notification_settings)
        setUserActivityTimestamps(data.payload?.activities)
      }
    }
    redirectIfUserNotActivated(data?.payload.user || currentUser)
    closeLoadingScreen()
    setDashboardLoaded(true)
  }

  return { fetchDashboardData, loadDashboard, prepareDashboard, dashboardLoaded }
}

