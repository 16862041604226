import { IActionButton } from '../../../../components/SimpleForm';
import { showToastMessage, useUiStore } from '../../../../stores/uiStore';
import { selectCurrentUser, setCurrentUser, useAuthStore } from '../../../../stores/authStore';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Input from '../../../../components/Input';
import validators from '../../../../utils/validators';
import { useSetUserProfileInfoMutation } from "../../../../services/api";
import { useDispatch, useSelector } from 'react-redux';
import { useProcessError } from '../../../../hooks/hooks';
import { useState } from 'react';
import ImageCropper from '../../../../utils/imageCropper';
import { uploadToS3 } from '../../../../utils/helpers';
import { AVATARS_FOLDER, AWS_BASE_URL } from '../../../../utils/constants';
import PhoneNoInput from '../../../../components/PhoneNoInput';

const THUMBNAIL_LIMIT = 150

export const useProfileEdit = (gotoView?: () => void) => {
  const { showConfirmDialog } = useUiStore()
  const { logUserOut } = useAuthStore()
  const dispatch = useDispatch()
  const processError = useProcessError()
  const currentUser = useSelector(selectCurrentUser)
  const [ profileImage, setProfileImage ] = useState(currentUser.avatar || '')
  const [thumbnailImage, setThumbnail] = useState('')

  const [setUserProfileInfo, {isLoading}] = useSetUserProfileInfoMutation()
  
  const formFields = [
    {
      name: 'bio',
      label: 'Bio',
      component: Input,
      fullWidth: true,
      multiline: true
    },
    {
      name: 'full_name',
      label: 'Full Name',
      component: Input,
      max_length: 80,
      fullWidth: true,
      validate: validators.required
    },
    {
      name: 'username',
      label: 'Username',
      component: Input,
      max_length: 80,
      fullWidth: true,
      validate: validators.required
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      component: PhoneNoInput,
      max_length: 80,
      fullWidth: true,
      validate: validators.required
    },
    {
      name: 'city',
      label: 'City',
      component: Input,
      max_length: 80,
      fullWidth: true,
    },
    {
      name: 'twitter',
      label: 'Twitter Username',
      component: Input,
      max_length: 80,
      fullWidth: true,
    },
    {
      name: 'instagram',
      label: 'Instagram User',
      component: Input,
      max_length: 80,
      fullWidth: true,
    },
    {
      name: 'linkedin',
      label: 'LinkedIn',
      component: Input,
      max_length: 80,
      fullWidth: true,
    },
  ]

  const actionButtons: IActionButton = {
    label: 'Save Changes',
    isSubmit: true,
    loading: isLoading,
    variant: 'contained',
    fullWidth: true
  }

  const submitFormConfirmation = (data: any) => {
    if (currentUser.username !== data.username) {
      showConfirmDialog({
        dialogTitle: 'Do you want to proceed?',
        dialogBody: 'You would be required to login again if you change your username.',
        onConfirmAction: async () => {
          await submitForm(data)
          logUserOut()
        },
      })
    } else {
      submitForm(data)
    }
  }

  const submitForm = async (data: any) => {
    const profileImageHasChanged = profileImage && (profileImage !== currentUser.avatar)

    if (profileImageHasChanged) {
      const filename = `${AVATARS_FOLDER}/${currentUser.username}-${Date.now()}-avatar-small.jpg`
      await Promise.all([
        uploadToS3({ fileUrl: profileImage, filename: filename.replace('-small.', '.') }),
        uploadToS3({ fileUrl: thumbnailImage, filename })
      ])
      data.avatar = `${AWS_BASE_URL}/${filename}`
    }

    try {
      const response = await setUserProfileInfo(data).unwrap()
      dispatch(showToastMessage({message: response.message, severity: 'success'}))
      gotoView && gotoView()
      const newUser = {...currentUser, ...data}
      if (profileImageHasChanged) newUser.avatar = thumbnailImage
      dispatch(setCurrentUser(newUser))
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  const onAvatarSelected = async (image: string) => {
    try {
      setProfileImage(image)
      const createdImage = await ImageCropper.createImage(image)

      if (createdImage.naturalHeight <= THUMBNAIL_LIMIT && createdImage.naturalWidth <= THUMBNAIL_LIMIT) {
        setThumbnail(thumbnailImage)
      } else {
        const thumbnail = await ImageCropper.resizeImage(createdImage, 250)
        if (thumbnail) setThumbnail(URL.createObjectURL(thumbnail))
      }
    } catch (e) {
      console.log(e)
    }
  }

  return { 
    formFields, 
    actionButtons, 
    currentUser, 
    submitForm, 
    submitFormConfirmation,
    profileImage,
    onAvatarSelected 
  }
}

