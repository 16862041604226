import { store } from "../store"
import gusiberiApi from "../services/api"
import { MessageType, PublicUser } from "./types"

type NewMessagePayload = MessageType & {
  request_id: string,
  user: PublicUser
}

type NewMessageData = {
  type: string,
  payload: NewMessagePayload
}

const websocketHandler = ({ data }: any) => {
  const { type, payload } = JSON.parse(data) as NewMessageData
  const handler = handlerMap[type]
  handler(payload as NewMessagePayload)
}

const newChatMessageHandler = (payload: NewMessagePayload) => {
  const state = store.getState()
  if (state.auth?.currentUser?.username === payload?.user?.username) return

  const { request_id, ...chatMessage } = payload
  const chatMessageContainer = document.getElementById('chat-message-container')
  store.dispatch(
    gusiberiApi.util.updateQueryData(
      "fetchChatMessages",
      { request_id },
      draftMessages => {
        // hack
        // const alreadyExists = draftMessages.payload.map(m => m.id).includes(chatMessage.id)
        // draftMessages.payload = alreadyExists ? draftMessages.payload : [chatMessage, ...draftMessages.payload]
        draftMessages.payload = [chatMessage, ...draftMessages.payload]
      }
    ) as any
  )
  store.dispatch(
    gusiberiApi.util.updateQueryData(
      "fetchUpcomingDates",
      {},
      draftDates => {
        draftDates.payload = draftDates.payload.map(date => {
          if (date.request_id === request_id) {
            date.last_sent_message = chatMessage
          }
          return date
        })
      }
    ) as any
  )

  if (!chatMessageContainer) return
  setTimeout(() => {
    chatMessageContainer.scrollTo(0, chatMessageContainer.scrollHeight)
  }, 1000)
}

export const dataTypes = {
  NEW_CHAT_MESSAGE: 'new_chat_message',
}

export const handlerMap: any = {
  [dataTypes.NEW_CHAT_MESSAGE]: newChatMessageHandler,
}

export default websocketHandler