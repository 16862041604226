import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Text from '../../../../../components/Text'
import { DateRequest, User } from '../../../../../utils/types'
import { DateExpense, DateExpensePayment } from '../../../../../services/api'
import { AllExpensesSkeleton } from './BuckBreaker'
import { getPIDOfUser, getUserByPID, toCurrency } from '../../../../../utils/helpers'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import { colors } from '../../../../../styles/_var'
import Button from '../../../../../components/Button/Button';
import { useAuthStore } from '../../../../../stores/authStore';
import { useUiStore } from '../../../../../stores/uiStore';
import ConfirmPayment from './ConfirmPayment';
import { ImagePreviewModal } from '../DateMemories/DateMedia';

type CommonProps = {
  expenses: DateExpense[]
  dateInfo: DateRequest
}

type CostBreakdownProps = {
  expensePayments: DateExpensePayment[],
  fetchingExpensePayments: boolean,
  panelOpen?: number | null
  onPanelSelected: (panelId: number) => void
} & CommonProps

type ParticipantBreakdownProps = {
  expensePayment: DateExpensePayment
  participantUser?: User | null
  panelOpen: boolean
  onPanelSelected: () => void
  isOwnedByCurrentUser: boolean
} & CommonProps

const ParticipantBreakdown = ({ 
  expensePayment,
  participantUser,
  panelOpen,
  onPanelSelected,
  expenses,
  isOwnedByCurrentUser,
  dateInfo
}: ParticipantBreakdownProps) => {
  const { showDialog } = useUiStore()
  if (!participantUser) return null

  const pending = expensePayment.payment_status === 'pending'
  const paid = expensePayment.payment_status === 'paid'

  const expensesForYou = expenses
    .filter(e => e.debtors.includes(expensePayment.date_participant_id) 
      && (e.date_participant_id !== getPIDOfUser(participantUser, dateInfo.date_participants)))

  const paymentPrompt = expensesForYou.map(e => {
    const name = getUserByPID(e.date_participant_id, dateInfo.date_participants)?.full_name.split(" ")?.[0]
    const amountOwed = e.debtors.length === 0 ? 0 : e.amount/e.debtors.length
    return `Pay ${name} ${toCurrency(amountOwed)}`
  })

  const openPaymentConfirmationModal = () => {
    showDialog({
      dialogBody: <ConfirmPayment id={expensePayment.id} request_id={dateInfo.request_id} />
    })
  }

  const openPreview = () => {
    showDialog({
      hideModalWrapper: true,
      dialogBody: <ImagePreviewModal image={expensePayment.receipt || ''} />
    })
  }

  return (
    <>
      <MenuItem sx={{padding: 0}} onClick={onPanelSelected}>
        <Box py={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <UserInfo 
              user={participantUser} 
              onlyShowName 
              useSmallAvatar 
              titleTextVariant='regular'  
            />
            {(expensePayment.amount_owed !== undefined) && (
              <Text 
                weight="semibold"
                variant='medium' 
                color={expensePayment.payment_status === 'pending' ? colors.danger : colors.green}
              >
                {toCurrency(expensePayment.amount_owed)}
              </Text>
            )}
          </Box>
        </Box>
      </MenuItem>
      {expensePayment.amount_owed > 0 && (
        <Collapse in={panelOpen}>
          <Box ml={5}>
            {panelOpen && <Divider />}
            {expensesForYou.map((e, i) => (
              <Box 
                mt={1} 
                mb={i === (expensesForYou.length - 1) ? 1 : undefined} 
                key={i} 
                display="flex" 
                justifyContent="space-between"
              >
                <Text>{e.description}</Text>
                <Text>{toCurrency(e.amount/e.debtors.length)}</Text>
              </Box>
            ))}
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {(isOwnedByCurrentUser && pending) ? (
                <Button 
                  variant='contained' 
                  style={{paddingTop: 1, paddingBottom: 1, background: colors.secondary}}
                  onClick={openPaymentConfirmationModal}
                >
                  <Text useDiv variant='small' color={colors.white} weight='bold'>
                    I've paid
                  </Text>
                </Button>
                ) : (paid && !!expensePayment.receipt) ? null : <div />
              }
              {(paid && !!expensePayment.receipt) && (
                <Text 
                  link 
                  variant="small" 
                  color={colors.link} 
                  weight="semibold" 
                  underline
                  onClick={openPreview}
                >
                  view receipt
                </Text>
              )}
              <Box py={1}>
                {paymentPrompt.map((p, i) => (
                  <Text variant="small" weight='semibold' right key={i}>
                    {p}
                  </Text>
                ))}
              </Box>
            </Box>
          </Box>
        </Collapse>
      )}
      <div />
      <Divider sx={{marginTop: 0, marginBottom: 0}} />
    </>
  )
}

const CostBreakdown = ({ 
  dateInfo, 
  expensePayments,
  fetchingExpensePayments,
  panelOpen,
  onPanelSelected,
  ...rest
}: CostBreakdownProps) => {
  const { currentUser } = useAuthStore()
  const hasExpensePayments = !!expensePayments.length

  
  return (
    <Box mt={2}>
      <Text weight='semibold' mb={1}>Breakdown</Text>
      {(!hasExpensePayments && fetchingExpensePayments) && Array(3).fill(1).map((_, i) => (
          <AllExpensesSkeleton key={i} />
        )
      )}
      {expensePayments.map((e, i) => (
        <ParticipantBreakdown 
          {...rest}
          dateInfo={dateInfo}
          isOwnedByCurrentUser={currentUser.username === getUserByPID(e.date_participant_id, dateInfo.date_participants)?.username}
          onPanelSelected={() => onPanelSelected(i)}
          panelOpen={panelOpen === i}
          key={e.id} 
          expensePayment={e} 
          participantUser={getUserByPID(e.date_participant_id, dateInfo.date_participants)}
        />
      ))}
    </Box>
  )
}

export default CostBreakdown