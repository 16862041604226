import InputLabel from "@mui/material/InputLabel"
import styled from "styled-components"
import { colors, fontSize, spacing } from "../../styles/_var"
import Input from "../Input"
import Select from "../Select"


export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
`

export const StyledInput = styled(Input)`
  & .MuiInputBase-input {
    padding-bottom: 7px;
  }
  
  margin-left: ${spacing.xxs};
  margin-top: 0;
`

export const StyledSelect = styled(Select)`
  & .MuiSelect-select.MuiSelect-select {
    font-size: ${fontSize.xs};
  }
`

export const StyledLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    background: ${colors.white};
    position: absolute;
    z-index: 3;
    top: -7px;
    left: 7px;
    padding: 0 4px;
  }
`
