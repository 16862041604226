import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import CalendarTodayRounded from '@mui/icons-material/CalendarTodayRounded';
import Text from '../../../components/Text'
import { colors } from '../../../styles/_var';
import { useFetchPastDatesQuery, useFetchUpcomingDatesQuery } from '../../../services/api';
import { useProcessError } from '../../../hooks/hooks';
import content from '../content';
import { DateCardList } from '../../../components/DateCard/DateCard';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../stores/authStore';
import CardSlider from '../../../components/CardSlider/CardSlider';

export const skeletonStyle = {
  borderRadius: '5px',
  width: '100%',
  maxWidth: '28rem',
  height: '16.6rem',
  border: `dashed 1px ${colors.darkgrey}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const NoUpcomingDates = React.memo(({ text }: {text?: string}) => {
  return (
    <Box>
      <Paper elevation={3} sx={skeletonStyle} style={{marginRight: 'auto', marginLeft: 'auto'}}>
        <CalendarTodayRounded fontSize='large' />
        <Text mr={2} ml={2}>{text || content.no_dates}</Text>
      </Paper>
    </Box>
  )
})

type UpcomingDatesProps = {
  groupByDate?: boolean,
  useSlider?: boolean,
  showPlaceholderWhenEmpty?: boolean
}

export const UpcomingDates = ({
  groupByDate, 
  useSlider, 
  showPlaceholderWhenEmpty=true
}: UpcomingDatesProps) => {
  const processError = useProcessError()
  const {data, error, isLoading} = useFetchUpcomingDatesQuery({})
  const currentUser = useSelector(selectCurrentUser)
  const hasPayload = !!data?.payload.length 

  useEffect(() => {processError(error)}, [error, processError])

  if (!showPlaceholderWhenEmpty && !hasPayload) return null

  return (
    <Box width="100%">
      {isLoading ? 
        <Skeleton variant="rectangular" sx={skeletonStyle} /> : 
          (hasPayload ? (useSlider 
            ? <CardSlider data={data.payload} title="Upcoming" showCount bgColor={colors.lightGreen} /> 
            : <DateCardList 
                groupByDate={groupByDate}
                currentUser={currentUser}
                dateList={data.payload} 
              />
          ) : <NoUpcomingDates />
      )}
    </Box>
  )
}

export const PastDates = ({groupByDate}: {groupByDate?: boolean}) => {
  const processError = useProcessError()
  const currentUser = useSelector(selectCurrentUser)
  const {data, error, isLoading} = useFetchPastDatesQuery({})

  useEffect(() => {processError(error)}, [error, processError])
  return (
    <Box width="100%">
      {isLoading ? <Skeleton variant="rectangular" sx={skeletonStyle} /> : (
        !!data?.payload.length ? (
          <DateCardList 
            groupByDate={groupByDate}
            bgColor={colors.lightGreen}
            currentUser={currentUser}
            dateList={data.payload} /> 
        ) : <NoUpcomingDates text="You have no past invites" />
      )}
    </Box>
  )
}

export default UpcomingDates
