const content = {
  signup: 'Sign Up',
  hero_title: 'Need help planning and scheduling the perfect activity or date?',
  hero_description: 'With Gusiberi you can easily plan, schedule & manage the perfect date or group activity with lovers, friends and family.',
  about_title: 'We are all about bringing people together',
  about_description: 'Want to organize games night with your colleagues or would you like to invite your friends to your birthday party? Let Gusiberi help you today',
  easy_setup: 'Easy Set-up',
  easy_setup_description: 'Getting started is super easy, you sign up and set up your profile in less than 10 minutes.',
  shareable_links: 'Shareable Links',
  shareable_links_description: 'Easily share invitation links to a date or an activity with friends, family or lovers.',
  safe: 'Safe and secure',
  safe_description: 'You can easily create an activity or a date and share it securely with friends, lovers or family.',
  how_it_works_title: 'How it works',
  how_it_works_description: 'Gusiberi is very easy and simple to use, get started with the following simples steps.',
  step_1_title: 'Sign Up & Create your profile',
  step_1_description: 'Sign up easily with your email address or your google login to get started and then create your profile.',
  step_2_title: 'Create your dating schedule',
  step_2_description: 'Create a schedule (recurring or one-off) to avoid conflicts and to prevent people for planning dates with you at the wrong time.',
  step_3_title: 'Preferred dating activities',
  step_3_description: 'Add your preferred dating activities so people know what kind of things you are into and can plan activities accordingly.',
  step_4_title: 'Share your profile',
  step_4_description: 'Share your profile with friend, family and even romantic interests from dating apps and have them schedule and plan the perfect date or activity with you.',
  step_5_title: 'Send date requests',
  step_5_description: 'Conveniently plan the ideal date or group activity with friends, families and lovers. You can also get invite updates and so much more.',
  step_6_title: 'And so much more',
  step_6_description: 'You can manage your upcoming dates, review dates that already happened, add people to your friends list to easily schedule dates with them & more.',
  coming_soon_text: 'Experience a modern and intuitive mobile experience.',
  coming_soon_desc: 'We are also working hard to ensure that along with the web app we bring you a mobile app for android and ios. Be one of the first to get notified when we launch. Join the waitlist.',
  coming_soon: 'Coming Soon',
  email_placeholder: 'Enter your email address',
  submit: 'Submit'
}

export default content