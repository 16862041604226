import React from 'react'
import Box from '@mui/material/Box'
import RightArrowIcon from '@mui/icons-material/KeyboardArrowRight'
import Text from '../../../components/Text'
import MapIcon from '@mui/icons-material/MapOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeRounded';
import Grid from '@mui/material/Grid'
import content from '../content'
import { colors, spacing } from '../../../styles/_var'
import { DashboardCard } from './Home.styles'
import ButtonBase from '@mui/material/ButtonBase';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/constants';
import { useIsMobile } from '../../../hooks/hooks';


type ICompleteAccountSetup = {
  hasFavouriteActivities?: boolean,
  hasSchedules?: boolean
}

const CompleteAccountSetup: React.FC<ICompleteAccountSetup> = React.memo(({
  hasFavouriteActivities,
  hasSchedules
}) => {
  const isMobile = useIsMobile()
  if (hasFavouriteActivities && hasSchedules) return null

  return (
    <Box mt={2}>
      <Text mb={3}>{content.complete_account_setup}</Text>
      <Grid container spacing={2}>
        {!hasSchedules && (
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Link to={routes.AVAILABILITY}>
                <Box padding={spacing.xxs} bgcolor={colors.pink} className='jr-availability'>
                  <MapIcon fontSize="large" />
                  <Text mt={1} mb={2} truncate={!isMobile}>{content.setup_availability}</Text>
                  <ButtonBase>
                    <Text variant='subtext' weight="semibold">Go to availability</Text>
                    <RightArrowIcon />
                  </ButtonBase>
                </Box>
              </Link>
            </DashboardCard>
          </Grid>
        )}
        {!hasFavouriteActivities && (
          <Grid item xs={6}>
            <DashboardCard elevation={2}>
              <Link to={routes.ACCOUNT_ACTIVITIES}>
                <Box padding={spacing.xxs} bgcolor={colors.lightGreen} className='jr-preferred-activities'>
                  <TimeIcon fontSize="large" />
                  <Text mt={1} mb={2} truncate={!isMobile}>{content.add_preferred_activities}</Text>
                  <ButtonBase>
                    <Text variant='subtext' weight="semibold">Go to settings</Text>
                    <RightArrowIcon />
                  </ButtonBase>
                </Box>
              </Link>
            </DashboardCard>
          </Grid>
        )}
      </Grid>
    </Box>
  )
})

export default CompleteAccountSetup