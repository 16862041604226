import React, { FunctionComponent } from 'react'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Text from '../../../components/Text';
import { DateRequestInfoWrapper } from './DateRequests.styles'
import { DateRequest } from '../../../utils/types';
import { colors, spacing } from '../../../styles/_var'
import DateRequestClass from '../../../services/DateRequestClass';
import TextWithIcon from '../../../components/TextWithIcon';
import { useAuthStore } from '../../../stores/authStore';
import LocationText from '../../../components/LocationText/LocationText';
import TimeIcon from '@mui/icons-material/AccessTimeRounded';
import DateRequestTabs from './components/DateRequestTabs';


interface DateRequestInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  dateInfo: DateRequest
}

export const LoadingSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={200} sx={{borderRadius: '8px', marginBottom: spacing.sm}} />
      <Skeleton variant="rectangular" height={200} sx={{borderRadius: '8px'}} />
    </Box>
  )
}

const DateRequestInfo: FunctionComponent<DateRequestInfoProps> = ({
  dateInfo,
  ...rest
}) => {
  const { currentUser } = useAuthStore()
  const dateObject = new DateRequestClass(dateInfo, currentUser)

  return (
    <DateRequestInfoWrapper {...rest}>
      <Container>
        <Paper sx={{marginBottom: 3}}>
          <Box p={2}>
            <Text useDiv inline variant="medium" weight="bold" mb={1}>
              {dateInfo.activity}.
            </Text>
            <LocationText 
              textProps={{
                weight: "semibold",
                color: colors.darkgrey,
                truncate: true,
                mb: 1
              }} 
              location={dateInfo.location} 
            />
            <TextWithIcon mb={1} icon={<TimeIcon />} weight="semibold">
              {dateObject.fullDateTime}
            </TextWithIcon>
            {!!dateInfo.description && <Text italic>{dateInfo.description}</Text>}
          </Box>
        </Paper>
      </Container>
      <DateRequestTabs dateInfo={dateInfo} />
    </DateRequestInfoWrapper>
  )
}

export default DateRequestInfo
