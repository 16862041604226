import React, { useState, useRef } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import { DateRequest } from '../../utils/types'
import Text from '../Text'
import { CardWrapper, NextButton, PrevButton } from './CardSlider.styles'
import { debounce } from '../../utils/helpers'
import { useAuthStore } from '../../stores/authStore'
import DateCard from '../DateCard/DateCard'
import { spacing } from '../../styles/_var'


type CardSliderProps = {
  data: DateRequest[]
  title?: string,
  showCount?: boolean
  bgColor?: string
} & BoxProps

const CardSlider = ({
  data,
  title,
  showCount,
  bgColor,
  ...boxProps
}: CardSliderProps) => {
  const { currentUser } = useAuthStore()
  const [prev, setPrev] = useState(false)
  const [next, setNext] = useState(true)
  const ref = useRef<HTMLDivElement>(null)
  
  if (!data?.length) return null

  const showNext = next && data.length > 1
  const showPrev = prev && data.length > 1

  const onHorizontalScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollLeft = 0, scrollWidth = 0, clientWidth = 0 } = ref.current || {}
  
    setPrev(scrollLeft !== 0)
    setNext(!(Math.abs(scrollLeft) === (scrollWidth - clientWidth)))
  }

  return (
    <Box {...boxProps}>
      {!!title && (
        <Text 
          weight="bold" 
          mb={2}
        >
          {title} {showCount && `(${data.length})`}
        </Text>
      )}
      <Box position="relative">
        {showNext && <NextButton onClick={() => {
          ref.current?.scrollBy({ left: 300, behavior: 'smooth'})
        }}>
          <RightIcon fontSize="large" />
        </NextButton>}

        {showPrev && <PrevButton onClick={() => {
          ref.current?.scrollBy({ left: -300, behavior: 'smooth'})
        }}>
          <LeftIcon fontSize="large" />
        </PrevButton>}
        {data.length === 1 ? (
          <DateCard 
            bgColor={bgColor}
            currentUser={currentUser}
            key={data[0].request_id} date={data[0]} 
            style={{maxWidth: 400}}
          />
        ) : (
          <CardWrapper ref={ref} onScroll={debounce(onHorizontalScroll, 100)}>
            {data.map((dateInfo, i) => (
              <DateCard 
                bgColor={bgColor}
                style={{ 
                  marginRight: spacing.xs,
                  maxWidth: 300
                }}
                currentUser={currentUser}
                key={dateInfo.request_id} date={dateInfo} 
              />
            ))}
          </CardWrapper>
        )}
      </Box>
    </Box>
  )
}

export default CardSlider
