import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Badge from '@mui/material/Badge';
import Tabs from '@mui/material/Tabs';
import { Outlet, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Text from '../../../components/Text';
import { routes } from '../../../utils/constants';
import Box from '@mui/material/Box';
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { FriendsWrapper } from './Friends.styles';
import MobileHeader from '../../../components/MobileHeader/MobileHeader';
import { useFetchPendingFriendRequestsQuery } from '../../../services/api'
import { LinkTab } from '../AccountSettings/SettingsNav';
import { animateComponent } from '../../../utils/hoc';
import AddOrInviteFriendDrawer from './AddOrInviteFriendDrawer';

const AnimatedBody = animateComponent(({ openDrawer }: {openDrawer: () => void}) => {
  return (
    <Container>
      <Box mt={3} display="flex" alignItems="center" flexDirection="column">
        <Outlet context={{openDrawer}} />
      </Box>
    </Container>
  )
}, true)


const Friends = () => {
  useSetHeaderTitle('Friends')
  const [openDrawer, setOpenDrawer] = useState(false)
  const { data } = useFetchPendingFriendRequestsQuery({})
  const noOfPendingRequests = data?.payload.length || 0

  const tabs = [
    {
      label: (shouldBold: Boolean) => <Text weight={shouldBold ? "semibold" : "regular"} capitalize>All Friends</Text>,
      route: routes.ALL_FRIENDS
    },
    {
      label: (shouldBold: Boolean) => <Text useDiv weight={shouldBold ? "semibold" : "regular"} capitalize>
        Friends Request {!!noOfPendingRequests && <Badge sx={{marginLeft: 1.5}} badgeContent={noOfPendingRequests} color="primary" />}</Text>,
      route: routes.FRIEND_REQUESTS
    },
  ]

  const location = useLocation()
  const [tabValue, setTabsValue] = useState(tabs.findIndex(e => e.route === location.pathname))
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue)
  };
  

  return (
    <FriendsWrapper>
      <MobileHeader />
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        {tabs.map((tab, i) => (
          <LinkTab 
            key={i}
            href={tab.route} 
            label={tab.label(tabValue === i)} />
        ))}
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      <AnimatedBody openDrawer={() => setOpenDrawer(true)} />
      <AddOrInviteFriendDrawer 
        isOpen={openDrawer} 
        closeDrawer={() => setOpenDrawer(false)} 
      />
    </FriendsWrapper>
  )
}

export default Friends
