import React from 'react'
import Text from '../../../components/Text'
import content from '../content'
import Box from '@mui/material/Box'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Button from '../../../components/Button/Button'
import { AuthContent } from './Auth.styles'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../../utils/constants'
import { animateComponent } from '../../../utils/hoc'
import SimpleFooter from '../../../components/SimpleFooter/SimpleFooter'
import GoogleAuthButton from '../../../components/GoogleAuthButton'

type LoginButtonsProps = {
  onClickButtons?: () => void
}

export const LoginButtons = ({ onClickButtons }: LoginButtonsProps) => {
  const navigate = useNavigate()
  const gotoRegister = () => {
    onClickButtons?.()
    navigate(routes.REGISTER)
  }

  return (
    <>
      <Button 
        onClick={gotoRegister}
        style={{justifyContent: "space-between"}}
        variant="contained"
        fullWidth
        mb={2}
        endIcon={<KeyboardArrowRightIcon />}
      >
        <Text color='white'>{content.sign_up_with_email}</Text>
      </Button>
      <GoogleAuthButton 
        onClick={onClickButtons} 
        buttonText='Continue with Google' 
      />
      <Text center variant="subtext" mt={2}>
        {content.got_account} <strong><Link to={routes.LOGIN} onClick={onClickButtons}>{content.sign_in}</Link></strong>
      </Text>
    </>
  )
}


const Auth = () => {
  return (
    <AuthContent>
      <Text weight={400} mb={1}>
        {content.welcome_title}
      </Text>
      <Text>{content.intro}</Text>
      <Box mt={4}>
        <LoginButtons />
      </Box>
      <SimpleFooter />
    </AuthContent>
  )
}

export default animateComponent(Auth)