// import React from 'react'
import crypto from 'crypto-js';
// import axios, { AxiosResponse } from 'axios';
// import ReactGA from "react-ga4";
// import moment from 'moment';
// import Auth from './auth';
import { ComponentSpacing, DateRequest, Duration, ParticipantRequest, User } from './types';
// import { regexPatterns, CreditCardIcons, errorMessage, AWS_BASE_URL, routes } from './constants';
import { colors, spacing } from '../styles/_var';
import appContent from './app_content';
import { DateTime } from 'luxon';
// import { RequestStatus } from '../pages/Dashboard/DateRequests/components/RequestCard';
import { Participant, baseUrl } from '../services/api';
import Auth from './auth';
import ImageCropper from './imageCropper';
import { regexPatterns } from './constants';
import html2canvas from 'html2canvas';
// import { getPresignedUrlRequest } from './apiRequests';
// import { userStore } from '../stores/userStore';
// import { Location } from 'react-router-dom';


// declare global {
//   namespace NodeJS {
//     interface ProcessEnv {
//       REACT_APP_ENCRYPTION_KEY: string,
//       REACT_APP_IV: string,
//       REACT_GOOGLE_API_KEY: any,
//       REACT_APP_NODE_ENV: 'development' | 'staging' | 'production',
//       REACT_APP_GOOGLE_OAUTH_CLIENT_KEY: string,
//       REACT_APP_PAYSTACK_PUBLIC_KEY: string,
//       REACT_APP_APP_SYNC_REGION: string,
//       REACT_APP_APP_SYNC_AUTH_TYPE: string,
//       REACT_APP_APP_SYNC_API_KEY: string,
//       REACT_APP_APP_SYNC_ENDPOINT: string,
//       REACT_APP_DD_CLIENT_KEY: string,
//       REACT_APP_GA_TRACKING_CODE: string
//     }
//   }

//   interface Window {
//     DD_LOGS: any
//   }
// }

// interface IFetchPayload {
//   url: string,
//   method?: RequestType,
//   body?: any,
//   headerOptions?: any,
//   useToken?: boolean,
//   useBaseUrl?: boolean,
// }

interface IUploadToS3 {
  filename: string,
  fileUrl: string,
  presignedUrl?: any,
  imageElementsToRefresh?: any,
  failSilently?: boolean
}


export const parseInputOnlyCurrency = (value: string): string => {
  if (!value) return ''
  const latestCharacter = value.substring(value.length - 1, value.length)
  const previousString = value.substring(0, value.length - 1)

  if (latestCharacter === '.') {
    return !previousString.includes('.') && !!previousString ? value : previousString
  }

  if (/[^\d]/g.test(latestCharacter)) {
    return previousString
  }

  return value
}

export const parseToNumber = (value: string): number | string => {
  if (!value) return '';

  return Number(value.replace(/[^\d]/g, ''));
}

// export const formatToCurrency = (value: string): string => {
//   if (!value) return '';

//   let newValue = String(value);
//   newValue = newValue.replace(/[^\d]/g, '')

//   if (newValue.length < 4) return `N${newValue}`;

//   return toCurrency(Number(newValue), false, true);
// }

// export function beautifyTime(rawDate: string) {
//   return moment(rawDate).format('hh:mm a')
// }

// export function beautifyDate(rawDate: string | Date) {
//   return moment.utc(rawDate).local().format('Do of MMM, YYYY');
// }

// export function maskCardNumber(cardNumber: string): string {
//     return `**** **** **** ${cardNumber.substr(-4)}`;
// }

// export function toCurrency(value: number, showDecimal:boolean = true, showCurrency:boolean = true) {
//   let formattedCurrency = `${showCurrency ? "N" : ""}${Number(value === -1 ? 0 : value).toLocaleString()}${
//     showDecimal ? ".00" : ""
//     }`;

//   if (formattedCurrency.includes('-')) {
//     formattedCurrency = `-${formattedCurrency.replace('-', '')}`
//   }

//   return formattedCurrency
// }

export function capitalize(text: string): string {
  return text[0].toUpperCase() + text.slice(1);
}

export function camelCasize(text: string): string {
  if (!text.includes(' ')) return text.toLowerCase();
  const [firstWord, secondWord] = text.split(' ');

  return firstWord.toLowerCase() + capitalize(secondWord);
}

export function isEmail(value: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
}

export function isPhoneNo(phoneNumber: string): boolean {
  return regexPatterns.phone.test(phoneNumber)
}

// export function parseToPhone(rawPhone: string): string {
//   return rawPhone ? `+234${rawPhone.replace(/^(\+?234)?0?/, '')}` : ''
// }

// export function parseUsername(username: string): string {
//   if (isPhoneNo(username)) {
//     return parseToPhone(username)
//   }

//   return username
// }

export function isPassword(password: string): string | undefined {
  return (password || '').length > 5 ? undefined : 'Password is too short';
}

export const isSame = (password: string ) => (confirmPassword: string): string | undefined => {
  return password === confirmPassword ? undefined : 'Password does not match'
}

export function encrypt(text: string): string {
  const cipherText = crypto.AES.encrypt(text, process.env.REACT_APP_ENCRYPTION_KEY || '').toString()
  return cipherText
}  

export function decrypt(cipherText: string): string {
  const bytes = crypto.AES.decrypt(cipherText, process.env.REACT_APP_ENCRYPTION_KEY || '')
  return bytes.toString(crypto.enc.Utf8)
}

// export function fetch(fetchPayload: IFetchPayload): Promise<AxiosResponse<any>> {
//   const { 
//     url,
//     method="GET",
//     body,
//     headerOptions,
//     useBaseUrl=true,
//     useToken=true 
//   } = fetchPayload

//   const baseUrls: any = {
//     development: `http://${window.location.hostname}:8000/api`,
//     staging: process.env.REACT_APP_STAGING_API,
//     production: process.env.REACT_APP_PROD_API
//   }
//   if (useBaseUrl) {
//     axios.defaults.baseURL = baseUrls[process.env.REACT_APP_NODE_ENV]
//   } else {
//     axios.defaults.baseURL = ''
//   }

//   const generateRequestOptions = () => {
//       const userToken:string | null = Auth.fetchToken();
//       const options:RequestOptions = { url, method: method };

//       if (body) options.data = body;

//       if (userToken && useToken) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
//       } else {
//         delete axios.defaults.headers.common['Authorization'];
//       }

//       return { ...options, ...(headerOptions || {}) };
//   }
//   const requestOptions = generateRequestOptions();

//   return new Promise((resolve, reject) => {
//     axios
//       .request(requestOptions)
//       .then((response: any) => {
//         resolve(response);
//       })
//       .catch((error: any) => {
//         if (tokenExpired(error)) {
//           userStore.logout(() => window.location.href = routes.LOGIN)
//         }
//         reject(error)
//       });
//   })
// }

export const retrieveErrorMessage: any = (errorObject: any): string => {
  const serverResponse = errorObject?.data || {};
  const errors = Object.values(serverResponse)[0];

  if (Array.isArray(errors)) {
    return `${errors}`;
  }

  return serverResponse.message || serverResponse.detail || appContent.errorMessage;
}

// export const max = (number: number) => (value: string) => {
//   if (!value) return '';
//   if (value.length > number) return value.substring(0, number-1)
//   return value;
// }

// export const formatPhoneNumber = (rawPhone: string): string => {
//   let phone = rawPhone;

//   if (!phone || phone.trim() === '') return phone;

//   if (phone.length > 7) {
//     if (phone.startsWith('+234') || phone.startsWith('234') || phone.startsWith('+2340')) {
//       phone = phone.replace(/\+?2340?/, '');
//     }
  
//     if (phone.startsWith('0')) {
//       phone = phone.replace(/^0/, '');
//     }
//   }

//   return phone.replace(/[^\d]/g, '');
// }

// export const formatToNumber = (value: string): string => {
//   if (!value || value.trim() === '') return value;
//   return value.replace(/[^\d]/g, '');
// }

// export const formatToFloat = (value: string): string => {
//   if (!value || value.trim() === '') return value;
//   if (/^\d*\.?\d*$/.test(value)) {
//     return value
//   } else {
//     return value.substring(0, value.length - 1)
//   }
// }

// export const formatToPhone = (rawPhone: string): string => {
//   if (!rawPhone || rawPhone.trim() === '') return rawPhone;
//   return `+234${rawPhone.replace(/\+?2340?/, '')}`;
// }

// export var formatToUsername = (username: string) => {
//   if (username) return username.replace(/ /g,  '').toLowerCase()
//   return ''
// }

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
}

// export const getAddressComp = (addressObject: any, compName: string): string => {
//   return (addressObject.address_components
//     .find((component: any) => 
//       component.types.some((e: any) => e.includes(compName))
//     ) || { long_name: ''}).long_name
// }

// export const addPaystackCommission = (amount: number = 0): number => {
//   let commission = 0

//   if (amount > 2000) {
//     commission += 100 + (0.015 * amount)
//   } else {
//     commission += (0.015 * amount)
//   }

//   if (commission > 2000) commission = 2000

//   return Math.round(amount + commission)
// }

// export const getAppSettings = (): any => {
//   const settings = sessionStorage.getItem('app_settings');
//   return settings ? JSON.parse(decrypt(settings)) : null
// }

// export const padToLengthWithZero = (toLength:number, str?: string) => {
//   let res = `${str}`;
//   if (!res || res.length >= toLength) return str
//   const numberOfZeros = toLength - res.length
//   for (let i=0; i<numberOfZeros; i++) res = '0' + res

//   return res;
// }

// export const isMobileScreen = () => {
//   return window.screen.width <= Number(size.sm.replace('px', ''));
// }

// export const pick = (payload: any, fields: string[]) => {
//   const results: any = {}

//   fields.forEach(field => {
//     if (field in payload) results[field] = payload[field]
//   })
  
//   return results
// }

export const exclude = (payload: any, fieldsToExclude: string[]) => {
  const results: any = {}

  Object.keys(payload).forEach(field => {
    if (!fieldsToExclude.includes(field)) results[field] = payload[field]
  })

  return results
}


export const getPresignedUrlRequest = async (filename: string) => {
  const url = baseUrl + '/users/presigned_url'
  const response = await fetch(url, { 
    method: "POST", 
    headers: {
      "Authorization": `Bearer ${Auth.fetchToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({file_key: filename})
  })
  return await response.json()
}

export const getImageBlob = async (imageUrl: string) =>  {
  const response = await fetch(imageUrl, {
    mode: 'no-cors',
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const imageBlob = await response.blob()
  return imageBlob
}

export const postImageToS3 = async (imageBlob: Blob, presignedUrl: any) => {
  await fetch(presignedUrl, {
    method: 'PUT', 
    body: imageBlob,
    headers: {
      "Content-Type": imageBlob.type
    }
  });
}

export const uploadToS3 = async ({ filename, fileUrl, presignedUrl, failSilently }: IUploadToS3) => {
  let signedUrl = presignedUrl

  try {
    const imageElement = await ImageCropper.createImage(fileUrl)
    const imageBlob  = await ImageCropper.imageElementToBlob(imageElement)
    if (!signedUrl) {
      const response = await getPresignedUrlRequest(filename)
      signedUrl = response.payload
    }

    if (!imageBlob) throw Error("Image is invalid")
    postImageToS3(imageBlob, signedUrl)
  } catch (e) {
    if (!failSilently) {
      return new Error('Failed to upload image')
    } else {
      console.log('could not upload image')
    }
  }
}

// export const generateFileUploadPath = (filekey: string, folderPath: string) => {
//   let uploadPath = folderPath
//   uploadPath += `${filekey}_${+(new Date())}.jpg`

//   return [uploadPath, `${AWS_BASE_URL}/${uploadPath}`]
// }

// export const uploadImage = async ({ key, imageUrl, folderPath, async=true, imageElementsToRefresh }: IUploadImage) => {
//   if (!imageUrl) return

//   const [filename, s3Path] = generateFileUploadPath(key, folderPath)

//   if (async) {
//     uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
//   } else {
//     await uploadToS3({ filename, fileUrl: imageUrl, imageElementsToRefresh })
//   }

//   return s3Path
// }

// export const getPercent = (value: number, percent: number) => value * (percent/100)

// export const processError = (errorObject: any) => {
//   try {
//     console.error(retrieveErrorMessage(errorObject))
//   } catch {}
// }

export const toUTC = (value: Date | string) => {
  if (!value) return ''
  if (typeof value === 'string') {
    return new Date(value).toISOString()
  }

  return value.toISOString()
}

// export const appendQueryString = (url: string, payload: any) => {
//   let qs = ''

//   Object.keys(payload).forEach(key => {
//     qs += !qs && !url.includes('?') ? '?' : '&'
//     qs += `${key}=${payload[key]}`
//   })

//   return `${url}${qs}`
// }

// export const getFromQueryString = (queryString: string, field: string, defaultValue?: string) => {
//   const matcher = new RegExp(`${field}=([^&]*)`, 'i')
//   return queryString.match(matcher)?.[1] || defaultValue
// }

// export const wordify = (word: string) => word.replace(/_/g, ' ')

// export const mapToProps = (mappings: any, Container: any) => {
//   return (props: any) => {
//     return React.createElement(Container, {...props, ...mappings})
//   }
// }

export const plurify = (singular: string, plural: string, count: number) => {
  return `${count} ${count > 1 ? plural : singular}`
}

export const getCurrency = () => String.fromCharCode(8358)

export const setPageTitle = (title: string) => {
  let titleText = 'Gusiberi'

  if (title) {
    titleText += ` - ${title}`
  }

  document.title = titleText
}


export const getStyleSpacing = ({ 
  mt, 
  mb,
  mr, 
  ml,
  mx,
  my,
  m
}: ComponentSpacing): any => {
  const style: any = {}
  const spacingKeys = Object.keys(spacing)

  if (mt && mt < spacingKeys.length) {
    style.marginTop = mt === 0 ? mt : (spacing as any)[spacingKeys[mt - 1]]
  }

  if (mb && mb < spacingKeys.length) {
    style.marginBottom = mb === 0 ? mt : (spacing as any)[spacingKeys[mb - 1]]
  } 
  
  if (mr && mr < spacingKeys.length) {
    style.marginRight = mr === 0 ? mt : (spacing as any)[spacingKeys[mr - 1]]
  }
  
  if (ml && ml < spacingKeys.length) {
    style.marginLeft = ml === 0 ? ml : (spacing as any)[spacingKeys[ml - 1]]
  }

  if (mx && mx < spacingKeys.length) {
    style.marginLeft = mx === 0 ? mx : (spacing as any)[spacingKeys[mx - 1]]
    style.marginRight = mx === 0 ? mx : (spacing as any)[spacingKeys[mx - 1]]
  }

  if (my && my < spacingKeys.length) {
    style.marginTop = my === 0 ? my : (spacing as any)[spacingKeys[my - 1]]
    style.marginBottom = my === 0 ? my : (spacing as any)[spacingKeys[my - 1]]
  } 

  if (m && m < spacingKeys.length) {
    style.margin = m === 0 ? m : (spacing as any)[spacingKeys[m - 1]]
  }

  return style
}

export const makeid = (length: number = 5) => {
  let counter = 0;
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (; counter < length; counter++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// export const isObject = (obj: any) => {
//   return obj !== null && !Array.isArray(obj) && typeof obj === 'object'
// }

// export const getDuration = (date: Date | string) => {
//   const start = moment.now() 
//   const end = moment.utc(date).local()
//   return moment.duration(end.diff(start))
// }

// export const humanize = (date: Date | string, prefix: boolean=true) => {
//   return getDuration(date).humanize(prefix)
// }

// export const humanizeAbbr = (date: Date | string) => {
//   try {
//     const humanizedTime = humanize(date, true)
//     const [number, desc] = humanizedTime.split(' ')
//     if (Number.isNaN(+number)) return humanizedTime
//     return `${number}${desc[0]}`
//   } catch {
//     return ''
//   }
// }

export const getFilenameFromS3Url = (s3url?: string) => {
  if (!s3url) return null
  const regex = /amazonaws.com\/(.*)/i
  const match = s3url.match(regex)

  return match ? match[1] : match
}

export const isWebsocketDisconnectedError = (errorPayload: any) => {
  const errors = errorPayload?.error.errors
  if (!Array.isArray(errors)) return false

  return errors.some(e => e?.message.toLowerCase() === 'connection closed')
}

export const tokenExpired = (error: any) => {
  const response = error?.response
  return response?.data?.detail === 'Signature has expired.' && response?.status === 403
}

export var isDev = process.env.REACT_APP_NODE_ENV === 'development'

export var isProd = process.env.REACT_APP_NODE_ENV === 'production'

// export const initializeGA = () => {
//   if (isDev) return
//   try {
//     ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
//   } catch (e) {
//     console.log(e)
//   }
// }

// export const trackPageView = (location: Location) => {
//   if (isDev) return
//   try {
//     ReactGA.send({ hitType: "pageview", page: location.pathname });
//   } catch (e) {
//     console.log(e)
//   }
// }

// export const gaSendEvent = (eventPayload: any) => {
//   if (isDev) return
//   try {
//     ReactGA.event(eventPayload)
//   } catch (e) {
//     console.log(e)
//   }
// }

export const setCookie = (cookieName: string, cookieValue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const getCookie = (cookieName: string) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = (cookieName: string) => setCookie(cookieName, '', -1)

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const hasCookie = (cookieName: string) => !!getCookie(cookieName)

export const dhm = (t: number, short: boolean=false): Duration => {
  let cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(t / cd),
    h = Math.floor( (t - d * cd) / ch),
    m = Math.round( (t - d * cd - h * ch) / 60000)

  if ( m === 60 ){
    h++;
    m = 0;
  }
  if ( h === 24 ){
    d++;
    h = 0;
  }

  return [
    {type: short ? 'd' :'day', value: d},
    {type: short ? 'h' : 'hour', value: h},
    {type: short ? 'm' : 'minute', value: m}
  ]
}

export const humanize = (t: DateTime | string, short: boolean = false) => {
  const parseTime = typeof t === 'string' ? DateTime.fromISO(t) : t
  const now = DateTime.now()
  const times = dhm(now.diff(parseTime).milliseconds, short)
  const time = times.find(e => e.value !== 0)
  return time ? `${time.value}${short ? "" : " "}${time.type}${(time.value > 1 && !short) ? 's' : ''} ago` : `0 minutes ago`
}

export const debounce = (func: any, timeout:number = 300) => {
  let timer: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export const getSMUrl = (text: string, smKind: string) => {
  return `https://${smKind}.com/${text}`
}

export const timeslotMatchesDate = (date1: string | DateTime, date2: string | DateTime) => {
  const parsedDate1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2

  return parsedDate1.month === parsedDate2.month 
      && parsedDate1.weekday === parsedDate2.weekday
      && parsedDate1.hour === parsedDate2.hour
}

export const dateIsEqual = (date1: string | DateTime, date2: string | DateTime) => {
  const parsedDate1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2

  return parsedDate1.toLocaleString(DateTime.DATE_FULL) === parsedDate2.toLocaleString(DateTime.DATE_FULL)
}

export const dateTimeIsEqual = (date1: string | DateTime, date2: string | DateTime) => {
  const parsedDate1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2

  return parsedDate1.toLocaleString(DateTime.DATETIME_MED) === parsedDate2.toLocaleString(DateTime.DATETIME_MED)
}

export const dateWeekdayIsEqual = (date1: string | DateTime, date2: string | DateTime) => {
  const parsedDate1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2

  return parsedDate1.weekday === parsedDate2.weekday
}

export const timeIsEqual = (date1: string | DateTime, date2: string | DateTime) => {
  const parsedDate1 = typeof date1 === 'string' ? DateTime.fromISO(date1) : date1
  const parsedDate2 = typeof date2 === 'string' ? DateTime.fromISO(date2) : date2

  return parsedDate1.toLocaleString(DateTime.TIME_24_SIMPLE) === parsedDate2.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export const dateIsInPast = (d: string | DateTime) => {
  const date = typeof d === "string" ? DateTime.fromISO(d) : d
  return date.diffNow().milliseconds <= 0
}

export const getStatus = (date: DateRequest) => {
  if (dateIsInPast(date.start_date)) return 'completed'
  return 'pending'
}

export const groupByDay = <T={[i: string]: string}>(payload: T[]=[], groupByField: keyof T) => {
  const grouped: Record<string, Array<T>> = {}
  for (let item of payload) {
    const day = DateTime.fromISO(item[groupByField] as string).toLocaleString(DateTime.DATE_SHORT)
    const readableDay = slugify(humanReadableDate(day))
    grouped[readableDay] = [...(grouped[readableDay] || []), item]
  }

  return grouped
}

// this is super buggy, try to fix this.
export const humanReadableDate = (date: string) => {
  const selectedDate = DateTime.fromFormat(date, 'M/d/yyyy')

  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });
  const lastWeek = today.minus({ days: 7 })
  const twoWeeksAgo = today.minus({ days: 14 })
  const lastMonth = today.minus({ months: 1 })
  const twoMonthsAgo = today.minus({ months: 2 })
  const lastYear = today.minus({ years: 1 })
  const twoYearsAgo = today.minus({ years: 2 })

  const tomorrow = today.plus({ days: 1 });
  const nextWeek = today.plus({ days: 7 })
  const nextMonth = today.plus({ months: 1 })
  const twoMonthsLater = today.plus({ months: 2 })
  const nextYear = today.plus({ years: 1 })
  const twoYearsLater = today.plus({ years: 2 })

  if (dateIsEqual(today, selectedDate)) {
    return "Today"
  } else if (dateIsEqual(yesterday, selectedDate)) {
    return "Yesteday"
  } else if (lastWeek < selectedDate && selectedDate < today) {
    return "this week"
  } else if (twoWeeksAgo < selectedDate && selectedDate <= lastWeek) {
    return "last week"
  } else if (lastMonth < selectedDate && selectedDate <= twoWeeksAgo) {
    return "this month"
  } else if (twoMonthsAgo < selectedDate && selectedDate <= lastMonth) {
    return "last month"
  } else if (lastYear < selectedDate && selectedDate <= twoMonthsAgo) {
    return "earlier this year"
  } else if (twoYearsAgo < selectedDate && selectedDate <= lastYear) {
    return "last year"
  } else if (selectedDate < twoYearsAgo) {
    return "In the past"
  } else if (dateIsEqual(selectedDate, tomorrow)) {
    return "Tomorrow"
  } else if (today < selectedDate && selectedDate <=  nextWeek) {
    return "this week"
  } else if (nextWeek < selectedDate && selectedDate <= nextMonth) {
    return "this month"
  } else if (nextMonth < selectedDate && selectedDate <= twoMonthsLater) {
    return "next month"
  } else if (twoMonthsAgo < selectedDate && selectedDate <= nextYear) {
    return "this year"
  } else if (nextYear < selectedDate && selectedDate <= twoYearsLater) {
    return "next year"
  } else if (selectedDate > twoYearsLater) {
    return "In the future"
  } else {
    return "Date"
  }
}

export const pluralize = (word: string | undefined, count: number | undefined | null) => {
  if (!word || !count) return word
  if (count > 1) return word + 's'
  if (word[word.length - 1].toLowerCase() === 'y') return word.substring(0, word.length - 1) + 'ies'
  return word
}

export const generateRandomNumber = (length: number = 5) => {
  return Math.floor(Math.random() * (10 ** length))
}

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const stringAvatar = (name: string="") => {
  const splitNames = name.toUpperCase().split(' ')
  const initials = () => `${splitNames[0][0]} ${splitNames[1][0]}`
  
  return {
    sx: {
      // bgcolor: stringToColor(name),
      bgcolor: colors.secondary
    },
    children: splitNames.length > 1 ? initials() : name[0]
  };
}

export const moveToFront = <T=any>(list: T[], matcher: (item: T) => boolean): T[] => {
  const foundItem = list.find(matcher)
  return foundItem 
    ? [foundItem, ...list.filter(item => !matcher(item))]
    : list
}

export const participantToUser = (participant: Participant | ParticipantRequest) : User => {
  return {
    full_name: participant.user?.full_name || participant.name, 
    username: participant.user?.username || participant.contact,
    avatar: participant.user?.avatar
  } as User
}

export const isEmpty = (payload: {}) => {
  return payload ? Object.keys(payload).length === 0 : true
}

export const firstField = (payload: Record<string, any>) => {
  if (isEmpty(payload)) return null
  return payload[Object.keys(payload)[0]]
}

export const getSmallImage = (fileUrl: string) => {
  return fileUrl.replace('.jpg', '-small.jpg')
}

export const getLargeImage = (fileUrl: string) => {
  return fileUrl.replace('-small.jpg', '.jpg')
}

export const isUserStatusPending = (user: User, dateParticipants: Participant[] = []) => {
  return getParticipantObject(user, dateParticipants)?.response === "pending"
}

export const getParticipantObject = (user: User, dateParticipants: Participant[]=[]) => {
  return dateParticipants.find(p => p.user?.username === user.username)
}

export const getPIDOfUser = (user: User, dateParticipants: Participant[]=[]) => {
  return getParticipantObject(user, dateParticipants)?.id
}

export const getUserByPID = (pid: number, dateParticipants: Participant[]=[], forceCreate=true) => {
  const participant = dateParticipants.find(p => p.id === pid)
  if (!participant) return null
  if (!forceCreate) return participant.user
  if (participant.user) return participant.user
  return {full_name: participant.name, username: participant.contact} as User
}

export const getNameInitials = (name: string) => {
  if (name === '') return ''

  const names = name.split(' ')
  if (names.length > 1) return `${names[0][0]} ${names[1][0]}`
  if (names[0].length > 1) return `${name[0]} ${name[1]}`
  return name[0]
}

export const getParticipantById = (pid: number, dateParticipants: Participant[] = []) => {
  return dateParticipants.find(p => p.id === pid)
}

export const shuffle = <T>(array: T[]) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const toCurrency = (amount: number) => {
  return `$${Number(amount/100).toFixed(2)}`
}

export const getFirstName = (name: string | undefined, fallback: string='') => {
  return name?.split(' ')?.[0] || fallback
}

export const userIsParticipant = (activity: DateRequest, user?: User) => {
  if (!user) return false
  return !!getPIDOfUser(user, activity.date_participants)
}


export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
}

export const probablyBadToken = (e: any) => {
  if (e?.originalStatus === 401 && typeof e?.data === "string" && e?.data?.includes('token')) {
    console.log('bad token')
  }
  return e?.originalStatus === 401 && typeof e?.data === "string" && e?.data?.includes('token')
}

export const exportAsImage = async (element: HTMLElement | null, filename: string) => {
  if (!element) return
  const canvas = await html2canvas(element, {useCORS: true});
  const link = document.createElement('a');

  link.download = `${filename}.png`;
  link.href = canvas.toDataURL();
  link.click();
}

export const numToPosition = (num: number): string => {
  if (String(num).slice(-1) === '1') return `${num}st`
  if (String(num).slice(-1) === '2') return `${num}nd`
  if (String(num).slice(-1) === '3') return `${num}rd`
  return `${num}th`
}

export const durationToString = (duration: Duration) => {
  let durationString = ''
  duration.filter(d => d.value > 0).forEach(d => {
    durationString += `${Math.abs(d.value)} ${d.type}${d.value > 1 ? 's' :''} `
  })
  return durationString
}

export const fullDateRange = (rStartDate: DateTime | string, rEndDate: DateTime | string) => {
  const startDate = typeof rStartDate === 'string' ? DateTime.fromISO(rStartDate) : rStartDate
  const endDate = typeof rEndDate === 'string' ? DateTime.fromISO(rEndDate) : rEndDate

  const fullDate = startDate.toLocaleString(DateTime.DATE_MED)
  const fullStartTime = startDate.toLocaleString(DateTime.TIME_SIMPLE)
  const fullEndTime = endDate.toLocaleString(DateTime.TIME_SIMPLE)
  return `${fullStartTime} - ${fullEndTime}, ${fullDate}`
}

export const slugify = (text: string) => {
  return text.toLowerCase().replace(/ /g, '_')
}

export const unslugify = (text: string) => {
  return text.replace(/_/g, ' ')
}

export const getParticipantFirstName = (p: Participant) => {
  const name = p.name || p.user?.full_name
  return name?.split(' ')[0] || ''
}