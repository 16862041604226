import { useState, useEffect, useRef } from "react"
import { useAuthStore } from "../../../stores/authStore"
import { useProcessError } from "../../../hooks/hooks"
import gusiberiApi, { 
  useSendChatMessageMutation,
  useLazyFetchChatMessagesQuery,
  useFetchDateRequestQuery,
  useFetchChatMessagesQuery,
  useMarkMessagesAsSeenMutation
} from '../../../services/api';
import * as gen from '../../../utils/gen'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { MessageType, User } from '../../../utils/types';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DateRequestClass from "../../../services/DateRequestClass";
import { dataTypes } from "../../../utils/websocketHandler";
import { dateIsInPast, getUserByPID } from "../../../utils/helpers";
import { DateTime } from "luxon";


export const useMessages = (dateObject?: DateRequestClass) => {
  const { chatId } = useParams()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const { currentUser } = useAuthStore()
  const processError = useProcessError()
  const [sendChatMessage] = useSendChatMessageMutation()
  const participantUsers = (dateObject?.dateRequest.date_participants
    ?.map(p => getUserByPID(p.id, dateObject.dateRequest.date_participants))
    .filter(u => !!u) || []) as User[]

  const getTemporaryPayload = (statusLabel: string): MessageType => ({
    id: 0,
    message,
    user: currentUser,
    seen: true,
    status: statusLabel,
    created_at: ''
  })

  const optimisticUpdate = (temporaryPayload: MessageType) => {
    if (!chatId) return

    dispatch(
      gusiberiApi.util.updateQueryData(
        'fetchChatMessages',
        {request_id: chatId},
        draftMessages => {
          draftMessages.payload = [temporaryPayload, ...draftMessages.payload]
        }
      ) as any
    )
  }

  const realUpdate = (response: {payload: MessageType}, statusLabel: string) => {
    if (!chatId) return

    dispatch(
      gusiberiApi.util.updateQueryData(
        'fetchChatMessages',
        {request_id: chatId},
        draftMessages => {
          draftMessages.payload = draftMessages.payload.map(message => {
            if (message.status === statusLabel) return {...response.payload, user: currentUser}
            return message
          })
        }
      ) as any
    )
  }

  const publishToRecipient = (createdMessage: MessageType) => {
    if (!dateObject || !dateObject.otherParticipant) return
    gen.publish(dateObject.dateRequest.request_id, JSON.stringify({
      type: dataTypes.NEW_CHAT_MESSAGE,
      payload: {
        ...createdMessage,
        request_id: dateObject.dateRequest.request_id,
        user: {
          username: currentUser.username,
          avatar: currentUser.avatar,
          full_name: currentUser.full_name
        }
      }
    }))
  }

  const sendMessage = async () => {
    if (!chatId) return

    try {
      const chatMessage = message
      const currentTimestamp = Date.now()
      const statusLabel = `pending-${currentTimestamp}`
      const temporaryPayload = getTemporaryPayload(statusLabel)

      optimisticUpdate(temporaryPayload)
      setMessage('')

      const response = await sendChatMessage({message: chatMessage, request_id: chatId}).unwrap()
      realUpdate(response, statusLabel)
      publishToRecipient(response.payload)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const endDate = dateObject?.dateRequest.end_date

  const chatIsActive = !endDate ? true : !dateIsInPast(DateTime.fromISO(endDate).plus({days: 1}))

  return { 
    chatId,
    setMessage, 
    message, 
    sendMessage, 
    currentUser,
    participantUsers,
    chatIsActive
  }
}

export const useMessagesEffect = () => {
  const dispatch = useDispatch()
  const processError = useProcessError()
  const { currentUser } = useAuthStore()
  const { chatId } = useParams()
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const { data: dateInfo } = useFetchDateRequestQuery({request_id: chatId || '0'})
  const { data: messagesFromServer, isSuccess, isFetching } = useFetchChatMessagesQuery({request_id: chatId || '0'})
  const [fetchMessages] = useLazyFetchChatMessagesQuery()
  const [markAsSeenRequest] = useMarkMessagesAsSeenMutation()

  const markMessageAsSeen = async () => {
    const message = messagesFromServer?.payload[0]
    if (!chatId || !message) return
    if ((message.user.username === currentUser.username) || message.seen) return
    const lastMessage = {...message}

    try {
      await markAsSeenRequest({ request_id: chatId })
      dispatch(
        gusiberiApi.util.updateQueryData(
          'fetchChatMessages',
          {request_id: chatId},
          draftMessages => {
            draftMessages.payload = draftMessages.payload.map(m => {
              if (m.id === lastMessage.id) m.seen = true 
              return m
            })
          }
        ) as any
      )
      dispatch(
        gusiberiApi.util.updateQueryData(
          "fetchUpcomingDates",
          {},
          draftDates => {
            draftDates.payload = draftDates.payload.map(date => {
              if (date.request_id === chatId && date.last_sent_message) {
                date.last_sent_message.seen = true 
              }
              return date
            })
          }
        ) as any
      )
    } catch(e) {
      console.log('error:: ', e)
    }
  }

  useEffect(() => {
    if (isSuccess) scrollToBottom()
  }, [isSuccess])

  useEffect(() => {
    markMessageAsSeen()
    // eslint-disable-next-line
  }, [])


  const nextPage = messagesFromServer?.pagination_info.next_page
  const loadOlder = async () => {
    if (!nextPage || !chatId) return
    const oldestMessage = messagesFromServer?.payload[messagesFromServer?.payload.length - 1]

    try {
      const response = await fetchMessages({ 
        request_id: chatId, 
        page: nextPage, 
        offset: oldestMessage.created_at 
      }).unwrap()

      dispatch(
        gusiberiApi.util.updateQueryData(
          'fetchChatMessages',
          {request_id: chatId},
          draftMessages => {
            draftMessages.payload = [...draftMessages.payload, ...response.payload]
            draftMessages.pagination_info = response.pagination_info
          }
        ) as any
      )
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const scrollToBottom = () => {
    chatContainerRef?.current?.scrollTo(0, chatContainerRef.current.scrollHeight)
  }

  return { 
    chatContainerRef, 
    dateInfo: dateInfo?.payload, 
    dateObject: dateInfo?.payload && (new DateRequestClass(dateInfo?.payload, currentUser)),
    chatMessages: messagesFromServer?.payload || [],
    fetchMessages,
    isFetching,
    hasOlderMessages: !!nextPage && !isFetching,
    loadOlder,
    scrollToBottom
  }
}