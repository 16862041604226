import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/MoreVertRounded';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import { colors } from '../../../../../../styles/_var';
import Text from '../../../../../../components/Text'

type MoreOptionsButtonProps = {
  canCreateNewScoreboard: boolean,
  addNewScoreboard: () => void,
  formTeams: () => void,
  canFormTeams: boolean
}


const MoreOptionsButton = ({ 
  canCreateNewScoreboard,
  addNewScoreboard,
  canFormTeams,
  formTeams
}: MoreOptionsButtonProps) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = () => setOpenMenu(!openMenu)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    toggleMenu()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toggleMenu()
  }

  const onClickNewScoreboard = () => {
    addNewScoreboard()
    handleClose()
  }

  const onClickFormTeams = () => {
    formTeams()
    handleClose()
  }

  return (
    <Box>
      <IconButton sx={{background: colors.lightgrey, marginLeft: 1}} onClick={handleClick}>
        <MenuIcon fontSize='medium' />
      </IconButton>
      <Popover
        sx={{marginTop: 11}}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <List sx={{width: 140}}>
          <ListItemButton onClick={onClickFormTeams} disabled={!canFormTeams}>
            <Text color={colors.darkgrey}>Form Teams</Text>
          </ListItemButton>
          <ListItemButton disabled={!canCreateNewScoreboard} onClick={onClickNewScoreboard}>
            <Text color={colors.darkgrey}>New Scoreboard</Text>
          </ListItemButton>
        </List>
      </Popover>
    </Box>
  )
}

export default MoreOptionsButton
