import React from 'react'
import ActivityIcon from '@mui/icons-material/RowingRounded';
import Paper from '@mui/material/Paper';
import SxProps from '@mui/material/styles';
import { spacing } from '../../../../styles/_var';
import { skeletonStyle } from '../../Home/UpcomingDates';
import Box from '@mui/material/Box'
import Text from '../../../../components/Text';

const NoActivity = React.memo(() => {
  const style: SxProps.SxProps = {
    ...skeletonStyle,
    padding: spacing.xxs,
    maxWidth: '15rem',
    height: '10rem',
  }

  return (
    <Box justifyContent="center" display="flex">
      <Paper elevation={3} sx={style}>
        <ActivityIcon fontSize='large' />
        <Text center>No Favourite Dating Activites</Text>
      </Paper>
    </Box>
  )
})

export default NoActivity