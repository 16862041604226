import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../utils/constants'
import { PPWrapper, Section } from './HomePage.styles'
import Text from '../../components/Text'

const TermsOfService = () => {
  return (
    <PPWrapper>
      <Link to={routes.LANDING_PAGE}>
        <img 
          style={{marginRight: 'auto', marginLeft: 'auto', textAlign: 'center', display: 'block'}}
          src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" 
          alt="gusiberi logo" 
          height={38} />
      </Link>
      <Section>
        <Text mt={3} weight='bold' variant='medium' mb={1} center>
          Terms and Conditions of Use
        </Text>
        <Text>
          Welcome to Gusiberi's Terms and Conditions of Use (these “Terms”). This is a contract between you and the Gusiberi company and we want you to know yours and our rights before you use the Gusiberi website or application (“Gusiberi” or the “App”). Please take a few moments to read these Terms before enjoying the App, because once you access, view or use the App, you are going to be legally bound by these Terms (so probably best to read them first!).
        </Text>
      </Section>
      <Section>
        <Text weight='bold'>
          Please be aware that if you subscribe to services for a term (the “Initial Term”), then the terms of your subscription will be automatically renewed for additional periods of the same duration as the Initial Term at Gusiberi's then-current fee for such services, unless you cancel your subscription in accordance with Section 5 below.
        </Text>
        <Text mt={1}>
          You should also note that Section 13 of these Terms contains provisions governing how claims that you and Gusiberi have against each other are resolved.<strong>In particular, it contains an arbitration agreement that will, with limited exceptions, require disputes between us to be submitted to binding and final arbitration.</strong>If you are an existing user or a new user who signed up for Gusiberi before January 18, 2021, you have a right to opt out of the arbitration agreement pursuant to Section 13 below. If you do not opt out of the arbitration agreement in accordance with Section 13, or if you sign up for Gusiberi after January 18, 2021, (1) you will only be permitted to pursue claims and seek relief against us on an individual basis only; and (2) you are waiving your right to seek relief in a court of law and to have a jury trial on your claims.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>1. Gusiberi RULES</Text>
        <Text>Before you can use the App, you will need to register for an account (“Account”). In order to create an Account you must:</Text>
        <Text mt={1}>1. be at least 18 years old or the age of majority to legally enter into a contract under the laws of your home country; and</Text>
        <Text>2. be legally permitted to use the App by the laws of your home country.</Text>
        <Text mt={1}>
          You can create an Account via manual registration, or by using your Facebook login details. If you create an Account using your Facebook login details, you authorise us to access, display and use certain information from your Facebook account (e.g. profile pictures, relationship status, location and information about Facebook friends). For more information about what information we use and how we use it, please check out our privacy policy.
        </Text>
        <Text mt={1}>Unfortunately, we cannot allow you to use another person's Account or to share your Account with any other person without permission.</Text>
        <Text mt={1}>
          You'll have great fun on Gusiberi, but if you feel the need to leave, you can delete your Account at any time by going to the 'Settings' page when you are logged in and clicking on the 'Delete account' link. Your Account will be deleted immediately but it may take a little while for YourContent (defined below) to be completely removed from the App. Your profile information will be treated in accordance with our Privacy Policy. If you delete your Account and try to create a new account within this time period using the same credentials, we will re-activate your Account for you.
        </Text>
        <Text mt={1}>
          We use a combination of automated systems and a team of moderators to monitor and review
          accounts and messages for content that indicates breaches of these Terms. We reserve the right at our sole discretion to terminate or suspend any Account, restrict access to the App, or make use of any operational, technological, legal or other means available to enforce the Terms (including without limitation blocking specific IP addresses), at any time without liability and without the need to give you prior notice. Without limiting the foregoing in any way, we expressly reserve the right to terminate or suspend your Account without notice (1) for violating these terms, (2) due to your conduct on the App, or your conduct with other users of the App (including your “offline” conduct), if we, in our sole discretion, determine your conduct was inappropriate or improper, (3) if we or our affiliates, in our or their sole discretion, determine your conduct on other apps operated by our affiliates was inappropriate or improper, or (4) for any reasons whatsoever that we deem, in our sole discretion, justifies termination. If your Account is terminated or suspended, you agree you will not receive a refund for any paid service or features you have already been charged for.
        </Text>
        <Text mt={1}>
          You may not access, tamper with, or use non-public areas of the App or our systems. Certain portions of the App may not be accessible if you have not registered for an Account.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>2. TYPES OF CONTENT</Text>
        <Text>There are three types of content that you will be able to access on the App:</Text>
        <Text mt={1}>1. content that you upload and provide (“Your Content”);</Text>
        <Text>2. content that members provide (“Member Content”); and</Text>
        <Text>3. content that the Gusiberi Company provides (including, without limitation, database(s) and/or software) (“Our Content”).</Text>
      </Section>
      <Section>
        <Text weight='bold'>There is certain content we can't allow on Gusiberi</Text>
        <Text mt={1}>
          We want our users to be able express themselves as much as possible and post all sorts of things on Gusiberi, but we have to impose restrictions on certain content which:
        </Text>
        <ul>
          <li>
            <Text>
              contains language or imagery which could be deemed offensive or is likely to harass,
              upset, embarrass, alarm or annoy any other person (including for example and without
              limitation, language that could be deemed discriminatory towards an individual's race,
              colour, ethnicity, national origin, religion, disability, sexual orientation, gender
              expression, gender identity or physical appearance);
            </Text>
          </li>
          <li>
            <Text>
              is obscene, pornographic, violent or otherwise may offend human dignity (including for
              example and without limitation, language that could be deemed discriminatory towards
              an individual's race, colour, ethnicity, national origin, religion, disability, sexual
              orientation, gender expression, gender identity or physical appearance);
            </Text>
          </li>
          <li>
            <Text>
              is abusive, insulting or threatening, discriminatory or which promotes or encourages
              racism, sexism, hatred or bigotry (including for example and without limitation, language
              that could be deemed discriminatory towards an individual's race, colour, ethnicity,
              national origin, religion, disability, sexual orientation, gender expression, gender identity
              or physical appearance);
            </Text>
          </li>
          <li>
            <Text>Encourages any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which in itself constitutes committing a criminal offence;</Text>
          </li>
          <li>
            <Text>Is defamatory or libellous;</Text>
          </li>
          <li>
            <Text>relates to commercial activities (including, without limitation, sales, competitions and advertising, links to other websites or premium line telephone numbers);</Text>
          </li>
          <li>
            <Text>Involves the transmission of “junk” mail or “spam”;</Text>
          </li>
          <li>
            <Text>
              contains any spyware, adware, viruses, corrupt files, worm programmes or other
              malicious code designed to interrupt, damage or limit the functionality of or disrupt any
              software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from Gusiberi or otherwise;
            </Text>
          </li>
          <li>
            <Text>
              itself, or the posting of which, infringes any third party's rights (including, without
              limitation, intellectual property rights and privacy rights);
            </Text>
          </li>
          <li>
            <Text>shows another person which was created or distributed without that person's consent;</Text>
          </li>
          <li>
            <Text>is harmful to minors; or</Text>
          </li>
          <li>
            <Text>impersonates any other person, including falsely stating your name. 
            </Text>
          </li>
        </ul>
        <Text>Gusiberi Company operates a zero-tolerance policy for this kind of content.</Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>Your Content</Text>
        <Text>
          You agree that Your Content must comply with our Guidelines updated from time to time. As
          Your Content is unique, you are responsible and liable for Your Content and will indemnify,
          defend, release, and hold us harmless from any claims made in connection with Your Content.
          Sorry that was a bit of a mouthful, but you are what you post!
        </Text>
        <Text mt={1}>
          You may not display any personal contact or banking information on your individual profile page
          whether in relation to you or any other person (for example, names, home addresses or
          postcodes, telephone numbers, email addresses, URLs, credit/debit card or other banking
          details). If you do choose to reveal any personal information about yourself to other users,
          whether via email or otherwise, it is at your own risk. We encourage you to use the same caution in disclosing details about yourself to third parties online as you would under any other circumstances.
        </Text>
        <Text mt={1}>
          As Gusiberi is a public community, Your Content will be visible to other users of the App all
          around the world instantly - so make sure you are comfortable sharing Your Content before you
          post. As such, you agree that Your Content may be viewed by other users and any person visiting, participating in or who is sent a link to the App (e.g. individuals who receive a link to a user's profile or shared content from other Gusiberi Users). By uploading Your Content on Gusiberi, you represent and warrant to us that you have all necessary rights and licences to do so, and automatically grant us a non-exclusive, royalty free, perpetual, worldwide licence to use Your Content in any way (including, without limitation, editing, copying, modifying, adapting, translating, reformatting, creating derivative works from, incorporating into other works, advertising, distributing and otherwise making available to the general public such Content, whether in whole or in part and in any format or medium currently known or developed in the future).
        </Text>
        <Text mt={1}>
          We may assign and/or sub-license the above licence to our affiliates and successors without any further approval by you.
        </Text>
        <Text mt={1}>We do not have any obligation to store Your Content - if it's important, you should make a copy.</Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>Member Content</Text>
        <Text>
          Other members of Gusiberi will also share content via the App. Member Content belongs to the
          user who posted the content and is stored on our servers and displayed via the App at the
          direction of the user providing the Member Content.
        </Text>
        <Text mt={1}>
          You do not have any rights in relation to other users' Member Content, and you may only use
          other Gusiberi users' personal information to the extent that your use of it matches Gusiberi's
          purpose of allowing people to meet one another. You may not use other users' information for
          commercial purposes, to spam, to harass, stalk or to make unlawful threats. We reserve the right to terminate your Account if you misuse other users' information.
        </Text>
        <Text mt={1}>
          Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the
          Digital Millennium Copyright Act 1998. If you have a complaint about Member Content, please
          see the Digital Millennium Copyright Act section below for more information.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>Our Content</Text>
        <Text>
          You may be wondering what happens to the rest of the Content on Gusiberi. Well, it belongs to us! Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, and other intellectual property appearing on Gusiberi, as well as the Gusiberi software and database(s), are owned, controlled or licensed by us and are protected by copyright, trademark, data, database rights and/or other intellectual property law rights. All right, title and interest in and to Our Content remains with us at all times.
        </Text>
        <Text mt={1}>
          We grant you a non-exclusive, limited, personal, non-transferable, revocable, licence to access
          and use Our Content, without the right to sublicense, under the following conditions:
        </Text>
        <Text mt={1}>
          1. you shall not use, sell, modify, or distribute Our Content except as permitted by the
          functionality of the App;
        </Text>
        <Text>2. you shall not use our name in metatags, keywords and/or hidden text;</Text>
        <Text>
          3. you shall not create derivative works from Our Content or scrape, disable, decompile,
          analyse or in any way commercially exploit Our Content, in whole or in part, in any way;
          and
        </Text>
        <Text>4. you shall use Our Content for lawful purposes only.</Text>
        <Text mt={1}>We reserve all other rights.</Text>
        <Text mt={1} weight='bold'>No Obligation to Pre-Screen Content.</Text>
        <Text mt={1}>
          Since Gusiberi is an online community, we generally try to avoid getting in the way and therefore don't assume any obligation to pre-screen any of Your Content or any Member Content.
          However, there may be times where we need to step in, and we reserve the right to review, pre-
          screen, refuse and/or remove any Member Content and Your Content, including content exchanged between users in direct messages.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>3. RESTRICTIONS ON THE APP</Text>
        <Text>You agree to:</Text>
        <ul>
          <li>
            <Text>
              comply with all applicable laws, including without limitation, privacy laws, intellectual property laws, anti-spam laws, equal opportunity laws and regulatory requirements;
            </Text>
          </li>
          <li>
            <Text>use your real name and real age in creating your Gusiberi account and on your profile; and</Text>
          </li>
          <li>
            <Text>use the services in a professional manner.</Text>
          </li>
        </ul>
        <Text mt={1}>You agree that you will not:</Text>
        <ul>
          <li>
            <Text>act in an unlawful or unprofessional manner including being dishonest, abusive or
discriminatory;</Text>
          </li>
          <li>
            <Text>
              misrepresent your identity, your age, your current or previous positions, qualifications or affiliations with a person or entity;
            </Text>
          </li>
          <li>
            <Text>disclose information that you do not have the consent to disclose;</Text>
          </li>
          <li>
            <Text>stalk or harass any other user of the App;</Text>
          </li>
          <li>
            <Text>create or operate a pyramid scheme, fraud or other similar practice, or;</Text>
          </li>
          <li>
            <Text>
              develop, support or use software, devices, scripts, robots, other types of mobile code or
              any other means or processes (including crawlers, browser plugins and add-on or other
              technology) to scrape or otherwise exfiltrate from Gusiberi or its services, or otherwise
              copy profiles and other data from the services.
            </Text>
          </li>
        </ul>
        <Text mt={1}>
          We don't like users misbehaving in the Gusiberi community. You can report any abuse or complain
          about Member Content by contacting us, outlining the abuse and/or complaint. You can also
          report a user directly from a profile or in chat by clicking the ‘Block &amp; Report' link. We reserve the right to investigate any possible violations of these Terms, any Gusiberi user's rights, or any third party rights and we may, in our sole discretion, immediately terminate any user's right to use of the App without prior notice, as set out further in Section 1 above, and/or remove any improper, infringing or otherwise unauthorised Member Content submitted to the App.
        </Text>
        <Text mt={1}>
          We don't control any of the things our users say or do, so you are solely responsible for your interactions with other users of the App.
        </Text>
        <Text weight='bold'>
          YOU UNDERSTAND THAT Gusiberi COMPANY DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS. Gusiberi COMPANY ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO VERIFY THE STATEMENTS OF ITS USERS. Gusiberi COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF ANY USERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS. Gusiberi COMPANY RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTRATION SEARCHES) AT ANY TIME AND TO USE AVAILABLE PUBLIC
          RECORDS FOR ANY PURPOSE.
        </Text>
        <Text mt={1}>
          You agree to, and hereby do, release Gusiberi Company and its successors from any claims,
          demands, losses, damages, rights, and actions of any kind, including personal injuries, death and property damage, that either directly or indirectly arises from your interactions with or conduct of other users of the App. If you are a California resident, you hereby waive California Civil Code Section 1542, which states, “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.” The foregoing release does not apply to any claims, demands, or any losses, damages, rights and actions of any kind, including personal injuries, death or property damage for any unconscionable commercial practice by Gusiberi Company or for such party's fraud, deception, false, promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the App.
        </Text>
        <Text mt={1}>
          Scraping or replicating any part of the App without our prior consent is expressly prohibited. This includes by any means (automated or otherwise) other than through our currently available,
          published interfaces - unless you have been specifically allowed to do so in a separate agreement with us.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>4. PRIVACY</Text>
        <Text>
          For information about how the Gusiberi Company collects, uses, and shares your personal data,
          please check out our  privacy policy. By using Gusiberi, you acknowledge that we may use such
          data in accordance with our privacy policy.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>5. PAYMENT TERMS</Text>
        <Text weight='bold'>Generally.</Text>
        <Text mt={1}>
          Gusiberi may offer products and services for purchase on the App (“In-App Purchase”). If you
          choose to make an In-App Purchase, you acknowledge and agree that additional terms, disclosed
          to you at the point of purchase, may apply, and that such additional terms are incorporated
          herein by reference.
        </Text>
        <Text mt={1}>
          You may make an In-App Purchase through the following payment methods (“Payment Method”): (a) making a purchase through a third-party platform such as the Apple App Store and Google Play Store (“Third Party Store”), or (b) paying with your credit card, debit card, or PayPal account, which will be processed by a third-party processor. Once you have made an In-App Purchase, you authorise us to charge your chosen Payment Method. If payment is not received by us from your chosen Payment Method, you agree to promptly pay all amounts due upon demand by us.
        </Text>
        <Text mt={1}>
          Subscriptions and Auto-Renewal: Gusiberi may offer some services as automatically-renewing subscriptions, e.g., a one-week subscription, one-month subscription, or three-month
          subscription (“Premium Services”). IF YOU PURCHASE AN AUTOMATICALLY RENEWING SUBSCRIPTION, YOUR SUBSCRIPTION WILL RENEW AT THE END OF THE PERIOD, UNLESS YOU CANCEL, AT Gusiberi'S THEN-CURRENT PRICE FOR SUCH SUBSCRIPTIONS. To avoid charges for a new subscription period, you must cancel, as described below, before the end of the then-current subscription period. Deleting your account or deleting the application from your device does not cancel your subscription. You will be given notice of changes in the pricing of the Premium Services to which you have subscribed and an opportunity to cancel. If Gusiberi changes these prices and you do not cancel your subscription, you agree that you will be charged at Gusiberi's then-current pricing for subscription.
          Cancelling Subscriptions. If you purchased a subscription directly from Gusiberi, you may
          cancel or change your Payment Method via the payment settings option under your profile. If you
          purchased a subscription through a Third Party Store, such as the Apple App Store or the Google
          Play Store, you will need to access your account with that Third Party Store and follow
          instructions to change or cancel your subscription. If you cancel your subscription, you may use your subscription until the end of the period you last paid for, but (i) you will not (except as set forth in the subsection entitled “Refunds” below) be eligible for a prorated refund, (ii) your subscription will not be renewed when that period expires and (iii) you will then no longer be able to use the Premium Services or In-App Purchases enabled by your subscription. Free Trials. If you sign up for a free trial and do not cancel, your trial may convert into a paid subscription and your Payment Method will be charged at the then-current price for such subscription. Once your free trial converts to a paid subscription, your paid subscription will continue to automatically renew at the end of each period, and your Payment Method will be charged, until you cancel. To avoid charges for a new subscription period, you must cancel before the end of the then-current subscription period or free trial period as described above. Deleting your account or deleting the application from your device does not cancel your free trial. If you have signed up for a free trial on Gusiberi through the Apple Store or Google Play Store previously, you will not be eligible for another free trial and you will then be automatically signed up to a subscription and charged as described in this paragraph.
        </Text>
        <Text weight='bold' mt={1}>
          Refunds. Generally, all charges for purchases are nonrefundable, and there are no
          refunds or credits for partially used periods.
        </Text>
        <Text mt={1}>
          For subscribers residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode Island, and Wisconsin, the terms below apply:
        </Text>
        <Text mt={1}>
          You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business day following the date you subscribed. In the event that you die before the end of your subscription period, your estate shall be entitled to a refund of that portion of any payment you had made for your subscription which is allocable to the period after your death. In the event that you become disabled (such that you are unable to use Gusiberi) before the end of your subscription period, you shall be entitled to a refund of that portion of any payment you had made for your subscription which is allocable to the period after your disability by providing the company notice in the same manner as you request a refund as described below.
        </Text>
        <Text weight='bold'>To request a refund:</Text>
        <Text>
          In addition to cancelling, you must request a refund to receive one. If you subscribed using your Apple ID, refunds are handled by Apple, not Gusiberi. To request a refund, go to iTunes, click on your Apple ID, select “Purchase history,” find the transaction and hit “Report Problem”. You can also submit a request.
          For all other purchases: please contact customer support with your order number (you can find
          the order number in the order confirmation email, or if you purchased from the Google Play
          Store by logging in to Google Wallet). You may also mail or deliver a signed and dated notice
          which states that you, the buyer, are canceling this agreement, or words of similar effect. Please also include the email address or mobile number associated with your account along with your order number.
        </Text>
        <Text mt={1} weight='bold'>Taxes.</Text>
        <Text mt={1}>
          The payments required under this Section 5 do not include any Sales Tax that may be due in
          connection with the Premium Services provided under these Terms. If Gusiberi determines it has a
          legal obligation to collect a Sales Tax from you in connection with these Terms, Gusiberi will
          collect such Sales Tax in addition to the payments required under this Section 5. If any Premium Services, or payments for any Premium Services, under these Terms are subject to any Sales Tax in any jurisdiction and you have not remitted the applicable Sales Tax to Gusiberi, you will be responsible for the payment of such Sales Tax and any related penalties or interest to the relevant tax authority. As used herein, “Sales Tax” means any sales or use tax and any other tax measured by sales proceeds that is the functional equivalent of a sales tax where the applicable taxing jurisdiction does not otherwise impose a sales or use tax.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>6. VIRTUAL ITEMS</Text>
        <Text>
          At times, you may be able to purchase a limited, personal, non-transferable, non-sublicensable,
          revocable licence to access special limited-use features from Gusiberi, referred to here as “Virtual Items.” You can only purchase Virtual Items through Gusiberi or Gusiberi's partners. Virtual Items represent a limited license right governed by this Agreement, and, except as otherwise prohibited by applicable law, no title or ownership in or to Virtual Items is being transferred or assigned to you. This Agreement, and your purchase of Virtual Items, does not constitute the sale of any rights in Virtual Items.
        </Text>
        <Text mt={1}>
          Any Virtual Item balance shown in your account does not constitute a real-world balance or
          reflect any stored value, but instead shows the extent of your licence to access Virtual Items.
          Virtual Items do not incur fees for non-use. Your licence in Virtual Items will terminate on the earlier of Gusiberi ceasing provision of services or your account closing or terminating. Gusiberi may also at times provide Virtual Items as bonuses to, or parts of, paid subscriptions for its services. Your ability to use Virtual Items you have access to in this manner may terminate at the end of each of your subscription periods and your access to Virtual Items may not “roll over” or accumulate through additional subscription periods. Your access to Virtual Items gained through subscriptions may also end if you cancel your subscription.
        </Text>
        <Text mt={1}>
          Gusiberi, in its sole discretion, reserves the right to charge fees for the right to access or use Virtual Items and/or may distribute Virtual Items with or without charge. Gusiberi may manage, regulate, control, modify, or eliminate Virtual Items at any time, including taking actions that may impact the perceived value or purchase price, if applicable, of any Virtual Items and Gusiberi shall have no liability to you for doing so. You shall not sell, redeem, or otherwise transfer Virtual Items to any person or entity. Virtual Items may only be redeemed through our Services.
        </Text>
        <Text mt={1}>
          ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR
          SERVICES ARE FINAL AND NON-REFUNDABLE. YOU ACKNOWLEDGE THAT Gusiberi IS
          NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT
          RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN
          ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>
          7. PUSH NOTIFICATIONS; LOCATION-BASED FEATURES
        </Text>
        <Text>
          We may provide you with emails, text messages, push notifications, alerts and other messages
          related to the App and/or the Gusiberi services, such as enhancements, offers, products, events,
          and other promotions. After downloading the App, you will be asked to accept or deny push
          notifications/alerts. If you deny, you will not receive any push notifications/alerts. If you accept, push notifications/alerts will be automatically sent to you. If you no longer wish to receive push notifications/alerts from the App, you may opt out by changing your notification settings on your mobile device. With respect to other types of messaging or communications, such as emails, text messages, etc., you can unsubscribe or opt out by either following the specific instructions included in such communications, or by emailing us with your request
          at feedback@team.Gusiberi.com.
        </Text>
        <Text>
          The App may allow access to or make available opportunities for you to view certain content and
          receive other products, services and/or other materials based on your location. To make these
          opportunities available to you, the App will determine your location using one or more reference points, such as GPS, Bluetooth and/or software within your mobile device. If you have set your mobile device to disable GPS, Bluetooth or other location determining software or do not authorise the App to access your location data, you will not be able to access such location-specific content, products, services and materials. For more about how the App uses and retains your information, please read the privacy policy.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>8. DISCLAIMER</Text>
        <Text>
          THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO YOU “AS
          IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT
          GUARANTEE THE COMPATIBILITY OF ANY MATCHES.
          SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS OR
          IMPLIED WARRANTIES, THEN WE GRANT THE MINIMUM EXPRESS OR IMPLIED
          WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER
          ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR
          GUARANTEE NOT EXPRESSLY STATED IN THIS SECTION.
          ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL BE
          UNINTERRUPTED, SECURE OR ERROR FREE OR THAT YOUR USE OF THE APP OR SITE
          WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT, ANY
          MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR
          RELIABLE. YOUR USE OF THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE SOLELY
          RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. THE Gusiberi
          COMPANY IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. Gusiberi COMPANY
          DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>9. LIMITATION OF LIABILITY</Text>
        <Text>
          NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT,
          INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE, INCLUDING, WITHOUT
          LIMITATION, LOSS OF DATA, INCOME, PROFIT OR GOODWILL, LOSS OF OR DAMAGE TO
          PROPERTY AND CLAIMS OF THIRD PARTIES ARISING OUT OF YOUR ACCESS TO OR USE
          OF THE APP, SITE, OUR CONTENT, OR ANY MEMBER CONTENT, HOWEVER CAUSED,
          WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
          PROPRIETARY RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
          THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. IF YOU BECOME DISSATISFIED IN ANY WAY WITH THE APP OR SITE,
          YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR USE OF THE APP AND SITE.
          YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE APP OR
          SITE. BECAUSE SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES,
          THESE PROVISIONS MAY NOT APPLY TO YOU. IF ANY PORTION OF THIS LIMITATION ON
          LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN
          OUR AGGREGATE LIABILITY SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100).
          THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS
          OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE APP AND SITE
          WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU AGREE THAT THE
          LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE
          REMEDIES SPECIFIED HEREIN WILL SURVIVE EVEN IF FOUND TO HAVE FAILED IN
          THEIR ESSENTIAL PURPOSE. THE FOREGOING DOES NOT APPLY TO LIABILITY ARISING
          FROM ANY FRAUD OR FRAUDULENT MISREPRESENTATIONS, OR ANY OTHER LIABILITY
          THAT CANNOT BE LIMITED BY APPLICABLE LAW.
        </Text>
      </Section>
      <Section>
        <Text weight='bold' variant='medium'>10. INDEMNITY</Text>
        <Text>
          All the actions you make and information you post on Gusiberi remain your responsibility.
          Therefore, you agree to indemnify, defend, release, and hold us, and our partners, licensors,
          affiliates, contractors, officers, directors, employees, representatives and agents, harmless, from and against any third party claims, damages (actual and/or consequential), actions, proceedings, demands, losses, liabilities, costs and expenses (including reasonable legal fees) suffered or reasonably incurred by us arising as a result of, or in connection with:
        </Text>
        <Text mt={1}>1. any negligent acts, omissions or wilful misconduct by you;</Text>
        <Text>2. your access to and use of the App;</Text>
        <Text>3. the uploading or submission of Content to the App by you;</Text>
        <Text>4. any breach of these Terms by you; and/or</Text>
        <Text>5. your violation of any law or of any rights of any third party. </Text>
        <Text mt={1}>
          We retain the exclusive right to settle, compromise and pay any and all claims or causes of action which are brought against us without your prior consent. If we ask, you will co-operate fully and reasonably as required by us in the defence of any relevant claim. The foregoing provision does not require you to indemnify Gusiberi Company for any unconscionable commercial practice or any fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the App.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>
          11. PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
        </Text>
        <Text>
          If you believe any content on Gusiberi infringes the copyright in a work that you own, please submit a notification alleging such infringement (“DMCA Takedown Notice”) to Gusiberi Company's Copyright Agent. The Takedown Notice must include the following:
        </Text>
        <Text mt={1}>
          1. A physical or electronic signature of a person authorised to act on behalf of the owner of an exclusive right that is allegedly infringed;
        </Text>
        <Text>
          2. Identification of the copyrighted work claimed to have been infringed, or, if multiple
          copyrighted works at a single online site are covered by a single notification, a
          representative list of such works;
        </Text>
        <Text>
          3. Identification of the material claimed to be infringing or to be the subject of infringing
          activity and that is to be removed or access disabled and information reasonably sufficient to permit the service provider to locate the material;
        </Text>
        <Text>
          4. Information reasonably sufficient to permit the service provider to contact you, such as
          an address, telephone number, and, if available, an electronic mail;
        </Text>
        <Text>
          5. A statement that you have a good faith belief that use of the material in the manner
          complained of is not authorised by the copyright owner, its agent, or the law; and
        </Text>
        <Text>
          6. A statement that, under penalty of perjury, the information in the notification is accurate
          and you are authorised to act on behalf of the owner of the exclusive right that is allegedly
          infringed.
        </Text>
        <Text mt={1}>
          Takedown Notices should be sent to Gusiberi Company's Copyright Agent at copyright@Gusiberi.com
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>12. THIRD PARTY APP STORE</Text>
        <Text>
          The following additional terms and conditions apply to you if you download the App from a Third
          Party Store. To the extent that the other terms and conditions of these Terms are less restrictive than, or otherwise conflict with, the terms and conditions of this Section, the more restrictive or conflicting terms and conditions in this Section will apply, but solely with respect to the App and the Third Party Store. You acknowledge and agree that:
        </Text>
        <Text mt={1}>
          1. These Terms are concluded solely between you and the Gusiberi Company and not with the
          providers of the Third Party Store, and the Gusiberi Company (and not the Third Party
          Store providers) is solely responsible for the App and the content thereof. To the extent
          that these Terms provide for usage rules for the App which are less restrictive or in
          conflict with the applicable terms of service of the Third Party Store from which you
          obtain the App, the more restrictive or conflicting term of the Third Party Store will take
          precedence and will apply.
        </Text>
        <Text>
          2. The Third Party Store provider has no obligation whatsoever to provide any maintenance
          and support services with respect to the App. The Gusiberi Company is solely responsible
          for any product warranties, whether express or implied by law, to the extent not
          effectively disclaimed. The Third Party Store provider will have no warranty obligation
          whatsoever with respect to the App, and any other claims, losses, liabilities, damages,
          costs or expenses attributable to any failure to conform to any warranty will be the sole
          responsibility of the Gusiberi Company.
        </Text>
        <Text>
          3. The Gusiberi Company, not the Third Party Store provider, is responsible for addressing
          any claims you or any third party may have relating to the App or your possession and/or
          use of the App, including, but not limited to: (i) product liability claims; (ii) any claim
          that the App fails to conform to any applicable legal or regulatory requirement; (iii)
          claims arising under consumer protection or similar legislation; and/or (iv) intellectual
          property infringement claims.
        </Text>
        <Text>
          4. The Third Party Store provider and its subsidiaries are third party beneficiaries of these
          Terms, and, upon your acceptance of these Terms, the Third Party Store provider from
          whom you obtained the App will have the right (and will be deemed to have accepted the
          right) to enforce these Terms against you as a third party beneficiary thereof. In the event of a conflict between a Third Party Store's or mobile carrier's applicable terms and conditions and these Terms, the terms and conditions of the Third Party Store or mobile carrier shall govern and control. We are not responsible and have no liability whatsoever for third-party goods or services you obtain through a Third Party Store or mobile carrier. We encourage you to make whatever investigation you feel necessary or appropriate before proceeding with any online transaction with any of these third parties.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>
          13. TERMINATION AND REMEDIES
        </Text>
        <Text>
          These Terms commence on the date you accept them (as described in the preamble) and continue
          until terminated in accordance with the terms herein.
        </Text>
        <Text mt={1}>
          If you want to terminate these Terms, you may do so by (a) notifying Gusiberi Company at any
          time, and (b) closing your Account. Your notice should be sent to Gusiberi Company's address
          below. Please note that if you terminate these Terms, your subscription will continue until the
          end of the subscription period for which applicable fees have been paid, and you will not be
          entitled to a refund except as stated in Section 5.
        </Text>
        <Text mt={1}>
          Gusiberi Company may terminate or suspend these Terms, including your Account, if you breach
          these Terms or if Gusiberi Company is required to do so by applicable law. You agree that all
          terminations for cause shall be made in Gusiberi Company's sole discretion and that Gusiberi
          Company shall not be liable to you or any third party for any termination of your Account.
          In the event that Gusiberi Company determines, in its sole discretion, that you have breached any portion of these Terms, or have otherwise demonstrated conducted inappropriate for the App,
          Gusiberi Company reserves the right to: (a) warn you via email (to any email addresses you have
          provided to Gusiberi Company) that you have violated the Terms; (b) delete your User Content; (c) discontinue your Account; (d) discontinue your subscription(s); (e) notify and/or send your User Content to and/or fully cooperate with the proper law enforcement authorities for further action; and/or (f) pursue to any other action which Gusiberi Company deems to be appropriate.
        </Text>
        <Text mt={1}>
          Termination of these Terms or your Account includes the removal of access to your Account, and
          all related information and content associated with or inside your Account.
        </Text>
        <Text mt={1}>
          All provisions of these Terms, which by their nature should survive, shall survive termination of these Terms, including, without limitation, the Arbitration Agreement, ownership provisions,
          warranty disclaimers and limitation of liability.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>14. MISCELLANEOUS</Text>
        <Text>There are a few more things we need to mention before you can use Gusiberi.</Text>
        <Text mt={1}>
          These Terms, which we may amend from time to time, constitute the entire agreement between
          you and the Gusiberi Company. The Terms supersede all previous agreements, representations
          and arrangements between us (written or oral), excluding the Privacy Policy. Nothing in this
          clause shall limit or exclude any liability for fraudulent misrepresentation.
        </Text>
        <Text mt={1}>
          The Gusiberi Company has taken reasonable steps to ensure the currency, availability, correctness and completeness of the information contained on Gusiberi and provides that information on an &quot;as is&quot;, &quot;as available&quot; basis. The Gusiberi Company does not give or make any warranty or representation of any kind about the information contained on Gusiberi, whether express or implied. Use of Gusiberi and the materials available on it is at your sole risk. The Gusiberi Company is not responsible for any loss arising from the transmission, use of data, or inaccurate Member Content.
        </Text>
        <Text mt={1}>
          You are responsible for taking all necessary precautions to ensure that any material you may
          obtain from Gusiberi is free of viruses or other harmful components. You accept that Gusiberi will not be provided uninterrupted or error free, that defects may not be corrected or that The Gusiberi Company, or the server that makes it available, are free of viruses or bugs, spyware, Trojan horse or any similar malicious software. The Gusiberi Company is not responsible for any damage to your computer hardware, computer software, or other equipment or technology including, but without limitation damage from any security breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or transmission, computer line or network failure or any other technical or other malfunction.
        </Text>
        <Text mt={1}>
          The communications between you and Gusiberi Company may take place via electronic means,
          whether you use the App or send Gusiberi Company emails, or whether Gusiberi Company posts
          notices in the App or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Gusiberi Company in electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Gusiberi Company provides to you electronically satisfy if it were to be in writing. The foregoing does not affect your statutory rights, including but not limited to the Electronic Signatures in Global and National Commerce Act at 15 U.S.C. Sec. 7001 et. seq.
        </Text>
        <Text mt={1}>We know our Terms are awesome, but we may have to change them now and again</Text>
        <Text mt={1}>
          As Gusiberi grows, we might have to make changes to these Terms so we reserve the right to
          modify, amend or change the Terms at any time (a “Change”). If we do this then the Changes will
          be posted on this page and we will indicate the Effective Date of the updates at the bottom of the Terms. In certain circumstances, we may send an email to you notifying you of a Change. It's also possible that we might ask you to agree to our Changes, but we'll let you know. You should regularly check this page for notice of any Changes - we want our users to be as informed as possible.
        </Text>
        <Text mt={1}>
          Your continued use of Gusiberi following any Change constitutes your acceptance of the Change
          and you will be legally bound by the new updated Terms. If you do not accept any Changes to the
          Terms, you should stop using Gusiberi immediately (uh oh, that's going to be hard!).
        </Text>
        <Text mt={1}>Additional items:</Text>
        <Text>
          If, for any reason, any of the Terms are declared illegal, invalid or otherwise unenforceable by a court of a competent jurisdiction, then to the extent that term is illegal, invalid or unenforceable, it shall be severed and deleted from the Terms and the remainder of the Terms shall survive, remain in full force and effect and continue to be binding and enforceable.
        </Text>
        <Text mt={1}>
          No failure or delay in exercising any right, power or privilege under the Terms shall operate as a waiver of such right or acceptance of any variation of the Terms and nor shall any single or partial exercise by either party of any right, power or privilege preclude any further exercise of the right or the exercise of any other right, power or privilege.
        </Text>
        <Text mt={1}>You represent and warrant that:</Text>
        <Text>
          1. you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and
        </Text>
        <Text>2. you are not listed on any U.S. Government list of prohibited or restricted parties.</Text>
        <Text mt={1}>
          By using the App, you agree and acknowledge that Gusiberi is a global app operating through
          servers located in a number of countries around the world, including the United States. If you live in a country with data protection laws, the storage of your personal data may not provide you with the same protections as you enjoy in your country of residence. By submitting your personal information, or by choosing to upgrade the services you use, or by making use of the applications available on Gusiberi, you agree to the transfer of your personal information to, and storage and processing of your personal information in, any such countries and destinations.
        </Text>
        <Text mt={1}>
          The App may contain links to third-party websites or resources. In such cases, you acknowledge
          and agree that we are not responsible or liable for:
        </Text>
        <Text>1. the availability or accuracy of such websites or resources; or</Text>
        <Text>2. the content, products, or services on or available from such websites or resources.</Text>
        <Text mt={1}>
          Links to such websites or resources do not imply any endorsement. You acknowledge sole
          responsibility for and assume all risk arising from your use of any such websites or resources.
          Framing, in-line linking or other methods of association with the App are expressly prohibited
          without first obtaining our prior written approval.
        </Text>
        <Text mt={1}>
          These Terms, and any rights and licences granted hereunder, may not be transferred or assigned
          by you, but may be assigned by us without restriction.
        </Text>
        <Text>
          In the event there is a discrepancy between this English language version and any translated
          copies of the Terms, the English version shall prevail.
        </Text>
        <Text mt={1}>
          If you have any questions, complaints or claims with respect to the App, please contact us
          at feedback@Gusiberi.com
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>15. GOVERNING LAW AND FORUM.</Text>
        <Text>
          Subject to Section 13(3), your access to the App, Our Content, and any Member Content, any
          claims arising from or related to your relationship with the Gusiberi Company, and these Terms
          are governed and interpreted by the laws of the State of Texas. All claims arising out of or relating to these Terms and/or your relationship with the Gusiberi Company that for whatever reason are not submitted to arbitration, and all claims or cases challenging the enforceability or applicability of the arbitration provisions herein, will be litigated exclusively in the federal or state courts of Travis County, Texas. You agree that such courts shall have personal jurisdiction and venue and waive any objection based on inconvenient forum. You agree that you will not file or participate in a class action against us.
        </Text>
      </Section>
      <Section>
        <Text variant='medium' weight='bold'>16. THE Gusiberi COMPANY.</Text>
        <Text>
          The Terms constitute a binding legal agreement between you as user (“you”) and the Gusiberi Company (“we” or “us”). The Gusiberi Company a trade name under Matuski Limited.
        </Text>
        <Text weight='bold' mt={1}>Effective date</Text>
        <Text>The Terms were last updated on: 7th December, 2022.</Text>
      </Section>
    </PPWrapper>
  )
}

export default TermsOfService
