import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { colors, spacing } from '../../../../styles/_var'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button/Button'
import Input from '../../../../components/Input'
import { useDeleteAccount } from './Security.hooks'


const DeleteAccount = React.memo(() => {
  const { 
    setPassword,
    setErrorMessage,
    password,
    errorMessage,
    deleteAccount,
    isLoading
  } = useDeleteAccount()

  return (
    <Paper sx={{marginTop: spacing.sm, padding: spacing.xs}}>
      <Box>
        <Text center mb={2} weight="semibold">Delete your account</Text>
        <Text weight="semibold" center lighten>Please note that this action is irreversible</Text>
        <Input
          mt={1}
          onFocus={() => setErrorMessage('')}
          error={!!errorMessage}
          placeholder="Enter your password to delete."
          value={password}
          type="password"
          fullWidth
          size='small'
          onChange={e => setPassword(e.target.value)} />
        <Text style={{position: 'absolute'}} variant='small' color={colors.danger}>{errorMessage}</Text>
        <Button
          onClick={deleteAccount}
          sx={{background: colors.danger}}
          mt={3}
          fullWidth
          variant="contained"
        >
          {isLoading ? 'Deleting Account...' : 'Delete Account'}
        </Button>
      </Box>
    </Paper>
  )
})

export default DeleteAccount