import React from 'react'
import IconButton from '@mui/material/IconButton';
import { colors } from '../../../../../../styles/_var';
import Box from '@mui/material/Box';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

type NavButtonsProps = {
  onClickBack: () => void,
  onClickForward: () => void,
  canGoBack: boolean,
  canGoForward: boolean
}

const NavButtons = ({ 
  onClickBack, 
  onClickForward,
  canGoBack,
  canGoForward
}: NavButtonsProps) => {
  return (
    <Box display="flex" sx={{borderRightColor: colors.lightgrey, borderRightStyle: "dotted", paddingRight: 1}}>
      <IconButton 
        sx={{marginRight: 1, background: colors.lightgrey}} 
        disabled={!canGoBack}
        onClick={onClickBack}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton 
        sx={{background: colors.lightgrey}} 
        disabled={!canGoForward}
        onClick={onClickForward}
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  )
}

export default NavButtons