import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse';
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded';
import { ComponentSpacing } from '../../utils/types'
import { getStyleSpacing } from '../../utils/helpers'
import { Paper } from '@mui/material'
import { colors } from '../../styles/_var'
import Text from '../Text';

type CollapsibleCardProps = {
  textTitle: string,
  children: React.ReactNode,
  headerColor?: string,
  color?: string,
  initialCollapseState?: boolean,
  showArror?: boolean
} & ComponentSpacing

const CollapsibleCard = ({
  textTitle,
  children,
  headerColor,
  color,
  initialCollapseState=true,
  showArror=true,
  ...styleSpacing
}: CollapsibleCardProps) => {
  const [collapse, setCollapse] = useState(initialCollapseState)
  const spacing = getStyleSpacing(styleSpacing)
  const arrowStyle: React.CSSProperties = {
    fontSize: 25,
    transition: "transform ease .3s",
    cursor: "pointer",
    color,
    visibility: !showArror ? "hidden" : undefined
  }

  if (!collapse) {
    arrowStyle.transform = "rotate(180deg)"
  }


  return (
    <Paper elevation={3} style={spacing}>
     <Box 
        pt={1} 
        pb={1}
        px={2}
        bgcolor={headerColor || colors.lightGreen} 
        display="flex" 
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setCollapse(c => !c)} 
      >
        <Text weight="semibold" color={color}>
          {textTitle}
        </Text>
        <ArrowDropDownRounded style={arrowStyle} />
      </Box>
      <Collapse in={collapse}>
        {children}
      </Collapse>
    </Paper>
  )
}

export default CollapsibleCard