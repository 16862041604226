import Box from '@mui/material/Box'
import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import ImporantIcon from '@mui/icons-material/ReportGmailerrorred'
import { colors } from '../../../../styles/_var'
import Text from '../../../../components/Text';
import TextWithIcon from '../../../../components/TextWithIcon';
import IconButton from '@mui/material/IconButton';
import { SupplySuggestion, SupplyWithParticipant } from '../../../../utils/types';
import Button from '../../../../components/Button/Button';
import { useAuthStore } from '../../../../stores/authStore';
import Avatar from '../../../../components/Avatar/Avatar';


type ISupplySuggestionsListItemProps = {
  suggestion: SupplySuggestion,
  removeSuggestion: (id: number) => void,
  showDelete?: boolean
  onSelect?: (id: number) => void
  supplies?: SupplyWithParticipant[]
}

type ISupplySuggestionsListProps = {
  suggestions: SupplySuggestion[],
} & Omit<ISupplySuggestionsListItemProps, 'suggestion'>

const SupplySuggestionsList = ({
  suggestions,
  supplies,
  onSelect,
  ...rest
}: ISupplySuggestionsListProps) => {
  const { currentUser } = useAuthStore()
  const hasPickedSuggestion = (suggestion: SupplySuggestion) => {
    return supplies?.some(s => 
      s.item === suggestion.item &&
      s.additional_info === suggestion.additional_info &&
      s.user?.username === currentUser.username
    )
  }

  return (
    <>
      {suggestions.map(suggestion => (
        <SupplySuggestionsListItem 
          suggestion={suggestion} 
          key={suggestion.id}
          onSelect={hasPickedSuggestion(suggestion) ? undefined : onSelect}
          supplies={supplies}
          {...rest}
        />
      ))}
    </>
  )
}

const SupplySuggestionsListItem = ({
  suggestion,
  removeSuggestion,
  showDelete = true,
  supplies,
  onSelect
}: ISupplySuggestionsListItemProps) => {
  const listOfUsersThatPicked = supplies?.filter(s => s.item === suggestion.item && s.additional_info === suggestion.additional_info)?.map(s => s.user?.avatar || s.name)?.filter(s => s !== undefined) || []

  return (
    <Box 
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{borderBottom: `dotted 1px ${colors.lightgrey}`}} 
      mb={2} 
      pb={1}
    >
      <Box>
        {suggestion.is_vital 
          ? <TextWithIcon variant="medium" icon={<ImporantIcon color="primary" sx={{marginLeft: 0.5}} />} after>{suggestion.item}</TextWithIcon>
          : <Text variant="medium">{suggestion.item}</Text>
        }
        <Text variant="small" color={colors.grey}>{suggestion.additional_info}</Text>
        {listOfUsersThatPicked.length > 0 && (
            <Box mt={0.5}>
            {listOfUsersThatPicked.map((user, index) => user === undefined ? null : (
              <Avatar 
                key={index} 
                src={user} 
                sx={{width: 22, height: 22}} 
                addBorder={false}
                mr={1}
              >
                {user[0]?.toUpperCase()}
              </Avatar>
            ))}
          </Box>
        )}
      </Box>
      <Box>
        {!!onSelect && (
          <Button 
            size="small" 
            variant='contained' 
            sx={{padding: 0, minWidth: 32, borderRadius: '50%'}}
            onClick={() => !!suggestion.id && onSelect(suggestion.id)}
          >
            <Text variant='small' color={colors.white}>OK</Text>
          </Button>
        )}
        {showDelete && (
          <IconButton color="error" onClick={() => suggestion.id && removeSuggestion(suggestion.id)}>
            <CancelIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default SupplySuggestionsList
