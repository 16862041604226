import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import Slider from '@mui/material/Slider'
import Cropper from 'react-easy-crop'
import { colors, spacing } from '../../styles/_var'
import Text from '../Text'
import ImageCropper, { IPixelCrop } from '../../utils/imageCropper'
import Button from '../Button/Button'

interface IImagePreviewer {
  imageUrl: string,
  onClose: () => void,
  onComplete: (image: string) => void,
  maxWidth?: number,
  aspectRatio?: number,
}

let croppedProperties: any = null;

const ImagePreviewer: FunctionComponent<IImagePreviewer> = ({
  imageUrl,
  onClose,
  onComplete,
  maxWidth,
  aspectRatio,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [completingCrop, setCompletingCrop] = useState(false)

  const onCropComplete = (_: IPixelCrop, croppedAreaPixels: IPixelCrop) => {
    croppedProperties = croppedAreaPixels
  }

  const saveCroppedImage = async () => {
    if (completingCrop) return
    setCompletingCrop(true)

    if (croppedProperties) {
      let resizeValue = 1
  
      if (maxWidth && croppedProperties.width > maxWidth) {
        resizeValue = maxWidth / croppedProperties.width
      }
  
      const cropper = new ImageCropper(imageUrl)
      const croppedImage = await cropper.getCroppedImg(croppedProperties, rotation, resizeValue)
      if (croppedImage) onComplete(croppedImage)
    } else {
      onComplete(imageUrl)
    }

    onClose()
    setCompletingCrop(false)
  }

  return (
    <ImagePreviewModal onClick={onClose}>
      <ImagePreviewContainer onClick={(e) => e.stopPropagation() }>
        <Text variant="medium">Image Previewer</Text>
        <ImagePreviewPane>
          <Cropper 
            image={imageUrl}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom} />
        </ImagePreviewPane>
        <ControlSection>
          <SliderContainer>
            <Text variant="regular">Zoom</Text>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(_:any, zoom: any) => {
                setZoom(zoom)
              }}
            />
          </SliderContainer>
          <SliderContainer>
            <Text variant="regular">Rotation</Text>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(_: any, rotation: any) => setRotation(rotation)}
            />
          </SliderContainer>
          <Button
            variant="contained"
            onClick={saveCroppedImage}
            loading={completingCrop}
          >
            Complete
          </Button>
          <Button 
            variant="outlined"
            ml={2}
            onClick={onClose}
          >
            Cancel
          </Button>
        </ControlSection>
      </ImagePreviewContainer>
    </ImagePreviewModal>
  )
}

export default ImagePreviewer

const ImagePreviewModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
`

const ImagePreviewContainer = styled.div`
  position: relative;
  width: 60%;
  min-width: 35rem;
  max-width: 80rem;
  background-color: ${colors.white};
  padding: ${spacing.xs} ${spacing.sm};
  border-radius: 1rem;
  top: 20%;
  margin: auto;
`

const ImagePreviewPane = styled.div`
  position: relative;
  height: 30rem;
  width: 100%;
`

const ControlSection = styled.div``

const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${spacing.xs} 0;
  p {
    width: 9rem;
    text-align: left;
  }
`