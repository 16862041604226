import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import Popover from '@mui/material/Popover';
import DateRequestClass from '../../../services/DateRequestClass';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Text from '../../../components/Text';
import { routes } from '../../../utils/constants';
import { colors } from '../../../styles/_var';
import { useNavigate } from 'react-router-dom';
import { useDeleteDateRequestMutation } from '../../../services/api';
import { useUiStore } from '../../../stores/uiStore';
import { useProcessError } from '../../../hooks/hooks';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

type RequestOptionsButtonProps = { 
  dateInfo?: DateRequestClass
} & React.HTMLAttributes<HTMLDivElement>


const useRequestOptionsButton = ({ dateInfo }: RequestOptionsButtonProps) => {
  const [openMenu, setOpenMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate()
  const [deleteRequest] = useDeleteDateRequestMutation()
  const { 
    openLoadingScreen, 
    closeLoadingScreen,
    showConfirmDialog,
    openSuccessToast
  } = useUiStore()
  const processError = useProcessError()

  const editActivityLink = dateInfo?.dateRequest ? routes.EDIT_DATE_REQUEST.replace(':date_id', dateInfo.dateRequest.slug) : ''
  
  const toggleMenu = () => setOpenMenu(!openMenu)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    toggleMenu()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toggleMenu()
  }

  const onClickEdit = () => {
    handleClose()
    navigate(editActivityLink)
  }

  const onClickDelete = async () => {
    if (!dateInfo?.dateRequest) return
    handleClose()
    showConfirmDialog({
      dialogTitle: "Delete Date Request",
      dialogBody: "Are you sure you want to permanently delete this date request?",
      onConfirmAction: async () => {
        openLoadingScreen("Deleting date request...")
        try {
          const response = await deleteRequest({request_id: dateInfo.dateRequest.request_id}).unwrap()
          openSuccessToast({message: response.message})
        } catch (e) {
          processError(e as FetchBaseQueryError)
        }

        closeLoadingScreen()
        navigate(routes.DASHBOARD)
      }
    })
  }

  return {
    openMenu,
    anchorEl,
    handleClick,
    handleClose,
    onClickEdit,
    onClickDelete
  }
}


const RequestOptionsButton = ({ dateInfo, ...rest }: RequestOptionsButtonProps) => {
  const { 
    openMenu, 
    anchorEl, 
    handleClick, 
    handleClose, 
    onClickEdit, 
    onClickDelete 
  } = useRequestOptionsButton({ dateInfo })
  
  if (!dateInfo?.iAmSender) return null

  return (
    <div {...rest}>
      <IconButton 
        onClick={handleClick}
        id="edit-button"
        disabled={dateInfo.hasExpired}
      >
        <SettingsIcon fontSize="large" />
      </IconButton> 
      <Popover
        sx={{marginTop: 10}}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <List sx={{width: 120}}>
          <ListItemButton onClick={onClickEdit}>
            <ListItemIcon sx={{minWidth: '25px'}}>
              <EditIcon />
            </ListItemIcon>
            <Text color={colors.darkgrey} weight="semibold">Edit</Text>
          </ListItemButton>
          <ListItemButton onClick={onClickDelete}>
            <ListItemIcon sx={{minWidth: '25px'}}>
              <DeleteIcon />
            </ListItemIcon>
            <Text color={colors.darkgrey} weight="semibold">Delete</Text>
          </ListItemButton>
        </List>
      </Popover>
    </div>
  )
}

export default RequestOptionsButton
