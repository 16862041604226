import React from 'react'
import SimpleForm from '../../../components/SimpleForm';
import { RequestDrawerWrapper } from './OpenDateRequests.styles';
import CreateStep2 from './CreateStep2';
import { useOpenDateRequests, constants, OpenDateRequestsFormValues } from './OpenDateRequests.hooks';
import { OpenDateRequest } from '../../../utils/types';

export type CreateOpenDateRequestProps = {
  closeDrawer?: () => void,
  updateData?: OpenDateRequest
}

const CreateOpenDateRequest = (props: CreateOpenDateRequestProps) => {
  const {
    drawerHeight,
    infoDetailsForm,
    infoDetailsActionButton,
    handleNext,
    handleComplete,
    requestConfirmAction,
    requestConfirmForm,
    steps,
    goBack,
    formValues,
  } = useOpenDateRequests(props)

  return (
    <RequestDrawerWrapper p={2} height={drawerHeight}>
      {steps === constants.STEP_1 && (
        <SimpleForm<OpenDateRequestsFormValues>
          animateForm
          initialValues={formValues}
          formFields={infoDetailsForm}
          actionButtons={infoDetailsActionButton}
          submitForm={handleNext}
          buttonWrapperClass='fixed-button-bottom'
        />
      )}
      {steps === constants.STEP_2 && (
        <CreateStep2
          goBack={goBack}
          form={requestConfirmForm}
          actionButton={requestConfirmAction}
          submitForm={handleComplete}
          formValues={formValues}
        />
      )}
    </RequestDrawerWrapper>
  )
}

export default CreateOpenDateRequest
