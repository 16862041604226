import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Text from '../../../../components/Text'
import { User } from '../../../../utils/types'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'


const CalendarHeader = ({ user }: { user: User }) => {
  const firstName = user?.full_name.split(' ')[0]

  return (
    <>
      <UserInfo user={user} pt={3} paddingX={2} />
      <Box paddingX={2} mt={2} pb={2}>
        <Text>View {firstName}'s calendar below and select an available date!</Text>
      </Box>
      <Divider />
    </>
  )
}

export default CalendarHeader