import { StoreHelpers } from "react-joyride";

export class AppServices {
  private static joyRideHelper: StoreHelpers

  public static setJoyRideHelpers(helpers: StoreHelpers) {
    if (!AppServices.joyRideHelper) {
      AppServices.joyRideHelper = helpers
    }
  }

  public static getJoyRideHelpers() {
    return AppServices.joyRideHelper
  }
}