import React, { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button/Button'
import { colors } from '../../../../styles/_var'
import { useDispatch } from 'react-redux'
import { closeDialog } from '../../../../stores/uiStore'

type DeclineModalProps = {
  onDecline: (declineReason: string) => void
}

const DeclineModal: FunctionComponent<DeclineModalProps> = ({
  onDecline
}) => {
  const dispatch = useDispatch()
  const [content, setContent] = useState("")

  const closeModal = () => dispatch(closeDialog())

  const declineContent = () => {
    onDecline(content)
    closeModal()
  }

  return (
    <Box px={2} py={3}>
      <Text weight="semibold" mb={1}>
        Please why are you declining this date request?
      </Text>
      <Input 
        value={content} 
        rows={3}
        multiline
        placeholder="Write a message"
        onChange={e => setContent(e.target.value)} />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant='outlined' mr={2} onClick={closeModal}>
          <Text>Cancel</Text>
        </Button>
        <Button variant='contained' onClick={declineContent}>
          <Text color={colors.white}>Decline</Text>
        </Button>
      </Box>
    </Box>
  )
}


export default DeclineModal