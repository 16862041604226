import React, { CSSProperties, FunctionComponent, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import countries from '../../assets/json/countries.json'
import { InputProps } from '../Input/Input'
import { Wrapper, StyledInput, StyledLabel, StyledSelect } from './PhoneNoInput.styles'


type PhoneNoInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  error?: boolean,
  helperText?: string,
  className?: string,
  style?: CSSProperties
  value?: string
} & InputProps

const PhoneNoInput: FunctionComponent<PhoneNoInputProps> = React.memo(({
  helperText,
  style,
  className,
  ...rest
}) => {
  const [extension, ] = (rest.value || '').split('-')
  const [ext, setExtension] = useState(extension || '+1')

  let value = rest.value?.replace(`${ext}-`, '')
  if (value && value.length > 4) {
    value = value?.replace(/^0/, '') 
  }


  return (
    <Wrapper className={className} style={style}>
      <div style={{position: 'relative'}}>
        <StyledLabel id='extension'>Ext</StyledLabel>
        <StyledSelect
          variant='outlined'
          labelId='extension'
          value={ext}
          onChange={(e: SelectChangeEvent<any>) => {
            setExtension(e.target.value)
          }}
          style={{minWidth: '84px'}}
        >
          {countries.map((country, i) => (
            <MenuItem value={country.dialCode} key={i}>
              {country.emoji} {country.dialCode}
            </MenuItem>
          ))}
        </StyledSelect>
      </div>
      <StyledInput 
        {...rest}  
        label="Phone number" 
        value={value}
        onChange={e => {
          e.target.value = `${ext}-${e.target.value}`
          rest.onChange(e)
        }}
      />
    </Wrapper>
  )
})

export default PhoneNoInput