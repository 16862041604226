export interface IPixelCrop {
  width: number,
  height: number,
  x: number,
  y: number
}

class ImageCropper {
  private createdImage: HTMLImageElement;
  private url: string;

  constructor(imageUrl: string) {
    this.url = imageUrl
    this.createdImage = new Image()
  }

  static createImage(imageUrl: string) {
    const imagePromise = new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => {
        const createdImage = image
        resolve(createdImage)
      })
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = imageUrl
    })

    return imagePromise
  }

  static async imageElementToBlob(image: HTMLImageElement, width?: number, height?: number): Promise<Blob | null> {
    const canvas = document.createElement("canvas");
    canvas.width = width || image.width;
    canvas.height = height || image.height;

    canvas.getContext("2d")?.drawImage(image, 0, 0, canvas.width, canvas.height);

    return new Promise<Blob|null>(resolve => {
      canvas.toBlob((file) => {
        resolve(file)
      }, 'image/jpeg')
    })
  }

  static resizeImage(image: HTMLImageElement, maxWidth: number) {
    var MAX_WIDTH = maxWidth;
    var MAX_HEIGHT = maxWidth;

    var width = image.width;
    var height = image.height;

    // resizing logic goes here
    if (width > height) {
      if (width > MAX_WIDTH) {
        height = height * (MAX_WIDTH / width);
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width = width * (MAX_HEIGHT / height);
        height = MAX_HEIGHT;
      }
    }
    
    return ImageCropper.imageElementToBlob(image, width, height)
  }

  getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180
  }

  rotateSize(width: number, height: number, rotation: number) {
    const rotRad = this.getRadianAngle(rotation)

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  async getCroppedImg(
    pixelCrop: IPixelCrop,
    rotation = 0,
    resize = 0,
    flip = { horizontal: false, vertical: false },
  ) {
    const image = await ImageCropper.createImage(this.url)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    const rotRad = this.getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
      image.width,
      image.height,
      rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<string>((resolve, reject) => {
      if (resize) {
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = canvas.width * resize;
        scaledCanvas.height = canvas.height * resize;

        scaledCanvas.getContext('2d')?.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
        scaledCanvas.toBlob((file: any) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      } else {
        canvas.toBlob((file) => {
          resolve(file ? URL.createObjectURL(file) : '')
        }, 'image/jpeg')
      }
    })
  }

  static async createThumnbail(imageUrl: string, maxWidth: number = 250) {
    const createdImage = await ImageCropper.createImage(imageUrl)
    if (createdImage.naturalHeight >= maxWidth || createdImage.naturalWidth >= maxWidth) {
      const thumbnail = await ImageCropper.resizeImage(createdImage, 250)
      if (thumbnail) return URL.createObjectURL(thumbnail)
    }
  }

  // deprecated
  async getCroppedImage(pixelCrop: IPixelCrop, rotation: number = 0, resize?: number) {
    this.createdImage = await ImageCropper.createImage(this.url)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
  
    const maxSize = Math.max(this.createdImage.width, this.createdImage.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
  
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea
  
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2)
    ctx?.rotate(this.getRadianAngle(rotation))
    ctx?.translate(-safeArea / 2, -safeArea / 2)
  
    // draw rotated image and store data.
    ctx?.drawImage(
      this.createdImage,
      safeArea / 2 - this.createdImage.width * 0.5,
      safeArea / 2 - this.createdImage.height * 0.5
    )
    const data = ctx?.getImageData(0, 0, safeArea, safeArea)
  
    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    // paste generated rotate image with correct offsets for x,y crop values.
    if (data) {
      ctx?.putImageData(
        data,
        Math.round(0 - safeArea / 2 + this.createdImage.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + this.createdImage.height * 0.5 - pixelCrop.y)
      )
    } else {
      throw Error('Error occured')
    }
  
    // As Base64 string
    // return canvas.toDataURL('image/jpeg');
  
    // As a blob
    return new Promise<string>(resolve => {
      if (resize) {
        const scaledCanvas = document.createElement('canvas');
        scaledCanvas.width = canvas.width * resize;
        scaledCanvas.height = canvas.height * resize;

        scaledCanvas.getContext('2d')?.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
        scaledCanvas.toBlob((file: any) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      } else {
        canvas.toBlob((file: any) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      }
    })
  }
}

export default ImageCropper