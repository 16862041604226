import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/CloseRounded';
import CalendarIcon from '@mui/icons-material/CalendarMonthRounded';
import styled from 'styled-components'
import ImageDownloadIcon from '@mui/icons-material/FileDownloadRounded';
import { BackdropWrapper } from '../../../DateRequests.styles'
import { ActivityBackDrop } from '../../../../PlanActivity/CreateActivity/CreateActivity.styles'
import Text from '../../../../../../components/Text'
import { colors } from '../../../../../../styles/_var'
import TextWithIcon from '../../../../../../components/TextWithIcon'
import ParticipantScore from './ParticipantScore';
import WinnerCard from './WinnerCard';
import { DateRequest, User } from '../../../../../../utils/types';
import { DateTime } from 'luxon';
import { TeamScoreboardData, useLazyFetchDateRequestQuery, useLazyFetchDateScoreboardRequestQuery } from '../../../../../../services/api';
import { getUserByPID, moveToFront } from '../../../../../../utils/helpers';
import { useDateMemoryScoreboard, ScoreBoard } from '../DateMemories.hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useHideFooterOnMount } from '../../../../../../hooks/hooks';

type ScorecardContentProps = {
  dateInfo: DateRequest,
  defaultScores: ScoreBoard[],
  scoreboardIndex?: number,
  winner?: number | string | null,
  style?: React.CSSProperties,
  className?: string,
}

const Scorecard = () => {
  const { date_id } = useParams()
  useHideFooterOnMount()
  const [fetchDate, { data: dateInfoFromServer }] = useLazyFetchDateRequestQuery()
  const [fetchScores, { data: scoresFromServer }] = useLazyFetchDateScoreboardRequestQuery()
  const scoreboardData = !!scoresFromServer?.payload?.length 
    ? scoresFromServer.payload.map(s => s.memory_object) 
    : undefined
  const [params,] = useSearchParams()
  const scoreboardIndex = +(params.get('scoreboardIndex') || '0')

  useEffect(() => {
    if (!date_id) return

    Promise.all([
      fetchDate({ request_id: date_id }),
      fetchScores({ request_id: date_id })
    ])
  }, [date_id, fetchDate, fetchScores])
  if (!dateInfoFromServer || !scoreboardData) return null
  
  return (
    <ScorecardContent 
      dateInfo={dateInfoFromServer.payload} 
      defaultScores={scoreboardData}  
      scoreboardIndex={scoreboardIndex}
    />
  )
}

const ScorecardContent = (props: ScorecardContentProps) => {
  const {dateInfo, defaultScores, scoreboardIndex, ...rest}= props
  const ref = useRef<HTMLElement>(null)
  const scoresData = defaultScores[scoreboardIndex || 0]?.scores_data
  const teams = scoresData.results && scoresData.members && scoresData.teams
  const isTeam = !!teams
  const navigate = useNavigate()
  const { downloadScorecard, scores, winner } = useDateMemoryScoreboard(defaultScores, dateInfo, !!scoreboardIndex ? +scoreboardIndex : undefined)

  return (
    <Wrapper {...rest} ref={ref}>
      <BackdropWrapper>
        <ActivityBackDrop backdrop={dateInfo.backdrop_image} />
        <ActionButton width="100%" display="flex" justifyContent="space-between">
          <IconButton sx={{position: "relative"}} onClick={() => navigate(-1)}>
            <CloseIcon fontSize="large" />
          </IconButton>
          <IconButton   
            sx={{position: "relative"}}       
            onClick={() => {
              downloadScorecard(ref.current, dateInfo.activity.replace(/ /g, '-')).then(() => {
                navigate(-1);
              })
          }}>
            <ImageDownloadIcon fontSize="large" />
          </IconButton>
        </ActionButton>
      </BackdropWrapper>
      <ScorecardInfo elevation={4}>
        <Box p={2}>
          <Text variant="semi-large" weight="semibold" center mb={1}>
            {dateInfo.activity}
          </Text>
          <TextWithIcon 
            justifyContent="center"
            icon={<CalendarIcon fontSize="large" />} 
            variant="medium"
            lineHeight={1}
          >
            {DateTime.fromISO(dateInfo.end_date).toLocaleString(DateTime.DATE_FULL)}
          </TextWithIcon>
        </Box>
      </ScorecardInfo>
      {isTeam 
        ? <TeamScorecardContent scores={scores} {...props} winner={winner} /> 
        : <SoloScorecardContent scores={scores} {...props} winner={winner} />
      }
      <Box 
        display="flex" 
        justifyContent="center" 
        flexDirection="column" 
        alignItems="center"
        mb={2}
      >
        <Text variant="extra-small" weight="semibold" center>
          Powered by
        </Text>
        <img 
          src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" 
          height="25px"
          width="fit-content"
          alt="gusiberi logo"
        />
      </Box>
    </Wrapper>
  )
}


const TeamScorecardContent = ({
  dateInfo,
  defaultScores,
  scoreboardIndex=0,
  scores,
  winner,
  ...rest
}: ScorecardContentProps & {scores: Record<string, string[]>}) => {
  const scoresData = (defaultScores[scoreboardIndex || 0]?.scores_data) as TeamScoreboardData
  if (!winner) return null
  const winningTeamMembers = (scoresData)?.members[winner]
  const winningTeamMembersUser = winningTeamMembers?.map(pid => getUserByPID(pid, dateInfo.date_participants)).filter(Boolean) as User[]
  const teamsAndScores = moveToFront(
    Object.keys(scores).map((teamName) => ({
      team: {
        teamName,
        members: scoresData
          ?.members[teamName]
          ?.map(pid => getUserByPID(pid, dateInfo.date_participants))
          .filter(Boolean) as User[],
      },
      scores: scores[teamName],
      isWinner: winner === teamName,
    })),
    item => item.isWinner
  )

  return (
    <ScoreboardContent {...rest}>
      <WinnerCard user={winningTeamMembersUser} teamName={`${winner}`} scores={[]} />
      <Box mt={1.2}>
        {teamsAndScores.map((teamData, index) => (
          <ParticipantScore 
            index={index+1}
            isWinner={teamData.isWinner}
            key={index} 
            team={teamData.team}
            scores={teamData.scores}
            totalScore={teamData.scores.reduce((acc, curr) => acc + (parseInt(curr)), 0)}
          />
        ))}
      </Box>
    </ScoreboardContent>
  )
}


const SoloScorecardContent =({
  dateInfo,
  scores,
  winner
}: ScorecardContentProps & {scores: Record<string, string[]>}) => {
  if (!winner || typeof winner === 'string' ) return null
  const winnerScore = scores[winner]
  const winnerParticipant = getUserByPID(winner || 0, dateInfo.date_participants)
  
  const participantsAndScores = moveToFront(
    Object.keys(scores).map(pid => ({
      pid: pid,
      user: getUserByPID(parseInt(pid), dateInfo.date_participants),
      totalScore: scores[parseInt(pid)].reduce((acc, curr) => acc + (parseInt(curr)), 0),
      scores: scores[parseInt(pid)]
    })).sort((a, b) => b.totalScore - a.totalScore).slice(0, 5),
    item => +item.pid === winner
  )

  return (
    <ScoreboardContent>
      {!!winnerParticipant && (
        <WinnerCard 
          user={winnerParticipant}
          scores={winnerScore} 
        />
      )}
      <Box mt={1.2}>
        {participantsAndScores.map((participant, index) => (
          <ParticipantScore 
            index={index+1}
            isWinner={winner === parseInt(participant.pid)}
            key={index} 
            user={participant.user} 
            totalScore={participant.totalScore} 
            scores={participant.scores}
          />
        ))}
      </Box>
    </ScoreboardContent>
  )
}


export default Scorecard

const ScoreboardContent = styled(Box)`
  margin-top: -15px;
`

const Wrapper = styled(Box)`
  max-width: 450px;
  background-color: ${colors.lightgrey};
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: scroll;
`

const ScorecardInfo = styled(Paper)`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);

  &.MuiPaper-root {
    background: rgba(255, 255, 255, 0.92);
  }
`

const ActionButton = styled(Box)`
  position: absolute;
  top: 0;
  & button {
    position: relative;
    margin-top: 5px;

    &:first-child {
      margin-left: 5px;
    }

    &:last-child {
      margin-right: 5px;
    }
  }
`
