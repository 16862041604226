import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon';
import Box from '@mui/material/Box'
import { DateTimeslot } from '../../../utils/types';
import { DeleteButton, Spacer, TimeBox } from './Availability.styles';
import { colors } from '../../../styles/_var';
import TrashIcon from '@mui/icons-material/DeleteOutlineRounded'


type ScheduleTimeItemProps = {
  dateSchedule: DateTimeslot,
  isLast?: boolean,
  onDeleteSchedule: () => void,
  style?: React.CSSProperties
}

const ScheduleTimeItem: FunctionComponent<ScheduleTimeItemProps> = ({
  dateSchedule: {start_time, end_time},
  isLast,
  onDeleteSchedule,
  style={}
}) => {
  const startTime = DateTime.fromISO(start_time)
  const endTime = DateTime.fromISO(end_time)

  return (
    <Box 
      style={style}
      display="flex" 
      alignItems="center" 
      justifyContent="space-between" 
      mb={isLast ? undefined : 1}
    >
      <Box display="flex" alignItems="center">
        <TimeBox variant="subtext" inline>{startTime.toFormat('h:mm a')}</TimeBox>
        <Spacer />
        <TimeBox variant="subtext" inline>{endTime.toFormat('h:mm a')}</TimeBox>
      </Box>
      <Box display="flex" alignItems="center">
        <DeleteButton 
          variant='contained' 
          onClick={onDeleteSchedule} 
          color="error"
        >
          <TrashIcon fontSize='large' sx={{color: colors.white}} />
        </DeleteButton>
      </Box>
    </Box>
  )
}

export default ScheduleTimeItem