import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { IActionButton } from '../../../components/SimpleForm'
import validators from '../../../utils/validators';
import { closeDialog, openConfirmDialog, openDialog, showToastMessage } from '../../../stores/uiStore';
import { useActivateDayMutation, useAddScheduleMutation, useDeactivateDayMutation, useDeleteScheduleMutation } from "../../../services/api"
import { useProcessError } from "../../../hooks/hooks"
import { addAvailability, disableWeekday, enableWeekday, removeAvailability, selectAvailability } from '../../../stores/availabilityStore';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DAYS_OF_THE_WEEK, DAY_OF_THE_WEEK, DateTimeslot } from "../../../utils/types"
import AddSchedule from "./AddSchedule";
import { DateTime } from "luxon";


export const useAddSchedule = (dayOfWeek: DAY_OF_THE_WEEK) => {
  const [isRecurring, setIsRecurring] = useState(true)
  const [addSchedule, {isLoading}] = useAddScheduleMutation()
  const dispatch = useDispatch()
  const processError = useProcessError()

  const initialFormValues = {start_time: DateTime.now(), end_time: DateTime.now().plus({hours: 1})}

  const formFields = [
    {
      name: 'start_time',
      label: `Start ${!isRecurring ? 'Date' : ''}Time`,
      component: isRecurring ? TimePicker : DateTimePicker,
      sx: {width: '100%'},
      validate: validators.required
    },
    {
      name: 'end_time',
      label: `End ${!isRecurring ? 'Date' : ''}Time`,
      component: isRecurring ? TimePicker : DateTimePicker,
      sx: {width: '100%'},
      validate: validators.required
    }
  ]

  const actionButtons: IActionButton[] = [
    {
      label: 'Cancel',
      variant: 'outlined',
      onClick: () => dispatch(closeDialog()),
      sx: {width: '9rem', height: '4rem'}
    },
    {
      loading: isLoading,
      label: 'Save',
      isSubmit: true,
      variant: 'contained',
      ml: 1,
      sx: {width: '9rem', height: '4rem'}
    },
  ]

  const submitForm = async (data: any) => {
    let startDate, endDate
    const { start_time, end_time } = data

    if (isRecurring) {
      startDate = start_time.set({weekday: DAYS_OF_THE_WEEK.indexOf(dayOfWeek) + 1})
      endDate = end_time.set({weekday: DAYS_OF_THE_WEEK.indexOf(dayOfWeek) + 1})
    } else {
      startDate = start_time
      endDate = end_time
    }

    const payload: Omit<DateTimeslot, "id"> = {
      recurring: isRecurring,
      start_time: startDate.toISO(),
      end_time: endDate.toISO()
    }

    try {
      const response = await addSchedule(payload).unwrap()
      dispatch(closeDialog())
      dispatch(showToastMessage({message: 'Time slot added!', severity: 'success'}))
      dispatch(addAvailability({day: dayOfWeek, schedule: response.payload}))
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  return { 
    submitForm, 
    setIsRecurring, 
    isRecurring, 
    isLoading, 
    formFields,
    actionButtons, 
    initialFormValues 
  }
}

export const useSchedule = () => {
  const processError = useProcessError()
  const [deleteSchedule] = useDeleteScheduleMutation()
  const [activateDay] = useActivateDayMutation()
  const [deactivateDay] = useDeactivateDayMutation()
  const dispatch = useDispatch()
  const data = useSelector(selectAvailability)

  const handleToggle = (a: any) => () => null

  const onCheckBoxClicked = (day: DAY_OF_THE_WEEK) => async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(enableWeekday(day))
      try {
        await activateDay({day_of_week: DAYS_OF_THE_WEEK.indexOf(day) + 1}).unwrap()
        dispatch(showToastMessage({ message: `schedule for ${day} has been activated`, severity: 'success' }))
      } catch (error) {
        processError(error as FetchBaseQueryError)
      }
    } else {
      dispatch(disableWeekday(day))
      
      if (!data[day]?.date_schedule_timeslots?.length) {
        deactivateDay({day_of_week: DAYS_OF_THE_WEEK.indexOf(day) + 1}).unwrap()
      } else {
        dispatch(openConfirmDialog({
          dialogTitle: 'Are you sure?',
          dialogBody: 'Would you like to de-activate this timeslots for today',
          onConfirmAction: async () => { 
            dispatch(disableWeekday(day))
            try {
              await deactivateDay({day_of_week: DAYS_OF_THE_WEEK.indexOf(day) + 1}).unwrap()
              dispatch(showToastMessage({ message: `schedule for ${day} has been deactivated`, severity: 'success' }))
            } catch (error) {
              processError(error as FetchBaseQueryError)
            }
          }
        }))
      }
      
    }
  }

  const onAddSchedule = (day: DAY_OF_THE_WEEK) => () => {
    dispatch(openDialog({dialogBody: <AddSchedule dayOfWeek={day}  />}))
  }

  const removeSchedule = (scheduleId: number, day: DAY_OF_THE_WEEK) => () => {
    dispatch(openConfirmDialog({
      dialogBody: 'Are you sure you want to delete this time slot?',
      onConfirmAction: async () => {
        try {
          dispatch(removeAvailability({id: scheduleId, day}))
          const response = await deleteSchedule({id: scheduleId}).unwrap()
          dispatch(showToastMessage({message: response.message, severity: 'success'}))
        } catch (error) {
          processError(error as FetchBaseQueryError)
        }
      }
    }))
  }

  return { data, handleToggle, onAddSchedule, onCheckBoxClicked, removeSchedule }
}

