import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import SimpleForm, { IActionButton } from '../../../components/SimpleForm';
import { OpenDateRequestsFormValues } from './OpenDateRequests.hooks';
import TextWithIcon from '../../../components/TextWithIcon';
import { colors } from '../../../styles/_var';
import { SummaryWrapper } from './OpenDateRequests.styles';
import LocationText from '../../../components/LocationText/LocationText';
import { fullDateRange } from '../../../utils/helpers';

type CreateStep2Props = {
  form: any[],
  formValues?: OpenDateRequestsFormValues,
  goBack: () => void,
  actionButton: IActionButton,
  submitForm: (values: OpenDateRequestsFormValues) => void
}

const CreateStep2 = ({
  form,
  actionButton,
  submitForm,
  goBack,
  formValues
}: CreateStep2Props) => {
  useEffect(() => {
    if (!formValues) goBack()
  }, [formValues, goBack])

  return (
    <Box>
      <TextWithIcon 
        variant="small"
        onClick={goBack}
        icon={<LeftIcon sx={{color: colors.link}} />} 
        before 
        mb={1}
        color={colors.link}
      >
        Back
      </TextWithIcon>
      <Summary formValues={formValues} />
      <SimpleForm<OpenDateRequestsFormValues>
        animateForm
        initialValues={formValues}
        formFields={form}
        actionButtons={actionButton}
        submitForm={submitForm}
        buttonWrapperClass='fixed-button-bottom'
      />
    </Box>
  )
}

const Summary = ({ formValues }: {formValues?: OpenDateRequestsFormValues}) => {
  const fullDateTime = React.useMemo(() => {
    if (!formValues) return ''
    return fullDateRange(formValues.start_date, formValues.end_date)
  }, [formValues])

  if (!formValues) return null

  return (
    <SummaryWrapper>
      <LocationText 
        textProps={{
          weight: "semibold",
          color: colors.darkgrey,
          truncate: true,
        }}
        location={formValues.location} 
      />
      <TextWithIcon 
        lineHeight={10} 
        icon={<ClockIcon sx={{color: colors.grey}} />} 
        weight="semibold" 
        mt={1} 
        lighten
      >
        {fullDateTime}
      </TextWithIcon>
    </SummaryWrapper>
  )
}

export default CreateStep2