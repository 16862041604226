import { useEffect, useRef } from "react"
import { Step } from 'react-joyride'
import { useUiStore } from "../stores/uiStore"
import content from "../pages/Dashboard/content"
import { useSetDemoViewedMutation } from "../services/api"
import { useAuthStore } from "../stores/authStore"

export const useTour = (steps: Step[]) => {
  const { userIsActivated } = useAuthStore()
  const {saveJoyRideSteps, setJoyRideState} = useUiStore()

  useEffect(() => {
    if (!steps.length || !userIsActivated) return
    saveJoyRideSteps(steps)
    setJoyRideState(true)
    // eslint-disable-next-line
  }, [])
}

export const useCannotRunJoyRide = (demoName: 'dashboard' | 'schedule'): boolean => {
  const { hasShownDashboardDemo, hasShownScheduleDemo } = useUiStore()
  const { activityTimestamps, isLoggedIn, userIsActivated } = useAuthStore()
  return (demoName === 'dashboard' ? hasShownDashboardDemo : hasShownScheduleDemo)
    || !!activityTimestamps.demo_viewed_on
    || !isLoggedIn
    || !userIsActivated
}

export const useDashboardTour = () => {
  const effectHasRun = useRef(false)

  const { setDemoHasBeenShown } = useUiStore()
  const [touchDemoViewed, ] = useSetDemoViewedMutation()
  const { activityTimestamps } = useAuthStore()
  const cantRunJoyRide = useCannotRunJoyRide('dashboard')
  const shouldNotShow = effectHasRun.current || cantRunJoyRide

  useTour(shouldNotShow ? [] : [
    {
      target: '.jr-messages',
      content: 'Chat messages are enabled here for upcoming dates or group activities and expire once the activity or date is over.'
    },
    {
      target: '.jr-notifications',
      content: 'Your notifications can be found here! It looks like you have some unread notifications, you should check them out now.'
    },
    {
      target: '.jr-availability',
      content: content.availability_tip
    },
    {
      target: '.jr-preferred-activities',
      content: content.fav_activities_tip
    },
    { 
      target: '.jr-group-activity',
      content: 'Click here to plan a group activity with your friends or family here.'
    },
    {
      target: '.jr-plan-date',
      content: 'Click here to plan a date or meetup with a friend.',
    },
    {
      target: '.jr-open-invite',
      content: 'Here you can create an open invite that would be visible to all the friends in your friends circle. You\'ll get notified when any of them are interested in your open invite.'
    }]
  )

  if (shouldNotShow) return

  effectHasRun.current = true
  setTimeout(() => {
    setDemoHasBeenShown("hasShownDashboardDemo", true)
    if (!activityTimestamps.demo_viewed_on) touchDemoViewed({})
  }, 1000)
}

export const useAvailabilityTour = () => {
  const effectHasRun = useRef(false)
  const { setDemoHasBeenShown } = useUiStore()
  const [touchDemoViewed, ] = useSetDemoViewedMutation()
  const { activityTimestamps } = useAuthStore()
  const cantRunJoyRide = useCannotRunJoyRide('schedule')
  const shouldNotShow = effectHasRun.current || cantRunJoyRide

  useTour(shouldNotShow ? [] : [
    {
      target: '.jr-add-schedule',
      content: 'Click here to add an available timeslot to setup your schedule.'
    }
  ])

  if (shouldNotShow) return

  effectHasRun.current = true
  setTimeout(() => {
    setDemoHasBeenShown("hasShownScheduleDemo", true)
    if (!activityTimestamps.demo_viewed_on) touchDemoViewed({})
  }, 1000) //
}