import React from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import SimpleForm, { IActionButton } from '../../../../components/SimpleForm'
import Input from '../../../../components/Input'
import validators from '../../../../utils/validators'
import { useSendSupportMessageMutation } from '../../../../services/api'
import { useProcessError } from '../../../../hooks/hooks'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useDispatch } from 'react-redux'
import { showToastMessage } from '../../../../stores/uiStore'

const ContactUs = React.memo(() => {
  const [sendMessage, { isLoading }] = useSendSupportMessageMutation()
  const processError = useProcessError()
  const dispatch = useDispatch()

  const fields = [
    {
      name: 'subject',
      component: Input,
      placeholder: 'Subject',
      validate: validators.required
    },
    {
      name: 'message',
      component: Input,
      placeholder: 'Message',
      multiline: true,
      minRows: 3,
      validate: validators.required
    }
  ]

  const actionButton: IActionButton = {
    label: 'Send Message',
    loading: isLoading,
    isSubmit: true,
    variant: 'contained'
  }

  const sendHelpMessage = async (data: any, form: any) => {
    try {
      const response = await sendMessage({...data}).unwrap()
      dispatch(showToastMessage({message: response.message, severity: 'success'}))
      form.reset()
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  return (
    <Box mt={3}>
      <Text weight="semibold" center maxWidth={320} mb={4}>
        Are you having any issues? Please reach out to us and our rep would get back to you as soon as possible.
      </Text>
      <SimpleForm
        submitForm={sendHelpMessage}
        formFields={fields}
        actionButtons={actionButton} />
    </Box>
  )
})

export default ContactUs
