import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import CalendarHeader from './CalendarHeader'
import Calendar from './Calendar'
import TimeSlotSelector from './TimeslotSelector'
import ContinueButton from './ContinueButton'
import { DateTimeslot, User } from '../../../../utils/types'
import { ProfileResponse } from '../../../../services/api'
import { DateTime } from 'luxon'
import { animateComponent } from '../../../../utils/hoc'


type SelectDateAndTimeProps = {
  user?: User,
  data?: ProfileResponse,
  shouldBeDisabled: (date: DateTime) => boolean,
  selectedDate?: DateTime | null,
  setSelectedDate: (date?: DateTime | null) => void,
  timeSlotsForDate: DateTimeslot[],
  selectedTimeslot: number | null,
  setSelectedTimeslot: (timeslot: number | null) => void,
  isLoading: boolean,
  onComplete: () => void,
  selectedTimeslotPayload?: DateTimeslot,
  onSelectDate: (date?: DateTime | null) => void
}

const SelectDateAndTime: FunctionComponent<SelectDateAndTimeProps> = ({
  user,
  data,
  shouldBeDisabled,
  selectedDate,
  timeSlotsForDate,
  selectedTimeslot,
  setSelectedTimeslot,
  isLoading,
  onComplete,
  onSelectDate
}) => {
  if (!data || !user) return null

  return (
    <Container>
      <Paper component={Box} mt={4} mb={4}>
        <CalendarHeader user={user} />
        <Box padding={2}>
          <Calendar 
            shouldBeDisabled={shouldBeDisabled}
            selectedDate={selectedDate}
            onSelectDate={onSelectDate} />
        </Box>
        {!!timeSlotsForDate && (
          <TimeSlotSelector 
            timeslots={timeSlotsForDate}
            selectedTimeslot={selectedTimeslot}
            selectedDate={selectedDate}
            setSelectedTimeslot={setSelectedTimeslot} 
          />
        )}
      </Paper>
      <ContinueButton disabled={isLoading || !selectedTimeslot} onClick={onComplete} />
    </Container>
  )
}

export default animateComponent<SelectDateAndTimeProps>(SelectDateAndTime, true)
