import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Text from '../../../../components/Text'
import { DashboardCard } from '../../../Dashboard/Home/Home.styles'
import { colors, spacing } from '../../../../styles/_var'
import { DateTimeslot, User } from '../../../../utils/types'
import { DateDetails } from './AddDetails'
import { capitalize, dhm } from '../../../../utils/helpers'
import { DateTime } from 'luxon'
import Button from '../../../../components/Button/Button'
import TextWithIcon from '../../../../components/TextWithIcon'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import { animateComponent } from '../../../../utils/hoc'
import { DateRequestsRequest, useSendDateRequestMutation } from '../../../../services/api'
import { useProcessError } from '../../../../hooks/hooks'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'

type SendRequestProps = {
  user?: User,
  details: DateDetails,
  selectedDate?: DateTime | null,
  selectedTimeslot?: DateTimeslot,
  goBack: () => void,
  onSuccess?: () => void
}

type Keys = keyof DateDetails

const SendRequest: FunctionComponent<SendRequestProps> = ({
  user,
  details,
  selectedDate,
  selectedTimeslot,
  goBack,
  onSuccess
}) => {
  const [postRequest, {isLoading}] = useSendDateRequestMutation()
  const processError = useProcessError()

  if (!user || !selectedTimeslot || !selectedDate) return null

  const startTime = DateTime.fromISO(selectedTimeslot.start_time)
  const endTime = DateTime.fromISO(selectedTimeslot.end_time)

  const appointmentStartDate = selectedDate.set({
      hour: !!details.start_date ? details.start_date.hour : startTime.hour,
      minute: !!details.start_date ? details.start_date.minute : startTime.minute,
      second: !!details.start_date ? details.start_date.second : startTime.second 
    }) 

  const appointmentEndDate = selectedDate.set({
      hour: !!details.end_date ? details.end_date.hour : endTime.hour,
      minute: !!details.end_date ? details.end_date.minute : endTime.minute,
      second: !!details.end_date ? details.end_date.second : endTime.second
    })

  const durationArr = dhm(appointmentEndDate.diff(appointmentStartDate).milliseconds)
  const duration = durationArr.find(e => e.value !== 0)

  const fullDate = appointmentStartDate.toLocaleString(DateTime.DATE_MED)
  const start = appointmentStartDate.toLocaleString(DateTime.TIME_SIMPLE)
  const end = appointmentEndDate.toLocaleString(DateTime.TIME_SIMPLE)

  const onSendDateRequest = async () => {
    const requestPayload = {
      ...details,
      sent_to: user.username,
      start_date: appointmentStartDate.toISO(),
      end_date: appointmentEndDate.toISO(),
    } as Omit<DateRequestsRequest, 'request_id'>
    try {
      await postRequest(requestPayload).unwrap()
      onSuccess && onSuccess()
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }


  return (
    <Container>
      <Box mt={3}>
        <TextWithIcon
          style={{width: 'fit-content'}}
          mb={2} 
          icon={<LeftIcon />}
          onClick={goBack}
        >
          Go Back
        </TextWithIcon>
        <DashboardCard elevation={2} noEffect>
          <Box padding={spacing.xxs} bgcolor={colors.veryLightGreen}>
            <UserInfo user={user} mb={2} />
            <Divider />
            <Box mt={2}>
              <Box mb={1}>
                <Text weight="semibold">Date</Text>
                <Text mb={1}>{start} - {end}, {fullDate}.</Text>
              </Box>
              <Box mb={1}>
                <Text weight="semibold">Duration</Text>
                <Text>{duration?.value} {duration?.type}</Text>
              </Box>
              {Object.keys(details).filter(k => !k.includes('date')).map((k, i) => (
                <Box key={i} mb={1}>
                  <Text weight="semibold">{capitalize(k).replace('_', ' ')}</Text>
                  <Text>{(() => {
                      const value = details[k as Keys]
                      if (!value) return ''
                      if (typeof value === "string") return value
                      if (typeof value === "object") return (value as DateTime).toLocaleString(DateTime.DATETIME_MED)
                    })()}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        </DashboardCard>
        <div className='fixed-button-bottom'>
          <Button
            fullWidth
            variant="contained"
            loading={isLoading}
            onClick={onSendDateRequest}>
            <Text color={colors.white}>Send Date Request</Text>
          </Button>
        </div>
      </Box>
    </Container>
  )
}

export default animateComponent<SendRequestProps>(SendRequest, true)
