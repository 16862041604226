import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import { SummaryWrapper } from '../OpenDateRequests.styles'
import { OpenDateRequest } from '../../../../utils/types'
import Text from '../../../../components/Text'
import LocationText from '../../../../components/LocationText/LocationText'
import { fullDateRange } from '../../../../utils/helpers'
import TextWithIcon from '../../../../components/TextWithIcon'
import { colors } from '../../../../styles/_var'


type OpenDateRequestSummaryProps = { request: OpenDateRequest } & BoxProps
const OpenDateRequestSummary = ({ request, ...boxProps }: OpenDateRequestSummaryProps) => {
  return (
    <SummaryWrapper {...boxProps}>
      <Text weight="semibold" variant="medium">{request.activity}</Text>
      <Text>{request.description}</Text>
      <Box mt={1}>
        <LocationText 
          location={request.location} 
          textProps={{truncate: true, weight: 'semibold', color: colors.darkgrey}} 
        />
        <TextWithIcon 
          lineHeight={10} 
          icon={<ClockIcon sx={{color: colors.grey}} />} 
          weight="semibold" 
          mt={1} 
          lighten
        >
          {fullDateRange(request.start_date, request.end_date)}
        </TextWithIcon>
      </Box>
    </SummaryWrapper>
  )
}

export default OpenDateRequestSummary
