import React from 'react'
import Box from '@mui/material/Box'
import FrownIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import Container from '@mui/material/Container'
import ChatIcon from '@mui/icons-material/SmsOutlined';
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { DateRequest } from '../../../../utils/types'
import TextWithIcon from '../../../../components/TextWithIcon'
import { PageWrapper, colors, spacing } from '../../../../styles/_var';
import { useHideFooterOnMount, useIsMobile } from '../../../../hooks/hooks';
import { useUiStore } from '../../../../stores/uiStore';
import { useAuthStore } from '../../../../stores/authStore';
import DateRequestClass from '../../../../services/DateRequestClass';
import { useDateRequest } from '../DateRequests.hooks';
import FriendshipStatusView from '../../../../components/FriendshipStatusView/FriendshipStatusView';
import Button from '../../../../components/Button/Button';
import DeclineModal from './DeclineModal';
import Text from '../../../../components/Text';
import ProfileHeader from '../../../Profile/ProfilePage/components/ProfileHeader';
import { RequestLabel } from './RequestCard';
import LocationText from '../../../../components/LocationText/LocationText';
import RequestOptionsButton from '../RequestOptionsButton';
import { getFirstName } from '../../../../utils/helpers';

type DateViewProps = {
  dateRequest: DateRequest,
  isLoading: boolean,
  isError: boolean
}


const NoLoadedView = () => {
  return (
    <TextWithIcon 
      icon={<FrownIcon sx={{color: colors.danger}} />} 
      justifyContent="center" 
      color={colors.danger}
    >
      Failed to load
    </TextWithIcon>
  )
}

const DateView = ({ dateRequest, isError }: DateViewProps) => {
  return (
    <Container>
      <Box>
        {(!dateRequest && isError) && <NoLoadedView />}
        {!!dateRequest && <LoadedView dateRequest={dateRequest} />}
      </Box>
    </Container>
  )
}


const LoadedView = ({dateRequest: dateInfo}: {dateRequest: DateRequest}) => {
  useHideFooterOnMount()
  const isMobile = useIsMobile()
  const { showDialog } = useUiStore()
  const { currentUser } = useAuthStore()
  const dateObject = new DateRequestClass(dateInfo, currentUser)
  const status = dateObject.dateStatus
  const { 
    accepting, 
    declining, 
    onAcceptRequest, 
    postDeclineRequest,
  } = useDateRequest(dateInfo)
  const isExpired = status === 'expired'

  const completedAction = () => {
    return (
      <Box 
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
        px={2}
      >
        <FriendshipStatusView 
          friendshipStatus={dateObject.otherParticipant?.friendship_status} 
          user={dateObject.otherParticipant?.user?.username || ''}
          width="90%"
          mt={undefined} />
        {isExpired && (
          <Button useLink to={`/messages/${dateInfo.request_id}`} fullWidth variant="outlined" mb={2} mt={2}>
            <TextWithIcon textSpacing={{ml: 1}} icon={<ChatIcon fontSize="large" />} center>Messages</TextWithIcon>
          </Button>
        )}
      </Box>
    )
  }

  const onDeclineRequest = () => {
    showDialog({
      dialogBody: <DeclineModal onDecline={postDeclineRequest}  />
    })
  }
  
  const pendingActions = () => {
    if (dateObject.iAmSender) return (
      <Box className="fixed-button-bottom">
        <Button variant="contained" fullWidth sx={{backgroundColor: colors.lightDanger}}>
          <Text weight="semibold" color={colors.white}>
            Delete Request
          </Text>
        </Button>
      </Box>
    )

    return (
      <Box 
        className="fixed-button-bottom"
        display="flex"
        justifyContent="space-between"
      >
        <Button variant="outlined" fullWidth mr={2} loading={declining} onClick={onDeclineRequest}>
          <Text center>Decline</Text>
        </Button>
        <Button variant="contained" fullWidth loading={accepting} onClick={onAcceptRequest}>
          <Text center color={colors.white}>Accept</Text>
        </Button>
      </Box>
    )
  }

  const acceptedActions = () => (
    <Box 
      className="fixed-button-bottom"
      display="flex"
      justifyContent="space-between"
    >
      <Button useLink to={`/messages/${dateInfo.request_id}`} variant="contained" fullWidth>
        <TextWithIcon icon={<ChatIcon sx={{mr: 2}} />} center color={colors.white}>Chat</TextWithIcon>
      </Button>
    </Box>
  )

  const declineNote = () => (
    <Paper>
      <Box mt={2} p={2}>
        <Text weight="semibold" mb={1}>Decline Reason</Text>
        <Text>{dateObject?.recipient?.note || 'Not available'}</Text>
      </Box>
    </Paper>
  )

  const actionsMap: any = {
    'pending': pendingActions,
    'accepted': acceptedActions,
    'declined': declineNote
  }

  return (
    <PageWrapper style={{marginTop: spacing.sm, paddingBottom: 0}}>
      <Box p={isMobile ? undefined : 3}>
        <Paper elevation={3} sx={{ paddingTop: 1, paddingBottom: 2, marginBottom: spacing.sm }}>
          <RequestOptionsButton dateInfo={dateObject} style={{marginLeft: 5}} />
          {!!dateObject.otherParticipant?.user && (
            <ProfileHeader
              mt={1}
              user={dateObject.otherParticipant?.user} 
            />
          )}
          {!!dateObject.otherParticipant?.user?.bio && (
            <Box mt={3} px={3} pb={2}>
              <Text center>{dateObject.otherParticipant?.user.bio}</Text>
            </Box>
          )}
          <Box mb={2}>{completedAction()}</Box>
        </Paper>
        <Paper sx={{marginBottom: spacing.sm}} elevation={3}>
          <Box 
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="medium" weight="semibold">Date Details</Text>
            <RequestLabel type={status} />
          </Box>
          <Divider />
          <Box p={2}>
            <Text mb={1} mr={1} inline>Duration:</Text><Text inline weight="semibold">{dateObject.duration}</Text><br />
            <Text mb={1} mr={1} inline>Activity:</Text><Text inline weight="semibold">{dateInfo.activity}</Text> <br />
            <LocationText 
              textProps={{
                weight: "semibold",
                color: colors.darkgrey,
                truncate: true,
              }}
              location={dateInfo.location} 
            />
            <TextWithIcon 
              lineHeight={10} 
              icon={<ClockIcon sx={{color: colors.grey}} />} 
              weight="semibold" 
              mt={1} 
              lighten
            >
              {dateObject.fullDateTime}
            </TextWithIcon>
            {!!dateInfo.description && (
              <Box mt={1}>
                <Text>{getFirstName(dateObject.sender?.user?.full_name)} Said</Text>
                <Text italic inline weight="semibold" lighten>
                  "{dateInfo.description}"
                </Text>
              </Box>
            )}
          </Box>
        </Paper>
        {!!actionsMap[status] && actionsMap[status].call()}
      </Box>
    </PageWrapper>
  )  
}


export default DateView

