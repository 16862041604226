import { useState } from "react";
import { useDispatch } from "react-redux";
import { useProcessError } from "../../../../hooks/hooks";
import { FavouriteActivity } from "../../../../utils/types";
import { useDeleteFavouriteActivityMutation, useFetchFavouriteActivitiesQuery, usePostFavouriteActivitiesMutation, useUpdateFavouriteActivitiesMutation } from '../../../../services/api';
import AddActivity, { AddActivityProps } from "./AddActivity";
import { closeDialog, openDialog, showToastMessage, openConfirmDialog } from "../../../../stores/uiStore";
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';


export const useAddActivity = ({ onAdded, updateData }: AddActivityProps) => {
  const processError = useProcessError()
  const dispatch = useDispatch()

  const defaultChips = updateData?.favourite_places?.split("||") || []
  const [chips, setChips] = useState<string[]>(defaultChips)
  const [places, setPlaces] = useState('')
  const [activity, setActivity] = useState(updateData?.description || '')
  const [errorMessage, setErrorMessage] = useState('')
  const [postFavouriteActivity, { isLoading: isPosting }] = usePostFavouriteActivitiesMutation()
  const [updateFavouriteActivity, { isLoading: isUpdating }] = useUpdateFavouriteActivitiesMutation()
  const isUpdate = !!updateData?.id

  const onSubmit = async () => {
    if (!activity) return setErrorMessage('You need to add an activity')
    const postAction = isUpdate ? updateFavouriteActivity : postFavouriteActivity

    try {
      const response = await postAction({
        id: updateData?.id || 0,
        description: activity,
        favourite_places: chips.join('||')
      }).unwrap()

      onAdded && onAdded(response.payload)
      dispatch(closeDialog())
      dispatch(showToastMessage({message: 'Yaay! Added!', severity: 'success'}))
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  const removeChip = (chipName: string) => {
    setChips(chips.filter(chip => chip !== chipName))
  }

  const storeChip = () => {
    const strippedPlaces = places.trim()
    if (strippedPlaces && !chips.includes(strippedPlaces)) {
      setChips([...chips, strippedPlaces])
      setPlaces('')
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      storeChip()
    }
  }

  return {
    onKeyDown,
    storeChip,
    removeChip,
    onSubmit,
    activity,
    setActivity,
    errorMessage,
    places,
    setPlaces,
    chips,
    isUpdate,
    isLoading: isUpdate ? isUpdating : isPosting,
  }
}


export const useActivityList = () => {
  const [listToDelete, setListToDelete] = useState<null | number>(null)
  const dispatch = useDispatch()
  const processError = useProcessError()
  const [deleteActivity, { isLoading: deleting }] = useDeleteFavouriteActivityMutation()
  const { refetch  } = useFetchFavouriteActivitiesQuery({})

  const removeActivity = (id: number) => {
    dispatch(openConfirmDialog({
      dialogBody: 'Are you really tired of this dating activity?',
      onConfirmAction: async () => {
        try {
          setListToDelete(id)
          const response = await deleteActivity({ id }).unwrap()
          dispatch(showToastMessage({message: response.message, severity: 'success'}))
          refetch()
        } catch (error) {
          processError(error as FetchBaseQueryError)
        } finally {
          setListToDelete(null)
        }
      }
    }))
  }

  const openUpdateActivityModal = (data: FavouriteActivity) => {
    dispatch(openDialog({
      dialogBody: <AddActivity updateData={data} onAdded={() => refetch()} />
    }))
  }

  return { 
    removeActivity, 
    openUpdateActivityModal,
    listToDelete,
    deleting
  }
}


export const useActivities = () => {
  const { refetch, data, isLoading, isSuccess } = useFetchFavouriteActivitiesQuery({})
  const dispatch = useDispatch()

  const openAddActivityModal = () => {
    dispatch(openDialog({
      dialogBody: <AddActivity onAdded={() =>  refetch()}/>
    }))
  }

  return { openAddActivityModal, data, isLoading, isSuccess}
}