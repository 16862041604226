import styled from 'styled-components'
import { colors } from '../../styles/_var'

export const ChipWrapper = styled.div`
  display: inline-block;
  padding: 1.5px;
  border-radius: 4px;
  width: fit-content;
  background: linear-gradient(90deg, rgba(24,45,8,1) 0%, rgba(84,156,58,1) 35%, rgba(189,237,87,1) 68%, rgba(24,45,8,1) 100%);
`

export const ChipContent = styled.div`
  background: ${colors.white};
  border-radius: 2px;
  padding: 3px 6px;
  width: fit-content;

  svg {
    margin-right: 0;
  }
`