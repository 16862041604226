import React from 'react'
import Box from '@mui/material/Box'
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { OpenDateRequest, User } from '../../../../utils/types'
import Text from '../../../../components/Text'
import LocationText from '../../../../components/LocationText/LocationText'
import { fullDateRange, pluralize } from '../../../../utils/helpers'
import { colors, spacing } from '../../../../styles/_var'
import Button from '../../../../components/Button/Button'
import TextWithIcon from '../../../../components/TextWithIcon'
import { routes } from '../../../../utils/constants'


type OpenDateRequestCardProps = {
  request: OpenDateRequest,
  cardColor?: string,
  currentUser?: User,
  toggle?: (payload: {slug: string}) => void,
}

const OpenDateRequestCard = ({
  request,
  cardColor=colors.greenBackground,
  currentUser,
  toggle
}: OpenDateRequestCardProps) => {
  const viewedByOwner = currentUser?.username === request.user?.username
  const route = routes.OPEN_REQUEST.replace(':slug', request.slug)
  const numOfInterests = request.open_date_request_interests?.length || 0
  const currentUserInterested = request.open_date_request_interests?.some(interest => interest.user?.username === currentUser?.username)

  const numOfInterestButton = (
    <Button 
      useLink
      to={route}
      variant="outlined" 
      fullWidth 
      mt={1} 
      sx={{
        background: colors.white, 
        border: `solid 1 px ${colors.green}`
      }}
    >
      <TextWithIcon 
        after
        fullWidth
        justifyContent='space-between'
        icon={<KeyboardArrowRightRounded sx={{color: colors.green}} fontSize="large" />}
        weight="semibold" 
        color={colors.green}
      >
        {numOfInterests} {pluralize('interest', numOfInterests)}
      </TextWithIcon>
    </Button>
  )

  const toggleInterestButton = (
    <Button
      variant="outlined" 
      fullWidth 
      onClick={() => currentUser && toggle && toggle({slug: request.slug})}
      mt={1} 
      sx={{
        background: colors.white, 
        border: `solid 1 px ${colors.green}`
      }}
    >
      <Text center weight="semibold" color={colors.green}>
        {currentUserInterested ? "Not Interested" : "I'm Interested"}
      </Text>
    </Button>
  )

  return (
    <Paper elevation={4} sx={{marginBottom: spacing.sm}}>
      <Box sx={{background: cardColor}}>
        <Box p={1.5}>
          <Text center weight="semibold">Open Invite</Text>
        </Box>
        <Divider />
        <Box p={2}>
          <LocationText 
            underline
            underlineStyle="dotted"
            color={colors.darkgrey}
            noIcon 
            location={request.location} 
            textProps={{truncate: true, weight: 'semibold'}}
          />
          <Text lineHeight={25}>{fullDateRange(request.start_date, request.end_date)}</Text>
          {viewedByOwner ? numOfInterestButton : toggleInterestButton}
        </Box>
      </Box>
    </Paper>
  )
}

export default OpenDateRequestCard
