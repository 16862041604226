import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { DateTimeslot, User } from '../../../../utils/types'
import Text from '../../../../components/Text'
import { DateTime } from 'luxon'
import { dhm, durationToString } from '../../../../utils/helpers'
import SimpleForm, { IActionButton } from '../../../../components/SimpleForm'
import Input from '../../../../components/Input'
import validators from '../../../../utils/validators'
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'
import TextWithIcon from '../../../../components/TextWithIcon'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeftRounded'
import { animateComponent } from '../../../../utils/hoc'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'
import AddressInput from '../../../../components/Input/AddressInput'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export type DateDetails = {
  activity: string, 
  location: string,
  reason?: string,
  name?: string,
  email?: string,
  social_media?: string,
  start_date?: DateTime,
  end_date?: DateTime
}

type AddDetailsProps = {
  user?: User,
  activeTimeslot?: DateTimeslot,
  onDetailsAdded?: (details: DateDetails) => void,
  initialData?: DateDetails,
  goBack: () => void
}

const AddDetails: FunctionComponent<AddDetailsProps> = ({
  user,
  activeTimeslot,
  onDetailsAdded,
  initialData,
  goBack
}) => {
  if (!activeTimeslot) return null

  const startDateTime = DateTime.fromISO(activeTimeslot?.start_time)
  const endDateTime = DateTime.fromISO(activeTimeslot?.end_time)

  const fullDate = startDateTime.toLocaleString(DateTime.DATE_MED)
  const startTime = startDateTime.toLocaleString(DateTime.TIME_SIMPLE)
  const endTime = endDateTime.toLocaleString(DateTime.TIME_SIMPLE)

  const durationArr = dhm(endDateTime.diff(startDateTime).milliseconds)

  const formFields = [
    {
      name: 'activity',
      placeholder: 'Date Title',
      component: Input,
      validate: validators.required
    },
    {
      name: 'location',
      placeholder: 'Where is it happening?',
      component: AddressInput,
      validate: validators.required,
      isLocation: true
    },
    {
      name: 'description',
      multiline: true,
      rows: 2,
      placeholder: 'Describe your date plans.',
      component: Input,
    },
    {
      name: 'start_date',
      label: 'Start Time',
      component: TimePicker,
      sx: {width: '100%'},
      validate: validators.required
    },
    {
      name: 'end_date',
      label: 'End Time',
      component: TimePicker,
      sx: {width: '100%'},
      validate: validators.required
    },
  ]

  const actionButton: IActionButton = {
    label: 'Confirm Details',
    isSubmit: true,
    loading: false,
    variant: 'contained',
    fullWidth: true,
    endIcon: <KeyboardArrowRightRounded />,
    style: {display: 'flex', justifyContent: 'space-between'}
  }

  const isWholeDay = (durationArr.find(e => e.type === 'hour')?.value || 0) >= 23
  if (!isWholeDay) {
    formFields.splice(3, 1)
    formFields.splice(3, 1)
  }

  return (
    <Container>
      <TextWithIcon
        style={{width: 'fit-content'}}
        mt={2} 
        icon={<LeftIcon />}
        onClick={goBack}
      >
        Go Back
      </TextWithIcon>
      <Paper component={Box} mt={2}>
        {!!user && <UserInfo user={user} pt={3} paddingX={2} />}
        <Box mt={3}><Divider /></Box>
        {!!activeTimeslot && (
          <Box padding={2}>
            {!isWholeDay && <Text mb={1}>Duration: <strong>{durationToString(durationArr)}</strong></Text>}
            <Text mb={1}>Date: <strong>{!isWholeDay ? `${startTime} - ${endTime},` : ''} {fullDate}</strong></Text>
            <Text>Timezone: <strong>{Intl.DateTimeFormat().resolvedOptions().timeZone}</strong></Text>
          </Box>
        )}
      </Paper>
      <Box mt={4}>
        <Text variant="medium" weight="semibold" mb={3}>Add Details</Text>
        <SimpleForm 
          initialValues={initialData}
          formFields={formFields}
          actionButtons={actionButton}
          submitForm={data => onDetailsAdded && onDetailsAdded(data)}
          buttonWrapperClass='fixed-button-bottom' />
      </Box>
    </Container>
  )
}

export default animateComponent<AddDetailsProps>(AddDetails, true)