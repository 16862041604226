import React, { useState } from 'react'
import Container from '@mui/material/Container'
import ButtonBase from '@mui/material/ButtonBase'
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import HomeIcon from '@mui/icons-material/HomeOutlined';
import SolidHomeIcon from '@mui/icons-material/Home';
import DatesIcon from '@mui/icons-material/FormatListBulletedRounded';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import SolidCalendar from '@mui/icons-material/CalendarToday';
import FriendsIcon from '@mui/icons-material/Diversity1Outlined';
import SolidFriendsIcon from '@mui/icons-material/Diversity1';
import { selectCurrentUser, selectFirstLetterOfName } from '../../stores/authStore'
import { NavbarContent, NavbarWrapper, NavItem } from './DashboardNavbar.styles'
import ArrowIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Avatar from '../Avatar/Avatar'
import { routes } from '../../utils/constants'
import { Link, useLocation } from 'react-router-dom'
import Text from '../Text'
import { animateComponent } from '../../utils/hoc';
import { selectShowNav } from '../../stores/uiStore';
import HeaderMenu from '../MobileHomeHeader/HeaderMenu';
import { colors, spacing } from '../../styles/_var';

export const DashboardNavbar = () => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const showNav = useSelector(selectShowNav)
  const currentUser = useSelector(selectCurrentUser)
  const firstLetterOfName = useSelector(selectFirstLetterOfName)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const menuIsOpen = Boolean(anchorEl);

  if (!showNav) return null

  return (
    <NavbarWrapper>
      <Popover
        sx={{marginTop: spacing.xxs}}
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <HeaderMenu closeMenu={handleClose} />
      </Popover>
      <Container>
        <NavbarContent>
          <Link to={routes.DASHBOARD}>
            <ButtonBase component={NavItem} $selected={location.pathname.includes(routes.DASHBOARD)}>
              {location.pathname.includes(routes.DASHBOARD) 
                ? <SolidHomeIcon /> 
                : <HomeIcon />
              }
              <Text variant='small'>Home</Text>
            </ButtonBase>
          </Link>
          <Link to={routes.DATE_REQUESTS}>
            <ButtonBase component={NavItem} $selected={location.pathname.includes(routes.DATE_REQUESTS)}>
              <DatesIcon />
              <Text variant='small'>Invites</Text>
            </ButtonBase>
          </Link>
          <Link to={routes.FRIENDS}>
            <ButtonBase component={NavItem} $selected={location.pathname.includes(routes.FRIENDS)}>
              {location.pathname.includes(routes.FRIENDS)
                ? <SolidFriendsIcon />
                : <FriendsIcon />
              }
              <Text variant='small'>Friends</Text>
            </ButtonBase>
          </Link>
          <Link to={routes.AVAILABILITY}>
            <ButtonBase component={NavItem} $selected={location.pathname.includes(routes.AVAILABILITY)}>
              {location.pathname.includes(routes.AVAILABILITY)
                ? <SolidCalendar />
                : <CalendarIcon />
              }
              <Text variant='small'>Availability</Text>
            </ButtonBase>
          </Link>
          <ButtonBase onClick={handleClick}>
            <Box display="flex" alignItems="center">
              <Avatar src={currentUser.avatar} sx={{width: 35, height: 35}}>
                <Text color={colors.white} uppercase>{firstLetterOfName}</Text>
              </Avatar>
              <ArrowIcon sx={{marginLeft: '4px'}} />
            </Box>
          </ButtonBase>
        </NavbarContent>
      </Container>
    </NavbarWrapper>
  )
}


export default animateComponent(DashboardNavbar)
