import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CancelIcon from '@mui/icons-material/ClearRounded';
import Divider from "@mui/material/Divider"
import UsernameIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/LocalPhoneRounded';
import MessageIcon from '@mui/icons-material/MailOutline';
import RocketIcon from '@mui/icons-material/RocketLaunch';
import MailIcon from '@mui/icons-material/EmailRounded';
import IconButton from '@mui/material/IconButton';
import { RequestDrawerWrapper } from '../OpenDateRequests/OpenDateRequests.styles'
import Text from '../../../components/Text'
import PhoneNoInput from '../../../components/PhoneNoInput';
import Button, { ButtonProps } from '../../../components/Button/Button';
import { colors } from '../../../styles/_var';
import TextWithIcon from '../../../components/TextWithIcon';
import { User } from '../../../utils/types';
import { AddByEmailInput } from './Friends.styles';
import UsernameInput from '../../../components/UsernameInput/UsernameInput';
import { UserInfo } from '../../../components/UserInfo/UserInfo';
import { useSendFriendRequestMutation } from '../../../services/api';
import { useUiStore } from '../../../stores/uiStore';
import { retrieveErrorMessage } from '../../../utils/helpers';


const SubmitButton = (props: ButtonProps) => {
  return (
    <Button variant="contained" mt={1} fullWidth {...props}>
      <TextWithIcon icon={<RocketIcon />} weight="semibold" color={colors.white}>
        Submit
      </TextWithIcon>
    </Button>
  )
}

type AddByPhoneNumberProps = {
  submit: (phoneNumber: string) => void
}
const AddByPhoneNumber = ({ submit }: AddByPhoneNumberProps) => {
  const [phoneNo, setPhoneNo] = useState('')

  return (
    <Box p={2}>
      <Text mb={2} weight="semibold">Invite by phone number</Text>
      <Box height={50}>
        <PhoneNoInput value={phoneNo} onChange={e => setPhoneNo(e.target.value)} />
      </Box>
      <SubmitButton disabled={!phoneNo} onClick={() => submit(phoneNo)} />
    </Box>
  )
}

type AddByEmailProps = {
  submit: (email: string) => void
}
const AddByEmail = ({ submit }: AddByEmailProps) => {
  const [email, setEmail] = useState('')
  return (
    <Box p={2}>
      <Text mb={2} weight="semibold">Invite by email</Text>
      <Box height={50}>
        <AddByEmailInput 
          InputProps={{
            startAdornment: <MessageIcon style={{marginRight: 10}} />
          }}
          fullWidth 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          size="small" 
        />
      </Box>
      <SubmitButton onClick={() => submit(email)} disabled={!email} />
    </Box>
  )
}

type AddByUsernameProps = {
  submit: (username: string) => void
}
const AddByUsername = ({ submit }: AddByUsernameProps) => {
  const [user, setUser] = useState<null | User>(null)

  return (
    <Box p={2}>
      <Text mb={2} weight="semibold">Add by username</Text>
      <Box height={50}>
        {!user && <UsernameInput onSelectUser={setUser} />}
        {!!user && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <UserInfo user={user} />
            <IconButton onClick={() => setUser(null)}>
              <CancelIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <SubmitButton onClick={() => submit(user?.username || '')} />
    </Box>
  )
}


type AddOrInviteFriendProps = {
  onComplete?: () => void
}
const AddOrInviteFriend = ({ onComplete }: AddOrInviteFriendProps) => {
  const PHONE_NUMBER_TAB = 0
  const EMAIL_TAB = 1
  const USERNAME_TAB = 2
  const drawerHeight = 220
  const [tabValue, setTabValue] = useState(PHONE_NUMBER_TAB)
  const [sendRequest, ] = useSendFriendRequestMutation()
  const {openSuccessToast, openErrorToast} = useUiStore()

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const sendAddOrinviteRequest = async (recipient: string) => {
    try {
      const response = await sendRequest({sent_to: recipient}).unwrap()
      openSuccessToast({message: response.message})
      onComplete?.()
    } catch (e) {
      openErrorToast({message: retrieveErrorMessage(e)})
    }
  }

  return (
    <RequestDrawerWrapper height={drawerHeight}>
      <Box>
        <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
          <Tab label={<PhoneIcon fontSize="large" />} />
          <Tab label={<MailIcon fontSize="large" />} />
          <Tab label={<UsernameIcon fontSize="large" />} />
        </Tabs>
        <Divider sx={{position: 'relative', top: '-1.5px'}} />
        {tabValue === PHONE_NUMBER_TAB && <AddByPhoneNumber submit={sendAddOrinviteRequest} />}
        {tabValue === EMAIL_TAB && <AddByEmail submit={sendAddOrinviteRequest} />}
        {tabValue === USERNAME_TAB && <AddByUsername submit={sendAddOrinviteRequest} />}
      </Box>
    </RequestDrawerWrapper>
  )
}

export default AddOrInviteFriend
