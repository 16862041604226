import styled from 'styled-components'
import { colors } from '../../styles/_var'

export const AvatarWrapper = styled(({ addBorder, ...rest }) => <div {...rest} />)`
  border-radius: 50%;
  display: inline-block;
  height: fit-content;
  width: fit-content;
  box-sizing: border-box;

  ${({ addBorder }) => addBorder ? `
    border: solid 1.2px ${colors.lightPrimary};
    padding: 3px;
  ` : `
    border: solid 2px ${colors.darkGreen};
  `}
`