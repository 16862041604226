import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { colors } from "../../../../../styles/_var";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";

export const ScoreRow = styled(TableRow)`
  transition: all ease .2s;

  & .winner-medal {
    cursor: pointer;
    visibility: hidden;
    transition: visibility ease .01s;

    &.winner {
      cursor: auto;
      visibility: visible;
    }
  }

  &:hover {
    background: ${colors.lightergrey};
    & .winner-medal {
      visibility: visible;
    }
  }
`

export const ParticipantCell = styled(TableCell)`
  display: flex !important;
`

export const ImageThumbnail = styled.div<{$loaded?: boolean, $url: string}>`
  width: 130px;
  height: 110px;
  position: relative;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 5px;
  transform: scale(1);
  transition: transform ease .2s;
  background-color: ${colors.lightgrey};

  &:hover {
    transform: scale(1.05)
  }

  ${({ $url }) => $url && `
    background-image: url(${$url});
  `}

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .4; 

  ${({ $loaded }) => $loaded && `
    opacity: 1;
  `}

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MIconButton = styled(IconButton)`
  background-color: rgba(255, 255, 255, 0.7) !important;
  margin-right: 7px !important;
  box-shadow: 1px 1px 3px 2px ${colors.grey};
  height: fit-content;
`

export const PreviewImage = styled.img`
  max-width: 80%;
  height: auto;
  overflow-y: scroll;
`

export const PreviewImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: transparent;
`