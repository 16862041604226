import { useState } from 'react'
import { useResetPasswordMutation } from '../../../services/api'
import validators from '../../../utils/validators'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useProcessError } from '../../../hooks/hooks'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useUiStore } from '../../../stores/uiStore'
import { routes } from '../../../utils/constants'

const useResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const processError = useProcessError()
  const {openSuccessToast} = useUiStore()
  const resetToken = searchParams.get('reset_token') || ''
  const email = searchParams.get('email') || ''
  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const [error, setError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const validateInput = () => {
    if (validators.isPassword(newPassword)) return setError("Invalid password")
    if (newPassword !== confirmPassword) return setError("Passwords do not match")

    return true
  }

  const onSubmit = async () => {
    if (validateInput() !== true) return

    try {
      const { message } = await resetPassword({
        email,
        reset_token: resetToken,
        new_password: newPassword
      }).unwrap()
      openSuccessToast({ message })
      navigate(routes.LOGIN)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  return { 
    validateInput, 
    onSubmit,
    error,
    isLoading,
    setConfirmPassword,
    setNewPassword,
    newPassword,
    confirmPassword,
    setError
  }
}

export default useResetPassword