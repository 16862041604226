import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import Joyride, { ACTIONS, CallBackProps } from 'react-joyride';
import Login from './pages/Auth/Login/Login'
import HomePage from './pages/HomePage/HomePage'
import { routes } from './utils/constants'
import Auth from './pages/Auth/AuthIndex/Auth'
import Authentication from './utils/auth'
import AuthLayout from './pages/Auth/AuthLayout'
import Register from './pages/Auth/Register/Register'
import DashboardLayout from './pages/Dashboard/DashboardLayout/DashboardLayout'
import Home from './pages/Dashboard/Home/Home'
import Toast from './components/Toast'
import LoadingScreen from './components/LoadingScreen'
import Availability from './pages/Dashboard/Availability/Availability'
import Dialog from './components/Dialog'
import ConfirmationDialog from './components/ConfirmationDialog'
import AccountSettings from './pages/Dashboard/AccountSettings/AccountSettings'
import UserProfile from './pages/Dashboard/AccountSettings/UserProfile/UserProfile'
import Security from './pages/Dashboard/AccountSettings/Security/Security'
import Support from './pages/Dashboard/AccountSettings/Support/Support'
import Activities from './pages/Dashboard/AccountSettings/Activities/Activities'
import Profile from './pages/Profile/Profile'
import PlanDate from './pages/Profile/PlanDate/PlanDate'
import DateRequests from './pages/Dashboard/DateRequests/DateRequests'
import DateRequestPage from './pages/Dashboard/DateRequests/DateRequestPage'
import UnapprovedRequests from './pages/Dashboard/DateRequests/UnapprovedRequests'
import ApprovedRequests from './pages/Dashboard/DateRequests/ApprovedRequests'
import Friends from './pages/Dashboard/Friends/Friends'
import AllFriends from './pages/Dashboard/Friends/AllFriends'
import FriendRequests from './pages/Dashboard/Friends/FriendRequests'
import ProfilePage from './pages/Profile/ProfilePage/ProfilePage'
import ShareProfile from './pages/Dashboard/ShareProfile/ShareProfile'
import Notifications from './pages/Dashboard/Notifications/Notifications'
import Messages from './pages/Dashboard/Messages/Messages'
import ChatMessage from './pages/Dashboard/Messages/ChatMessage'
import ActivateAccount from './pages/Dashboard/ActivateAccount/ActivateAccount'
import RequestResetPassword from './pages/Auth/RequestResetPassword/RequestResetPassword'
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword'
import PrivacyPolicy from './pages/HomePage/PrivacyPolicy'
import TermsOfService from './pages/HomePage/TermsOfService'
import Billing from './pages/Dashboard/AccountSettings/Billing/Billing'
import CreateActivity from './pages/Dashboard/PlanActivity/CreateActivity/CreateActivity'
import UpdateActivity from './pages/Dashboard/PlanActivity/UpdateActivity/UpdateActivity'
import { useAuthStore } from './stores/authStore'
import Scorecard from './pages/Dashboard/DateRequests/components/DateMemories/Scorecard/Scorecard'
import ErrorPage from './pages/errorPages/ErrorPage'
import OpenDateRequestView from './pages/Dashboard/OpenDateRequests/OpenDateRequestView'
import { useUiStore } from './stores/uiStore';
import { AppServices } from './utils/AppServices';

const AppRoutes = () => {
  const { isLoggedIn } = useAuthStore()
  const userIsLoggedIn = isLoggedIn || Authentication.isLoggedIn()
  const { joyRideRunning, joyRideSteps, resetJoyRide } = useUiStore()

  const handleCallBack = (data: CallBackProps) => {
    if (data.action === ACTIONS.START) {
      setTimeout(() => {
        const button: HTMLButtonElement = document.getElementsByClassName('react-joyride__beacon')[0] as HTMLButtonElement
        button?.click()
      }, 1000)
    } else if (data.action === ACTIONS.RESET) {
      resetJoyRide()
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Toast />
      <Dialog />
      <ConfirmationDialog />
      <LoadingScreen />
      <Routes>
        <Route path={routes.LANDING_PAGE} element={<HomePage />} />
        <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={routes.TERMS_OF_USE} element={<TermsOfService />} />
        <Route path={routes.ACTIVATE} element={<ActivateAccount />} />
        {!userIsLoggedIn && (
          <>
            <Route path={routes.NEW_DATE_REQUEST} element={<DateRequestPage />} />
            <Route path={routes.DATE_REQUEST} element={<DateRequestPage />} />
          </>
        )}
        <Route path="/" element={<AuthLayout />}>
          <Route path={routes.LOGIN} element={<Login />} />
          <Route path={routes.AUTH} element={<Auth />} />
          <Route path={routes.REGISTER} element={<Register />} />
          <Route path={routes.REQUEST_RESET_PASSWORD} element={<RequestResetPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
        </Route>
        <Route path="/" element={<DashboardLayout />}>
          <Route path={routes.PLAN_ACTIVITY} element={<CreateActivity />} />
          <Route path={routes.EDIT_DATE_REQUEST} element={<UpdateActivity />} />
          <Route path={routes.SCORECARD} element={<Scorecard />} />
          <Route path={routes.DATE_REQUESTS} element={<DateRequests />}>
            <Route path={routes.UPCOMING_DATES} element={<ApprovedRequests />} />
            <Route path={routes.PENDING_REQUESTS} element={<UnapprovedRequests />} />
          </Route>
          <Route path={routes.FRIENDS} element={<Friends />}>
            <Route path={routes.ALL_FRIENDS} element={<AllFriends />} />
            <Route path={routes.FRIEND_REQUESTS} element={<FriendRequests />} />
          </Route>
          {userIsLoggedIn && (
            <>
              <Route path={routes.DATE_REQUEST} element={<DateRequestPage />} />
              <Route path={routes.NEW_DATE_REQUEST} element={<DateRequestPage />} />
            </>
          )}
          <Route path={routes.DASHBOARD} element={<Home />} />
          <Route path={routes.AVAILABILITY} element={<Availability />} />
          <Route path={routes.SHARE_PROFILE} element={<ShareProfile />} />
          <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
          <Route path={routes.MESSAGES} element={<Messages />} />
          <Route path={routes.CHAT_MESSAGE} element={<ChatMessage />} />
          <Route path={routes.ACCOUNT_SETTINGS} element={<AccountSettings />}>
            <Route path={routes.ACCOUNT_PROFILE} element={<UserProfile />} />
            <Route path={routes.ACCOUNT_SECURITY} element={<Security />} />
            <Route path={routes.ACCOUNT_SUPPORT} element={<Support />} />
            <Route path={routes.ACCOUNT_ACTIVITIES} element={<Activities />} />
            <Route path={routes.ACCOUNT_BILLING} element={<Billing />} />
          </Route>
          <Route path={routes.OPEN_REQUEST} element={<OpenDateRequestView />} />
        </Route>
        <Route path={routes.PROFILE_PAGE} element={<Profile />}>
          <Route path={routes.PROFILE_PAGE} element={<ProfilePage />} />
          <Route path={routes.PLAN_DATE} element={<PlanDate />} />
        </Route>
        <Route path="*" element={<ErrorPage errorCode="404" />} />
      </Routes>
      {!!joyRideSteps.length && (
        <Joyride 
          styles={{
            tooltipContent: {
              padding: '20px 0px 0px 0px'
            }
          }}
          continuous 
          disableScrolling
          steps={joyRideSteps} 
          callback={handleCallBack} 
          run={joyRideRunning}
          getHelpers={h => AppServices.setJoyRideHelpers(h)}
        />
      )}
    </LocalizationProvider>
  )
}

export default AppRoutes
