import React from 'react'
import { ActivityBackDrop } from './CreateActivity.styles'
import Box from '@mui/material/Box'
import MobileHeader from '../../../../components/MobileHeader/MobileHeader'
import { usePlanActivity, usePlanActivityEffects } from './CreateActivity.Hooks'
import AddActivityDetails from '../components/AddActivityDetails'
import AddActivityParticipant from '../components/AddActivityParticipant'
import ImageUpload from '../../../../components/ImageUpload'
import { PageWrapper } from '../../../../styles/_var'
import AddSupplySuggestions from '../components/AddSupplySuggestions'


const CreateActivity = () => {
  usePlanActivityEffects()
  const { 
    currentStep,
    participants,
    activityDetails,
    storeParticipant,
    createActivityRequest,
    removeParticipant,
    saveParticipants,
    setBackdropImage,
    suppliesSuggestions,
    finishActivityCreation,
    addSupplySuggestion,
    removeSupplySuggestion
  } = usePlanActivity({})

  const getStepView = () => {
    switch (currentStep) {
      case "step1":
        return (
          <AddActivityDetails createActivityRequest={createActivityRequest} />
        )
      case "step2":
        return (
          <AddActivityParticipant
            removeParticipant={removeParticipant}
            participants={participants}
            storeParticipant={storeParticipant}
            saveParticipants={saveParticipants}
            details={activityDetails} 
          />
        )
      case "step3":
        return (
          <AddSupplySuggestions 
            supplies={suppliesSuggestions} 
            addSuggestion={addSupplySuggestion}
            removeSuggestion={removeSupplySuggestion}
            finishActivityCreation={finishActivityCreation}
          />
        )
      default:
    }
  }

  return (
    <PageWrapper>
      <MobileHeader />
      <ImageUpload
          showIcon={currentStep === 'step1'}
          aspectRatio={3/1}
          onImageChange={setBackdropImage}
          contentArea={({ openFileChooser, croppedImage }) => (
            <ActivityBackDrop backdrop={croppedImage} onClick={openFileChooser} />
          )}
        />
      <Box mt={2} pl={3} pr={3}>
        {getStepView()}
      </Box>
    </PageWrapper>
  )
}

export default CreateActivity
