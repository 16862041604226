import React from 'react'
import ImageDownloadIcon from '@mui/icons-material/FileDownloadRounded';
import { colors } from '../../../../../../styles/_var';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box'


type ActionButtonsProps = {
  hasWinner: boolean,
  onClickDownload: () => void,
  onClickAddIcon: () => void,
}

const ActionButtons = ({ onClickAddIcon, onClickDownload, hasWinner }: ActionButtonsProps) => {
  return (
    <Box display="flex">
      <IconButton onClick={onClickAddIcon} sx={{background: colors.lightgrey}}>
        <AddIcon fontSize="medium" />
      </IconButton>
      {hasWinner && (
        <IconButton 
          onClick={onClickDownload}
          sx={{background: colors.lightgrey, marginLeft: 1}}
        >
          <ImageDownloadIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionButtons
