import React from 'react'
import SimpleFooter from '../../../components/SimpleFooter/SimpleFooter'
import Text from '../../../components/Text'
import Input from '../../../components/Input'
import Button from '../../../components/Button/Button'
import { colors } from '../../../styles/_var'
import useResetPassword from './ResetPassword.hooks'
import { AuthContent } from '../AuthIndex/Auth.styles'


const ResetPassword = () => {
  const {
    setError,
    setNewPassword,
    setConfirmPassword,
    onSubmit,
    isLoading,
    error,
    confirmPassword,
    newPassword
  } = useResetPassword()
  

  return (
    <AuthContent>
      <Text weight="semibold" center mb={1}>Create New Password</Text>
      <Input
        size="small"
        mb={1}
        onFocus={() => setError('')}
        error={!!error}
        placeholder="Enter new password" 
        type="password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)} />
      <Input
        size="small"
        onFocus={() => setError('')}
        error={!!error}
        helperText={error}
        placeholder="Confirm new password" 
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)} />

      <Button
        loading={isLoading} 
        fullWidth 
        mt={1} 
        center 
        variant="contained" 
        onClick={onSubmit}
      >
        <Text color={colors.white}>Reset Password</Text>
      </Button>
      <SimpleFooter />
    </AuthContent>
  )
}

export default ResetPassword
