import React from 'react'
import Container from '@mui/material/Container'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box';

const SearchLoading = ({ amount = 1}: {amount?: number}) => {
  return (
    <Container>
      {Array(amount).fill(true).map((_, i) => (
        <Box
          mt={2}
          key={i}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Skeleton variant="circular" width={40} height={40} />
          <Box ml={2} flex={1}>
            <Skeleton variant="text" height={15} />
            <Skeleton variant="text" height={15} />
          </Box>
        </Box>
      ))}
    </Container>
  )
}


export default SearchLoading