import React from 'react';
import styled from 'styled-components'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Text from '../../../../components/Text'
import { colors } from '../../../../styles/_var'
import { ParticipantResponse } from '../../../../services/api'
import { addSpacing } from '../../../../utils/hoc';


export const Label = styled(Text)`
  width: 50px;
  text-align: center;
  border: solid 1px ${colors.grey};
  border-radius: 5px;
  padding: 1px 10px;
  height: fit-content;
  color: ${colors.primary};
`

export const Chip = addSpacing<React.HTMLAttributes<HTMLSpanElement> & {$small?: boolean}>(styled.span<{$small?: boolean}>`
  background: ${colors.primary};
  color: ${colors.white};
  ${({ $small }) => $small ? `
    border-radius: 40%;
    font-size: 8px;
    padding: 1px 10px;
  ` : `
    font-size: 10px;
    border-radius: 30%;
    padding: 2px 10px;
  `}
  display: inline-block;
  text-align: center;
`)

export const CreatorLabel = styled(Label)`
  background-color: ${colors.pink};
  box-sizing: border-box;
`

export const StatusLabel = styled(Label)<{$type: ParticipantResponse}>`
  box-sizing: border-box;
  ${({ $type }) => $type === "pending" && `
    background: ${colors.lightyellow};
  `}

  ${({ $type }) => $type === "declined" && `
    background: ${colors.danger};
    color: ${colors.white};
  `}

  ${({ $type }) => $type === "accepted" && `
    background: ${colors.veryLightGreen};
    color: ${colors.black};
  `}
`

export const BorderLinearProgress = styled(LinearProgress)`
  border-radius: 10px;
  & .${linearProgressClasses.bar}: {
    border-radius: 10px;
  }
`