import React, { FunctionComponent, ReactNode } from 'react'
import TextWithIcon from '../TextWithIcon'
import CancelIcon from '@mui/icons-material/ClearRounded';
import { ChipContent, ChipWrapper } from './Chips.styles'
import { ComponentSpacing } from '../../utils/types';
import { getStyleSpacing } from '../../utils/helpers';
import IconButton from '@mui/material/IconButton';
import Text from '../Text';

interface ChipProps extends ComponentSpacing, React.HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode,
  innerStyle?: React.CSSProperties,
  onClose?: () => void
}

const Chip: FunctionComponent<ChipProps> = React.memo(({ 
  children,
  mt,
  mb,
  ml,
  mr,
  onClose,
  style={},
  innerStyle={},
  ...rest
}) => {
  if (!children) return null

  const spacingStyle = getStyleSpacing({ mt, mb, ml, mr })

  return (
    <ChipWrapper style={{...spacingStyle, ...style}} {...rest}>
      <ChipContent style={innerStyle}>
        {!onClose && <Text>{children}</Text>}
        {!!onClose && <TextWithIcon icon={(
          <IconButton sx={{marginRight: '2px'}} onClick={onClose}>
            <CancelIcon />
          </IconButton>
        )}>
          {children}
        </TextWithIcon>}
      </ChipContent>
    </ChipWrapper>
  )
})

export default Chip