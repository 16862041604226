import React from 'react'
import Container from '@mui/material/Container'
import CalendarImage from '../../../../assets/images/calendar.png'
import Box from '@mui/material/Box'
import { animateComponent } from '../../../../utils/hoc'
import Text from '../../../../components/Text'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../../../stores/authStore'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../../../utils/constants'
import Button from '../../../../components/Button/Button'
import { colors } from '../../../../styles/_var'


const RequestSent = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const navigate = useNavigate()
  const gotoRegister = () => navigate(routes.REGISTER)

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" mt={6}>
        <img src={CalendarImage} alt="request sent" width={60} height="auto" />
        <Text center variant="medium" weight="semibold">
          Your date request has been sent successfully.
        </Text>
        {isLoggedIn && (
          <Button to={routes.DASHBOARD} useLink mt={2} variant="contained">
            <Text color={colors.white}>Back to Home</Text>
          </Button>
        )}
        {!isLoggedIn && (
          <>
            <Text mt={2} center maxWidth={250}>
              Need help planning and scheduling the perfect date? Get started with Gusiberi for free.
            </Text>
            <Text variant="subtext" lighten center>(No credit card required)</Text>
            <Button variant="contained" mt={3} onClick={gotoRegister}>
              <Text color={colors.white} weight="semibold">Create your account</Text>
            </Button>
            <Text center variant="subtext" mt={1}>
              Got an account? <strong><Link to={routes.LOGIN}>Sign In</Link></strong>
            </Text>
          </>
        )}
      </Box>
    </Container>
  )
}

export default animateComponent(RequestSent)