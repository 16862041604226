import React from 'react'
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box'
import { RequestWrapper } from './DateRequests.styles'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useDatePageRequest } from './DateRequestPage.hooks';
import { routes } from '../../../utils/constants';
import DateView from './components/DateView';
import GroupActivityView from './components/GroupActivityView';
import { LoadingSkeleton } from './DateRequestInfo';


const DateRequestPage = () => {
  const { isLoading, data, isError, date_id } = useDatePageRequest()
  const navigate = useNavigate()

  if (!date_id) return null

  const loading = (
    <Box mt={2} p={3}>
      <LoadingSkeleton />
    </Box>
  )

  const body = !data?.payload ? null : (
    data.payload.date_type === "private_date" ? (
      <DateView dateRequest={data.payload} isLoading={isLoading} isError={isError} />
    ) : (
      <GroupActivityView 
        dateRequest={data.payload} 
        isLoading={isLoading}
        isError={isError}
      />
    )
  )

  return (
    <RequestWrapper>
      <MobileHeader onNavigateBack={() => navigate(routes.DASHBOARD)} />
      {isLoading && loading}
      {body}
    </RequestWrapper>
  )
}


export default DateRequestPage
