import React from 'react'
import Box from '@mui/material/Box'
import { useFetchActivePendingRequestQuery, useFetchPastPendingRequestQuery } from '../../../services/api'
import FrownIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import { colors } from '../../../styles/_var'
import Text from '../../../components/Text'
import TextWithIcon from '../../../components/TextWithIcon'
import { useDatesStore } from '../../../stores/datesStore';
import { groupByDay, unslugify } from '../../../utils/helpers';
import { DateRequest } from '../../../utils/types';
import DateCard from '../../../components/DateCard/DateCard';
import { useAuthStore } from '../../../stores/authStore';


const UnapprovedRequests = () => {
  const { currentUser } = useAuthStore()
  const { pendingType, setPendingType, showingUpcoming } = useDatesStore()
  const { 
    data: activePendingRequests, 
    isLoading: loadingActivePendingRequests 
  } = useFetchActivePendingRequestQuery({})
  const { 
    data: pastPendingRequests, 
    isLoading: loadingPastPendingRequests 
  } = useFetchPastPendingRequestQuery({})
  const requests = showingUpcoming ? activePendingRequests : pastPendingRequests
  const noUpcomingRequests = "You have no pending upcoming invites."
  const noExpiredRequests = "You have no pending expired invites."
  const isLoading = showingUpcoming ? loadingActivePendingRequests : loadingPastPendingRequests
  const groupedByDate = requests?.payload && groupByDay<DateRequest>(requests.payload, 'start_date')

  return (
    <Box width="100%">
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Text 
          style={{cursor: 'pointer'}}
          mr={2} 
          onClick={() => setPendingType('upcoming')}
          weight={showingUpcoming ? "semibold" : "regular"}>
          Upcoming
        </Text>
        <Text>|</Text>
        <Text 
          style={{cursor: 'pointer'}}
          ml={1} 
          onClick={() => setPendingType('past')}
          weight={!showingUpcoming ? "semibold" : "regular"}>
          Past
        </Text>
      </Box>
      {!!groupedByDate && Object.keys(groupedByDate).map((date, i) => (
        <Box key={i}>
          <Text mb={1} weight="semibold" capitalize>{unslugify(date)}</Text>
          {groupedByDate[date].map((dateInfo: any, _: any) => (
            <DateCard 
              style={{marginBottom: 15}}
              date={dateInfo} 
              currentUser={currentUser} 
              key={dateInfo.request_id} 
              bgColor={colors.pink}
            />
          ))} 
        </Box>
      ))}
      {(!requests?.payload.length && !isLoading) && (
        <TextWithIcon
          icon={<FrownIcon fontSize="large" sx={{color: colors.danger}} />} 
          weight="semibold"
          color={colors.danger} 
          justifyContent="center"
          mt={3}
        >
          {pendingType === 'upcoming' ? noUpcomingRequests : noExpiredRequests}
        </TextWithIcon>
      )}
    </Box>
  )
}

export default UnapprovedRequests