import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import MobileHomeHeader from '../../../components/MobileHomeHeader/MobileHomeHeader'
import { HomeWrapper } from './Home.styles'
import { useDateScheduleQuery, useFetchFriendsOpenDateRequestQuery, usePreferredDatingActivityQuery } from '../../../services/api'
import CompleteAccountSetup from './CompleteAccountSetup'
import DashboardActions from './DashboardActions'
import UpcomingDates from './UpcomingDates'
import { animateComponent } from '../../../utils/hoc'
import PendingRequests from './PendingRequests'
import { DateScheduleResponse } from '../../../utils/types'
import OpenDateRequestList from '../OpenDateRequests/components/OpenDateRequestList'
import { useHome } from './Home.hooks'



const Home = () => {
  useHome()

  const { data: dateSchedulesData } = useDateScheduleQuery({})
  const { data: favouriteActivitesData } = usePreferredDatingActivityQuery({})
  const hasSchedules = Object.keys((dateSchedulesData || {}) as DateScheduleResponse)
    .some((day) => !!(dateSchedulesData as any)?.[day]?.date_schedule_timeslots?.length)
  const { data: openRequests } = useFetchFriendsOpenDateRequestQuery({})

  return (
    <HomeWrapper>
      <MobileHomeHeader />
      <Container>
        <CompleteAccountSetup
          hasSchedules={hasSchedules}
          hasFavouriteActivities={!!favouriteActivitesData?.payload.length} 
        />
        <DashboardActions />
        <PendingRequests />
        <Box mt={3}>
          <UpcomingDates useSlider showPlaceholderWhenEmpty={false} />
        </Box>
        <OpenDateRequestList mt={3} openRequests={openRequests?.payload} />
      </Container>
    </HomeWrapper>
  )
}

export default animateComponent(Home,true)