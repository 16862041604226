import React from 'react'
import styled from 'styled-components';
import AcceptIcon from '@mui/icons-material/DoneAllRounded';
import DeclineIcon from '@mui/icons-material/CloseRounded';
import CollapsibleCard from '../../../../../components/CollapsibleCard/CollapsibleCard'
import { colors } from '../../../../../styles/_var'
import Text from '../../../../../components/Text'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import { ComponentSpacing, DateRequest, ParticipantRequest, User } from '../../../../../utils/types'
import { getUserByPID, participantToUser } from '../../../../../utils/helpers'
import { StatusLabel } from '../styles'
import { useAuthStore } from '../../../../../stores/authStore'
import { IconButton } from '@mui/material'
import { ParticipantResponse } from '../../../../../services/api';
import Avatar from '../../../../../components/Avatar/Avatar';


type InviteRequestProps = {
  requests: ParticipantRequest[],
  dateInfo: DateRequest,
  updateParticipantRequest: (request: {
    request_id: string,
    prequest_id: number,
    approval_status: ParticipantResponse
  }) => void
} & ComponentSpacing

type ReqeustViewProps = {
  request: ParticipantRequest
  currentUser: User,
  addedBy?: User | null,
  createdBy: User,
  updateRequest: (response: ParticipantResponse) => void
}

const RequestView = ({ 
  request, 
  currentUser,
  addedBy,
  createdBy,
  updateRequest
}: ReqeustViewProps) => {
  if (!addedBy) return null

  const canAction = currentUser.username === createdBy.username
  const isPending = request.approved === 'pending'
  const isDeclined = request.approved === 'declined'
  const user = participantToUser(request)

  return (
    <Box 
      p={2} 
      display="flex" 
      justifyContent="space-between"
      alignItems="center"
    >
      <div style={{position: "relative"}}>
        <UserInfo user={user} />
        <AddedByAvatar removeBorder src={addedBy.avatar} />
      </div>
      {(canAction && isPending) ? (
        <Box>
          <IconButton onClick={() => updateRequest("accepted")}>
            <AcceptIcon color="success" />
          </IconButton>
          <IconButton onClick={() => updateRequest("declined")}>
            <DeclineIcon sx={{color: colors.lightDanger}} />
          </IconButton>
        </Box>
      ) : (
        <StatusLabel $type={request.approved} style={{width: 70}} useDiv>
          <Text 
            variant="small" 
            center 
            color={isDeclined ? colors.white : undefined}
          >
            {request.approved}
          </Text>
        </StatusLabel>
      )}
    </Box>
  )
}

const InviteRequest = ({ 
  requests, 
  dateInfo,
  updateParticipantRequest
}: InviteRequestProps) => {
  const title = `Invite Requests (${requests?.length || 0})`
  const {currentUser} = useAuthStore()

  const getAddedBy = (pid: number) => {
    return getUserByPID(pid, (dateInfo?.date_participants || []), false)
  }

  const updateRequest = (requestId: number) => (response: ParticipantResponse) => {
    updateParticipantRequest({
      request_id: dateInfo.request_id,
      prequest_id: requestId,
      approval_status: response
    })
  }

  if (!requests.length) return null

  return (
    <CollapsibleCard
      showArror={!!requests?.length}
      textTitle={title}
      headerColor={colors.secondary}
      color={colors.white}
    >
      {requests?.map((r, i) => (
        <div key={r.id}>
          <RequestView 
            updateRequest={updateRequest(r.id)}
            createdBy={dateInfo.created_by}
            request={r} 
            currentUser={currentUser} 
            addedBy={getAddedBy(r.added_by_id)}
          />
          {i !== requests.length - 1 && <Divider />}
        </div>
      ))}
    </CollapsibleCard>
  )
}


export default InviteRequest

const AddedByAvatar = styled(Avatar)`
  width: 22px !important;
  height: 22px !important;
  position: absolute !important;
  top: -7px;
  border: solid 1px ${colors.lightgrey};
`