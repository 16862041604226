import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Text from '../../../../components/Text';
import PlusIcon from '@mui/icons-material/AddRounded';
import { AddActivityLink } from './Activities.styles';
import NoActivity from './NoActivity';
import ActivityList from './ActivityList';
import { useActivities } from './Activities.hooks';
import { animateComponent } from '../../../../utils/hoc';


const Activities = React.memo(() => {
  const {
    data,
    isLoading,
    isSuccess,
    openAddActivityModal
  } = useActivities()

  return (
    <Container>
      <Box mt={3} maxWidth={600} marginLeft="auto" marginRight="auto">
        <AddActivityLink onClick={openAddActivityModal} mb={2} icon={<PlusIcon />}>
          Add Activity
        </AddActivityLink>
        {(!data?.payload.length && isSuccess) && <NoActivity />}
        {isLoading && <Text center>We are fetching your activities love...</Text>}
        {isSuccess && !!data?.payload?.length && <ActivityList activities={data.payload} />}
      </Box>
    </Container>
  )
})

export default animateComponent(Activities, true)
