import styled from 'styled-components'
import { device } from '../../../styles/_var'

export const AuthContent = styled.div`
  max-width: 34rem;
  margin-left: auto;
  margin-right: auto;

  @media ${device.sm} {
    padding: 2rem;
    box-shadow: 0 0 2px 2px lightgray;
    border-radius: 4px;
  }
`


export const AuthLayoutWrapper = styled.div``