import React from 'react'
import ErrorPage from '../../pages/errorPages/ErrorPage';


type MyState = {
  hasError: boolean
}

type MyProps = {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return
    }

    // localStorage.clear()
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage errorCode="500" />
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary
