import styled from 'styled-components'
import { colors, spacing } from '../../styles/_var'

export const NavbarWrapper = styled.div`
  background: ${colors.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0 0px 4px 1px #EDEDED;
`

export const NavbarContent = styled.div`
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a:nth-child(1) {
    svg {
      font-size: 2.3rem;
    }
  }
  a:nth-child(2) {
    svg {
      position: relative;
      top: -0.5px !important;
      font-size: 1.9rem;
    }
  }
  a:nth-child(3) {
    svg {
      font-size: 2rem;
    }
  }
  a:nth-child(4) {
    svg {
      font-size: 1.8rem;
    }
  }
`

export const NavItem = styled.div<{$selected: boolean}>`
  text-align: center;
  padding: ${spacing.xxs} !important;
  display: flex;
  flex-direction: column;

  svg {
    height: 3.1rem;
  }

  opacity: .8;

  ${({ $selected }) => $selected && `
    opacity: 1;
    svg {
      font-size: 2.55rem !important;
    }
    p {
      font-family: 'Gilroy-semi-bold';
    }
  `}
`