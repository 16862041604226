import { DateTime } from "luxon";
import { dateIsInPast, dhm, durationToString, getUserByPID } from "../utils/helpers";
import { DateRequest, User } from "../utils/types";

class DateRequestClass {
  dateRequest: DateRequest
  startTime: DateTime
  endTime: DateTime
  currentUser?: User

  constructor(dateRequest: DateRequest, currentUser?: User) {
    this.dateRequest = dateRequest
    this.startTime = DateTime.fromISO(this.dateRequest.start_date)
    this.endTime = DateTime.fromISO(this.dateRequest.end_date)
    this.currentUser = currentUser
  }

  get createdBy() {
    return this.dateRequest.created_by
  }

  get creatorUsername() {
    return this.createdBy.username
  }

  get requestId() {
    return this.dateRequest.request_id
  }

  get duration() {
    const duration = dhm(this.endTime.diff(this.startTime).milliseconds).filter(e => e.value !== 0)
    return durationToString(duration)
  }

  get fullDate() {
    return this.startTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
  }

  get fullStartTime() {
    return this.startTime.toLocaleString(DateTime.TIME_SIMPLE)
  }

  get fullEndTime() {
    return this.endTime.toLocaleString(DateTime.TIME_SIMPLE)
  }

  get fullDateTime() {
    return `${this.fullDate}. ${this.fullStartTime} - ${this.fullEndTime}`
  }

  get otherParticipant() {
    return this.allOtherParticipants?.find(participant => participant.user?.username !== this.currentUser?.username)
  }

  get allOtherParticipants() {
    return this.dateRequest.date_participants?.filter(p => p.user?.username !== this.currentUser?.username)
  }

  get otherParticipantIsGuest() {
    return !this.dateRequest.created_by
  }

  get iAmSender() {
    return this.currentUser?.username === this.dateRequest.created_by?.username
  }

  get sender() {
    return this.dateRequest.date_participants?.find(p => p.user?.username === this.dateRequest.created_by?.username)  
  }

  get recipient() {
    return this.dateRequest.date_participants?.find(p => p.user?.username !== this.dateRequest.created_by?.username)  
  }

  get myParticipantObject() {
    return this.dateRequest?.date_participants?.find(p => p.user?.username === this.currentUser?.username)
  }

  get otherParticipantObject() {
    return this.dateRequest?.date_participants?.find(p => p.user?.username !== this.currentUser?.username)
  }

  get myRequestIsPending() {
    if (this.iAmSender) return false
    return this.myParticipantObject?.response === "pending"
  }

  get iAmRecipient() {
    return !this.iAmSender
  }

  get hasExpired() {
    return dateIsInPast(this.endTime)
  }

  get dateStatus() {
    if (this.hasExpired) return "expired"
    if (this.recipient?.response === "accepted") return "accepted"
    if (this.recipient?.response === "declined") return "declined"
    return "pending"  
  }

  get participantUsers() {
    return (this.dateRequest.date_participants
      ?.map(p => getUserByPID(p.id, this.dateRequest.date_participants))
      .filter(u => !!u) || []) as User[]
  }
}


export default DateRequestClass