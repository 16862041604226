import styled from "styled-components";
import Container from '@mui/material/Container'
import { colors, spacing, device } from "../../../styles/_var";

export const NotificationsWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    padding-top: 0.4rem;
    margin-top: ${spacing.sm};
  }
`

export const NotificationItem = styled(Container)`
  border-bottom: solid 1px ${colors.lightergrey} !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: ${spacing.xs} !important;
  align-items: center !important;
`