import React from 'react'
import Box from '@mui/material/Box';
import MaterialDialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Text from '../Text';
import { StyledDrawer } from './Drawer.styles'
import Divider from '@mui/material/Divider';
import { colors } from '../../styles/_var';
import IconButton from '@mui/material/IconButton';
import { useIsMobile } from '../../hooks/hooks';
import { Transition } from '../Dialog';
import { SxProps } from '@mui/system/';

type DrawerProps = {
  sx?: SxProps
  header?: string | React.ReactNode,
  children: React.ReactNode,
  hideBackdrop?: boolean,
  open?: boolean,
  onClose?: () => void,
  onOpen?: () => void,
  disableEnforceFocus?: boolean,
  anchor?: 'left' | 'right' | 'top' | 'bottom',
  showCloseIcon?: boolean
}

const Drawer = ({
  sx,
  header,
  children,
  hideBackdrop=false,
  open=false,
  onClose=() => {},
  onOpen=() => {},
  disableEnforceFocus=true,
  anchor="bottom",
  showCloseIcon=false
}: DrawerProps) => {
  const isMobile = useIsMobile()

  const renderHeader = () => {
    if (typeof header === 'string') {
      return (
        <>
          <Box px={2} display="flex" justifyContent="space-between">
            <Text my={2} variant="medium" weight="semibold">
              {header}
            </Text>
            {showCloseIcon && (
              <IconButton onClick={onClose}>
                <CloseIcon style={{fontSize: "1.9rem"}} />
              </IconButton>
            )}
          </Box>
          <Divider style={{background: colors.transparentgrey}} />
        </>
      )
    }

    return header
  }

  const renderDrawer = () => {
    return (
      <StyledDrawer
        disableAutoFocus={disableEnforceFocus}
        hideBackdrop={hideBackdrop}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        anchor={anchor}
      >
        {!!header && renderHeader()}
        {children}
      </StyledDrawer>
    )
  }

  const renderModal = () => {
    return (
      <MaterialDialog
        sx={sx || {
          '& .MuiPaper-root': {
            minWidth: "400px",
            width: '60%',
            maxWidth: "500px",
          }
        }}
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        {renderHeader()}
        {children}
      </MaterialDialog>
    )
  }

  return isMobile ? renderDrawer() : renderModal()
}

export default Drawer
