import React, { FunctionComponent, useState } from 'react';
import { getStyleSpacing } from '../../utils/helpers';
import { ComponentSpacing } from '../../utils/types';
import { TextWrapper, StyledText, Tooltip, StyledTextDiv, StyledTextSpan } from './Text.styles'
import Collapse from '@mui/material/Collapse';

export type TextVariant = 'title' | 'extra-small' | 'small' | 'medium' | 'regular' | 'subtext' | 'large' | 'semi-large'
export type TextWeight = 'regular' | 'semibold' | 'bold' | 'extrabold'

export type CSSTextProps = {
  $center?: boolean,
  $left?: boolean
  $right?: boolean,
  $justify?: boolean,
  $variant: TextVariant,
  $inline?: boolean,
  $nowrap?: boolean,
  $truncate?: boolean,
  $hasTooltip?: boolean,
  $link?: boolean,
  $lowercase?: boolean,
  $uppercase?: boolean,
  $italic?: boolean,
  $underline?: boolean,
  $capitalize?: boolean,
  $textDecorationStyle?: React.CSSProperties["textDecorationStyle"],
}

export interface ITextProps extends React.HTMLAttributes<HTMLParagraphElement>, ComponentSpacing {
  variant?: TextVariant,
  className?: string,
  nowrap?: boolean,
  color?: string,
  truncate?: boolean,
  inline?: boolean,
  tooltip?: string,
  center?: boolean,
  left?: boolean,
  right?: boolean,
  justify?: boolean,
  maxWidth?: number,
  opacity?: number,
  lighten?: boolean,
  weight?: number | TextWeight,
  link?: boolean,
  uppercase?: boolean,
  lowercase?: boolean,
  capitalize?: boolean,
  italic?: boolean,
  underline?: boolean,
  lineHeight?: number,
  useDiv?: boolean,
  useSpan?: boolean,
  textDecorationStyle?: React.CSSProperties["textDecorationStyle"],
  maxWords?: number
}

const Text: FunctionComponent<ITextProps> = React.memo(({
  children,
  tooltip,
  mt,
  mb,
  mr,
  ml,
  my,
  mx,
  m,
  color,
  maxWidth,
  style={},
  opacity,
  lighten,
  weight,
  lineHeight,
  useDiv,
  useSpan,
  maxWords,
  ...rest
}) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const setToolTip = (status: boolean) => (e: React.MouseEvent) => {
    if (!tooltip) return
    setShowToolTip(status);
  }

  const spacingComponents = { mr, ml, mb, mt, my, mx, m }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  if (color) componentStyle.color = color

  if (maxWidth) {
    componentStyle.maxWidth = maxWidth
    if (!rest.left) {
      componentStyle.marginLeft = 'auto'
      componentStyle.marginRight = 'auto'
    }
  }

  if (opacity || opacity === 0) componentStyle.opacity = opacity

  if (lighten) componentStyle.opacity = 0.6

  if (lineHeight) componentStyle.lineHeight = `${lineHeight}px`

  if (weight) {
    if (weight === 'bold') {
      componentStyle.fontFamily = 'Gilroy-bold'
    } else if ((typeof weight === 'number' && weight === 400) || weight === 'semibold') {
      componentStyle.fontFamily = 'Gilroy-semi-bold'
    } else if ((typeof weight === 'number' && weight > 400) || weight === 'extrabold') {
      componentStyle.fontFamily = 'Gilroy-extra-bold'
    } else {
      componentStyle.fontWeight = weight
    }
  }

  const [cssProps, remainingTextProps] = toSCCSSProps(rest)
  const Component = React.useMemo(() => {
    if (useSpan) return StyledTextSpan
    if (useDiv) return StyledTextDiv
    return StyledText
  }, [useDiv, useSpan])

  const newChildren = React.Children.map(children, (child) => {
    if (typeof child === 'string' && !!maxWords && child.length > maxWords) {
      return child.substring(0, maxWords) + '...'
    }
    return child
  })

  if (!tooltip) {
    return React.createElement(Component, {
      ...cssProps,
      ...remainingTextProps,
      style: componentStyle,
    }, newChildren)
  }

  return (
    <TextWrapper inline={rest.inline} style={spacingStyle}>
      {React.createElement(Component, {
        ...cssProps,
        ...remainingTextProps,
        onMouseEnter: setToolTip(true),
        onMouseLeave: setToolTip(false),
        $hasTooltip: !!tooltip
      }, newChildren)}
      {!!tooltip && (
        <Collapse in={showToolTip}>
          <Tooltip>{tooltip}</Tooltip>
        </Collapse>
      )}
    </TextWrapper>
  )
})

const toSCCSSProps = ({
  center,
  left,
  right,
  justify,
  variant,
  inline,
  nowrap,
  truncate,
  tooltip,
  link,
  lowercase,
  uppercase,
  italic,
  underline,
  capitalize,
  textDecorationStyle,
  ...rest
}: ITextProps): [CSSTextProps, Partial<ITextProps>] => {
  return [
    {
      $center: center,
      $left: left,
      $right: right,
      $justify: justify,
      $variant: variant || "regular",
      $inline: inline,
      $nowrap: nowrap,
      $truncate: truncate,
      $hasTooltip: !!tooltip,
      $link: link,
      $lowercase: lowercase,
      $uppercase: uppercase,
      $italic: italic,
      $underline: underline,
      $capitalize: capitalize,
      $textDecorationStyle: textDecorationStyle,
    },
    rest
  ]
}


export default Text;