import React from 'react'
import Box from '@mui/material/Box'
import { animateComponent } from '../../../utils/hoc'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import AddDetails from './components/AddDetails'
import RequestSent from './components/RequestSent'
import SelectDateAndTime from './components/SelectDateAndTime'
import SendRequest from './components/SendRequest'
import { useAddDetails, usePlanDate, useSelectDateAndTime } from './PlanDate.hooks'


const PlanDate = () => {
  useSetHeaderTitle('Plan a Date', true)
  const { 
    currentStep,
    setCurrentStep,
    DATE,
    ADD_DETAILS,
    SEND_REQUEST,
    CONFIRMATION_PAGE 
  } = usePlanDate()
  const dateTimeProps = useSelectDateAndTime()
  const { formData, onDetailsAdded } = useAddDetails({ setCurrentStep, nextStep: SEND_REQUEST})

  const onDateTimeSelected = () => setCurrentStep(ADD_DETAILS)
  
  return (
    <Box maxWidth={600} marginLeft="auto" marginRight="auto">
      {(() => {
        switch (currentStep) {
          case DATE:
            return (
              <SelectDateAndTime 
                {...dateTimeProps} 
                onComplete={onDateTimeSelected} 
              />
            )
          case ADD_DETAILS:
            return (
              <AddDetails
                goBack={() => setCurrentStep(DATE)}
                initialData={formData}
                onDetailsAdded={onDetailsAdded}
                user={dateTimeProps.user}
                activeTimeslot={dateTimeProps.selectedTimeslotPayload} />
            )
          case SEND_REQUEST:
            return (
              <SendRequest 
                onSuccess={() => setCurrentStep(CONFIRMATION_PAGE)}
                goBack={() => setCurrentStep(ADD_DETAILS)}
                selectedDate={dateTimeProps.selectedDate}
                selectedTimeslot={dateTimeProps.selectedTimeslotPayload}
                user={dateTimeProps.user} 
                details={formData} />
            )
          case CONFIRMATION_PAGE:
            return <RequestSent />
          default:
            return <p>Oops! you shouldn't be seeing this</p>
        }
      })()}
    </Box>
  )
}

export default animateComponent(PlanDate, true)
