/* eslint no-self-assign: 0 */ 

import { useEffect, useMemo, useState } from "react"
import { exportAsImage, firstField, generateRandomNumber, getParticipantById, getUserByPID, isEmpty, participantToUser, uploadToS3 } from "../../../../../utils/helpers"
import { useUiStore } from "../../../../../stores/uiStore"
import gusiberiApi, { 
  DateComment,
  DateCommentsResponse,
  DateMemoryScoreboard,
  ScoreboardData,
  useDeleteMemoryRequestMutation,
  useFetchDateCommentsQuery,
  useFetchDateMediaQuery,
  useFetchDateScoreboardRequestQuery,
  useSaveDateCommentsMutation,
  useSaveDateScoreboardRequestMutation,
  useSaveDateMediaMutation,
  DateMemoryMedia,
  DateMediaResponse,
  TeamScoreboardData
} from "../../../../../services/api"
import { useProcessError } from "../../../../../hooks/hooks"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { useAuthStore } from "../../../../../stores/authStore"
import { DateRequest, User } from "../../../../../utils/types"
import { useDispatch } from "react-redux"
import { DateTime } from "luxon"
import { AWS_BASE_URL, DATE_IMAGES, routes } from "../../../../../utils/constants"
import ImageCropper from "../../../../../utils/imageCropper"

export type RoundScores = Record<string, string>
export type ScoreBoard = DateMemoryScoreboard['memory_object']
export type CacheOperation = "add" | "delete" | "update" | "set"


export const useDateMemoryScoreboard = (initialState = [] as ScoreBoard[], dateInfo: DateRequest, scoreboardIndex?: number) => {
  const { cancelDialog, showConfirmDialog } = useUiStore()
  const [scoreBoards, setScoreboards] = useState<ScoreBoard[]>(initialState || [])
  const [isFormTeamOpen, setIsFormTeamOpen] = useState(false)
  const [currentBoardIndex, setCurrentBoardIndex] = useState(scoreboardIndex || 0)
  const currentScoreboard = scoreBoards[currentBoardIndex]

  const scores = (currentScoreboard?.scores_data?.results || currentScoreboard?.scores_data || {}) as Record<string, string[]>
  const teams = currentScoreboard?.scores_data?.results && currentScoreboard?.scores_data?.members && currentScoreboard?.scores_data?.teams
  const isTeam = !!teams
  const teamMembers = currentScoreboard?.scores_data?.members as Record<string, number[]>
  const winner = (isTeam ? currentScoreboard?.scores_data?.winning_team : currentScoreboard?.winner?.id) as string | number | null

  const hasScores = !isEmpty(currentScoreboard?.scores_data)
  const numberOfRounds: number = isEmpty(scores) ? 0 : firstField(scores)?.length
  const [saveScoreboard, { isLoading: isSaving }] = useSaveDateScoreboardRequestMutation()
  const { currentUser } = useAuthStore()
  const processError = useProcessError()
  const { openSuccessToast } = useUiStore()

  useEffect(() => {
    if (initialState.length > 0 && scoreBoards.length === 0) {
      setScoreboards(initialState)
      setCurrentBoardIndex(initialState.length - 1)
    }
  }, [initialState, scoreBoards.length])

  const addNewRoundToScoreboard = (data: RoundScores) => {
    const newScores = {...scores}
    Object.keys(data).forEach(pid => {
      newScores[pid] = [...(newScores[pid] || []), data[pid]]
    })
    return newScores
  }

  const removeRoundFromScoreboard = (roundNumber: number) => {
    const newScores = {...scores}
    Object.keys(newScores).forEach(pid => {
      newScores[pid] = newScores[pid].filter((_, i) => i !== roundNumber)
    })

    return newScores
  }

  const prepareTeamPayload = (scores: Record<string, string[]>): TeamScoreboardData =>  {
    return {
      teams: currentScoreboard?.scores_data?.teams,
      members: currentScoreboard?.scores_data?.members as Record<string, number[]>,
      results: scores
    }
  }

  const getPayload = (scores: Record<string, string[]>): ScoreboardData => {
    if (isTeam) return prepareTeamPayload(scores)
    return scores
  }

  const deleteRound = (roundNumber: number) => {
    showConfirmDialog({
      dialogBody: "Are you sure you want to delete this round?",
      onConfirmAction: () => {
        const newScores = removeRoundFromScoreboard(roundNumber)
        setScores(newScores)
        saveScoreboardData(getPayload(newScores))
      }
    })
  }

  const addNewRound = async (newRound: RoundScores) => {
    const newScores = addNewRoundToScoreboard(newRound)
    setScores(newScores)
    cancelDialog()
    saveScoreboardData(getPayload(newScores))
  }

  const updateLabel = async (label: string) => {
    saveScoreboardData(getPayload(scores), undefined, label)
  }

  const saveScoreboardData = async (newScores: ScoreboardData, winner?: number, label?: string) => {
    try {
      const response = await saveScoreboard({
        request_id: dateInfo.request_id,
        scores: JSON.stringify(newScores),
        memory_type: 'scoreboard',
        scoreboard_id: currentScoreboard?.id,
        winner,
        label: label || currentScoreboard?.label
      }).unwrap()
      replaceScoreboard(response.payload.memory_object)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const saveFormTeam = async (teamsData: Record<string, number[]>) => {
    const transformedData: TeamScoreboardData = {
      teams: Object.keys(teamsData),
      members: teamsData,
      results: Object.entries(teamsData).reduce((prev, [teamName, _]) => {
        prev[teamName] = []
        return prev
      }, {} as Record<string, string[]>)
    }

    try {
      await saveScoreboardData(transformedData)
      setIsFormTeamOpen(false)
    } catch (e) {
      console.log(e)
    }
  }

  const pickWinner = async (pid: number) => {
    setWinner(pid)
    saveScoreboardData(getPayload(scores), pid)
  }

  const getRoundScores = (roundIndex: number) => {
    return Object.keys(scores).reduce((prev, curr) => {
      prev[curr] = scores[curr][roundIndex]
      return prev
    }, {} as RoundScores)
  }

  const editRound = (roundNumber: number) => (roundData: RoundScores) => {
    const newScores = updateScoresInRound(roundNumber, roundData)
    setScores(newScores)
    cancelDialog()
    saveScoreboardData(getPayload(newScores))
  }

  const updateScoresInRound = (roundNumber: number, roundData: RoundScores) => {
    const newScores = {...scores} as Record<string, string[]>
    
    Object.keys(newScores).forEach(pid => {
      const newRounds = [...newScores[pid]]
      newRounds[roundNumber] = String(roundData[pid])
      newScores[pid] = newRounds
    })

    return newScores
  }

  const winnerParticipant = useMemo(() => {
    if (!winner || typeof winner === 'string') return null
    return getUserByPID(winner, dateInfo.date_participants || [])
  }, [winner, dateInfo.date_participants])

  const downloadScorecard = async (element: HTMLElement | null, filename: string) => {
    if (!element) return

    openSuccessToast({message: "Downloading scorecard..."})
    return exportAsImage(element, filename)
  }

  const replaceScoreboard = (scoreboard: ScoreBoard) => {
    if (!scoreBoards.length) {
      setScoreboards([scoreboard])
    } else {
      setScoreboards(scoreBoards.map((b, i) => i === currentBoardIndex ? scoreboard : b))
    }
  }

  const setScores = (scores: Record<string, string[]>) => {
    setScoreboards(scoreBoards.map((b, i) => i === currentBoardIndex ? {...b, scores_data: getPayload(scores)} : b))
  }

  const setWinner = (id: number) => {
    setScoreboards(scoreBoards.map((b, i) => i === currentBoardIndex ? {...b, winner: getParticipantById(id, dateInfo.date_participants)} : b))
  }

  const gotoNextBoard = () => { 
    setCurrentBoardIndex(i => i + 1)
  }

  const gotoPreviousBoard = () => {
    setCurrentBoardIndex(i => i - 1)
  }

  const addNewScoreboard = () => {
    const newScoreboards = [...scoreBoards, {} as ScoreBoard]
    setScoreboards(newScoreboards)
    setCurrentBoardIndex(newScoreboards.length - 1)
  }

  const openFormTeam = () => {
    setIsFormTeamOpen(true)
  }

  const closeFormTeam = () => {
    setIsFormTeamOpen(false)
  }

  const hasNextBoard = currentBoardIndex < scoreBoards.length - 1
  const hasPreviousBoard = currentBoardIndex > 0
  const scorecardLink = routes.SCORECARD.replace(':date_id', dateInfo.request_id) + '?scoreboardIndex=' + currentBoardIndex

  return {
    scores,
    setScores,
    numberOfRounds,
    addNewRound,
    deleteRound,
    isSaving,
    currentUser,
    winner,
    setWinner,
    pickWinner,
    getRoundScores,
    editRound,
    winnerParticipant,
    downloadScorecard,
    currentScoreboard,
    updateLabel,
    gotoNextBoard,
    gotoPreviousBoard,
    addNewScoreboard,
    hasNextBoard,
    hasPreviousBoard,
    scorecardLink,
    hasScores,
    openFormTeam,
    closeFormTeam,
    isFormTeamOpen,
    saveFormTeam,
    saveScoreboardData,
    isTeam,
    teamMembers,
    teams
  }
}

export const useDateMemoryScoreboardEffect = (requestId: string) => {
  const { data, isLoading, isError } = useFetchDateScoreboardRequestQuery({
    request_id: requestId
  })

  return {
    scoresFromServer: data?.payload,
    isFetchingScores: isLoading,
    isFetchScoresError: isError
  }
}

export const useDateMemoryComments = (dateInfo: DateRequest) => {
  const [comment, setComment] = useState('')
  const { data, isLoading } = useFetchDateCommentsQuery({ request_id: dateInfo.request_id })
  const [ deleteMemory, { isLoading: deletingMemory } ] = useDeleteMemoryRequestMutation()
  const [makeRequest, { isLoading: savingComment}] = useSaveDateCommentsMutation()
  const processError = useProcessError()
  const { currentUser } = useAuthStore()
  const dispatch = useDispatch()

  const optimisticAdd = (newComment: DateComment) => {
    return cacheOperation("add", undefined, newComment)
  }

  const optimisticUpdate = (id: number, updatedComment: DateComment) => {
    return cacheOperation("update", id, updatedComment)
  }

  const optimisticDelete = (id: number) => {
    return cacheOperation("delete", id)
  }


  const removeComment = async (memory_id: number) => {
    const patchResult = optimisticDelete(memory_id)


    try {
      await deleteMemory({request_id: dateInfo.request_id, memory_id}).unwrap()
    } catch (e) {
      processError(e as FetchBaseQueryError)
      patchResult.undo()
    }
  }

  const cacheOperation = (operation: "update" | "add" | "delete", id?: number, payload?: DateComment) => {
    const onUpdate = (comments: DateCommentsResponse) => {
      if (!id || !payload) {
        comments.payload = comments.payload
      } else {
        comments.payload = comments.payload.map(c => {
          if (c.id === id) return payload
          return c
        })
      }
    }

    const onAdd = (comments: DateCommentsResponse) => {
      if (!payload) {
        comments.payload = comments.payload
      } else {
        comments.payload = [payload, ...comments.payload]
      }
    }

    const onDelete = (comments: DateCommentsResponse) => {
      if (!id) {
        comments.payload = comments.payload
      } else {
        comments.payload = comments.payload.filter(c => c.id !== id)
      }
    }

    return dispatch(
      gusiberiApi.util.updateQueryData(
        "fetchDateComments",
        {request_id: dateInfo.request_id},
        operation === "add" ? onAdd : (operation === "delete" ? onDelete : onUpdate)
      ) as any
    )
  }

  const saveComment = async () => {
    if (!comment.trim()) return

    const pid = getPIDOfUser(currentUser)
    const fakeId = generateRandomNumber(8)
    const newComment: DateComment = {
      id: fakeId,
      date_participant_id: String(pid),
      memory_object: {
        id: fakeId,
        comment,
        created_at: DateTime.now().toISODate()
      }
    }

    const patchResult = optimisticAdd(newComment)

    try {
      const response = await makeRequest({
        request_id: dateInfo.request_id,
        memory_type: 'comment',
        comment,
      }).unwrap()
      setComment('')
      optimisticUpdate(fakeId, {
        ...newComment,
        id: response.payload.id, 
        memory_object: response.payload.memory_object
      })
    } catch (e) {
      processError(e as FetchBaseQueryError)
      patchResult.undo()
    }
  }

  const getPIDOfUser = (user: User) => {
    return dateInfo.date_participants?.find(p => p.user?.username === user.username)?.id
  }

  const getUserFromPariticipants = (pid: string) => {
    const participant = dateInfo.date_participants?.find(p => p.id === +pid)
    return participant ? participantToUser(participant) : null
  }

  const shouldShowMenuOptions = (pid: string) => {
    return getUserFromPariticipants(pid)?.username === currentUser.username
  }

  return {
    comments: data?.payload,
    isLoading,
    savingComment,
    saveComment,
    setComment,
    getUserFromPariticipants,
    getPIDOfUser,
    comment,
    shouldShowMenuOptions,
    deletingMemory,
    removeComment,
  }
}

export const useDateMedia = (dateInfo: DateRequest) => {
  const { 
    data, 
    isLoading: fetchingMedia,
    refetch
  } = useFetchDateMediaQuery({ request_id: dateInfo.request_id })
  const [unsavedMedia, setUnsavedMedia] = useState<string[]>([])
  const [saveMedia] = useSaveDateMediaMutation()
  const { openErrorToast } = useUiStore()
  const [deleteMedia] = useDeleteMemoryRequestMutation()
  const { currentUser } = useAuthStore()
  const processError = useProcessError()
  const dispatch = useDispatch()
  const MAX_IMAGE_UPLOAD= 10

  const getPIDOfUser = (user: User) => {
    return dateInfo.date_participants?.find(p => p.user?.username === user.username)?.id
  }

  const cache = (operation: CacheOperation, payload?: DateMemoryMedia | DateMemoryMedia[], id?: number) => {
    const onAdd = (allMedia: DateMediaResponse) => {
      if (payload && !Array.isArray(payload)) {
        allMedia.payload = [payload, ...allMedia.payload]
      }
    }

    const onRemove = (allMedia: DateMediaResponse) => {
      if (id && !Array.isArray(payload)) {
        allMedia.payload = allMedia.payload.filter(m => m.id !== id)
      }
    }

    const onUpdate = (allMedia: DateMediaResponse) => {
      if (id && payload && !Array.isArray(payload)) {
        allMedia.payload = allMedia.payload.map(m => m.id === id ? payload : m)
      }
    }
    
    const onSet = (allMedia: DateMediaResponse) => {
      if (payload && Array.isArray(payload)) {
        allMedia.payload = payload // work in progress
      }
    }

    const actionMapper: Record<CacheOperation, (allMedia: DateMediaResponse) => void> = {
      add: onAdd,
      delete: onRemove,
      update: onUpdate,
      set: onSet  
    }

    dispatch(
      gusiberiApi.util.updateQueryData(
        "fetchDateMedia",
        {request_id: dateInfo.request_id},
        actionMapper[operation]
      ) as any
    )
  }
  
  const generateFakeMediaPayload = (imageUrl: string) => {
    const fakeId = generateRandomNumber(4)
    return {
      id: fakeId,
      date_participant_id: String(getPIDOfUser(currentUser) || 0),
      memory_object: {
        id: fakeId,
        url: imageUrl,
      }
    }
  }

  const uploadMediaToS3 = async (image: string, uploadUrl: string) => {
    const fakePayload = generateFakeMediaPayload(image)
    cache('add', fakePayload)
    await uploadMedia(image, uploadUrl)
    setUnsavedMedia(images => images.filter(i => i !== image))

    return fakePayload
  }

  const addImages = async (images: string[]) => {
    if (images.length > MAX_IMAGE_UPLOAD) {
      return openErrorToast({message: `You tried to upload ${images.length} images. (max: ${MAX_IMAGE_UPLOAD})`})
    }

    setUnsavedMedia(images)

    const uploadFilenames = images.map(_ => `${DATE_IMAGES}/${dateInfo.request_id}${generateRandomNumber(10)}.jpg`)
    const s3Urls = uploadFilenames.map(filename => `${AWS_BASE_URL}/${filename}`)

    await Promise.all(images.map((image, i) => uploadMediaToS3(image, uploadFilenames[i])))
    
    try {
      await saveMedia({
        memory_type: 'photo',
        url: s3Urls,
        request_id: dateInfo.request_id
      }).unwrap()
      setTimeout(refetch, 2000)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const uploadMedia =  async (imageUrl: string, defaultFilename?: string) => {
    const thumbnailUrl = await ImageCropper.createThumnbail(imageUrl, 200)
    const filename = defaultFilename || `${DATE_IMAGES}/${dateInfo.request_id}${generateRandomNumber(10)}.jpg`
    const thumbnailFilename = filename.replace('.jpg', '-small.jpg')

    await Promise.all([
      uploadToS3({ fileUrl: imageUrl, filename }),
      uploadToS3({ fileUrl: thumbnailUrl || imageUrl, filename: thumbnailFilename })
    ])

    return `${AWS_BASE_URL}/${filename}`
  }

  const removeMedia = async(mediaId: number) => {
    const prevData = [...(data?.payload || [])]
    cache('delete', undefined, mediaId)
    try {
      await deleteMedia({request_id: dateInfo.request_id, memory_id: mediaId}).unwrap()
    } catch (e) {
      processError(e as FetchBaseQueryError)
      cache('set', prevData)
    }
  }

  return {
    media: data?.payload || [],
    fetchingMedia,
    unsavedMedia,
    addImages,
    removeMedia
  }
}