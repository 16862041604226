import React, { useEffect, useRef, useState } from 'react'
import Container from '@mui/material/Container'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge';
import HeartIcon from '@mui/icons-material/FavoriteBorderRounded';
import MessageIcon from '@mui/icons-material/MailOutlineRounded';
import CancelIcon from '@mui/icons-material/ClearRounded';
import IconButton from '@mui/material/IconButton';
import { useIsMobile } from '../../hooks/hooks'
import { HeaderContent, MobileHomeHeaderWrapper, SearchBar } from './MobileHomeHeader.styles'
import Input from '../Input';
import SearchDrawer from './SearchDrawer';
import { colors, spacing } from '../../styles/_var'
import { Link } from 'react-router-dom';
import { routes } from '../../utils/constants';
import { useFetchNotificationsQuery, useFetchUpcomingDatesQuery } from '../../services/api';
import { useAuthStore } from '../../stores/authStore';
import { DateRequest } from '../../utils/types';

const MobileHomeHeader = () => {
  const ref = useRef<HTMLInputElement>()
  const isMobile = useIsMobile()
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState('')
  const { currentUser } = useAuthStore()


  const { data } = useFetchNotificationsQuery({})
  const { data: upcomingDates } = useFetchUpcomingDatesQuery({})

  const checkForUnreadMessage = (date: DateRequest) => date.last_sent_message?.user.username !== currentUser.username && date.last_sent_message?.seen === false
  const hasUnreadMessages = upcomingDates?.payload.some(checkForUnreadMessage)
  const noOfUnseenNotifications = data?.payload.filter(n => !n.seen).length


  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        ref?.current?.focus()
      }, 150)
    }
  }, [isOpen])


  if (!isMobile) return null


  return (
    <MobileHomeHeaderWrapper>
      <Container>
        <HeaderContent>
          <Link to={routes.LANDING_PAGE}>
            <img src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" alt="gusiberi logo" height={38} />
          </Link>
          <Box>
            <Link to={routes.NOTIFICATIONS} style={{marginRight: spacing.xs}}>
              <Badge 
                color="error" 
                overlap="circular" 
                badgeContent={noOfUnseenNotifications}
              >
                <HeartIcon sx={{fontSize: '2.9rem', color: colors.primary }} className="jr-notifications" />
              </Badge>
            </Link>
            <Link to={routes.MESSAGES}>
              <Badge variant={hasUnreadMessages ? 'dot' : undefined} color="error" overlap="circular">
                <MessageIcon sx={{fontSize: '2.9rem', color: colors.primary }} className='jr-messages' />
              </Badge>
            </Link>
          </Box>
        </HeaderContent>
        <SearchBar>
          <Input
            autoComplete="off"
            name="find_gusiberi_user"
            onClick={() => setIsOpen(true)}
            value={query}
            onChange={e => setQuery(e.target.value)}
            size="small"
            placeholder="Search..."
            mr={2}
            inputRef={ref}
            InputProps={{endAdornment: (
              <IconButton onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
                setQuery('')
              }}>
                {isOpen ? <CancelIcon fontSize="large" /> : <SearchRoundedIcon fontSize="large" />}
              </IconButton>
            )}} 
          />
        </SearchBar>
      </Container>
      <SearchDrawer
        query={query}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
       />
    </MobileHomeHeaderWrapper>
  )
}

export default MobileHomeHeader

