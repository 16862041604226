import React, { FunctionComponent, useCallback, useEffect } from 'react';
import Text from '../Text';
import { AlertColor } from '@mui/material/Alert'
import Alert from '@mui/material/Alert'
import { StyledStack } from './Toast.styles';
import { removeToast, selectToastMessage } from '../../stores/uiStore';
import { useDispatch, useSelector } from 'react-redux';

export interface IToastMessage {
  id?: string,
  message: string,
  severity?: AlertColor,
  duration?: number,
}


type Intervals = {
  [key: string]: NodeJS.Timeout
}


const Toast: FunctionComponent = () => {
  const dispatch = useDispatch()
  const toastMessages = useSelector(selectToastMessage)
  const deleteToast = useCallback((id?: string) => dispatch(removeToast(id)), [
    dispatch
  ]) 

  useEffect(() => {
    const intervals: Intervals = {}
    toastMessages.forEach(toast => {
      if (toast.id && !intervals[toast.id]) {
        intervals[toast.id] = setTimeout(() => deleteToast(toast.id), toast.duration)
      }
    })
  }, [toastMessages, deleteToast])

  return (
    <StyledStack spacing={1}>
      {toastMessages.map((toast) => (
        <Alert 
          onClose={() => deleteToast(toast.id)}
          severity={toast.severity}
          key={toast.id}
          variant='standard'
        >
          <Text variant="regular" truncate>
            {toast.message.substring(0, 100)}
          </Text>
        </Alert>
      ))}
    </StyledStack>
  )
}

export default Toast
