import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import { colors, size, device } from '../../../styles/_var'

export const HomeWrapper = styled.div`
  @media ${device.md} {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
  }
`

export const AccountCompleteWrapper = styled.div``

export const DashboardCard = styled(({ noEffect, ...rest }) => <Paper {...rest} />)`
  padding: 4px;
  border: solid 1px ${colors.lightgrey};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform: scale(1);

  @media (min-width: ${size.sm}) {
    ${({ noEffect }) => !noEffect && `
      &:hover {
        transform: scale(1.05);
      }
    `}
  }
`
