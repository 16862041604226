import React from 'react'
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { useFetchSentRequestsQuery, useProfileQuery } from '../../../services/api'
import LoadingSkeleton from './components/ProfileSkeleton'
import Box from '@mui/material/Box'
import { colors, spacing } from '../../../styles/_var'
import Text from '../../../components/Text'
import { UserProfile } from '../../../utils/types'
import { ActivityWrapper, ProfilePageWrapper } from '../Profile.styles'
import { DashboardCard } from '../../Dashboard/Home/Home.styles'
import Chip from '../../../components/Chip/Chip'
import Button, { CustomLink } from '../../../components/Button/Button'
import ProfileHeader from './components/ProfileHeader';
import FriendshipStatusView from '../../../components/FriendshipStatusView/FriendshipStatusView'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../stores/authStore'
import { useUiStore } from '../../../stores/uiStore'
import AddUserToActivity from './components/AddUserToActivity'
import { dateIsInPast, userIsParticipant } from '../../../utils/helpers'

const useProfilePage = () => {
  const currentUser = useSelector(selectCurrentUser)
  const { username = '' } = useParams()
  const { data, isLoading, isSuccess, isError } = useProfileQuery({username})
  const { data: sentRequestsResponse } = useFetchSentRequestsQuery({})
  const { 
    user, 
    activities, 
    friendship_status 
  } = data?.payload || {} as UserProfile
  const firstname = user?.full_name?.split(' ')[0]
  const isYou = currentUser.username === username
  const sentRequests = sentRequestsResponse?.payload
    .filter(activity => !dateIsInPast(activity.end_date) && !userIsParticipant(activity, user)) || []


  return {
    isYou,
    firstname,
    currentUser,
    isLoading,
    data,
    isSuccess,
    activities,
    friendship_status,
    user,
    sentRequests,
    showAddToActivity: !!sentRequests?.length,
    isError
  }
}


const ProfilePage = () => {
  useSetHeaderTitle('Profile', true)
  const { showDialog } = useUiStore()
  const {
    isYou,
    firstname,
    isLoading,
    data,
    isSuccess,
    activities,
    friendship_status,
    user,
    showAddToActivity,
    sentRequests,
    isError
  } = useProfilePage()

  const addParticipantToActivity = () => {
    showDialog({
      dialogBody: <AddUserToActivity user={user} sentRequests={sentRequests} />
    })
  }

  if (isError) {
    return (
      <Container>
        <Box mt={5}>
          <Text color={colors.danger} variant="medium" weight="semibold" center>
            User not found
          </Text>
        </Box>
      </Container>
    )
  }

  return (
    <Container>
      <ProfilePageWrapper>
        {isLoading && <LoadingSkeleton />}
        {(isSuccess && !!data) && (
          <>
            <ProfileHeader user={user} />
            <FriendshipStatusView friendshipStatus={friendship_status} user={user.username} />
            {!!user.bio && <Box mt={2}>
              <Text weight="semibold" variant="medium">Bio</Text>
              <DashboardCard elevation={2} noEffect>
                <Box padding={spacing.xxs} bgcolor={colors.lightyellow}>
                  <Text>{user.bio}</Text>
                </Box>
              </DashboardCard>
            </Box>}
            <Box mt={3} mb={2}>
              <Text weight="semibold" variant="medium">Preferred Date Activity</Text>
              <DashboardCard elevation={2} noEffect>
                <Box padding={spacing.xxs} bgcolor={colors.veryLightGreen}>
                  <Text weight="semibold" mb={1}>{firstname} really loves 🥰</Text>
                  {activities.map((activity, i) => (
                    <ActivityWrapper key={i}>
                      <Text weight="semibold" mb={1}>
                        {activity.description}
                      </Text>
                      {activity.favourite_places?.split("||").map((fp, i) => (
                        <Chip
                          key={i}
                          mr={2}
                          mb={1}
                          innerStyle={{background: colors.veryLightGreen}}
                        >
                          {fp}
                        </Chip>
                      ))}
                    </ActivityWrapper>
                  ))}
                </Box>
              </DashboardCard>
            </Box>
            {!isYou && (
              <Box className='fixed-button-bottom' display="flex">
                <Button
                  fullWidth
                  variant='contained'
                  LinkComponent={CustomLink}
                  href={`/${user.username}/plan`}
                >
                  <Text color={colors.white} weight="semibold">
                    Plan date
                  </Text>
                </Button>
                {showAddToActivity && (
                  <Button 
                    fullWidth 
                    ml={1} 
                    variant="outlined" 
                    onClick={addParticipantToActivity}
                  >
                    <Text>
                      Add to activity
                    </Text>
                  </Button>
                )}
              </Box>
            )}
          </>
        )}
      </ProfilePageWrapper>
    </Container>
  )
}

export default ProfilePage