import React, { FunctionComponent } from 'react'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button/Button'
import { colors } from '../../../../styles/_var'
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded'


type ContinueButtonProps = {
  disabled: boolean,
  onClick?: () => void
}

const ContinueButton: FunctionComponent<ContinueButtonProps> = ({
  disabled,
  onClick
}) => {
  return (
    <div className='fixed-button-bottom'>
      <Button
        onClick={onClick}
        disabled={disabled}
        sx={{display: 'flex', justifyContent: 'space-between'}}
        variant='contained'
        fullWidth
        endIcon={<RightIcon />}
      >
        <Text color={colors.white} weight="semibold">
          Continue
        </Text>
      </Button>
    </div>
  )
}

export default ContinueButton
