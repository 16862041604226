import React, { useRef, useState, useMemo, useEffect } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { debounce } from '@mui/material/utils';
import { 
  CardInner, 
  FindUserWrapper, 
  SearchBody, 
  SearchIcon, 
  ResultsContent 
} from './FindUser.styles'
import Text from '../Text'
import Input from '../Input';
import { animateComponent } from '../../utils/hoc';
import { useLazySearchUsersQuery } from '../../services/api';
import ResultList from '../MobileHomeHeader/ResultList';



export const ResultsMenu = animateComponent<{
  query: string, 
  style?: React.CSSProperties,
  className?: string,
  onClick?: (user: any) => void,
  renderBody?: (user: any) => React.ReactNode
}>(
  ({
    style, 
    query, 
    onClick,
    className, 
    renderBody
  }) => {
    const [searchUsers, { isLoading, isSuccess, data }] = useLazySearchUsersQuery()
    const runSearch = useMemo(() => debounce(query => {
      searchUsers({ query })
    }, 900), [searchUsers])

    useEffect(() => {
      if (!query.trim()) return
      runSearch(query)
    }, [runSearch, query])

    return (
      <ResultsContent style={style} className={className}>
        {(isLoading || !isSuccess) && <Text center>searching...</Text>}
        {(isSuccess && !data?.payload.length) && <Text>No user found</Text>}
        {(isSuccess && !!data?.payload.length) && (
          <ResultList 
            renderBody={renderBody}
            results={data.payload} 
            onClick={onClick}
          />
        )}
      </ResultsContent>
    )
  }
)


const FindUser = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [query, setQuery] = useState('')
  const menuIsOpen = query.trim().length > 2;

  return (
    <FindUserWrapper>
      <CardInner>
        <Text variant="regular" left mb={1} weight="semibold">
          Plan your next date.
        </Text>
        <SearchBody>
          <SearchIcon>
            <SearchRoundedIcon fontSize="large" />
          </SearchIcon>
          <Input 
            name="find_gusiberi_user"
            value={query}
            onChange={e => setQuery(e.target.value)}
            ref={ref}
            placeholder="Find date by username." 
            autoComplete="false" />
        </SearchBody>
        {menuIsOpen && <ResultsMenu query={query} />}
      </CardInner>
    </FindUserWrapper>
  )
}

export default FindUser