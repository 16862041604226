import React, { FunctionComponent } from "react"
import Text, { ITextProps } from "../Text/Text"
import { Wrapper } from './TextWithIcon.styles'
import { getStyleSpacing } from "../../utils/helpers"
import { ComponentSpacing } from "../../utils/types"

interface TextWithIconProps extends ITextProps {
  icon: React.ReactNode,
  fullWidth?: boolean,
  after?: boolean,
  before?: boolean,
  textSpacing?: ComponentSpacing,
  children: any,
  justifyContent?: 'space-between' | 'center' | 'space-evenly' | 'flex-start' | 'flex-end'
}

const TextWithIcon: FunctionComponent<TextWithIconProps> = ({ 
  icon,
  mt,
  mb,
  mr,
  ml,
  after,
  fullWidth,
  before=true,
  className,
  justifyContent,
  onClick,
  maxWidth,
  textSpacing={},
  style={},
  ...rest
}) => {
  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)

  if (justifyContent) style.justifyContent = justifyContent
  const textSpacingStyle = getStyleSpacing(textSpacing)

  return (
    <Wrapper
      className={className}
      style={{
        ...spacingStyle, 
        width: fullWidth ? '100%' : 'auto',
        maxWidth: maxWidth, 
        ...(style || {})
      }}
      onClick={onClick}
    >
      {before && !after && icon}
      <Text style={textSpacingStyle} {...rest} />
      {after && icon}
    </Wrapper>
  )
}

export default TextWithIcon
