import React from 'react'
import Input, {InputProps} from './Input'
import { usePlacesWidget } from 'react-google-autocomplete';



type AddressInputProps = InputProps & {
  onPlaceSelected?: (place: any) => void
}

const AddressInput = ({onPlaceSelected, ...rest}: AddressInputProps) => {
  const { ref } = usePlacesWidget<HTMLDivElement>({
    apiKey: 'AIzaSyBBq4acAhf09G0qRR80P3mAdD1kbDD1BaA',
    onPlaceSelected: (place) => {
      onPlaceSelected?.(place)
    },
    options: {
      types: [],
      componentRestrictions: { country: "ca" },
    },
  })

  return (
    <Input ref={ref} {...rest} />
  )
}

export default AddressInput