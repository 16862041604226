import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { Notification, NotificationGroup } from '../../../../utils/types'
import { NotificationItem } from '../Notifications.styles'
import Avatar from '../../../../components/Avatar/Avatar'
import Text from '../../../../components/Text'
import { humanize } from '../../../../utils/helpers'
import { ButtonBase } from '@mui/material'
import { Link } from 'react-router-dom'
import { colors } from '../../../../styles/_var'
import { useMarkNotificationAsSeenMutation } from '../../../../services/api'


const NOTIFICATION_TEXT: {[k in NotificationGroup]: string} = {
  friend_request_received: 'sent you a friends request',
  friend_request_accepted: 'accepted your friends request',
  message_received: 'sent you a new message',
  date_request_received: 'sent you a invite',
  date_request_declined: 'declined your invite',
  date_request_accepted: 'accepted your invite',
  date_canceled: 'canceled your upcoming date',
  date_rescheduled: 'rescheduled your date',
  upcoming_date_reminder: ''
}

const constructLink = ({group, subject_id, subject_id_2, sender}: Notification) => {
  if (group.includes('date')) {
    return `/date_requests/${subject_id_2 || subject_id}`
  }

  if (group.includes('friend') && sender) {
    return `/${sender.username}`
  }

  return ''
}

const DefaultNotificationView: FunctionComponent<{notification: Notification}> = ({
  notification
}) => {
  const {
    id,
    sender,
    created_at,
    seen,
    group,
  } = notification
  const notificationLink = constructLink(notification)
  const [markAsSeen] = useMarkNotificationAsSeenMutation()
  const markNotificationAsSeen = () => {
    if (seen) return
    markAsSeen({ id  })
  }

  return (
    <Link to={notificationLink} onClick={markNotificationAsSeen}>
      <ButtonBase component={NotificationItem} style={{background: !seen ? colors.veryLightGreen : 'transparent'}}>
        <Box display="flex" alignItems="center">
          <Avatar src={sender?.avatar} />
          <Text ml={1}><strong>{sender?.username}</strong> {NOTIFICATION_TEXT[group]}</Text>
        </Box>
        <Text variant='subtext'>{humanize(created_at, true)}</Text>
      </ButtonBase>
    </Link>
  )
}

export default DefaultNotificationView
