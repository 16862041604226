import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Text from '@mui/material/Typography';
import Progress from '@mui/material/CircularProgress'
import FormInput from '../FormInput';
import { isSame } from '../../utils/helpers';
import {
  RemainingFields,
  ShowMoreText,
  SimpleFormWrapper,
  FormWrapper,
  ActionButton,
  ActionButtonWrapper
} from './SimpleForm.styles';
import { useTransition, animated } from '@react-spring/web'
import { ButtonProps } from '../Button/Button';



export interface IActionButton extends ButtonProps {
  onClick?: () => void, 
  isSubmit?: boolean,
  label: string | React.ReactNode,
  loading?: boolean,
  invertOnHover?: boolean,
  disabled?: boolean,
  style?: React.CSSProperties
  variant?: "text" | "contained" | "outlined",
}

export interface IFormProps<T> extends React.HtmlHTMLAttributes<HTMLDivElement> {
  formFields: any[],
  actionButtons: IActionButton[] | IActionButton,
  formTitle?: string,
  initialValues?: Partial<T>,
  landscape?: boolean,
  submitForm: (payload: T, form?: any) => void,
  className?: string,
  style?: any,
  id?: string,
  fieldsToShow?: number,
  disabled?: boolean,
  formStyle?: any,
  buttonWrapperClass?: string,
  animateForm?: boolean
}

const SimpleForm = <T = any>({
  formTitle,
  formFields,
  actionButtons,
  initialValues = undefined,
  submitForm,
  fieldsToShow,
  landscape,
  disabled,
  formStyle={},
  buttonWrapperClass,
  animateForm,
  ...rest
}: IFormProps<T>) =>  {
  let submit: any;

  const [addressComponents] = useState<any>({})
  const [showAllFields, setShowAllFields] = useState(false)

  const transitions = useTransition(true, {
    from: { left: 200, opacity: 0 },
    enter: { left: 0, opacity: 1 },
  })

  const onSubmit = (values: any, form: any) => {
    const formValues = {...values}
    if (formValues.confirm_password) delete formValues.confirm_password
    submitForm({...formValues, ...addressComponents} as T, form)
  }

  const shouldRenderComponent = (dependencies: any = {}, values: any) => {
    const getDependencyTypeAndValue = (payload: any) => {
      const type = Object.keys(payload)[0]
      const value = payload[type]
      return [type, value]
    }

    return Object.keys(dependencies).every((dependency: string) => {
      const [dependencyType, value] = getDependencyTypeAndValue(dependencies[dependency])
      const formValue = values[dependency]

      switch (dependencyType) {
        case 'eq':
          return value === formValue
        case 'neq':
          return value !== formValue
        case 'lt':
          return value < formValue
        case 'lte':
          return value <= formValue
        case 'gt':
          return value > formValue
        case 'gte':
          return value >= formValue
        default:
          return true
      }
    })
  }

  const onInputKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode || e.charCode
    const key = e.code || e.key 

    if (keyCode === 13 || key === 'Enter') {
      submit(e)
    }
  }

  const renderComponent = (errors: any, touched: any, values: any, form?: any) => (fieldProps: any, index: number) => {
    const { name, dependencies=[] } = fieldProps;
    
    if (!shouldRenderComponent(dependencies, values)) return null

    if (fieldProps.validate) {
      const { name } = fieldProps;
      fieldProps.error = errors[name]
      fieldProps.touched = touched[name]
    }

    if (name === 'confirm_password') {
      fieldProps.validate = isSame(values?.password)
    }

    const { onPlaceSelected, isLocation, ...remainingFieldProps } = fieldProps

    if (isLocation) {
      return (
        <FormInput 
          key={index} 
          {...remainingFieldProps}
          onPlaceSelected={(place: any) => {
            fieldProps?.onPlaceSelected?.(place)
            if (place.formatted_address) form.change(fieldProps.name, place?.formatted_address)
          }}
          onKeyPress={onInputKeyPress} />
      )
    } else {
      return <FormInput key={index} {...fieldProps} onKeyPress={onInputKeyPress} />
    }
  }

  const renderForm = ({errors, touched, values, form}: any) => {
    return (
      <FormWrapper landscape={landscape} style={formStyle}>
        {!fieldsToShow ? formFields.map(renderComponent(errors, touched, values, form)) : (
          <>
            {formFields.slice(0, fieldsToShow).map(renderComponent(errors, touched, values, form))}
            <ShowMoreText role="switch" variant="subtitle1" onClick={() => setShowAllFields(!showAllFields)}>
              { !showAllFields ? 'Show optional fields' : 'Hide optional fields' }
            </ShowMoreText>
            <RemainingFields role="section" hidden={!showAllFields}>
              {formFields.slice(fieldsToShow).map(renderComponent(errors, touched, values))}
            </RemainingFields>
          </>
        )}
      </FormWrapper>
    )
  }

  const renderActionButtons = (submitButton: any) => ({
    onClick, 
    isSubmit,
    label,
    loading,
    invertOnHover,
    disabled,
    ...rest
  }: IActionButton, i: number=1) => {
    return (
      <ActionButton
        role="button"
        disabled={disabled}
        key={i}
        onClick={isSubmit ? submitButton : onClick}
        {...rest}
      >
        {loading ? <Progress /> : label}
      </ActionButton>
    )
  }

  const RenderAnimatedForm = (componentProps: any) => transitions(style => (
    <animated.div style={{position: 'relative', ...style}}>
      {renderForm(componentProps)}
    </animated.div>
  ))

  return (
    <SimpleFormWrapper disabled={disabled} {...rest}>
      {!!formTitle && <Text variant="body1" role="heading">{formTitle}</Text>}
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}>
        {({ handleSubmit, errors, touched, values, form }: any) => {
          submit = handleSubmit
          const formProps = {errors, touched, values, form}

          return (
            <>
              {animateForm ? <RenderAnimatedForm {...formProps} /> : renderForm(formProps)}
              {!disabled && (
                <ActionButtonWrapper
                  hasMultipleButtons={Array.isArray(actionButtons) && actionButtons.length}
                  className={`simple-form-action-buttons ${buttonWrapperClass || ''}`}
                >
                  {Array.isArray(actionButtons) ? actionButtons.map(renderActionButtons(handleSubmit)) : renderActionButtons(handleSubmit)(actionButtons)}
                </ActionButtonWrapper>
              )}
            </>
          )
        }}
      </Form>
    </SimpleFormWrapper>
  )
};


export default SimpleForm;