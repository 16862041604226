export const regexPatterns = {
  phone: /^(\+)*(\d+)(-)*/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  notUsername: /[@]?[^\w._|@]/
}

export const routes = {
  LANDING_PAGE: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  AUTH: '/auth',
  REQUEST_RESET_PASSWORD: '/request_reset_password',
  RESET_PASSWORD: '/reset_password',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  ACTIVATE: '/activate',
  AVAILABILITY: '/availability',
  ACCOUNT_SETTINGS: '/settings',
  ACCOUNT_PROFILE: '/settings',
  ACCOUNT_ACTIVITIES: '/settings/activities',
  ACCOUNT_BILLING: '/settings/billing',
  ACCOUNT_SUPPORT: '/settings/support',
  ACCOUNT_SECURITY: '/settings/security',
  PROFILE_PAGE: '/:username',
  PLAN_DATE: '/:username/plan',
  DATE_REQUEST: '/date_requests/:date_id',
  NEW_DATE_REQUEST: '/requests/:date_id',
  SCORECARD: '/requests/:date_id/scorecard',
  DATE_REQUESTS: '/requests',
  UPCOMING_DATES: '/requests',
  PENDING_REQUESTS: '/requests/pending',
  FRIENDS: '/friends',
  ALL_FRIENDS: '/friends',
  FRIEND_REQUESTS: '/friends/friend_requests',
  SHARE_PROFILE: '/share_profile',
  NOTIFICATIONS: '/notifications',
  MESSAGES: '/messages',
  CHAT_MESSAGE: '/messages/:chatId',
  PRIVACY_POLICY: '/privacy_policy',
  TERMS_OF_USE: '/terms_of_use',
  PLAN_ACTIVITY: '/plan_an_activity',
  EDIT_DATE_REQUEST: '/requests/:date_id/edit',
  OPEN_REQUEST: '/open_request/:slug'
}

export const activities = [
  "Restaurants",
  "Hiking",
  "Eatin out",
  "Bowling",
  "Ice skating",
  "Coffee date",
  "The park",
  "Pubs",
  "Going dancing",
  "Clubbing",
  "Strip clubs"
]

export const AWS_BASE_URL = process.env.REACT_APP_AWS_BASE_URL
export const AVATARS_FOLDER = 'avatars'
export const ACTIVITY_BACKDROPS = 'activity_backdrops'
export const DATE_IMAGES = 'date_images'
export const RECEIPTS_FOLDER = 'date_receipts'