import styled from "styled-components";
import { colors, spacing, device } from "../../../styles/_var";

export const MessagesWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    margin-top: ${spacing.sm};
  }
`

export const ChatMessageWrapper = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.md} {
    position: absolute;
    max-width: 65rem;
    box-shadow: 1px 1px 2px 1px lightgrey;
    left: 50%;
    transform: translateX(-50%);
  }
`
  
export const ChatMessageHeader = styled.div`
  border-bottom: solid 1px ${colors.lightgrey}; 
  padding-top: ${spacing.xs};
  padding-bottom: ${spacing.xs};
  padding-left: ${spacing.xs};
  padding-right: ${spacing.xs};
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
`

export const ChatMessageBody = styled.div`
  scroll-behavior: smooth;
  overflow-y: scroll;
  padding-bottom: 160px;
  /* flex: 1; */
`

export const ChatInput = styled.div`
  position: fixed;
  bottom: 0;
  height: 85px;
  box-shadow: 0 0px 4px 1px #999;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.white};
  display: flex;
  align-items: center;
  padding: ${spacing.xs};

  @media ${device.md} {
    position: absolute;
    width: 100%;
    box-shadow: none;
    border-top: solid 1px #ccc;
    bottom: 7rem;
  }
`