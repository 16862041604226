import React from 'react'
import { Link } from 'react-router-dom'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import content from '../content'
import Text from '../../../components/Text'
import { AuthContent } from '../AuthIndex/Auth.styles'
import { animateComponent } from '../../../utils/hoc'
import SimpleForm from '../../../components/SimpleForm'
import { routes } from '../../../utils/constants'
import TextWithIcon from '../../../components/TextWithIcon'
import { useRegisterForm } from './Register.hooks';



const Register = () => {
  const { submitForm, registerFields, actionButtons } = useRegisterForm()

  return (
    <AuthContent>
      <Link to={routes.AUTH}>
        <TextWithIcon icon={<LeftIcon />} variant="semi-large" mb={1}>
          {content.sign_up}
        </TextWithIcon>
      </Link>
      <Text mb={3} variant="subtext">{content.signup_instructions}</Text>
      <SimpleForm
        formFields={registerFields}
        submitForm={submitForm}
        actionButtons={actionButtons}
      />
      <Text center variant="subtext" mt={3}>
        {content.got_account} <strong><Link to={routes.LOGIN}>{content.sign_in}</Link></strong>
      </Text>
    </AuthContent>
  )
}

export default animateComponent(Register)
