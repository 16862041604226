import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import RequestCompleteIcon from '@mui/icons-material/EventAvailableRounded';
import { OpenDateRequest } from '../../../../utils/types'
import { useOpenDateRequests } from '../OpenDateRequests.hooks'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../utils/constants'
import Button from '../../../../components/Button/Button'
import OpenRequestDrawer from '../../Home/OpenRequestDrawer'
import Text from '../../../../components/Text'
import { colors } from '../../../../styles/_var'
import TextWithIcon from '../../../../components/TextWithIcon';


type OpenRequestActionButtonsProps = {
  openRequest: OpenDateRequest
} & BoxProps
const OpenRequestActionButtons = ({
  openRequest, 
  ...boxProps
}: OpenRequestActionButtonsProps) => {
  const [open, setOpen] = React.useState(false)
  const toggle = () => setOpen(!open)
  const { 
    removeRequest, 
    isDeleting, 
    closeOpenInterest,
    closing
  } = useOpenDateRequests({})
  const navigate = useNavigate()
  const backToRequests = () => navigate(routes.DATE_REQUESTS)
  const hasAcceptedInterests = openRequest.open_date_request_interests?.some(interest => interest.status === "accepted")

  return (
    <>
      <Box display="flex" alignItems="center" {...boxProps}>
        <Button 
          loading={isDeleting}
          variant="contained" 
          fullWidth 
          mr={1} 
          size="small"
          sx={{background: colors.lightDanger}}
          onClick={() => removeRequest(openRequest.slug, backToRequests)}
        >
          <Text color={colors.white}>Delete</Text>
        </Button>
        <Button variant="outlined" fullWidth onClick={toggle} size="small">
          <Text>Edit</Text>
        </Button>
        <OpenRequestDrawer 
          isOpen={open} 
          closeDrawer={toggle} 
          updateData={openRequest} 
        />
      </Box>
      <Box>
        <Button
          loading={closing}
          variant="contained" 
          fullWidth 
          size="small" 
          disabled={!hasAcceptedInterests}
          onClick={() => closeOpenInterest(openRequest.slug)}
        >
          <TextWithIcon 
            icon={<RequestCompleteIcon fontSize="large" />} 
            color={colors.white} 
            weight="semibold"
          >
            Mark as Completed
          </TextWithIcon>
        </Button>
        <Text center variant="small" mt={1} weight="semibold" lighten>
          Mark as completed when you have approved all the people interested and ready to create the activity/event.
        </Text>
      </Box>
    </>
  )
}

export default OpenRequestActionButtons
