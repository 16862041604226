import styled from 'styled-components'
import { colors, fontSize } from '../../styles/_var'

export const InputAndButtonWrapper = styled.div`
  background-color: ${colors.white};
  padding: 4px;
  border: solid 1px ${colors.grey};
  border-radius: 0.7rem;
  display: flex;

  & > div {
    flex: 1;
  }

  input {
    font-size: ${fontSize.rg};
  }
`
