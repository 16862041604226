import React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import EditIcon from '@mui/icons-material/CreateRounded';
import WinnerIcon from '@mui/icons-material/MilitaryTechRounded';
import DeleteIcon from '@mui/icons-material/ClearRounded';
import CircularProgress from '@mui/material/CircularProgress';
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Text from '../../../../../../components/Text'
import { UserInfo } from '../../../../../../components/UserInfo/UserInfo'
import { getUserByPID, participantToUser } from '../../../../../../utils/helpers'
import { useUiStore } from '../../../../../../stores/uiStore'
import AddRoundModal from '../AddRoundModal'
import { useDateMemoryScoreboard, useDateMemoryScoreboardEffect } from '../DateMemories.hooks'
import { DateRequest, User } from '../../../../../../utils/types'
import { colors } from '../../../../../../styles/_var';
import DateRequestClass from '../../../../../../services/DateRequestClass';
import IconButton from '@mui/material/IconButton';
import { ParticipantCell, ScoreRow } from '../style';
import { useNavigate } from 'react-router-dom';
import MoreOptionsButton from './MoreOptionsButton';
import ActionButtons from './ActionButtons';
import NavButtons from './NavButtons';
import FormTeamDrawer from './FormTeamDrawer';
import UsersAvatarGroup from '../../../../../../components/UsersAvatarGroup/UsersAvatarGroup';


type DateScoreboardProps = {
  dateInfo: DateRequest
}


const DateScoreboard = ({ dateInfo }: DateScoreboardProps ) => {
  const { 
    date_participants: participants = [],
    request_id
  } = dateInfo
  const { scoresFromServer, isFetchingScores } = useDateMemoryScoreboardEffect(request_id)
  const scoreboardData = scoresFromServer ? scoresFromServer?.map(s => s.memory_object) : undefined
  const { 
    addNewRound, 
    deleteRound,
    scores, 
    numberOfRounds, 
    isSaving, 
    currentUser,
    winner,
    getRoundScores,
    editRound,
    updateLabel,
    currentScoreboard,
    addNewScoreboard,
    hasNextBoard,
    hasPreviousBoard,
    gotoNextBoard,
    gotoPreviousBoard,
    scorecardLink,
    hasScores,
    isFormTeamOpen,
    openFormTeam,
    closeFormTeam,
    saveFormTeam,
    isTeam,
    teamMembers,
    teams
  } = useDateMemoryScoreboard(scoreboardData, dateInfo)
  const date = new DateRequestClass(dateInfo, currentUser)
  const { showDialog } = useUiStore()
  const navigate = useNavigate()

  const onAddOrEdit = (roundNumber?: number) => () => {
    const isEditing = roundNumber !== undefined
    showDialog({
      dialogBody: (
        <AddRoundModal
          isEditing={isEditing}
          scoreboardId={currentScoreboard?.id}
          isLoading={isSaving}
          onSubmit={isEditing ? editRound(roundNumber) : addNewRound}
          participants={participants}
          roundNumber={isEditing ? roundNumber + 1: numberOfRounds + 1}
          initialData={isEditing ? getRoundScores(roundNumber) : undefined} 
          isTeam={isTeam}
          teams={teams}
        />
      )
    })
  }

  if (isFetchingScores) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress style={{color: colors.primary}} />
      </Box>
    )
  }

  const renderActionButtons = () => {
    return (
      <Box px={1.5} display="flex" justifyContent="space-between">
        {date.iAmSender && (
          <ActionButtons 
            hasWinner={!!winner} 
            onClickAddIcon={onAddOrEdit()} 
            onClickDownload={() => navigate(scorecardLink)} 
          />
        )}
        <Box display="flex">
          <NavButtons 
            onClickBack={gotoPreviousBoard} 
            onClickForward={gotoNextBoard} 
            canGoBack={hasPreviousBoard} 
            canGoForward={hasNextBoard} 
          />
          <MoreOptionsButton 
            formTeams={openFormTeam}
            canCreateNewScoreboard={hasScores} 
            addNewScoreboard={addNewScoreboard}
            canFormTeams={!hasScores}
          />
        </Box>
      </Box>
    )
  }

  const renderHeaderRow = () => {
    return (
      <TableRow>
        <TableCell>
          <Text 
            weight="semibold"
            contentEditable 
            suppressContentEditableWarning
            onBlur={e => updateLabel(e.currentTarget.innerText)}
          >
            {currentScoreboard?.label || 'Game 1'}
          </Text>
        </TableCell>
        {new Array(numberOfRounds).fill(true).map((_, i) => (
          <TableCell key={i}>
            <Box 
              flexDirection="column" 
              display="flex" 
              alignItems="center" 
              justifyContent="center"
            >
              <Text weight="bold">Rd {i + 1}</Text>
              {date.iAmSender && (
                <Box ml={1}>
                  <IconButton
                    size="small" 
                    color="success"
                    onClick={onAddOrEdit(i)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    size="small"
                    color="error"
                    onClick={() => deleteRound(i)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderSoloTableBody = () => participants.map(p => (
    <ScoreRow key={p.id}>
      <ParticipantCell style={{alignItems: "center"}}>
        <UserInfo 
          onlyShowName
          onlyFirstName
          useSmallAvatar
          titleTextVariant="regular"
          user={participantToUser(p)} 
          style={{filter: winner !== p.id ? 'grayscale(100%)' : undefined}}
        />
        {winner === p.id && (
          <WinnerIcon
            sx={{color: colors.lightPrimary}}
            fontSize="large"  />
        )}
      </ParticipantCell>
      {scores[p.id]?.map((score, i) => (
        <TableCell key={i}>
          <Text center>{score}</Text>
        </TableCell>
      ))}
    </ScoreRow>
  ))

  const renderTeamTableBody = () => Object.keys(teamMembers).map(teamName => {
    const userTeamMembers = teamMembers[teamName]
      .map(pid => getUserByPID(pid, dateInfo.date_participants, false))
      .filter(Boolean) as User[]
    return (
      <ScoreRow key={teamName}>
        <ParticipantCell>
          <Box 
            display="flex" 
            flexDirection="column"
            style={{filter: winner !== teamName ? 'grayscale(100%)' : undefined}}
          >
            <UsersAvatarGroup users={userTeamMembers} max={4} />
            <Text weight="semibold">{teamName}</Text>
          </Box>
          {winner === teamName && (
            <WinnerIcon
              sx={{color: colors.lightPrimary}}
              fontSize="large"  />
          )}
        </ParticipantCell>
        {scores[teamName]?.map((score, i) => (
          <TableCell key={i}>
            <Text center>{score}</Text>
          </TableCell>
        ))}
      </ScoreRow>
    )
  })

  const renderTableBody = () => {
    return isTeam ? renderTeamTableBody() : renderSoloTableBody()
  }

  const renderScoreboardTable = () => {
    return (
      <Table>
        <TableHead>
          {renderHeaderRow()}
        </TableHead>
        <TableBody>
          {renderTableBody()}
        </TableBody>
      </Table>
    )
  }

  return (
    <Box p={2}>
      {renderActionButtons()}
      {renderScoreboardTable()}
      <FormTeamDrawer 
        dateRequest={dateInfo} 
        isOpen={isFormTeamOpen} 
        onClose={closeFormTeam} 
        onComplete={saveFormTeam}
      />
    </Box>
  )
}

export default DateScoreboard
