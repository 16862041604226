import { ButtonBase } from '@mui/material'
import styled from 'styled-components'
import Button from '../../../../components/Button/Button'
import TextWithIcon from '../../../../components/TextWithIcon'
import { colors, spacing } from '../../../../styles/_var'

export const AddActivityLink = styled(TextWithIcon)`
  transition: transform ease .2s;
  &:hover {
    transform: scale(1.01);
  }
`

export const AddButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-root {
    color: #888;
    position: absolute;
    right: 0;
    background-color: ${colors.white};
  }
`

export const RemoveButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    padding: 6px;
    background: ${colors.danger};
    border-radius: 50%;
    margin-right: ${spacing.xxs};
    color: ${colors.white};
  }
`

export const EditButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    padding: 6px;
    background: ${colors.lightPrimary};
    border-radius: 50%;
    margin-right: ${spacing.xxs};
    color: ${colors.white};
  }
`