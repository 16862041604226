import React from "react"
import Dialog from '@mui/material/Dialog';
import { Transition } from "../Dialog";
import { useUiStore } from "../../stores/uiStore";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Button/Button";
import Text from "../Text";

import Box from "@mui/material/Box";
import { colors } from "../../styles/_var";

const ConfirmationDialog = () => {
  const { cancelConfirmDialog, confirmationDialogPayload, showConfirmation } = useUiStore()
  const {
    dialogBody,
    dialogTitle='Please Confirm?',
    onCancelAction,
    onConfirmAction,
    cancelButtonText,
    confirmButtonText
  } = confirmationDialogPayload

  const handleClose = () => {
    if (onCancelAction) onCancelAction()
    cancelConfirmDialog()
  }

  const handleProceed = () => {
    if (onConfirmAction) onConfirmAction()
    cancelConfirmDialog()
  }

  return (
    <Dialog
      open={showConfirmation}
      TransitionComponent={Transition}
      onClose={() => null}
    >
      <Box px={3} pt={3} pb={1}>
        {!!dialogTitle && <Text variant="medium" mb={2} weight='bold'>{dialogTitle}</Text>}
        {!!dialogBody && <Text variant="regular">{dialogBody}</Text>}
      </Box>
      <DialogActions>
        <Box pb={1} px={1}>
          <Button onClick={handleClose} variant="outlined" mr={1} size="small">
            <Text>{cancelButtonText || 'Cancel'}</Text>
          </Button>
          <Button onClick={handleProceed} variant="contained" size="small">
            <Text color={colors.white}>{confirmButtonText || 'Proceed'}</Text>
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
  