import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    border-radius: 5px !important;
    width: 100%
  }
`
