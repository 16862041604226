import styled from "styled-components";
import Box from '@mui/material/Box'
import Text from "../../../components/Text";
import { colors } from "../../../styles/_var";


export const RequestDrawerWrapper = styled(Box)`
  overflow: scroll;

  & .MuiFormHelperText-root {
    margin-left: 0;
  }

  transition: height 0.3s ease-in-out;
`

export const SummaryWrapper = styled(Box)`
  background-color: ${colors.lightGreen};
  border: solid 1px ${colors.green};
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 2.3rem;
  box-shadow: 1px 1px 3px 1px lightgray;
`

export const OpenDateRequestWrapper = styled(Box)<{$closed?: boolean}>`
  filter: ${({ $closed }) => $closed && 'grayscale(100%)'};
  pointer-events: ${({ $closed }) => $closed && 'none'};
`

export const CloseLabel = styled(Text)`
  position: absolute;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  top: 50%;
  padding: 1rem 0;
  border-top: solid 3px ${colors.lightDanger};
  border-bottom: solid 3px ${colors.lightDanger};
`