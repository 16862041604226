import styled from 'styled-components'
import { colors, spacing, device } from '../../../../styles/_var'

export const Bubble = styled(({isMe, ...rest}) => <div {...rest} />)`
  padding: ${spacing.xs};
  margin-bottom: ${spacing.xs};
  border-radius: 10px;
  width: 70%;
  min-width: 140px;
  align-self: flex-end;

  ${({ isMe }) =>`
    background: ${isMe ? colors.veryLightGreen : colors.pink };
    align-self: ${isMe ? 'flex-start' : 'flex-end' };
  `}
`

export const SupportWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    padding-bottom: ${spacing.xs};
  }
`
