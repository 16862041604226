import React, { FunctionComponent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '../../../../components/Button/Button'
import Input from '../../../../components/Input'
import Text from '../../../../components/Text'
import { useFetchSupportConversationQuery, usePostSupportConversationMutation } from '../../../../services/api'
import Divider from '@mui/material/Divider'
import { useProcessError } from '../../../../hooks/hooks'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SupportConversation, SupportMessage } from '../../../../utils/types'
import { Bubble } from './Support.styles'
import { humanize } from '../../../../utils/helpers'

type ConversationListProps = {
  issue: SupportMessage
}

type ConversationBubbleProps = {
  conversation: SupportConversation,
  currentUserId: number
}

const ConversationBubble: FunctionComponent<ConversationBubbleProps> = React.memo(({
  conversation,
  currentUserId
}) => {
  return (
    <>
      <Bubble isMe={currentUserId === conversation.sender_id}>
        <Text>{conversation.message}</Text>
        <Text variant='small' right>
          {humanize(conversation.created_at)}
        </Text>
      </Bubble>
    </>
  )
})


const ConversationList: FunctionComponent<ConversationListProps> = React.memo(({
  issue
}) => {
  const [message, setMessage] = useState('')
  const [conversations, setConversations] = useState<SupportConversation[]>([])
  const processError = useProcessError()
  const { data, isLoading } = useFetchSupportConversationQuery({ id: issue.id }, { refetchOnMountOrArgChange: true })
  const [sendMessage, { isLoading: sending }] = usePostSupportConversationMutation()

  useEffect(() => {
    if (data?.payload) {
      setConversations(data.payload)
    }
  }, [data])

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      postMessage()
    }
  }

  const postMessage = async () => {
    try {
      const response = await sendMessage({ message, id: issue.id }).unwrap()
      setConversations([...conversations, response.payload])
      setMessage('')
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  if (isLoading) return <Text center weight="semibold">Fetching conversations...</Text>
  if (!conversations?.length) return <Text center weight="semibold">Give us a moment, we'll reach out shortly!</Text>

  return (
    <Box display="flex" flexDirection="column">
      <Input
        disabled={sending}
        placeholder="Enter your message"
        onKeyDown={onKeyPress}
        value={message} 
        onChange={e => setMessage(e.target.value)}
        multiline />
      <Button mb={3} variant="contained" mt={1} loading={sending}>
        Send Message
      </Button>
      <Divider />
      <Text weight="bold" mt={3} mb={2}>
        Messages ({conversations?.length})
      </Text>
      {conversations?.map((m, i) => <ConversationBubble conversation={m} key={i} currentUserId={issue.user_id} /> )}
    </Box>
  )
})

export default ConversationList
