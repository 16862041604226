import React, { useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardNavbar from '../../../components/DashboardNavbar/DashboarNavbar'
import DashboardSidebar from '../../../components/DashboardSidebar/DashboardSidebar'
import { DashboardContent } from './DashboardLayout.styles'
import DashboardHeader from '../../../components/DashboardHeader/DashboardHeader'
import { useAuthStore } from '../../../stores/authStore'
import { useIsMobile } from '../../../hooks/hooks'
import { useFetchDashboardData } from './DashboardLayout.hooks'
import * as gen from '../../../utils/gen'
import { Hub } from '@aws-amplify/core'
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import websocketHandler from '../../../utils/websocketHandler'
import { User } from '../../../utils/types'
import chatConfig from '../../../utils/chatConfig'
import { useFetchUpcomingDatesQuery, useUpdateLastOnlineMutation } from '../../../services/api'


chatConfig.configure()

type DLProps = {
  isMobile: boolean,
  router: any,
  currentUser: User,
  channelsToConnect: string[],
  dashboardLoaded: boolean
}

class DashboardLayout extends React.Component<DLProps, {}> {
  private hubListener: undefined | (() => void) = undefined
  private timerObject: NodeJS.Timeout | undefined = undefined

  componentDidMount() {
    if (chatConfig.ENABLE) this.initializeWebsocketSubscription()
    this.monitorWebsocketSubscription()
  }

  componentWillUnmount(): void {
    clearTimeout(this.timerObject)
    this.stopMonitoringWebsocketSubscription()
  }

  unregisterSubscriptions() {
    Object.values(chatConfig.subscriptionObjects).forEach(subscription => {
      subscription.unsubscribe()
    })
    chatConfig.subscriptionObjects = {}
  }

  connectToWebsocket() {
    const { connectionState, RETRY_STATES } = chatConfig
    if (connectionState && !RETRY_STATES.includes(connectionState)) return

    console.log('connection to websocket has begun')

    for (let channel of this.props.channelsToConnect) {
      if (Object.keys(chatConfig.subscriptionObjects).includes(channel)) {
        continue
      }

      const subscriptionObject = gen.subscribe(channel, payload => {
        websocketHandler(payload)
        chatConfig.subscriptionObjects[channel] = subscriptionObject
      }, (_: any) => {
        this.handleWebsocketSubscriptionError()
      })
    }
  }

  initializeWebsocketSubscription() {
    this.timerObject = setTimeout(() => {
      this.connectToWebsocket()
    }, 5000)
  }

  handleWebsocketSubscriptionError() {
    chatConfig.retryTime = chatConfig.retryTime * 5

    setTimeout(() => {
      console.log(`reconnecting in ${chatConfig.retryTime} seconds`)
      this.connectToWebsocket()
    }, chatConfig.retryTime)
  }

  monitorWebsocketSubscription() {
    if (this.hubListener) return

    this.hubListener = Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        chatConfig.connectionState = payload.data.connectionState as ConnectionState;

        if (chatConfig.connectionState === 'Connected') {
          chatConfig.retryTime = chatConfig.DEFAULT_RETRY_TIME
        } else if (chatConfig.RETRY_STATES.includes(chatConfig.connectionState)) {
          this.unregisterSubscriptions()
          this.handleWebsocketSubscriptionError()
        }
        console.log(chatConfig.connectionState)
      }
    });
  }

  stopMonitoringWebsocketSubscription() {
    if (this.hubListener) this.hubListener()
  }

  render() {
    return (
      <div>
        {!this.props.isMobile && <DashboardHeader />}
        {!this.props.isMobile && <DashboardSidebar />}
        <DashboardContent isMobile={this.props.isMobile}>
          {this.props.dashboardLoaded && <Outlet />}
        </DashboardContent>
        {this.props.isMobile && <DashboardNavbar />}
      </div>
    )
  }
}


const checkUserInfoExists = (Component: any) => {
  function ComponentWrapper(props: any) {
    const {currentUserLoaded} = useAuthStore()
    const { prepareDashboard, dashboardLoaded } = useFetchDashboardData()
    const [updateLastOnline, ] = useUpdateLastOnlineMutation()
    const lastOnlineInterval = useRef<NodeJS.Timeout | undefined>(undefined)

    useEffect(() => {
      prepareDashboard()
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
      if (lastOnlineInterval.current || !currentUserLoaded) return
      lastOnlineInterval.current = setInterval(() => updateLastOnline({}), 1000 * 60 * 5)
      const cleanUp = lastOnlineInterval.current
      return () => clearInterval(cleanUp)
    }, [currentUserLoaded, updateLastOnline])
  
    if (!currentUserLoaded) return null

    return (
      <Component {...props} dashboardLoaded={dashboardLoaded} />
    );
  }

  return ComponentWrapper;
}


const prepareDashboardData = (Component: any) =>  {
  function ComponentWithRouterProp(props: any) {
    const isMobile = useIsMobile()
    const {currentUser} = useAuthStore()
    const { data } = useFetchUpcomingDatesQuery({})

    return (
      <Component 
        {...props} 
        currentUser={currentUser}
        isMobile={isMobile}
        channelsToConnect={data?.payload.map(d => d.request_id) || []} 
      />
    );
  }

  return ComponentWithRouterProp;
}


export default checkUserInfoExists(
  prepareDashboardData(DashboardLayout)
)
