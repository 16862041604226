import React from 'react'
import Drawer from '../../../components/Drawer/Drawer'
import AddOrInviteFriend from './AddOrInviteFriend'

type AddOrInviteFriendDrawerProps = {
  isOpen: boolean,
  closeDrawer?: () => void
}

const AddOrInviteFriendDrawer = ({isOpen, closeDrawer}: AddOrInviteFriendDrawerProps) => {
  return (
    <Drawer
      disableEnforceFocus
      showCloseIcon
      anchor='bottom'
      open={isOpen}
      onOpen={() => {}}
      onClose={closeDrawer}
      header='Add or Invite Friend'
    >
      <AddOrInviteFriend onComplete={closeDrawer} />
    </Drawer>
  )
}

export default AddOrInviteFriendDrawer
