import gusiberiApi, { DateRequestsRequest, Participant, useAcceptDateRequestMutation, useAddParticipantMutation, useAddSuppliesSuggestionMutation, useDeclineDateRequestMutation, useRemoveParticipantMutation, useRemoveSuppliesSuggestionMutation, useResendParticipantInviteMutation, useScheduleAnotherMutation, useSendDateRequestMutation, useSendParticipantRequestMutation, useUpdateParticipantRequestMutation, useUpdateSentDateRequestMutation } from "../../../../services/api"
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { DateTime } from 'luxon'
import { useUiStore } from '../../../../stores/uiStore'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useHideFooterOnMount, useProcessError, useSetHeaderTitle } from '../../../../hooks/hooks'
import { IActionButton } from '../../../../components/SimpleForm'
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'
import AddressInput from '../../../../components/Input/AddressInput'
import validators from '../../../../utils/validators'
import Input from '../../../../components/Input'
import { useMemo, useState } from "react";
import { DateRequest, ParticipantRequest, SupplySuggestion } from "../../../../utils/types";
import { useNavigate } from "react-router-dom";
import { ACTIVITY_BACKDROPS, routes } from "../../../../utils/constants";
import { generateRandomNumber, uploadToS3 } from "../../../../utils/helpers";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import TextWithIcon from "../../../../components/TextWithIcon";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import { useDispatch } from "react-redux";

export interface CreateActivityFormValues {
  activity_id?: number
  activity: string
  description: string
  location: string
  start_date: DateTime,
  end_date: DateTime,
  buck_breaker_enabled: boolean,
  scoreboard_enabled: boolean
}

type IUsePlanActivity = {
  editMode?: boolean,
  dateInfo?: DateRequest
}

export const usePlanActivity = ({editMode = false, dateInfo}: IUsePlanActivity) => {
  const dispatch = useDispatch()
  const [tempSuggestions, setTempSuggestions] = useState<SupplySuggestion[]>([])
  const [openScheduleAgain, setOpenScheduleAgain] = useState(false)
  const [backdropImage, setBackdropImage] = useState('')
  const [savingParticipant, setSavingParticipant] = useState(false)
  const [updatingInvitationStatus, setUpdatingInvitationStatus] = useState<number[]>([])
  const [currentStep, setCurrentStep] = useState<'step1' | 'step2' | 'step3'>('step1')
  const [activityDetails, setActivityDetails] = useState<CreateActivityFormValues | undefined>(undefined)
  const [createdActivity, setCreatedActivity] = useState<DateRequest | undefined>(undefined)
  const [participants, setParticipants] = useState<Participant[]>([])
  const [postRequest, {isLoading}] = useSendDateRequestMutation()
  const [resendInvite, {isLoading: resendingInvite}] = useResendParticipantInviteMutation()
  const [updateDateRequest, {isLoading: updatingRequest}] = useUpdateSentDateRequestMutation()
  const [addParticipantRequest, {isLoading: addingParticipant}] = useAddParticipantMutation()
  const [deleteParticipantRequest, {isLoading: deletingParticipant}] = useRemoveParticipantMutation()
  const [updateParticipantRequest, {isLoading: updatingParticipantRequest}] = useUpdateParticipantRequestMutation()
  const [saveSupplySuggestion, {isLoading: savingSupplySuggestion}] = useAddSuppliesSuggestionMutation()
  const [deleteSupplySuggestions, {isLoading: deletingSupplySuggestions}] = useRemoveSuppliesSuggestionMutation()
  const [acceptInvitationRequest, ] = useAcceptDateRequestMutation()
  const [declineInvitationRequest, ] = useDeclineDateRequestMutation()
  const [scheduleAnother, ] = useScheduleAnotherMutation()
  const [sendParticipantRequest, {isLoading: sendingParticipantRequest}] = useSendParticipantRequestMutation()
  const { openSuccessToast, cancelDialog, showConfirmDialog, openLoadingScreen, closeLoadingScreen } = useUiStore()
  const processError = useProcessError()
  const navigate = useNavigate()

  const dParticipants = useMemo(() => dateInfo?.date_participants || [], [dateInfo?.date_participants])

  const formFields = [
    {
      name: 'activity',
      placeholder: 'Enter title ex: "Dinner with the girls"',
      component: Input,
      validate: validators.required
    },
    {
      name: 'description',
      multiline: true,
      rows: 2,
      placeholder: 'Add a brief description of this activity',
      component: Input,
    },
    {
      name: 'location',
      placeholder: 'Enter Location',
      component: AddressInput,
      validate: validators.required,
      isLocation: true,
    },
    {
      name: 'start_date',
      label: 'Start Time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%'},
      validate: validators.required
    },
    {
      name: 'end_date',
      label: 'End Time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%'},
      validate: validators.required
    },
    {
      name: 'buck_breaker_enabled',
      placeholder: (
        <TextWithIcon 
          useDiv
          icon={<HelpOutlineOutlined style={{marginLeft: 3}} />} 
          after 
          tooltip="With Buck breaker you can easily split expenses accrued during an activity or date. i.e Upload an expense along with the parties responsible and they get notified on how much they need to reimburse you."
        >
          Enable Buck Breaker
        </TextWithIcon>
      ),
      type: 'checkbox',
      id: 'switch-buck-breaker',
      component: ToggleSwitch,
      size: "medium"
    },
    {
      name: 'scoreboard_enabled',
      placeholder: (
        <TextWithIcon 
          useDiv
          icon={<HelpOutlineOutlined style={{marginLeft: 3}} />} 
          after 
          tooltip="Enables the scoreboard feature that helps you keep scores. This is ideal for sports or games related activities"
        >
          Enable Scoreboard
        </TextWithIcon>
      ),
      id: 'switch-scoreboard',
      type: 'checkbox',
      component: ToggleSwitch,
      size: "medium"
    }
  ]

  const initialValues = {
    start_date: DateTime.now().plus({hours: 1}),
    end_date: DateTime.now().plus({hours: 2}),
  } // so that date time pickers don't have errors onload

  const actionButton: IActionButton = {
    label: editMode ? 'Update Activity' : 'Create Activity',
    isSubmit: true,
    loading: isLoading || updatingRequest,
    variant: 'contained',
    fullWidth: true,
    endIcon: <KeyboardArrowRightRounded />,
    style: {display: 'flex', justifyContent: 'space-between'}
  }

  const mkActivityRequestPayload = (payload: CreateActivityFormValues, backdropFilename?: string): Omit<DateRequestsRequest, 'request_id'> => {
    const startTime = payload.start_date.toISO()
    const endTime = payload.end_date.toISO()
  
    return {
      ...payload,
      end_date: endTime,
      start_date: startTime,
      backdrop_image: backdropFilename && `${process.env.REACT_APP_AWS_BASE_URL}/${backdropFilename}`
    }
  }

  const createActivityRequest = async (payload: CreateActivityFormValues) => {
    const backdropFilename = !!backdropImage ?`${ACTIVITY_BACKDROPS}/backdrop-image${generateRandomNumber(9)}.jpg` : undefined
    const requestPayload = mkActivityRequestPayload(payload, backdropFilename)

    try {
      if (backdropFilename) {
        await uploadToS3({ 
          filename: backdropFilename, 
          fileUrl: backdropImage, 
          failSilently: true 
        })
      }

      const newActivity = await postRequest(requestPayload).unwrap() 
      setCreatedActivity(newActivity.payload)

      openSuccessToast({message: 'Activity created.'})
      setCurrentStep('step2')
      setActivityDetails(payload)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const updateActivityRequest = async (payload: CreateActivityFormValues, currentActivity: DateRequest) => {
    const backdropFilename = !!backdropImage ?`${ACTIVITY_BACKDROPS}/backdrop-image${generateRandomNumber(9)}.jpg` : undefined
    const requestPayload = {request_id: currentActivity.request_id, ...mkActivityRequestPayload(payload, backdropFilename)}
    if (!backdropImage) requestPayload.backdrop_image = currentActivity.backdrop_image

    try {
      if (backdropFilename) {
        await uploadToS3({ 
          filename: backdropFilename, 
          fileUrl: backdropImage, 
          failSilently: true 
        })
      }

      const newActivity = await updateDateRequest(requestPayload).unwrap() 
      openSuccessToast({message: newActivity.message})
      setTimeout(() => {
        navigate(`${routes.DATE_REQUEST.replace(':date_id', currentActivity.request_id)}`)
      }, 1000)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const saveParticipants = async (participants: Array<{name: string, contact: string}>, redirectToActivityDashboard: boolean = true) => {
    if (!createdActivity) return
    setSavingParticipant(true)

    try {
      const requestPayload = {
        request_id: createdActivity.request_id, 
        participants
      }
      const response = await  addParticipantRequest(requestPayload).unwrap()
      
      if (redirectToActivityDashboard) {
        navigate(routes.DATE_REQUEST.replace(":date_id", createdActivity.request_id))
      } else {
        setCurrentStep('step3')
      }

      setSavingParticipant(false)
      return response
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }

    setSavingParticipant(false)
  }

  const deleteParticipant = async (participantId: number) => {
    if (!createdActivity) return
    try {
      const requestPayload = {
        request_id: createdActivity.request_id,
        participant_id: participantId
      }

      const response = await deleteParticipantRequest(requestPayload).unwrap()
      removeParticipant(participantId)
      openSuccessToast({message: response.message})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const storeParticipant = (newParticipants: Participant[]) => {
    if (!dateInfo?.request_id) {
      return setParticipants([...participants, ...newParticipants])
    }

    dispatch(
      gusiberiApi.util.upsertQueryData(
        "fetchDateRequest",
        {request_id: dateInfo?.request_id},
        {payload: {
          ...dateInfo,
          date_participants: [...dParticipants, ...newParticipants]
        }}
      ) as any
    )
  }

  const storeParticipantRequests = (requests: ParticipantRequest[]) => {
    if (!dateInfo?.request_id) return 

    dispatch(
      gusiberiApi.util.upsertQueryData(
        "fetchDateRequest",
        {request_id: dateInfo?.request_id},
        {payload: {
          ...dateInfo,
          date_participant_requests: [...(dateInfo.date_participant_requests || []), ...requests]
        }}
      ) as any
    )
  }

  const removeParticipant = (participantId: number) => {
    if (!dateInfo?.request_id) {
      return setParticipants(participants.filter(p => p.id !== participantId))
    }

    dispatch(
      gusiberiApi.util.upsertQueryData(
        "fetchDateRequest",
        {request_id: dateInfo.request_id},
        {payload: {
          ...dateInfo,
          date_participants: dParticipants.filter(p => p.id !== participantId)
        }}
      ) as any
    )
  }

  const updateParticipant = (participantId: number, uploadData: Partial<Participant>) => {
    const newParticipants = (!!dateInfo?.request_id ? dParticipants : participants).map(p => {
      if (p.id === participantId) {
        return {...p, ...uploadData} as Participant
      } 
  
      return p
    })

    if (!dateInfo?.request_id) {
      return setParticipants(newParticipants)
    }

    if (dateInfo?.request_id) {
      dispatch(
        gusiberiApi.util.upsertQueryData(
          "fetchDateRequest",
          {request_id: dateInfo.request_id},
          {payload: {
            ...dateInfo,
            date_participants: newParticipants
          }}
        ) as any
      )
    }
  }

  const processInvitation = async (participantId: number, action: 'accepted' | 'declined', message: string = '', updateStore: boolean = true) => {
    if (!createdActivity) return

    setUpdatingInvitationStatus(statuses => [...statuses, participantId])
    const requestCall = action === "accepted" ? acceptInvitationRequest : declineInvitationRequest
    cancelDialog()
    
    try {
      const response = await requestCall({id: createdActivity?.request_id, message}).unwrap()
      openSuccessToast({message: response.message})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }

    if (updateStore) updateParticipant(participantId, {response: action})
    
    setUpdatingInvitationStatus(statuses => statuses.filter(id => id !== participantId))
  }

  const acceptInvitation = async (participantId: number, message: string = '',  updateStore: boolean = true) => {
    processInvitation(participantId, 'accepted', message, updateStore)
  }

  const declineInvitation = async (participantId: number, message: string = '', updateStore: boolean = true) => {
    processInvitation(participantId, 'declined', message, updateStore)
  }

  const scheduleActivityAgain = (newDate: {start_date: DateTime, end_date: DateTime}) => {
    if (!dateInfo?.request_id) return

    showConfirmDialog({
      dialogBody: "Are you sure you would like to schedule this activity again?",
      onConfirmAction: async () => {
        openLoadingScreen("Scheduling this activity again...")
        try {
          await scheduleAnother({
            request_id: dateInfo?.request_id, 
            start_date: newDate.start_date.toISO(),
            end_date: newDate.end_date.toISO()
          }).unwrap()
          openSuccessToast({message: 'Activity has been scheduled again'})
          navigate(routes.DASHBOARD)
          setOpenScheduleAgain(false)
        } catch (e) { 
          processError(e as FetchBaseQueryError)
        }
        closeLoadingScreen()
      }
    })
  }

  const postParticipant = async (data: Array<{name: string, contact: string}>) => {
    if (!dateInfo?.request_id) return

    try {
      const response = await saveParticipants(data, false)
      if (response &&!participants.some(p => p.id === response.payload[0].id)) {
        setParticipants(participants => [...participants, ...response.payload])
      }
      openSuccessToast({message: 'New participant added'})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
    cancelDialog()
  }

  const postParticipantRequest = async (data: Array<{name: string, contact: string}>) => {
    if (!dateInfo?.request_id) return

    try {
      const response = await sendParticipantRequest({ 
        request_id: dateInfo.request_id, 
        requests: data 
      }).unwrap()

      if (response && !dateInfo.date_participant_requests?.some(p => p.id === response.payload[0].id)) {
        storeParticipantRequests(response.payload)
      }
  
      openSuccessToast({message: 'Request Sent, waiting for approval!'})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
    cancelDialog()
  }

  const finishActivityCreation = () => {
    if (!createdActivity) return
    navigate(routes.DATE_REQUEST.replace(":date_id", createdActivity.request_id))
  }

  const addSupplySuggestion = async (suggestion: SupplySuggestion) => {
    if (!createdActivity) return
    const tempId = generateRandomNumber()
    setTempSuggestions([...tempSuggestions, {...suggestion, id: tempId}])

    const response = await saveSupplySuggestion({request_id: createdActivity.request_id, payload: suggestion}).unwrap()
    setTempSuggestions(tempSuggestions.filter(s => s.id !== tempId).concat(response.payload))
  }

  const removeSupplySuggestion = async (supplyId: number) => {
    if (!createdActivity) return
    setTempSuggestions(tempSuggestions.filter(s => s.id !== supplyId))
    deleteSupplySuggestions({request_id: createdActivity.request_id, id: supplyId})
  }

  const partitionedParticipants = useMemo(() => {
    const participantsToUse = !!dateInfo ? dParticipants : participants
    const creator = dateInfo && participantsToUse.find(p => p.user?.username === dateInfo?.created_by?.username)
    const pendingInvites = participantsToUse.filter(p => p.response === "pending" && p.id !== creator?.id)
    const acceptedInvites = participantsToUse.filter(p => p.response === "accepted" && p.id !== creator?.id)
    const declinedInvites = participantsToUse.filter(p => p.response === "declined" && p.id !== creator?.id)

    return { creator, pendingInvites, acceptedInvites, declinedInvites}
  }, [dateInfo, participants, dParticipants])

  return {
    formFields,
    initialValues,
    actionButton,
    createActivityRequest,
    postRequest,
    isLoading,
    currentStep,
    activityDetails,
    openSuccessToast,
    saveParticipants,
    addingParticipant,
    removeParticipant,
    deletingParticipant,
    storeParticipant,
    deleteParticipant,
    participants: !!dateInfo ? dParticipants : participants,
    setBackdropImage,
    updateActivityRequest,
    updatingRequest,
    setParticipants,
    setCreatedActivity,
    acceptInvitation,
    declineInvitation,
    updatingInvitationStatus,
    processInvitation,
    savingParticipant,
    resendInvite,
    resendingInvite,
    partitionedParticipants,
    scheduleActivityAgain,
    sendParticipantRequest,
    sendingParticipantRequest,
    storeParticipantRequests,
    postParticipantRequest,
    postParticipant,
    updateParticipantRequest,
    updatingParticipantRequest,
    openScheduleAgain,
    setOpenScheduleAgain,
    suppliesSuggestions: tempSuggestions,
    finishActivityCreation,
    savingSupplySuggestion,
    deletingSupplySuggestions,
    addSupplySuggestion,
    removeSupplySuggestion
  }
}

export const usePlanActivityEffects = () => {
  useSetHeaderTitle('Plan an activity', true)
  useHideFooterOnMount()
}