import React, { useState } from 'react'
import Box from '@mui/material/Box'
import PlusIcon from '@mui/icons-material/AddRounded';
import FrownIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import UpIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import DownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Input from '../../../components/Input'
import Text from '../../../components/Text';
import TextWithIcon from '../../../components/TextWithIcon';
import { SearchInput } from './Friends.styles';
import { useFriendCategories, useFriends } from './Friends.hooks';
import { useFetchFriendGroupsRequestQuery, useGetFriendsRequestQuery } from '../../../services/api';
import FriendGroupList from './components/FriendGroupList';
import Divider from '@mui/material/Divider';
import { Link, useOutletContext } from 'react-router-dom';
import { colors } from '../../../styles/_var';
import { FriendCategory } from '../../../utils/types';
import { UserInfo } from '../../../components/UserInfo/UserInfo';
import Button from '../../../components/Button/Button';

const InviteFriend = ({ openDrawer }: { openDrawer: () => void }) => {
  return (
    <Button 
      variant="outlined" 
      size="small" 
      style={{paddingTop: 3, paddingBottom: 3, borderColor: colors.tertiary}} 
      onClick={openDrawer}
    >
      <TextWithIcon 
        color={colors.tertiary} 
        variant="small" 
        weight="semibold" 
        icon={<PlusIcon fontSize="small" style={{color: colors.tertiary}} />}
      >
        Add/Invite Friends
      </TextWithIcon>
    </Button>
  )
}

const AllFriends = () => {
  const { openDrawer } = useOutletContext<{openDrawer: () => void}>()
  const { searchQuery, setSearchQuery } = useFriends()
  const [anchorEl, setAnchorEl] = useState<HTMLParagraphElement | null>(null);
  const [selectedFriendGroupId, setSelectedFriendGroupId] = useState(0)
  const { openNewFriendGroupModal } = useFriendCategories()
  const { data: friendGroups, isLoading } = useFetchFriendGroupsRequestQuery({})
  const { data } = useGetFriendsRequestQuery({})

  const friends = data?.payload.filter(f => selectedFriendGroupId === 0 || selectedFriendGroupId === f.category_id) || []
  const defaultGroup = { 
    id: 0, 
    name: 'All Friends', 
    friends_count: data?.payload.length || 0,
    friendships: data?.payload || []
  } as FriendCategory

  const allGroups = [defaultGroup, ...(friendGroups?.payload || [])]

  const query = searchQuery.trim().toLowerCase()
  const friendsToDisplay = friends
    .filter(friend => friend.friend_user.username.includes(query) ||
      friend.friend_user.full_name.toLowerCase().includes(query)
    )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const selectFriendGroup = (id: number) => () => {
    setSelectedFriendGroupId(id)
    handleClose()
  }

  const selectedFriendGroup = allGroups.find(e => e.id === selectedFriendGroupId)
  const menuIsOpen = Boolean(anchorEl);

  const friendSearchField = (
    <>
      <Popover
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 4, horizontal: 15 }}
      >
        <Box>
          {allGroups.map(group => (
            <ListItemButton key={group.id} onClick={selectFriendGroup(group.id)}>
              <Text variant="subtext">{group.name}</Text>
            </ListItemButton>
          ))}
        </Box>
      </Popover>
      {!!data?.payload.length && (
        <SearchInput>
          <Input 
            size="small"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            placeholder="Search friends list" 
            InputProps={{endAdornment: <SearchRoundedIcon />}} />
        </SearchInput>
      )}
    </>
  )

  // eslint-disable-next-line
  const friendGroupSection = (
    <>
      <Box mt={3} mb={2} display="flex" justifyContent="space-between">
        <Text weight="semibold" variant="medium">Friend Groups</Text>
        <TextWithIcon variant="medium" weight="semibold" icon={<PlusIcon />} onClick={openNewFriendGroupModal}>
          New
        </TextWithIcon>
      </Box>
      <Box mb={1.5}>
        <FriendGroupList data={allGroups} />
      </Box>
      <Divider />
    </>
  )

  if (isLoading) {
    return <Text center>Loading friends list...</Text>
  }


  return (
    <Box width="100%" mb={2} display="flex" flexDirection="column">
      {friendSearchField}
      <Box mt={3} mb={1} display="flex" justifyContent="space-between">
        <TextWithIcon 
          style={{width: "fit-content"}}
          onClick={handleClick}
          after 
          icon={menuIsOpen ? <UpIcon fontSize="large" /> : <DownIcon fontSize="large" />} 
          variant="medium" 
          weight="semibold">
          {selectedFriendGroup?.name}
        </TextWithIcon>
        <InviteFriend openDrawer={openDrawer} />
      </Box>
      {!friends.length && (
        <TextWithIcon
          mt={1}
          icon={<FrownIcon fontSize="large" sx={{color: colors.danger}} />} 
          weight="semibold"
          color={colors.danger} 
          justifyContent="center"
        >
          You have no friends yet.
        </TextWithIcon>
      )}
      {friendsToDisplay.map(({ friend_user }) => (
        <Link to={`/${friend_user.username}`} key={friend_user.username} >
          <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
            <UserInfo user={friend_user} />  
            <RightIcon fontSize="large" />
          </Box>
          <Divider />
        </Link>
      ))}
    </Box>
  )
}

export default AllFriends
