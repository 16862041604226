import React from 'react'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/AddTaskRounded';
import Divider from "@mui/material/Divider"
import Button from '../../../../components/Button/Button'
import Text from '../../../../components/Text'
import { DateRequest, User } from '../../../../utils/types'
import TextWithIcon from '../../../../components/TextWithIcon'
import MenuItem from '@mui/material/MenuItem';
import { useUiStore } from '../../../../stores/uiStore';
import { useAddParticipantMutation } from '../../../../services/api';
import { colors } from '../../../../styles/_var';
import { getFirstName } from '../../../../utils/helpers';


type AddUserToActivityProps = {
  user: User,
  sentRequests: DateRequest[]
}

const AddUserToActivity = ({user, sentRequests}: AddUserToActivityProps) => {
  const { cancelDialog, showConfirmDialog, openSuccessToast } = useUiStore()
  const [addParticipantRequest,] = useAddParticipantMutation()
  const firstname = getFirstName(user.full_name, 'unknown')
  const addToParticipant = (activity: DateRequest) => {
    showConfirmDialog({
      dialogBody: `You are adding ${firstname} to this activity`,
      onConfirmAction: () => {
        addParticipantRequest({
          request_id: activity.request_id,
          participants: [{contact: user.email || user.username, name: user.full_name}]
        })
        openSuccessToast({ message: 'User added to activity' })
        cancelDialog()
      }
    })
  }

  return (
    <Box minWidth={300}>
      <Box px={2} py={1.5}>
        <Text weight="semibold" variant="medium">
          Add {firstname} to activity
        </Text>
      </Box>
      <Divider />
      <Box>
        {sentRequests.map(activity => (
            <MenuItem key={activity.request_id} onClick={() => addToParticipant(activity)}>
              <TextWithIcon icon={<AddIcon color="success" />}>
                {activity.activity}
              </TextWithIcon>
            </MenuItem>
          ))
        }
        {!sentRequests.length && (
          <Text color={colors.danger} center my={1}>
            No available activities
          </Text>
        )}
      </Box>
      <Divider />
      <Box px={2} py={1.5} display="flex" justifyContent="flex-end">
        <Button variant="outlined" size="small" onClick={cancelDialog}>
          <Text>Cancel</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default AddUserToActivity
