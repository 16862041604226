import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { OpenDateRequest, User } from '../../../../utils/types'
import Text from '../../../../components/Text'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'
import OpenDateRequestCard from './OpenDateRequestCard'
import { colors } from '../../../../styles/_var'
import { useAuthStore } from '../../../../stores/authStore'
import { humanize } from '../../../../utils/helpers'
import { useCreateOpenRequestInterestMutation } from '../../../../services/api'

type OpenDateRequestListProps = {
  openRequests?: OpenDateRequest[]
} & BoxProps

type OpenDateRequestItemProps = {
  request: OpenDateRequest,
  currentUser?: User,
  toggle: (payload: {slug: string}) => void,
} & BoxProps

const OpenDateRequestItem = ({ request, currentUser, toggle, ...rest }: OpenDateRequestItemProps) => {
  return (
    <Box {...rest}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <UserInfo 
          gotoProfileOnClick
          useSmallAvatar 
          user={request.user} 
          onlyShowName titleTextVariant='regular'
          />
        {!!request.created_at && <Text variant="small">{humanize(request.created_at)}</Text>}
      </Box>
      {!!request.description && <Text mb={2} mt={1}>{request.description}</Text>}
      <OpenDateRequestCard 
        cardColor={colors.white} 
        currentUser={currentUser} 
        request={request}
        toggle={toggle}
      />
    </Box>
  )
}

const OpenDateRequestList = ({ openRequests, ...rest }: OpenDateRequestListProps) => {
  const {currentUser} = useAuthStore()
  const [toggleInterest,] = useCreateOpenRequestInterestMutation()
  if (!openRequests?.length) return null
  
  return (
    <Box {...rest}>
      <Text variant="medium" weight="semibold" mb={1}>Recent Activity</Text>
      {openRequests.map((request, i) => (
        <OpenDateRequestItem 
          toggle={toggleInterest}
          mb={3} 
          key={i} 
          request={request} 
          currentUser={currentUser} 
        />
      ))}
    </Box>
  )
}


export default OpenDateRequestList
