import styled, { css } from "styled-components";
import { colors, fontSize } from "../../styles/_var";
import { CSSTextProps } from "./Text";


export const cssText = css<CSSTextProps>`
  margin: 0;
  color: ${colors.primary};
  word-wrap: break-word;
  ${({ $center }) => $center && 'text-align: center;'}
  ${({ $left }) => $left && 'text-align: left;'}
  ${({ $right }) => $right && 'text-align: right;'}
  ${({ $justify }) => $justify && 'text-align: justify;'}
  ${({ $underline }) => $underline && 'text-decoration: underline;'}
  ${({ $variant }) => {
    if ($variant === 'title') {
      return `
        font-size: ${fontSize.xl};
        line-height: 3.6rem;
        letter-spacing: 0.03rem;
      `
    } else if ($variant === 'small') {
      return `
        font-size: ${fontSize.xs};
        line-height: 1.8rem;
      `
    } else if ($variant === 'medium') {
      return `
        font-size: ${fontSize.md};
        line-height: 2.5rem;  
      `
    } else if ($variant === 'subtext') {
      return `
        font-size: ${fontSize.sm};
        line-height: 2rem;
        letter-spacing: 0.01rem;
      `
    } else if ($variant === 'regular') {
      return `
        font-size: ${fontSize.rg};
        line-height: 2.2rem;
      `
    } else if ($variant === 'large') {
      return `
        font-size: ${fontSize.lg};
        line-height: 4.5rem;
        letter-spacing: 0.1rem;      
      `
    } else if ($variant === 'semi-large') {
      return `
        font-size: 2.4rem;  
        line-height: 2.8rem;
      `
    } else if ($variant === 'extra-small') {
      return `
        font-size: ${fontSize.xxs};  
        line-height: 1.4rem;
      `
    } else {
      return `
        font-size: ${fontSize.rg};
        line-height: 2.2rem;
      `
    }
  }}
  ${({ $nowrap }) => $nowrap && `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  ${({ $truncate }) => $truncate && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ $inline }) => $inline && `
    display: inline-block;
  `}
  ${({ $italic }) => $italic && `
    font-style: italic;
  `}
  ${({ $uppercase }) => $uppercase && `
    text-transform: uppercase;
  `}
  ${({ $lowercase }) => $lowercase && `
    text-transform: lowercase;
  `}
  ${({ $capitalize }) => $capitalize && `
    text-transform: capitalize;
  `}
  ${({ $hasTooltip }) => $hasTooltip && `
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  `}
  ${({ $link }) => $link && `
    cursor: pointer;
    transition: opacity ease .2s;
    &:hover {
      opacity: .8;
    }
  `}
  text-decoration-style: ${({ $textDecorationStyle }) => $textDecorationStyle};
`

export const TextWrapper = styled(({ inline, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ inline }) => inline && 'display: inline-block;'}
`

export const StyledText = styled.p<CSSTextProps>`
  ${cssText}
`;

export const StyledTextDiv = styled.div<CSSTextProps>`
  ${cssText}
`

export const StyledTextSpan = styled.span<CSSTextProps>`
  ${cssText}
`

export const Tooltip = styled.div`
  background-color: ${colors.green};
  border: solid 1.2px ${colors.darkGreen};
  color: ${colors.white};
  position: absolute;
  border-radius: 5px;
  padding: 1rem;
  line-height: 2rem;
  transition: all ease .7s;
  font-size: 14px;
  min-width: 200px;
  z-index: 3;
  opacity: 1;
`