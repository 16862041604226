import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import UpdateEmailPassword from './UpdateEmailPassword'
import NotificationSetting from './NotificationSetting'
import DeleteAccount from './DeleteAccount'
import { animateComponent } from '../../../../utils/hoc'



const Security = React.memo(() => {
  return (
    <Container>
      <Box mt={3} maxWidth={600} marginLeft="auto" marginRight="auto">
        <UpdateEmailPassword />
        <NotificationSetting />
        <DeleteAccount />
      </Box>
    </Container>
  )
})

export default animateComponent(Security, true)
