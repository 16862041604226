import React, { CSSProperties, FunctionComponent, useState } from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getStyleSpacing } from '../../utils/helpers'
import { ComponentSpacing } from '../../utils/types'
import { InputWrapper, StyledInput } from './Input.styles'

export type InputProps = {
  customLabel?: string | React.ReactNode,
  inputFieldStyle?: CSSProperties,
} & TextFieldProps & ComponentSpacing

const Input:FunctionComponent<InputProps> = React.forwardRef((props, ref) => {
  const {
    mt,
    mb,
    mr,
    ml,
    style={},
    variant='outlined',
    placeholder,
    size="medium",
    customLabel,
    inputFieldStyle,
    ...rest
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {...spacingStyle, ...style}

  const isPassword = props.type === 'password'

  const onClickPasswordToggle = () => {
    setShowPassword(!showPassword)
  }

  if (isPassword) {
    rest.type = showPassword ? 'text' : 'password'
    rest.InputProps = {
      endAdornment: passwordAdornment(showPassword, onClickPasswordToggle)
    }
  }


  return (
    <InputWrapper className={props.className || ''} style={componentStyle}>
      <StyledInput 
        style={inputFieldStyle}
        inputRef={ref}
        variant={variant} 
        placeholder={placeholder}
        size={size}
        {...rest}
      />
    </InputWrapper>
  )
})

const passwordAdornment = (showPassword: boolean, onClick: () => void) => (
  <IconButton
    aria-label="toggle password visibility"
    onClick={onClick}
    edge="end"
  >
    {showPassword ? <VisibilityOff /> : <Visibility />}
  </IconButton>
)


export default Input
