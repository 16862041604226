import styled from 'styled-components'
import Backdrop from '../../../../assets/images/activity-backdrop.jpeg'
import Text from '../../../../components/Text'
import { colors } from '../../../../styles/_var'
import { ParticipantResponse } from '../../../../services/api'

export const PlanActivityWrapper = styled.div``

export const ActivityBackDrop = styled(({ backdrop, ...rest }) => <div {...rest} />)`
  ${({ backdrop }) => backdrop ? `
    background-image: url(${backdrop});
  ` : `
    background-image: url(${Backdrop});
  `}
  height: 140px;
  background-size: cover;
  background-position: center;
`

export const Label = styled(Text)`
  width: 45px;
  text-align: center;
  border: solid 1px ${colors.primary};
  border-radius: 5px;
  padding: 1px 10px;
  height: fit-content;
  color: ${colors.primary};
`

export const CreatorLabel = styled(Label)`
  background-color: ${colors.pink};
`

export const StatusLabel = styled(Label)<{$type: ParticipantResponse}>`
  ${({ $type }) => $type === "pending" && `
    background: ${colors.lightyellow};
  `}

  ${({ $type }) => $type === "declined" && `
    background: ${colors.danger};
    color: ${colors.white};
  `}

  ${({ $type }) => $type === "accepted" && `
    background: ${colors.veryLightGreen};
    color: ${colors.black};
  `}
`