import React from 'react'
import Box from '@mui/material/Box'
import { CreatorLabel } from '../styles';
import { Participant } from '../../../../../services/api';
import Button from '../../../../../components/Button/Button';
import { colors } from '../../../../../styles/_var';
import { humanize, participantToUser } from '../../../../../utils/helpers';
import { UserInfo } from '../../../../../components/UserInfo/UserInfo';
import TextWithIcon from '../../../../../components/TextWithIcon';
import Cached from '@mui/icons-material/Cached';
import Delete from '@mui/icons-material/Delete';
import { useUiStore } from '../../../../../stores/uiStore';
import Text from '../../../../../components/Text';


type DateParticipantViewProps = {
  participant: Participant
  isCreator?: boolean,
  hideDelete?: boolean,
  hideResend?: boolean,
  onRemove?: () => void,
  resendInvite?: () => void,
  resendingInvite?: boolean
}

const DateParticipantView = ({
  participant,
  onRemove,
  isCreator,
  hideDelete,
  hideResend,
  resendInvite
}: DateParticipantViewProps) => {
  const { showConfirmDialog } = useUiStore()
  if (!participant) return null

  const user = participantToUser(participant)
  const showCreatorButton = isCreator
  const showRemoveButton = !hideDelete && !isCreator
  const showResendButton = !hideResend && !isCreator
  const showTimestamp = !showCreatorButton && !showRemoveButton && !showResendButton

  const removeButton = (
    <Button
      onClick={onRemove}
      variant="contained"
      size="small"
      fullWidth
      style={{paddingTop: '2px', paddingBottom: '2px', background: colors.danger}}
    >
      <TextWithIcon icon={<Delete />} useDiv variant="small" weight="semibold" color={colors.white}>
        remove
      </TextWithIcon>
    </Button>
  )

  const resendButton = (
    <Button
      onClick={() => {
        showConfirmDialog({
          dialogBody: "Are you sure you want to resend this invite?",
          onConfirmAction: resendInvite
        })
      }}
      variant="contained"
      size="small"
      mt={2}
      fullWidth
      style={{paddingTop: '2px', paddingBottom: '2px', background: colors.secondary}}
    >
      <TextWithIcon icon={<Cached />} useDiv variant="small" weight="semibold" color={colors.white}>
        resend link
      </TextWithIcon>
    </Button>
  )

  const timestampLabel = (
    <Text variant="small" weight="semibold" lighten right>
      {participant.updated_at && humanize(participant.updated_at)}
    </Text>
  )

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <UserInfo user={user} onlyShowName nameFirst={!isCreator} gotoProfileOnClick />
        <Box display="flex" alignItems="center" flexDirection="column">
          {showCreatorButton && <CreatorLabel variant="small" style={{width: '100%'}}>Creator</CreatorLabel>}
          {showRemoveButton && removeButton}
          {showResendButton && resendButton}
          {showTimestamp && timestampLabel}
        </Box>
      </Box>
    </Box>
  )
}

export default DateParticipantView