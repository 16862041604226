import React from 'react'
import { Outlet } from 'react-router-dom'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { SettingsWrapper } from './AccountSettings.styles'
import SettingsNav from './SettingsNav'


const AccountSettings = () => {
  useSetHeaderTitle('Account Settings')

  return (
    <SettingsWrapper>
      <MobileHeader />
      <SettingsNav />
      <Outlet />
    </SettingsWrapper>
  )
}

export default AccountSettings
