import React, { FunctionComponent } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { DateTime } from 'luxon'
import Text from '../../../../components/Text'
import { Slot } from '../PlanDate.styles'
import { DateTimeslot } from '../../../../utils/types'


type TimeSlotSelectorProps = {
  timeslots: DateTimeslot[],
  selectedTimeslot?: null | number,
  selectedDate?: DateTime | null,
  setSelectedTimeslot: (slot: null | number) => void
}

const TimeSlotSelector: FunctionComponent<TimeSlotSelectorProps> = ({
  timeslots,
  selectedTimeslot,
  selectedDate,
  setSelectedTimeslot
}) => {
  return(
    <>
      <Divider />
      <Box padding={2}>
        <Text>Date: <strong>{selectedDate?.toLocaleString(DateTime.DATE_HUGE)}</strong></Text>
        <Text variant="subtext" mt={1}>Select a time below for your date!</Text>
      </Box>
      <Divider />
      <Box padding={2} display="flex" flexDirection="column" alignItems="center">
        {timeslots.map((slot, i) => (
          <Slot key={i} selected={selectedTimeslot === slot.id} onClick={() => setSelectedTimeslot(slot.id)}>
            <Text weight="semibold" inline mr={1}>
              {DateTime.fromISO(slot.start_time).toLocaleString(DateTime.TIME_SIMPLE)} 
            </Text>
            - 
            <Text weight="semibold" inline ml={1}>
              {DateTime.fromISO(slot.end_time).toLocaleString(DateTime.TIME_SIMPLE)} 
            </Text>
          </Slot>
        ))}
      </Box>
    </>
  )
}

export default TimeSlotSelector