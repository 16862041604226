import React from 'react'
import { useTransition, animated } from '@react-spring/web'
import { getStyleSpacing } from './helpers'
import { ComponentSpacing } from './types'


export const animateComponent = <T extends object>(ReactComponent: React.ComponentType<T>, horizontal?: boolean, animateOptions?: any): React.FunctionComponent<T> => (props) => {
  const transitions = useTransition(true, {
    from: horizontal ? { left: 200, opacity: 0 } : { top: 200, opacity: 0 },
    enter: horizontal ? { left: 0, opacity: 1 } : { top: 0, opacity: 1},
    ...animateOptions
  })

  return transitions((style) => (
    <animated.div style={{position: 'relative', ...style}}>
      <ReactComponent {...props} />
    </animated.div>
  ))
}


export const addSpacing = <ComponentProps extends { style?: React.CSSProperties }>(WrappedComponent: React.ComponentType<ComponentProps>) => {
  const newComponent = ({
    m, mx, my, ml, mb, mr, mt, style={},
    ...rest
  }: ComponentSpacing & ComponentProps) => {
    const componentStyle = getStyleSpacing({m, mx, my, ml, mb, mr, mt})

    return (
      <WrappedComponent {...rest as ComponentProps} style={{...componentStyle, ...style}} />
    )
  }

  return newComponent
}