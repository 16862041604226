import styled, { css } from 'styled-components'
import { colors } from '../../styles/_var'


export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`

export const ToggleSwitchWrapper = styled(({ small, ...rest }) => <div {...rest} />)`
  position: relative;
  width: 7.5rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  ${({ small }) => small && smallSwitch}
`

export const ToggleInput = styled.input`
  display: none;
`

export const ToggleLabel = styled(({ checked, ...rest }) => <label {...rest} />)`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 2rem;
  margin: 0;
  position: relative;
  width: 100%;

  &:focus {
    outline: none;
    > span {
      box-shadow: 0 0 2px 5px red;
    }
  }
  > span:focus {
    outline: none;
  }

  ${({ checked }) => checked && `
    & span:first-child {
      margin-left: 0;
    }

    & span:last-child {
      right: 0px;
    }
  `}
`

export const ToggleInnerSwitch = styled(({ disabled, ...rest }) => <span {...rest} />)`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.2s ease-in 0s;
  ${({ disabled }) => disabled && disabledSwitch}
  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 3.4rem;
    padding: 0;
    line-height: 3.4rem;
    font-size: 1.4rem;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  &:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 1rem;
    background-color: ${colors.primary};
    color: #fff;
  }
  &:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 1rem;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
`

export const ToggleSwitchBody = styled(({ disabled, ...rest }) => <span {...rest} />)`
  display: block;
  width: 2.4rem;
  margin: 0.5rem;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4rem;
  border: 0 solid #bbb;
  border-radius: 2rem;
  transition: all 0.2s ease-in 0s;
  ${({ disabled }) => disabled && disabledSwitch}
`

export const disabledSwitch = css`
  background-color: #ddd;
  cursor: not-allowed;
  &:before {
    background-color: #ddd;
    cursor: not-allowed;
  }
`

export const smallSwitch = css`
  width: 4rem;

  & span:first-child {
    &:after,
    &:before {
      content: "";
      height: 2rem;
      line-height: 2rem;
    }
  }
  & span:last-child {
    width: 1.6rem;
    right: 2rem;
    margin: 0.2rem;
    height: 1.5rem;
  }
`