import React from 'react'
import { useFetchActivePendingRequestQuery } from '../../../services/api'
import CardSlider from '../../../components/CardSlider/CardSlider'
import { colors } from '../../../styles/_var'


const PendingRequests = () => {
  const { data, isSuccess } = useFetchActivePendingRequestQuery({})
  if (!(isSuccess && data?.payload.length)) return null

  return (
    <CardSlider 
      bgColor={colors.white}
      data={data.payload}
      title="Pending Requests" 
      showCount  
      mt={4}
    />
  )
}

export default PendingRequests