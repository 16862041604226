import React from 'react'
import MuiAvatar, { AvatarProps }  from '@mui/material/Avatar'
import { AvatarWrapper } from './Avatar.styles'
import { ComponentSpacing } from '../../utils/types'
import { getStyleSpacing } from '../../utils/helpers'


const Avatar: React.FC<AvatarProps & {addBorder?: boolean, removeBorder?: boolean} & ComponentSpacing> = ({ 
  addBorder=true, 
  removeBorder=false,
  mr,
  ml,
  mx,
  m,
  mt,
  mb,
  my,
  ...rest
}) => {
  const spacingStyle = getStyleSpacing({mr, ml, mt, mb, my, mx, m})

  if (removeBorder) {
    return <MuiAvatar {...rest} style={spacingStyle} />
  }

  return (
    <AvatarWrapper addBorder={addBorder} style={spacingStyle}>
      <MuiAvatar {...rest} />
    </AvatarWrapper>
  )
}

export default Avatar