import React from 'react'
import { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { ComponentSpacing } from '../../utils/types'
import { getStyleSpacing } from '../../utils/helpers'
import { ButtonWrapper } from './Button.styles'
import { Link } from 'react-router-dom'


export interface ButtonProps extends MUIButtonProps, ComponentSpacing {
  loading?: boolean,
  useLink?: boolean,
  to?: string,
  center?: boolean,
}

const Button = React.forwardRef<HTMLButtonElement | null, ButtonProps>(({
  loading,
  mt,
  mb,
  mr,
  ml,
  center,
  children,
  style={},
  useLink,
  to,
  ...rest
}, ref) => {
  const spacingComponents = { mr, ml, mb, mt }
  const spacingStyle = getStyleSpacing(spacingComponents)
  const componentStyle = {
    textTransform: 'none', 
    paddingTop: rest.size === "small" ? '7px' : '10px', 
    paddingBottom: rest.size === "small" ? '7px' : '10px', 
    ...spacingStyle, 
    ...style
  }

  if (useLink && to) {
    return (
      <ButtonWrapper center={center} {...rest} ref={ref} style={componentStyle} LinkComponent={CustomLink} href={to}>
        { children }
      </ButtonWrapper>
    )
  }

  return (
    <ButtonWrapper center={center} {...rest} ref={ref} style={componentStyle}>
      { loading ? <CircularProgress /> : children }
    </ButtonWrapper>
  )
})

export default Button


export const CustomLink = React.forwardRef<HTMLAnchorElement, {href: string}>(({ href, ...rest }, ref) => {
  return <Link to={href} {...rest} ref={ref} />
})
