import React, { useEffect, useState, useMemo } from 'react'
import { debounce } from '@mui/material/utils';
import Box from '@mui/material/Box';
import UsernameIcon from '@mui/icons-material/AlternateEmail';
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import { useLazySearchUsersQuery } from '../../services/api';
import { ResultItem, UIInput } from './UsernameInput.styles';
import { User } from '../../utils/types';
import Text from '../Text';

type ResultListProps = {
  isLoading: boolean,
  data?: User[],
  onClick?: (user: User) => void
}

const ResultList = ({
  isLoading,
  data,
  onClick
}: ResultListProps) => {
  return (
    <Paper elevation={3} sx={{marginBottom: '5px'}}>
      <Box py={1}>
        {isLoading && <Text mx={1}>Searching...</Text>}
        {!isLoading && !data?.length && <Text mx={1}>No user suggestions</Text>}
        {!isLoading && !!data?.length && data.map(u => (
          <ResultItem 
            px={1.5}
            user={u} 
            key={u.username} 
            useSmallAvatar 
            py={1} 
            onClick={() => onClick?.(u)}
          />
        ))}
      </Box>
    </Paper>
  )
}

type UsernameInputProps = {
  onSelectUser: (user: User) => void
}

const UsernameInput = ({ onSelectUser }: UsernameInputProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [error, setError] = useState<null | String>(null)
  const [value, setValue] = useState('')
  const [open, setOpen] = useState(false)
  const resetError = () => setError(null)

  const [searchUsers, { isLoading, data }] = useLazySearchUsersQuery()
    const runSearch = useMemo(() => debounce(query => {
      searchUsers({ query })
    }, 900), [searchUsers])

  useEffect(() => {
    if (value.length > 3) {
      runSearch(value)
      if (!open) setOpen(true)
    } else if (open && value.length <= 3) {
      setOpen(false)
    }
  }, [value, open, runSearch])


  return (
    <div>
      <UIInput 
        InputProps={{
          startAdornment: <UsernameIcon style={{marginRight: 10}} />,
        }}
        aria-describedby={'username_id'}
        name="potential_username"
        error={!!error}
        helperText={error}
        size="small" 
        placeholder="Enter name or username" 
        onFocus={resetError}
        value={value} 
        onChange={e => setValue(e.target.value)}
        onClick={e => setAnchorEl(e.currentTarget)}
      />
      <Popper
        sx={{ zIndex: 10200, width: anchorEl?.clientWidth }}
        id='username_id'
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
      >
        <ResultList 
          onClick={onSelectUser} 
          isLoading={isLoading} 
          data={data?.payload} 
        />
      </Popper>
    </div>
  )
}

export default UsernameInput
