import React, { FunctionComponent } from 'react'
import SimpleForm from '../../../../components/SimpleForm'
import Text from '../../../../components/Text'
import { usePlanActivity, CreateActivityFormValues } from '../CreateActivity/CreateActivity.Hooks'


type AddActivityDetailsProps = {
  createActivityRequest: (payload: CreateActivityFormValues) => void
}

const AddActivityDetails: FunctionComponent<AddActivityDetailsProps> = ({ createActivityRequest }) => {
  const {formFields, actionButton, initialValues} = usePlanActivity({})

  return (
    <>
      <Text variant="medium" weight="semibold" mb={2}>Add Activity Details</Text>
      <SimpleForm<CreateActivityFormValues>
        animateForm
        formFields={formFields}
        actionButtons={actionButton}
        submitForm={data => createActivityRequest(data)}
        buttonWrapperClass='fixed-button-bottom' 
        initialValues={initialValues}
      />
    </>
  )
}

export default AddActivityDetails
