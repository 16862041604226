import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import InputAdornment from '@mui/material/InputAdornment';
import Input from "../../../components/Input"
import { useRegisterMutation } from "../../../services/api"
import validators from "../../../utils/validators"
import Text from "../../../components/Text";
import Checkbox from "../../../components/Checkbox";
import { IActionButton } from "../../../components/SimpleForm";
import { colors } from "../../../styles/_var";
import { retrieveErrorMessage } from "../../../utils/helpers";
import { showToastMessage } from "../../../stores/uiStore";
import { routes } from "../../../utils/constants";
import Auth from "../../../utils/auth";
import PhoneNoInput from "../../../components/PhoneNoInput";


export const useRegisterForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [registerUser, { isLoading }] = useRegisterMutation()

  const registerFields = [
    {
      name: 'full_name',
      label: 'Full Name',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.required
    },
    {
      name: 'email',
      label: 'Email',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.isEmail
    },
    {
      name: 'username',
      label: 'Username',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.required,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Text>@</Text>
          </InputAdornment>
        )
      }
    },
    {
      name: 'phone_number',
      placeholder: 'Enter phone number', 
      component: PhoneNoInput,
      max_length: 50,
      fullWidth: true,
      validate: validators.required,
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.required,
    },
    {
      name: 'tandc',
      label: <Text variant='small' color="inherit">Agree to our <a style={{textDecoration: 'underline', color: colors.primary}} href={routes.TERMS_OF_USE} target='_blank' rel="noreferrer">terms of use</a>.</Text>,
      component: Checkbox,
      type: 'checkbox',
      className: 'tandc',
      validate: validators.required
    }
  ]

  const actionButtons: IActionButton = {
    label: <Text color="white">Continue</Text>,
    loading: isLoading,
    isSubmit: true,
    fullWidth: true,
    variant: "contained"
  }

  const submitForm = async (data: any) => {
    try {
      const response = await registerUser(data).unwrap()
      Auth.saveUserDetails(response)
      dispatch(showToastMessage({ message: 'You are now logged in' }))
      navigate(routes.DASHBOARD)
    } catch (e: any) {
      const errorMessage = retrieveErrorMessage(e)
      dispatch(showToastMessage({ message: errorMessage, severity: 'error' }))
    }
  }


  return { isLoading, submitForm, actionButtons, registerFields}
}