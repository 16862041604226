import React from 'react'
import Box from '@mui/material/Box'
import { Participant, ParticipantResponse } from '../../../../../services/api'
import Divider from '@mui/material/Divider'
import DateParticipantView from './DateParticipantView'
import { capitalize } from '../../../../../utils/helpers'
import { colors } from '../../../../../styles/_var';
import DateRequestClass from '../../../../../services/DateRequestClass';
import { ComponentSpacing } from '../../../../../utils/types';
import CollapsibleCard from '../../../../../components/CollapsibleCard/CollapsibleCard';

type InviteSectionProps = {
  hideIfNoData?: boolean,
  participantList: Participant[],
  type: ParticipantResponse,
  dateInfo: DateRequestClass,
  updatingInvitationStatus: number[],
  removeParticipant: (p: Participant) => void,
  resendInvite: ({
    request_id, 
    pid
  }: {request_id: string, pid: number}) => void,
  headerColor?: string,
  color?: string,
} & ComponentSpacing

const InviteSection = ({
  hideIfNoData=true,
  participantList,
  type,
  dateInfo,
  updatingInvitationStatus,
  removeParticipant,
  resendInvite,
  headerColor,
  color,
  ...styleSpacing
}: InviteSectionProps) => {
  const titleText = capitalize(type) + " invites"
  const hasData = !!participantList.length

  if (hideIfNoData && !hasData) return null

  return (
    <CollapsibleCard 
      textTitle={`${titleText} (${participantList?.length || 0})`}
      headerColor={headerColor || colors.lightGreen}
      showArror={hasData}
      color={color}
      {...styleSpacing}
    >
      {participantList?.map(p => !p ? null : (
        <Box key={p.id}>
          <DateParticipantView 
            onRemove={() => removeParticipant(p)}
            hideDelete={!dateInfo.iAmSender}
            hideResend={!dateInfo.iAmSender}
            isCreator={p.user?.username === dateInfo.creatorUsername}
            participant={p}
            resendInvite={() => resendInvite({request_id: dateInfo.requestId, pid: p.id})}
          />
          <Divider />
        </Box>
      ))}
    </CollapsibleCard>
  )
}


export default InviteSection

