import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import QRCode from "react-qr-code";
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { ShareProfileWrapper } from './ShareProfile.styles'
import Text from '../../../components/Text'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../stores/authStore';

const ShareProfile = () => {
  useSetHeaderTitle('Share Profile', true)
  const currentUser = useSelector(selectCurrentUser)
  
  return (
    <ShareProfileWrapper>
      <MobileHeader />
      <Container>
        <Box mt={3} display="flex" flexDirection="column" alignItems="center">
          <QRCode style={{maxWidth: '100%'}} value={`${process.env.REACT_APP_CLIENT_URL}/${currentUser.username}`} />
          <Text mt={1} italic weight="semibold">
            Plan a date with me on <span style={{color: 'dodgerblue', textDecoration: 'underline'}}>gusiberi.com</span>
          </Text>
        </Box>
        <Box mt={3}>
          <Text mb={2}>Now you can easily share your dating profile in person. Instead of sharing personal info like your phone number or social media usernames with strangers share your gusiberi profile instead.</Text>
          <Text mb={2} variant="medium" weight="semibold">How to share your profile</Text>
          <Text mb={1}>1. Scan your QR Code below</Text>
          <Text mb={1}>2. Click on the link</Text>
          <Text>3. Viola!! Now user can view your profile and send you a date request</Text>
        </Box>
      </Container>
    </ShareProfileWrapper>
  )
}

export default ShareProfile
