import React, { FunctionComponent } from 'react'
import { 
  ToggleSwitchBody, 
  ToggleInnerSwitch, 
  ToggleInput, 
  ToggleLabel, 
  ToggleSwitchWrapper, 
  SwitchContainer
} from './ToggleSwitch.styles'
import Text from '../Text'
import { ComponentSpacing } from '../../utils/types'
import { addSpacing } from '../../utils/hoc'

type IToggleSwitch = {
  id: string,
  name?: string,
  checked?: boolean,
  value?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  optionLabels?: string[],
  small?: boolean,
  disabled?: boolean,
  className?: string,
  style?: any,
  placeholder: string | React.ReactNode
} & ComponentSpacing

const ToggleSwitch: FunctionComponent<IToggleSwitch> = React.memo(({
  id,
  name,
  checked,
  onChange,
  optionLabels=['Yes', 'No'],
  small,
  disabled,
  className,
  placeholder,
  value,
  style={}
}) => {
  return (
    <SwitchContainer style={style}>
      <ToggleSwitchWrapper small={small} className={className}>
        <ToggleInput
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          value={value}
          onChange={e => {
            onChange?.(e, e.target.checked)
          }}
          disabled={disabled} 
        />
        <ToggleLabel htmlFor={id} checked={checked}>
          <ToggleInnerSwitch
            disabled={disabled}
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]} />
          <ToggleSwitchBody disabled={disabled} />
        </ToggleLabel>
      </ToggleSwitchWrapper>
      <Text ml={1} useDiv>{placeholder}</Text>
    </SwitchContainer>
  )
})

export default addSpacing<IToggleSwitch>(ToggleSwitch)

