import React from 'react'
import CenteredView from '../../components/CenteredView'
import Text from '../../components/Text'
import Auth from '../../utils/auth'
import HomePageNav from '../HomePage/HomePageNav'
import { Link } from 'react-router-dom'
import { routes } from '../../utils/constants'


type ErrorPageProps = {
  errorMessage?: boolean,
  errorCode: string
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  errorCode
}) => {
  return (
    <>
      {Auth.isLoggedIn() && <HomePageNav />}
      <CenteredView>
        {/* <ErrorLogo src={ErrorImages[errorCode]} /> */}
        <Text variant='large' weight='bold'>{errorCode}</Text>
        <Text variant='medium' mb={1}>
          {errorMessage || ErrorMessages[errorCode]}
        </Text>
        <Link to={routes.DASHBOARD}>
          <Text variant='medium' underline link>Back To home</Text>
        </Link>
      </CenteredView>
    </>
  )
}

export default ErrorPage

const ErrorMessages: any = {
  '404': 'Sorry, Page not found',
  '401': 'Sorry, Page unauthorized',
  '403': 'Sorry, Page forbidden',
  '500': 'Oop! Something went wrong, please refresh page.'
}

// const ErrorImages: any = {
//   '404': notFound,
//   '401':unauthorized,
//   '403': forbidden,
//   '500': notFound
// }
