import React from 'react'
import { DateRequest } from '../../../../../utils/types'
import { DateExpense } from '../../../../../services/api'
import Box from '@mui/material/Box'
import ExpenseItem from './ExpenseItem'

type ExpensesListProps = {
  dateInfo: DateRequest
  expenses: DateExpense[]
}

const ExpensesList = ({ dateInfo, expenses }: ExpensesListProps) => {
  return (
    <Box>
      {expenses?.map(e => (
        <ExpenseItem key={e.id} expense={e} dateInfo={dateInfo} />
      ))}
    </Box>
  )
}


export default ExpensesList
