import { Amplify } from '@aws-amplify/core'
import * as gen from './gen'


const chatConfig = {
  DEFAULT_RETRY_TIME: 5000,
  RETRY_STATES: [
    "ConnectionDisrupted",
    "Disconnected",
  ],
  subscriptionObjects: {} as Record<string, any>,
  connectionState: '',
  retryTime: 5000,
  configure: () => Amplify.configure(gen.config),
  ENABLE: process.env.REACT_APP_TURN_OFF_CHAT_REAL_TIME !== "Yes"
}

export default chatConfig