import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover';
import CancelIcon from '@mui/icons-material/ClearRounded';
import { DateRequest } from '../../../../../utils/types'
import Text from '../../../../../components/Text'
import MenuIcon from '@mui/icons-material/MoreVertRounded';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper'
import Input from '../../../../../components/Input'
import { colors } from '../../../../../styles/_var'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import Divider from '@mui/material/Divider'
import { useDateMemoryComments } from './DateMemories.hooks'
import { humanize } from '../../../../../utils/helpers'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton';
import { DateComment } from '../../../../../services/api';

type DateCommentsProps = {
  dateInfo: DateRequest
}

const DateComments = ({ dateInfo }: DateCommentsProps) => {
  const { 
    comments,
    isLoading,
    savingComment,
    saveComment,
    comment,
    setComment,
    getUserFromPariticipants,
    shouldShowMenuOptions,
    removeComment
  } = useDateMemoryComments(dateInfo)
  const [menuComment, setMenuComment] = useState<null | DateComment>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (c: DateComment) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuComment(c)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setMenuComment(null)
  }

  return (
    <Box p={2}>
      <Paper component={Box} p={2} mb={3}>
        <Text variant="regular" weight="semibold" mb={1}>
          Leave a comment
        </Text>
        <Input
          disabled={savingComment}
          rows={1}
          multiline
          value={comment} 
          onKeyDown={e => e.key === 'Enter' && saveComment()}
          onChange={e => setComment(e.target.value)} 
        />
      </Paper>
      {isLoading && (
        <Box textAlign="center">
          <CircularProgress color="info" />
        </Box>
      )}
      {comments?.length === 0 && (
        <Text center color={colors.danger} weight="semibold">
          No comments added yet!
        </Text>
      )}
      {comments?.map((c, i) => {
        const user = getUserFromPariticipants(c.date_participant_id)
        return (
          <Paper
            component={Box}
            py={1.8}
            key={i}
            mt={2}
          >
            <Box 
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={2}
              pb={2}
            >
              {!!user && (
                <UserInfo 
                  user={user} 
                  useSmallAvatar 
                  onlyShowName 
                  titleTextVariant="regular"
                />
              )}
              <Box display="flex" alignItems="center">
                <Text variant='small' lighten>{humanize(c.memory_object.created_at, true)}</Text>
                {shouldShowMenuOptions(c.date_participant_id) && (
                  <IconButton onClick={handleClick(c)}>
                    <MenuIcon />
                  </IconButton>
                )}
                <Popover
                  open={menuComment?.id === c.id}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <ListItemButton 
                    sx={{py: 1}} 
                    key={i} 
                    onClick={() => {
                      removeComment(c.id)
                      handleClose()
                    }}
                  >
                    <ListItemIcon sx={{minWidth: '29px'}}>
                      <CancelIcon />
                    </ListItemIcon>
                    <Text variant="subtext">Delete</Text>
                  </ListItemButton>
                </Popover>
              </Box>
            </Box>
            <Divider />
            <Box px={2}>
              <Text mt={2}>{c.memory_object.comment}</Text>
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

export default DateComments
