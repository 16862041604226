import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { AboutIcon, AboutSectionContainer } from './HomePage.styles'
import setup from '../../assets/images/setup.png'
import share from '../../assets/images/share.png'
import secure from '../../assets/images/secure.png'
import Text from '../../components/Text'
import content from './content'

const AboutSection = () => {
  return (
    <AboutSectionContainer>
      <Box mb={6}>
          <Text variant="semi-large" weight={400} center maxWidth={400}>
            {content.about_title}
          </Text>
          <Text mt={2} center maxWidth={400}>{content.about_description}</Text>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <AboutIcon><img src={setup} alt="about-setup" /></AboutIcon>
          <Text center weight={400} mt={1} mb={1}>{content.easy_setup}</Text>
          <Text center maxWidth={300}>{content.easy_setup_description}</Text>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AboutIcon><img src={share} alt="about-share"/></AboutIcon>
          <Text center weight={400} mt={1} mb={1}>{content.shareable_links}</Text>
          <Text center maxWidth={300}>{content.shareable_links_description}</Text>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AboutIcon><img src={secure} alt="about-safe" /></AboutIcon>
          <Text center weight={400} mt={1} mb={1}>{content.safe}</Text>
          <Text center maxWidth={300}>{content.safe_description}</Text>
        </Grid>
      </Grid>
    </AboutSectionContainer>
    
  )
}

export default AboutSection