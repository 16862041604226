import React from 'react'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FrownIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import MessageIcon from '@mui/icons-material/MessageRounded';
import { routes } from '../../../../utils/constants'
import { DateRequest } from '../../../../utils/types'
import { useAuthStore } from '../../../../stores/authStore'
import DateRequestClass from '../../../../services/DateRequestClass'
import { isEmpty } from '../../../../utils/helpers'
import { BackdropWrapper } from '../DateRequests.styles'
import { PageWrapper, colors } from '../../../../styles/_var'
import DateRequestInfo, { LoadingSkeleton } from '../DateRequestInfo'
import RequestOptionsButton from '../RequestOptionsButton';
import { ActivityBackDrop } from '../../PlanActivity/CreateActivity/CreateActivity.styles';
import TextWithIcon from '../../../../components/TextWithIcon';
import { useNavigate } from 'react-router-dom';

type GroupActivityViewProps = {
  dateRequest: DateRequest,
  isLoading: boolean,
  isError: boolean,
}

const GroupActivityView = ({ dateRequest, isLoading, isError }: GroupActivityViewProps) => {
  const {currentUser} = useAuthStore()
  const userLoaded = !isEmpty(currentUser)
  const navigate = useNavigate()
  
  const chatRoomLink = routes.CHAT_MESSAGE.replace(':chatId', dateRequest.request_id)
  const date = new DateRequestClass(dateRequest, currentUser)

  return (
    <PageWrapper>
      <BackdropWrapper>
        <ActivityBackDrop backdrop={dateRequest.backdrop_image} />
        <RequestOptionsButton dateInfo={date} />
        {userLoaded && (
          <IconButton 
            onClick={() => navigate(chatRoomLink)} 
            id="chat-button"
          >
            <MessageIcon fontSize="large" />
          </IconButton>
        )}
      </BackdropWrapper>
      <Container>
        <Box mt={3}>
          {isLoading && <LoadingSkeleton />}
          {(isError && !dateRequest) && (
            <TextWithIcon icon={<FrownIcon sx={{color: colors.danger}} />} justifyContent="center" color={colors.danger}>
              Failed to load
            </TextWithIcon>
          )}
        </Box>
      </Container>
      <DateRequestInfo dateInfo={dateRequest} />
    </PageWrapper>
  )
}


export default GroupActivityView
