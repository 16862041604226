import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../stores/authStore'
import ProfileEdit from './ProfileEdit'
import ProfileView from './ProfileView'

const Profile = React.memo(() => {
  const [showView, setShowView] = useState(true)
  const currentUser = useSelector(selectCurrentUser)

  return showView ? <ProfileView currentUser={currentUser} gotoEdit={() => setShowView(false)} /> : <ProfileEdit gotoView={() => setShowView(true)} />
})

export default Profile
