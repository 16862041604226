import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useChangePasswordMutation, useDeleteAccountMutation, useSetUserProfileInfoMutation } from "../../../../services/api";
import { selectCurrentUser, setCurrentUser } from "../../../../stores/authStore";
import { closeDialog, openConfirmDialog, openDialog, setLoadingScreenMessage, showToastMessage } from "../../../../stores/uiStore";
import { useProcessError } from "../../../../hooks/hooks";
import { User } from "../../../../utils/types";
import validators from "../../../../utils/validators";
import { UpdateEmail, UpdatePasswordModal } from "./UpdateEmailPassword";
import { routes } from "../../../../utils/constants";
import Auth from "../../../../utils/auth";


export const useUpdateEmail = (currentUser: User) => {
  const ref = useRef<any>(null)
  const processError = useProcessError()
  const [newEmail, setNewEmail] = useState(currentUser.email || '')
  const [errorMessage, setErrorMessage] = useState('')
  const [setProfile, {isLoading}] = useSetUserProfileInfoMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    ref?.current?.focus()
  }, [])

  const cancelModal = () => {
    dispatch(closeDialog())
  }

  const updateEmail = async () => {
    if (newEmail === currentUser.email) return cancelModal()
    if (!newEmail) return setErrorMessage("Field cannot be blank.")
    if (validators.isEmail(newEmail)) return setErrorMessage('Not a valid Email')

    try {
      const response = await setProfile({email: newEmail}).unwrap()
      dispatch(showToastMessage({message: response.message, severity: 'success'}))
      cancelModal()
      dispatch(setCurrentUser({...currentUser, email: newEmail}))
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  return {
    updateEmail,
    newEmail,
    setNewEmail,
    errorMessage,
    setErrorMessage,
    setProfile,
    isLoading,
    cancelModal,
    ref
  }
}

export const useUpdatePassword = () => {
  const processError = useProcessError()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [updatePassword, {isLoading}] = useChangePasswordMutation()
  const dispatch = useDispatch()

  const cancelModal = () => {
    dispatch(closeDialog())
  }

  const changePassword = async () => {
    if (!newPassword || !currentPassword) return setErrorMessage('Missing fields!!')
    if (newPassword.length < 6) return setErrorMessage('Your password is too short')

    try {
      const response = await updatePassword({
        old_password: currentPassword,
        new_password: newPassword
      }).unwrap()
      dispatch(showToastMessage({message: response.message, severity: 'success'}))
      cancelModal()
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }
  }

  return {
    currentPassword,
    newPassword,
    changePassword,
    cancelModal,
    isLoading,
    errorMessage,
    setNewPassword,
    setCurrentPassword,
    setErrorMessage
  }
}

export const useUpdateEmailPassword = () => {
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const updateEmail = () => {
    dispatch(openDialog({
      dialogBody: <UpdateEmail currentUser={currentUser} />
    }))
  }

  const updatePassword = () => {
    dispatch(openDialog({
      dialogBody: <UpdatePasswordModal />
    }))
  }

  return { updateEmail, updatePassword, currentUser }
}

export const useDeleteAccount = () => {
  const processError = useProcessError()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [terminateAccount, {isLoading}] = useDeleteAccountMutation()

  const deleteAccount = () => {
    if (!password) return setErrorMessage('Enter a valid password')
    dispatch(openConfirmDialog({
      dialogTitle: 'Are you sure?',
      dialogBody: 'This action is irreversible, want to continue?',
      onConfirmAction: async () => {
        try {
          await terminateAccount({password}).unwrap()
          dispatch(setLoadingScreenMessage('Redirecting to landing page'))
          Auth.logUserOut()
          setTimeout(() => {
            dispatch(setLoadingScreenMessage(''))
            window.location.href = routes.LANDING_PAGE
          }, 1000)
    
        } catch (error) {
          processError(error as FetchBaseQueryError)
        }
      }
    }))
  }

  return { deleteAccount, errorMessage, setErrorMessage, setPassword, isLoading, password}
}