import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Text from '../../../../../components/Text'
import Button from '../../../../../components/Button/Button'
import { colors } from '../../../../../styles/_var'
import { Participant } from '../../../../../services/api'
import { UserInfo } from '../../../../../components/UserInfo/UserInfo'
import { participantToUser } from '../../../../../utils/helpers'
import { useUiStore } from '../../../../../stores/uiStore'
import Input from '../../../../../components/Input'
import { RoundScores } from './DateMemories.hooks'


type AddRoundModalProps = {
  roundNumber: number,
  participants: Participant[],
  onSubmit: (results: Record<number, string>) => void,
  isLoading?: boolean,
  initialData?: RoundScores,
  scoreboardId?: number,
  isEditing?: boolean,
  isTeam?: boolean
  teams?: string[]
}

const AddSoloRoundModal = ({ 
  roundNumber, 
  participants=[], 
  onSubmit,
  isLoading,
  initialData={},
  isEditing
}: AddRoundModalProps) => {
  const { cancelDialog } = useUiStore()
  const [scores, setScores] = useState(participants.reduce((prev, curr) => {
    prev[curr.id] = String(initialData[curr.id] || "")
    return prev
  }, {} as Record<number, string>))
  const cleanScores = () => Object.keys(scores).reduce((prev, curr) => {
      prev[+curr] = scores[+curr] || "0"
      return prev
    }, {} as Record<number, string>)

  return (
    <Box maxWidth="400px" minWidth="300px">
      <Text
        center
        weight="semibold" 
        variant="medium"
        mb={1}
        mt={2}
      >
        {isEditing ? "Editing" : "Adding"} round {roundNumber}
      </Text>
      <Divider />
      <Box p={2}>
        {participants.map(p => (
          <Box py={1} display="flex" justifyContent="space-between" key={p.id}>
            <UserInfo
              useSmallAvatar
              titleTextVariant="regular"
              user={participantToUser(p)} 
              onlyShowName />
            <Input 
              size="small"
              type="number"
              ml={1} 
              onChange={e => setScores(scores => ({...scores, [p.id]: e.target.value}))}
              style={{width: '60px'}} 
              value={scores[p.id]} />
          </Box>
        ))}
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button 
          loading={isLoading}
          variant="contained" 
          mr={1} 
          size="small"
          onClick={() => onSubmit(cleanScores())}>
          <Text useDiv color={colors.white}>Save</Text>
        </Button>
        <Button variant="outlined" onClick={cancelDialog} size="small">
          <Text useDiv>Cancel</Text>
        </Button>
      </Box>
    </Box>
  )
}

const AddRoundModalForTeams = ({
  roundNumber, 
  onSubmit,
  isLoading,
  initialData={},
  isEditing,
  teams = []
}: AddRoundModalProps) => {
  const { cancelDialog } = useUiStore()
  const [scores, setScores] = useState(teams.reduce((prev, curr) => {
    prev[curr] = String(initialData[curr] || "")
    return prev
  }, {} as Record<string, string>))
  const cleanScores = () => Object.keys(scores).reduce((prev, curr) => {
    prev[curr] = scores[curr] || "0"
    return prev
  }, {} as Record<string, string>)
  
  if (!teams || teams?.length === 0) return null

  return (
    <Box maxWidth="400px" minWidth="300px">
      <Text
        center
        weight="semibold" 
        variant="medium"
        mb={1}
        mt={2}
      >
        {isEditing ? "Editing" : "Adding"} round {roundNumber}
      </Text>
      <Divider />
      <Box p={2}>
        {teams.map(team => (
          <Box py={1} display="flex" justifyContent="space-between" alignItems="center" key={team}>
            <Text>{team}</Text>
            <Input 
              size="small"
              type="number"
              ml={1} 
              onChange={e => setScores(scores => ({...scores, [team]: e.target.value}))}
              style={{width: '60px'}} 
              value={scores[team]} />
          </Box>
        ))}
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button 
          loading={isLoading}
          variant="contained" 
          mr={1} 
          size="small"
          onClick={() => onSubmit(cleanScores())}
        >
          <Text useDiv color={colors.white}>Save</Text>
        </Button>
        <Button variant="outlined" onClick={cancelDialog} size="small">
          <Text useDiv>Cancel</Text>
        </Button>
      </Box>
    </Box>
  )
}

const AddRoundModal = (props: AddRoundModalProps) => {
  return props.isTeam ? <AddRoundModalForTeams {...props} /> : <AddSoloRoundModal {...props} />
}

export default AddRoundModal
