import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import PhotoIcon from '@mui/icons-material/PhotoCameraBackRounded';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import WarningIcon from '@mui/icons-material/ErrorOutline';
import Popover from '@mui/material/Popover';
import EyeIcon from '@mui/icons-material/VisibilityRounded';
import DownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import DeleteIcon from '@mui/icons-material/ClearRounded';
import MenuDotsIcon from '@mui/icons-material/MoreVertRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { DateRequest } from '../../../../../utils/types'
import Button from '../../../../../components/Button/Button'
import { colors } from '../../../../../styles/_var'
import Text from '../../../../../components/Text'
import TextWithIcon from '../../../../../components/TextWithIcon'
import { useDateMedia } from './DateMemories.hooks';
import ImageUpload from '../../../../../components/ImageUpload';
import { ImageThumbnail, MIconButton, PreviewImage, PreviewImageWrapper } from './style';
import { getSmallImage } from '../../../../../utils/helpers';
import { DateMemoryMedia } from '../../../../../services/api';
import { useUiStore } from '../../../../../stores/uiStore';

type DateMediaProps = {
  dateInfo: DateRequest
}

const DateMedia = ({ dateInfo }: DateMediaProps) => {
  const { 
    fetchingMedia, 
    media,
    unsavedMedia,
    addImages,
    removeMedia
  } = useDateMedia(dateInfo)

  return (
    <Box p={2}>
      <ImageUpload 
        multiple
        maxWidth={1000}
        onImagesChange={addImages}
        contentArea={({ openFileChooser }) => (
          <Button center variant="contained" onClick={openFileChooser}>
            <TextWithIcon 
              useDiv 
              icon={<PhotoIcon />} 
              color={colors.white} 
              weight="semibold"
            >
              Add Photos
            </TextWithIcon>
          </Button>
        )}
      />
      {fetchingMedia && (
        <Box textAlign="center" mt={1}>
          <CircularProgress color="info" />
        </Box>
      )}
      {!fetchingMedia && (
        <TextWithIcon
          mt={1}
          color={colors.info} 
          icon={<WarningIcon color="info" />} 
          variant="small"
          weight="semibold"
          justifyContent="center"
        >
          You can only upload 10 images at a time
        </TextWithIcon>
      )}
      {(!fetchingMedia && !media?.length) && (
        <Text center color={colors.danger} weight="semibold" mt={2}>
          You haven't added any photos from this event.
        </Text>
      )}
      {(!fetchingMedia && !!media.length) && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {media.map(m => (
              <MediaThumbnail
                key={m.id}
                media={m}
                loaded={!unsavedMedia.includes(m.memory_object.url)}
                onDelete={removeMedia}
              />
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  )
}

export default DateMedia


type MediaThumbnailProps = {
  media: DateMemoryMedia
  loaded: boolean
  onDelete: (id: number) => void
}

const MediaThumbnail = ({
  media,
  loaded,
  onDelete
}: MediaThumbnailProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { showDialog } = useUiStore()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPreview = () => {
    showDialog({
      hideModalWrapper: true,
      dialogBody: <ImagePreviewModal image={media.memory_object.url} caption={media.memory_object.caption} />
    })
  }

  const menuIsOpen = !!anchorEl

  return (
    <Grid
      item 
      xs={4}
      sm={4}
      md={3}
      lg={3}
      key={media.id} 
      display="flex"
      justifyContent="center"
    >
      <ImageThumbnail
        $url={getSmallImage(media.memory_object.url)} 
        $loaded={loaded} 
      >
        <MIconButton onClick={handleClick}>
          <MenuDotsIcon color="primary" />
        </MIconButton>
        <Popover
          open={menuIsOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <ListItemButton 
            sx={{py: 1}} 
            onClick={() => {
              openPreview()
              handleClose()
            }}
          >
            <ListItemIcon sx={{minWidth: '29px'}}>
              <EyeIcon />
            </ListItemIcon>
            <Text variant="subtext">Preview</Text>
          </ListItemButton>
          <a href={media.memory_object.url} download target="_blank" rel="noreferrer">
            <ListItemButton 
              sx={{py: 1}} 
              onClick={() => {
                handleClose()
              }}
            >
              <ListItemIcon sx={{minWidth: '29px'}}>
                <DownloadIcon />
              </ListItemIcon>
              <Text variant="subtext">Download</Text>
            </ListItemButton>
          </a>
          <ListItemButton 
            sx={{py: 1}} 
            onClick={() => {
              onDelete(media.id)
              handleClose()
            }}
          >
            <ListItemIcon sx={{minWidth: '29px'}}>
              <DeleteIcon />
            </ListItemIcon>
            <Text variant="subtext">Delete</Text>
          </ListItemButton>
        </Popover>
      </ImageThumbnail>
    </Grid>
  )
}

type ImagePreviewModalProps = {
  image: string,
  caption?: string
}

export const ImagePreviewModal = ({image, caption}: ImagePreviewModalProps) => {
  const [showLoadingText, setShowLoadingText] = useState(true)

  return (
    <PreviewImageWrapper>
      <PreviewImage 
        src={image}
        alt={caption}
        onLoad={() => setShowLoadingText(false)}
      />
      {showLoadingText && (
        <Text variant="small" weight="semibold" center color={colors.white}>
          Loading...
        </Text>
      )}
    </PreviewImageWrapper>
  )
}