import styled from 'styled-components'
import MUIButton from '@mui/material/Button'
import { colors, fontSize } from '../../styles/_var'

export const ButtonWrapper = styled(({center, ...rest }) => <MUIButton {...rest} />)`
  .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    width: 23.9px !important;
    height: 23.9px !important;
    color: ${colors.white};
  }

  &.MuiButtonBase-root.MuiButton-root {
    font-size: ${fontSize.rg};
  }

  &.MuiButton-sizeSmall {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  ${({ center }) => center && `
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
  `}
`