import React from 'react'
import { colors } from '../../styles/_var'
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import TextWithIcon from '../TextWithIcon'
import Text, { ITextProps } from '../Text';

type LocationTextProps = {
  location: string
  textProps?: ITextProps,
  noIcon?: boolean,
  color?: string,
  underline?: boolean,
  underlineStyle?: React.CSSProperties["textDecorationStyle"],
  element?: React.ElementType
}

const LocationText = ({ 
  location, 
  textProps={}, 
  noIcon=false,
  underline=true,
  color=colors.link,
  underlineStyle='dotted',
  element
}: LocationTextProps) => {
  const props = {
    underline,
    color,
    link: true,
    textDecorationStyle: underlineStyle,
    ...textProps
  }

  const url = `https://maps.google.com/?q=${location}`
  const elementProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
    style: {cursor: 'pointer'},
    ...(element === 'a' ? {
      target: '_blank',
      rel: 'noreferrer',
      href: url
    } : {
      onClick: (e) => {
        e.stopPropagation()
        window.open(url)
      }
    })
  }

  return React.createElement(element || 'a', elementProps, [
    noIcon ?
      <Text {...props}>{location}</Text>
    : 
      <TextWithIcon icon={<LocationIcon />} {...props} key="1">{location}</TextWithIcon>
  ])
}

export default LocationText