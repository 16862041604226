import React, { FunctionComponent } from 'react'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box';
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { spacing } from '../../../../styles/_var'
import TextWithIcon from '../../../../components/TextWithIcon'
import Text from '../../../../components/Text'
import { RequestCardWrapper, RequestLabelWrapper } from '../DateRequests.styles'
import { getStatus } from '../../../../utils/helpers'
import { DateRequest } from '../../../../utils/types'
import { Link } from 'react-router-dom'
import DateRequestClass from '../../../../services/DateRequestClass';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../stores/authStore';
import { UserInfo } from '../../../../components/UserInfo/UserInfo';


type RequestCardProps = {
  dateInfo: DateRequest,
  showLabel?: boolean
}

export type RequestStatus = 'pending' | 'expired' | 'canceled' | 'declined' | 'accepted' | 'completed'

type RequestLabelProps = {
  type: RequestStatus
}

export const RequestLabel: FunctionComponent<RequestLabelProps> = ({
  type
}) => {
  return (
    <RequestLabelWrapper type={type}>
      <Text variant='subtext' uppercase weight="semibold">{type}</Text>
    </RequestLabelWrapper>
  )
}

export const RequestCard: FunctionComponent<RequestCardProps & React.HTMLAttributes<HTMLDivElement>> = ({ 
  dateInfo,
  showLabel, 
  ...rest
 }) => {
  const currentUser = useSelector(selectCurrentUser)
  const dateObject = new DateRequestClass(dateInfo, currentUser)

  return (
    <RequestCardWrapper elevation={3} {...rest}>
      <Link to={`/date_requests/${dateInfo.request_id}`}>
        <Box component={Box} p={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {!!dateObject.otherParticipant?.user && <UserInfo user={dateObject.otherParticipant?.user} />}
            {!showLabel && (
              <TextWithIcon icon={<RightIcon />} variant="small" weight="semibold" after>
                View Details
              </TextWithIcon>
            )}
            {showLabel && (
              <RequestLabel type={getStatus(dateInfo)} />
            )}
          </Box>
          <Divider sx={{marginY: spacing.xs}} />
          <Text weight="semibold">{dateInfo.location}</Text>
          <Text>{dateObject.fullDateTime}</Text>
        </Box>
      </Link>
    </RequestCardWrapper>
  )
}