const content = {
  preparing_dashboard: 'Hold on please while we prepare your dashboard',
  complete_account_setup: 'Please complete your account setup to continue using gusiberi.',
  setup_availability: 'Set up your dating schedule.',
  add_preferred_activities: 'Add your preferred dating activities',
  no_dates: 'You have no upcoming invites',
  upcoming_dates: 'Upcoming Invites',
  availability_tip: 'Please set up your availability here, this would make it easier for your friends to plan dates with you.',
  fav_activities_tip: 'Add a list of your favourite activities here. If friends know what activities you like it can help inform their date or hangout plans with you.',
}

export default content