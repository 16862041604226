import styled from 'styled-components'
import { colors } from '../../styles/_var'

export const FooterWrapper = styled.div`
  height: 8rem;
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > .MuiBox-root:first-child {
    & p:nth-child(1) {
      position: relative;
    }
  
    & p:nth-child(2) {
      position: relative;
      right: -20px;
      top: -10px;
    }
  }
`
