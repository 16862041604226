import styled from 'styled-components'

export const DashboardContent = styled(({ isMobile, ...rest }) => <div {...rest} />)`
  ${({ isMobile }) => !isMobile && `
    width: calc(100% - 24rem);
    position: relative;
    left: 24rem;
    top: 6rem;
    overflow-x: hidden;
    padding-bottom: 2rem;
    height: 100vh;
  `}

  ${({ isMobile }) => isMobile && `
    padding-bottom: 12rem;
  `}
`