import styled from "styled-components";
import { spacing } from "../../styles/_var";

export const SimpleFooterWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: ${spacing.xs} 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
