import React, { useEffect } from 'react'
import Container from '@mui/material/Container'
import { DateRequest, Supply, SupplyWithParticipant } from '../../../../../utils/types'
import AddIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box'
import RsvpIcon from '@mui/icons-material/Rsvp';
import ScheduleAgainIcon from '@mui/icons-material/RefreshRounded';
import { usePlanActivity } from '../../../PlanActivity/CreateActivity/CreateActivity.Hooks'
import { useAuthStore } from '../../../../../stores/authStore'
import DateRequestClass from '../../../../../services/DateRequestClass'
import DateParticipantView from './DateParticipantView'
import { Participant, usePickSuppliesSuggestionMutation } from '../../../../../services/api'
import { useUiStore } from '../../../../../stores/uiStore'
import DateResponseModal from '../DateResponseModal'
import Text from '../../../../../components/Text'
import { colors } from '../../../../../styles/_var'
import Button from '../../../../../components/Button/Button'
import TextWithIcon from '../../../../../components/TextWithIcon'
import AddParticipantModal from '../../../PlanActivity/components/AddParticipantModal';
import { getParticipantObject, isUserStatusPending } from '../../../../../utils/helpers';
import Paper from '@mui/material/Paper';
import InviteSection from './InviteSection';
import { Emoji } from 'emoji-picker-react';
import InviteRequest from './InviteRequest';
import ScheduleAgainDrawer from './ScheduleAgainDrawer';
import SuppliesSection from './SuppliesSection';


type DateParticipantsProps = {
  dateInfo: DateRequest
}

const DateParticipants = ({ dateInfo }: DateParticipantsProps) => {
  const {
    setParticipants,
    participants,
    deleteParticipant,
    setCreatedActivity,
    acceptInvitation,
    declineInvitation,
    updatingInvitationStatus,
    savingParticipant,
    resendInvite,
    partitionedParticipants,
    scheduleActivityAgain,
    sendingParticipantRequest,
    postParticipantRequest,
    postParticipant,
    updateParticipantRequest,
    openScheduleAgain,
    setOpenScheduleAgain,
    removeSupplySuggestion
  } = usePlanActivity({dateInfo})
  const [pickSupplySuggestion, ] = usePickSuppliesSuggestionMutation()
  const { currentUser } = useAuthStore()
  const { showConfirmDialog, showDialog } = useUiStore()
  const date = new DateRequestClass(dateInfo, currentUser)
  
  useEffect(() => {
    setParticipants(dateInfo.date_participants || [])
    setCreatedActivity(dateInfo)
    // eslint-disable-next-line
  }, [])

  const respondAction = (participant: Participant) => {
    showDialog({
      dialogBody: (
        <DateResponseModal 
          onDecline={() => declineInvitation(participant.id)}
          onAccept={() => acceptInvitation(participant.id)}
          participant={participant} 
        />
      )
    })
  }

  const removeParticipant = (participant: Participant) => {
    showConfirmDialog({
      dialogTitle: "Confirm",
      dialogBody: "Are you sure you want to remove this participant?",
      onConfirmAction: () => deleteParticipant(participant.id)
    })
  }

  const onAddParticipant = () => {
    showDialog({
      dialogBody: (
        <AddParticipantModal 
          isLoading={savingParticipant}
          storeParticipant={postParticipant}
        />
      )
    })
  }

  const onAddParticipantRequest = () => {
    showDialog({
      dialogBody: (
        <AddParticipantModal 
          title="Send Invite"
          isLoading={sendingParticipantRequest}
          storeParticipant={postParticipantRequest}
        />
      )
    })
  }

  const currentUserParticipant = getParticipantObject(currentUser, participants)

  const pickSuggestion = (suggestionId: number) => {
    const suggestion = dateInfo.supplies_suggestions?.find(s => s.id === suggestionId)
    const participantId = date.myParticipantObject?.id
    if (!participantId || !suggestion) return

    pickSupplySuggestion({ id: suggestionId, request_id: dateInfo.request_id, participantId, suggestion})
  }

  const tfmSupply = (supply: Supply): SupplyWithParticipant => {
    const participant = participants.find(p => p.id === supply.date_participant_id)
    return {
      ...supply,
      name: participant?.name,
      user: participant?.user
    }
  }

  const {
    creator,
    acceptedInvites,
    pendingInvites,
    declinedInvites
  } = partitionedParticipants
  const canDeclineInvite = currentUserParticipant?.response === "accepted"
  const isLoading = !!currentUserParticipant?.id && updatingInvitationStatus.includes(currentUserParticipant?.id)

  const addParticipantButton = (
    <>
      <Box textAlign="center" mt={2}>
        <Button 
          variant="contained"
          onClick={onAddParticipant}
        >
          <TextWithIcon weight="semibold" icon={<AddIcon />} useDiv color={colors.white }>
            Add Participant
          </TextWithIcon>
        </Button>
      </Box>
    </>
  )
  
  const respondToInviteButton = (
    <Box textAlign="center">
      <Button 
        loading={isLoading}
        size="small"
        variant="contained"
        onClick={() => currentUserParticipant && respondAction(currentUserParticipant)}
      >
        <Text weight="semibold" useDiv color={colors.white}>
          &#128077;
          Respond to Invite
          &#128078;
        </Text>
      </Button>
    </Box>
  )

  const scheduleAnotherButton = (
    <Box textAlign="center" mt={2}>
      <Button 
        loading={isLoading}
        size="small"
        variant="outlined"
        onClick={() => setOpenScheduleAgain(true)}
      >
        <TextWithIcon icon={<ScheduleAgainIcon fontSize="large" />} weight="semibold">
          Schedule Again
        </TextWithIcon>
      </Button>
    </Box>
  )

  const updateResponseButton = (
    <Button 
      sx={{backgroundColor: canDeclineInvite ? colors.lightDanger : undefined}}
      color={canDeclineInvite ? "error" : "primary"}
      loading={isLoading}
      size="small"
      variant="contained"
      onClick={() => currentUserParticipant && respondAction(currentUserParticipant)}
    >
      <Text 
        weight="semibold" 
        useDiv 
        color={colors.white} 
        style={{display: "flex"}}
      >
        {canDeclineInvite
          ? "Decline Invite " 
          : "Accept Invite "}
        <span style={{marginLeft: 5}} />
        {canDeclineInvite 
          ? <Emoji unified='1f63f' size={20} />
          : <Emoji unified='1f970' size={20} />}
      </Text>
    </Button>
  )

  const addParticipantRequestButton = (
    <Button 
      variant="outlined" 
      size="small" 
      ml={1} 
      onClick={onAddParticipantRequest}
    >
      <TextWithIcon 
        icon={<RsvpIcon fontSize="large" />} 
        weight="semibold" 
        useDiv 
      >
        Invite Others
      </TextWithIcon>
    </Button>
  )

  const creatorActionButtons = () => {
    if (date.hasExpired) return scheduleAnotherButton
    return addParticipantButton
  }

  const participantActionButtons = () => {
    if (date.hasExpired) return null
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {isUserStatusPending(currentUser, participants) 
          ? respondToInviteButton 
          : updateResponseButton}
        {addParticipantRequestButton}
      </Box>
    )
  }

  return (
    <Container>
      {date.iAmSender && creatorActionButtons()}
      {date.iAmRecipient && participantActionButtons()}

      {!!creator && (
        <Paper elevation={3} style={{marginBottom: 25, marginTop: 25}}>
          <DateParticipantView 
            onRemove={() => removeParticipant(creator)}
            hideDelete
            isCreator
            participant={creator}
          />      
        </Paper>
      )}
      <SuppliesSection
        mb={3} 
        suggestedSupplies={dateInfo.supplies_suggestions || []} 
        canDelete={date.iAmSender}
        pickSuggestion={pickSuggestion}
        removeSuggestion={removeSupplySuggestion}
        supplies={(dateInfo.supplies || []).map(tfmSupply)}
      />
      <InviteSection 
        participantList={acceptedInvites}
        type="accepted"
        dateInfo={date}
        updatingInvitationStatus={updatingInvitationStatus}
        removeParticipant={removeParticipant}
        resendInvite={resendInvite}
        mb={3}
      />
      <InviteSection 
        headerColor={colors.lightyellow}
        participantList={pendingInvites}
        type="pending"
        dateInfo={date}
        updatingInvitationStatus={updatingInvitationStatus}
        removeParticipant={removeParticipant}
        resendInvite={resendInvite}
        mb={3}
      />
      <InviteSection 
        color={colors.white}
        headerColor={colors.lightDanger}
        participantList={declinedInvites}
        type="declined"
        dateInfo={date}
        updatingInvitationStatus={updatingInvitationStatus}
        removeParticipant={removeParticipant}
        resendInvite={resendInvite}
        mb={3}
      />
      <InviteRequest 
        updateParticipantRequest={updateParticipantRequest}
        dateInfo={dateInfo} 
        requests={date.dateRequest.date_participant_requests || []} 
      />
      {participants.length === 1 && (
        <Text 
          center
          mt={1}
          mb={1}
          color={colors.danger}
          weight="semibold"
        >
          You haven't added any participants.
        </Text>
      )}
      <ScheduleAgainDrawer 
        onSubmit={scheduleActivityAgain}
        closeDrawer={() => setOpenScheduleAgain(false)} 
        isOpen={openScheduleAgain}
        request={dateInfo} 
      />
    </Container>
  )
}

export default DateParticipants
