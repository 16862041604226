import { encrypt, decrypt, deleteAllCookies } from './helpers';

class Auth {
  static isLoggedIn() {
    return !!localStorage.getItem('user_token');
  }

  static hasToken() {
    return !!localStorage.getItem('user_token');
  }

  static saveUserDetails(userPayload: any) {
    const { token, user } = userPayload;

    localStorage.setItem('username', user.username);
    localStorage.setItem('user_token', encrypt(token));
  }

  static fetchToken() {
    var token = localStorage.getItem('user_token');
    return token ? decrypt(token) : null;
  }

  static fetchUsername() {
    return localStorage.getItem('username')
  }

  static updateUsername(username: any) {
    localStorage.setItem('username', username)
  }

  static logUserOut() {
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies()
  }
}

export default Auth
