import styled from "styled-components";
import Box from "@mui/material/Box";
import Checkbox from "../Checkbox";

export const FriendSelectorWrapper = styled.div`
  position: relative;
  min-width: 280px;
`

export const ActionButtonSection = styled(Box)`
  background: rgba(255, 255, 255, 0.93);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const SCheckBox = styled(Checkbox)`
  & svg {
    width: 2rem;
    height: 2rem;
  }
`

export const FriendsListWrapper = styled.div`
  max-height: 320px;
  overflow-y: scroll;
  padding-bottom: 80px;
`