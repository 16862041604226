import Box from '@mui/material/Box'
import React from 'react'
import { ComponentSpacing, SupplySuggestion, SupplyWithParticipant } from '../../../../../utils/types'
import { addSpacing } from '../../../../../utils/hoc'
import ImporantIcon from '@mui/icons-material/ReportGmailerrorred'
import CollapsibleCard from '../../../../../components/CollapsibleCard/CollapsibleCard'
import { colors } from '../../../../../styles/_var'
import SupplySuggestionsList from '../../../PlanActivity/components/SupplySuggestionsList'
import Text from '../../../../../components/Text'

type ISuppliesSectionProps = {
  style?: React.CSSProperties,
  suggestedSupplies: SupplySuggestion[],
  canDelete: boolean,
  pickSuggestion: (suggestionId: number) => void,
  removeSuggestion: (suggestionId: number) => void
  supplies: SupplyWithParticipant[]
} & ComponentSpacing

const SuppliesSection = ({
  style, 
  suggestedSupplies, 
  pickSuggestion,
  removeSuggestion,
  supplies,
  canDelete
}: ISuppliesSectionProps) => {
  if (!suggestedSupplies?.length) return null
  return (
    <Box style={style}>
      <CollapsibleCard 
        textTitle={`Things to bring (${suggestedSupplies?.length || 0})`}
        headerColor={colors.lightergrey}
        showArror={true}
        color={colors.black}
      >
        <Box p={2}>
          <Text center variant="small" mb={2} color={colors.grey} weight="semibold" maxWidth={250}>
            Note that items with <ImporantIcon /> are very important. Click 'ok' to choose what you'd bring along.
          </Text>
          <SupplySuggestionsList 
            suggestions={suggestedSupplies}
            removeSuggestion={removeSuggestion}
            onSelect={pickSuggestion}
            supplies={supplies}
            showDelete={canDelete}
          />
        </Box>
      </CollapsibleCard>
    </Box>
  )
}


export default addSpacing<ISuppliesSectionProps>(SuppliesSection)