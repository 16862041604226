import React, { FunctionComponent } from "react";
import { MessagesWrapper } from "./Messages.styles";
import Box from '@mui/material/Box'
import MessageIcon from '@mui/icons-material/MapsUgcOutlined';
import { animateComponent } from "../../../utils/hoc";
import { useSetHeaderTitle } from "../../../hooks/hooks";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import Text from "../../../components/Text";
import { useAuthStore } from "../../../stores/authStore";
import { NotificationSkeleton } from "../Notifications/Notifications";
import { colors } from "../../../styles/_var";
import { useFetchChatMessagesQuery, useFetchUpcomingDatesQuery } from "../../../services/api";
import { DateRequest } from "../../../utils/types";
import DateRequestClass from "../../../services/DateRequestClass";
import { humanize } from "../../../utils/helpers";
import Button from "../../../components/Button/Button";
import UsersAvatarGroup from "../../../components/UsersAvatarGroup/UsersAvatarGroup";


const NoMessages = () => {
  return (
    <Box p={3}>
      <Text center mb={1} weight="semibold">You have no upcoming dates.</Text>
      <Text center lighten>Chat threads for your upcoming dates would be displayed here and would disappear when date has expired.</Text>
    </Box>
  )
}

const ActiveDate: FunctionComponent<{date: DateRequest}> = ({ date }) => {
  const { currentUser } = useAuthStore()
  const dateObject = new DateRequestClass(date, currentUser)
  const { data } = useFetchChatMessagesQuery({ request_id: date.request_id })
  const lastSentMessage = (data?.payload && data?.payload[0]) || date.last_sent_message
  const hasUnseen = lastSentMessage?.user?.username !== currentUser?.username && lastSentMessage?.seen === false

  return (
    <Button 
      useLink to={`/messages/${date.request_id}`} 
      fullWidth 
      style={{paddingRight: 0, paddingLeft: 0}}
    >
      <Box
        width="100%"
        px={2}
        py={2.3} 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{
          borderBottom: `solid 1px ${colors.lightergrey}`,
          backgroundColor: hasUnseen ? colors.veryLightGreen : undefined 
        }}
      >
        <Box display="flex" alignItems="center">
          <UsersAvatarGroup 
            max={3} 
            users={dateObject.participantUsers} 
          />
          <Box ml={1} mr={1}>
            <Text weight="semibold" truncate>{date?.activity}</Text>
            {!!lastSentMessage && (
              <Text variant="subtext" truncate style={{maxWidth: "200px"}}>
                {lastSentMessage?.user?.username === currentUser.username ? 'You' : 'Them'} <strong style={{marginRight: 1, marginLeft: 1}}>&#x2022;</strong> {lastSentMessage.message}
              </Text>
            )}
            {!lastSentMessage && <Text lighten variant="subtext">Upcoming date on <strong>{dateObject.fullDate}</strong></Text>}
          </Box>
        </Box>
        <Box>
          {!!lastSentMessage && <Text variant="subtext" right>{humanize(lastSentMessage?.created_at, true)}</Text>}
          {!lastSentMessage && <MessageIcon sx={{fontSize: '2.2rem'}} />}
        </Box>
      </Box>
    </Button>
  )
}


const Messages = () => {
  useSetHeaderTitle('Messages', true)
  const { data, isLoading, isSuccess } = useFetchUpcomingDatesQuery({})
  const noMessages = isSuccess && !data?.payload.length

  return (
    <MessagesWrapper>
      <MobileHeader />
      {noMessages && <NoMessages />}
      {!!data?.payload && (
        <Box>
          {data?.payload.map(date => <ActiveDate key={date.request_id} date={date} />)}
        </Box>
      )}
      {isLoading && <NotificationSkeleton />}
    </MessagesWrapper>
  )
}

export default animateComponent(Messages, true)