import React from 'react'
import AvatarGroup from '@mui/material/AvatarGroup'
import Text from '../Text'
import Avatar from '@mui/material/Avatar'
import { getNameInitials } from '../../utils/helpers'
import { colors } from '../../styles/_var'
import { User } from '../../utils/types'

type UsersAvatarGroupProps = {
  users: User[],
  max?: number,
  greyOut?: boolean,
  style?: React.CSSProperties
  spacing?: number
}

const UsersAvatarGroup = ({ users, max=2, greyOut=false, style={}, spacing=16 }: UsersAvatarGroupProps) => {
  if (greyOut) {
    style["filter"] = "grayscale(1)"
  }
  return (
    <AvatarGroup 
      max={max} 
      spacing={spacing}
      total={users.length}
      style={style}
    >
      {users.map((user, i) => (
        <Avatar 
          sx={{bgcolor: colors.secondary}} 
          key={i} 
          alt={user?.full_name} 
          src={user?.avatar}
        >
          <Text>{getNameInitials(user?.full_name || '')}</Text>
        </Avatar>
      ))}
    </AvatarGroup>
  )
}

export default UsersAvatarGroup

