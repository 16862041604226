import React from "react"
import DialogTitle from '@mui/material/DialogTitle';
import MaterialDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { closeDialog, selectDialogPayload, selectShowDialog } from "../../stores/uiStore";
import { useDispatch, useSelector } from "react-redux";


export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Dialog = () => {
  const showDialog = useSelector(selectShowDialog)
  const { 
    dialogTitle, 
    dialogBody, 
    hideModalWrapper, 
    wrapperStyle={},
    onDialogClose 
  } = useSelector(selectDialogPayload)
  const dispatch = useDispatch()

  return (
    <MaterialDialog
      style={wrapperStyle}
      className={hideModalWrapper ? "hide-modal-wrapper" : ""}
      open={showDialog}
      TransitionComponent={Transition}
      onClose={() => { 
        onDialogClose ? onDialogClose() : dispatch(closeDialog()) 
      }}
    >
      {!!dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      {!!dialogBody && dialogBody}
    </MaterialDialog>
  )
}

type LocalDialogProps = {
  dialogTitle: string,
  dialogBody: React.ReactNode,
  wrapperStyle?: React.CSSProperties,
  hideModalWrapper?: boolean,
  open: boolean,
  onClose?: () => void
}

export const LocalDialog = ({
  wrapperStyle={},
  hideModalWrapper=false,
  open,
  dialogTitle,
  dialogBody,
  onClose
}: LocalDialogProps) => {
  return (
    <MaterialDialog
      style={wrapperStyle}
      className={hideModalWrapper ? "hide-modal-wrapper" : ""}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      {!!dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      {!!dialogBody && dialogBody}
    </MaterialDialog>
  )
}

export default Dialog
  