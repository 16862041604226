import React, { FunctionComponent } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/EditRounded';
import Divider from '@mui/material/Divider'
import TextWithIcon from '../../../../components/TextWithIcon'
import { colors, spacing } from '../../../../styles/_var'
import Text from '../../../../components/Text'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button/Button'
import { User } from '../../../../utils/types'
import { useUpdateEmail, useUpdateEmailPassword, useUpdatePassword } from './Security.hooks'

export const UpdateEmail: FunctionComponent<{currentUser: User}> = React.memo(({
  currentUser
}) => {
  const {
    ref,
    errorMessage,
    setErrorMessage,
    newEmail,
    setNewEmail,
    cancelModal,
    updateEmail,
    isLoading
  } = useUpdateEmail(currentUser)


  return (
    <Box sx={{padding: spacing.xs, minWidth: 270}}>
      <Text weight="semibold" mb={2}>Update Email Address</Text>
      <Input
        ref={ref}
        onFocus={() => setErrorMessage('')}
        error={!!errorMessage}
        fullWidth
        label="Your new Email"
        size="medium"
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)} />
      <Text style={{position: 'absolute'}} variant="small" color={colors.danger}>{errorMessage}</Text>
      <Box mt={3}>
        <Button variant="outlined" mr={1} onClick={() => cancelModal()}>Cancel</Button>
        <Button variant='contained' onClick={() => updateEmail()} loading={isLoading}>
          Update Email
        </Button>
      </Box>
    </Box>
  )
})

export const UpdatePasswordModal: FunctionComponent = React.memo(() => {
  const {
    setCurrentPassword,
    setErrorMessage,
    errorMessage,
    currentPassword,
    newPassword,
    setNewPassword,
    changePassword,
    cancelModal,
    isLoading
  } = useUpdatePassword()

  return (
    <Box sx={{padding: spacing.xs, minWidth: 270}}>
      <Text weight="semibold" mb={2}>Update your Password</Text>
      <Input
        mb={2}
        type="password"
        name="old_password"
        onFocus={() => setErrorMessage('')}
        error={!!errorMessage}
        fullWidth
        label="Current Password"
        size="medium"
        value={currentPassword}
        onChange={e => setCurrentPassword(e.target.value)} />
      <Input
        type="password"
        name="new_password"
        onFocus={() => setErrorMessage('')}
        error={!!errorMessage}
        fullWidth
        label="New Password"
        size="medium"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)} />
      <Text style={{position: 'absolute'}} variant="small" color={colors.danger}>{errorMessage}</Text>
        <Box mt={3}>
          <Button variant="outlined" mr={1} onClick={cancelModal}>
            <Text>Cancel</Text>
          </Button>
          <Button variant='contained' onClick={changePassword} loading={isLoading}>
            <Text color={colors.white}>Update Password</Text>
          </Button>
        </Box>
    </Box>
  )
})

const UpdateEmailPassword: FunctionComponent= React.memo(() => {
  const {updateEmail, updatePassword, currentUser} = useUpdateEmailPassword()

  return (
    <Paper sx={{padding: spacing.xs, marginTop: spacing.sm}}>
      <Text center mb={2} weight="semibold">Update User Account</Text>
      <Box display="flex" justifyContent="space-between">
        <div>
          <Text>Your Email Address</Text>
          <Text weight='semibold'>{currentUser.email}</Text>
        </div>
        <TextWithIcon
          onClick={updateEmail}
          variant="subtext"
          weight="semibold"
          icon={<EditIcon />}>
            Change
          </TextWithIcon>
      </Box>
      <Divider sx={{marginTop: spacing.sm, marginBottom: spacing.sm}} />
      <Box display="flex" justifyContent="space-between">
        <div>
          <Text>Password</Text>
          <Text weight='semibold'>**************</Text>
        </div>
        <TextWithIcon
          onClick={updatePassword}
          variant="subtext"
          weight="semibold"
          icon={<EditIcon />}
        >
          Change
        </TextWithIcon>
      </Box>
    </Paper>
  )
})

export default UpdateEmailPassword