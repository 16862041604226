import React from 'react'
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'
import { DateTime } from 'luxon'
import ToggleSwitch from '../../../components/ToggleSwitch';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import validators from '../../../utils/validators'
import Input from '../../../components/Input'
import AddressInput from '../../../components/Input/AddressInput'
import { IActionButton } from '../../../components/SimpleForm';
import Text from '../../../components/Text';
import { colors } from '../../../styles/_var';
import { useIsMobile, useProcessError } from '../../../hooks/hooks';
import { useAcceptOpenRequestInterestMutation, useCloseOpenDateRequestMutation, useCreateOpenDateRequestMutation, useCreateOpenRequestInterestMutation, useDeleteOpenDateRequestMutation, useRejectOpenRequestInterestMutation, useUpdateOpenDateRequestMutation } from '../../../services/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CreateOpenDateRequestProps } from './CreateOpenDateRequests';
import { useUiStore } from '../../../stores/uiStore';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../utils/constants';

export type OpenDateRequestsFormValues = {
  activity: string,
  description?: string,
  location: string,
  start_date: DateTime,
  end_date: DateTime,
  num_people_needed?: number,
  show_num_of_interests?: boolean
}

export const constants = {
  STEP_1: 1,
  STEP_2: 2,
  HEADER_MENU_HGHT: 200,
  DEFAULT_DRAWER_HGHT: 320,
  MOBILE_OFFSET: 160,
  DESKTOP_OFFSET: 180
}

const defaultOptionalValues: Partial<OpenDateRequestsFormValues> = {
  show_num_of_interests: false,
  num_people_needed: -1,
}

export const useOpenDateRequests = ({ closeDrawer=()=> {}, updateData }: CreateOpenDateRequestProps) => {
  const isMobile = useIsMobile()
  const { openSuccessToast, showConfirmDialog } = useUiStore()
  const processError = useProcessError()
  const [createRequest, { isLoading: creating }] = useCreateOpenDateRequestMutation()
  const [updateRequest, { isLoading: updating }] = useUpdateOpenDateRequestMutation()
  const [deleteRequest, { isLoading: deleting }] = useDeleteOpenDateRequestMutation()
  const [acceptInterest, { isLoading: accepting }] = useAcceptOpenRequestInterestMutation()
  const [rejectInterest, { isLoading: rejecting }] = useRejectOpenRequestInterestMutation()
  const [createInterest, { isLoading: creatingInterest }] = useCreateOpenRequestInterestMutation()
  const [closeInterest, { isLoading: closing}] = useCloseOpenDateRequestMutation()
  const navigate = useNavigate()
  const [steps, setSteps] = React.useState(constants.STEP_1)
  const getInitialFormValues = (): undefined | OpenDateRequestsFormValues => {
    if (!updateData) {
      return {
        start_date: DateTime.now().plus({ hours: 1 }),
        end_date: DateTime.now().plus({ hours: 2 }),
        location: '',
        activity: '',
        description: undefined,
        num_people_needed: undefined,
        show_num_of_interests: undefined
      }
    }

    return {
      start_date: typeof updateData.start_date === 'string' ? DateTime.fromISO(updateData.start_date) : updateData.start_date,
      end_date: typeof updateData.end_date === 'string' ? DateTime.fromISO(updateData.end_date) : updateData.end_date,
      location: updateData.location,
      activity: updateData.activity,
      description: updateData.description,
      num_people_needed: updateData.num_people_needed,
      show_num_of_interests: updateData.show_num_of_interests
    }
  }
  const [formValues, setFormValues] = React.useState<OpenDateRequestsFormValues | undefined>(getInitialFormValues())
  const infoDetailsForm = [
    {
      name: 'activity',
      size: "small",
      placeholder: 'Enter title ex: "Need a +1"',
      component: Input,
      validate: validators.required,
      hideErrorText: true
    },
    {
      size: "small",
      name: 'location',
      placeholder: 'Enter Location',
      component: AddressInput,
      validate: validators.required,
      isLocation: true,
      hideErrorText: true
    },
    {
      name: 'start_date',
      label: 'Start Time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%', '& .MuiInputBase-root input': {padding: '8.5px 14px'}},
      validate: validators.required
    },
    {
      name: 'end_date',
      label: 'End Time',
      component: DateTimePicker,
      shouldDisableDate: (day: DateTime) => day.toUTC().diffNow('days').days < -1,
      sx: {width: '100%', '& .MuiInputBase-root input': {padding: '8.5px 14px'}},
      validate: validators.required
    },
  ]

  const infoDetailsActionButton: IActionButton = {
    isSubmit: true,
    label: 'Continue',
    variant: 'contained',
    fullWidth: true,
    endIcon: <KeyboardArrowRightRounded />,
  }
  const requestConfirmForm = [
    {
      name: 'description',
      size: "small",
      placeholder: 'Add a comment/description',
      multiline: true,
      rows: 2,
      component: Input,
      validate: validators.required,
      hideErrorText: true
    },
    {
      name: 'num_people_needed',
      size: "small",
      placeholder: 'Number of people needed',
      component: Input,
      type: 'number',
      helperText: (
        <Text useSpan weight="semibold" variant="small" color={colors.primary}>
          Leave blank if not sure
        </Text>
      ),
    },
    {
      name: 'share_with',
      size: 'small',
      label: 'Share With',
      value: 'Friends',
      placeholder: 'All Friends',
      disabled: true,
      component: Input,
    },
    {
      name: 'show_num_of_interests',
      placeholder: <Text>Show number of interests on post.</Text>,
      id: 'switch-scoreboard',
      type: 'checkbox',
      component: ToggleSwitch,
      size: "medium"
    }
  ]

  const requestConfirmAction: IActionButton = {
    isSubmit: true,
    label: 'Submit',
    variant: 'contained',
    fullWidth: true,
    loading: creating || updating,
    endIcon: <KeyboardArrowRightRounded />,
  }

  const defaultDrawerHeight = React.useMemo(() => {
    const offset = isMobile ? constants.MOBILE_OFFSET : constants.DESKTOP_OFFSET
    if (steps === constants.STEP_1) return constants.DEFAULT_DRAWER_HGHT
    return constants.DEFAULT_DRAWER_HGHT + offset
  }, [steps, isMobile])
  
  const drawerHeight = defaultDrawerHeight > window.innerHeight 
    ? window.innerHeight - constants.HEADER_MENU_HGHT 
    : defaultDrawerHeight

  const handleComplete = async (values: OpenDateRequestsFormValues) => {
    const isUpdate = !!updateData
    const successMessage = isUpdate 
      ? 'Open Request updated successfully' 
      : 'Open Request created successfully'
    const payload = {
      ...defaultOptionalValues,
      ...formValues, 
      ...values,
    }
    setFormValues(payload)

    try {
      if (isUpdate) {
        await updateRequest({slug: updateData.slug, ...payload}).unwrap()
      } else {
        await createRequest(payload).unwrap()
      }
      openSuccessToast({message: successMessage})
    } catch (error) {
      processError(error as FetchBaseQueryError)
    }

    closeDrawer?.();
  }

  const handleNext = (values: OpenDateRequestsFormValues) => {
    setFormValues(values)
    setSteps(constants.STEP_2)
  }

  const removeRequest = (requestSlug: string, onComplete?: () => void) => {
    showConfirmDialog({
      dialogTitle: 'Delete Open Request',
      dialogBody: 'Are you sure you want to delete this Open Request?',
      onConfirmAction: () => handleDelete(requestSlug || '', onComplete)
    })
  }

  const handleDelete = async (requestSlug: string, onComplete?: () => void) => {
    try {
      await deleteRequest({slug: requestSlug})
      openSuccessToast({message: 'Open Request deleted successfully'})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }

    onComplete?.()
  }

  const goBack = () => {
    setSteps(constants.STEP_1)
  }

  const acceptOpenInterest = (slug: string) => (id: number) => {
    acceptInterest({slug, id})
  }

  const rejectOpenInterest = (slug: string) => (id: number) => {
    rejectInterest({slug, id})
  }

  const createOpenInterest = (slug: string) => {
    createInterest({slug})
  }

  const closeOpenInterestHandler = async (slug: string) => {
    try {
      const response = await closeInterest({slug}).unwrap()
      openSuccessToast({message: "Open Request completed successfully"})
      navigate(routes.NEW_DATE_REQUEST.replace(':date_id', response.payload.slug))
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const closeOpenInterest = (slug: string) => {
    showConfirmDialog({
      onConfirmAction: () => closeOpenInterestHandler(slug),
      dialogTitle: 'Close Open Request',
      dialogBody: 'This would create an activity with the people who have shown interest. Continue?'
    })
  }

  return {
    handleNext,
    handleComplete,
    goBack,
    drawerHeight,
    requestConfirmAction,
    requestConfirmForm,
    infoDetailsActionButton,
    infoDetailsForm,
    steps,
    formValues,
    isLoading: creating || updating,
    handleDelete,
    isDeleting: deleting,
    removeRequest,
    accepting,
    rejecting,
    creatingInterest,
    closing,
    acceptOpenInterest,
    rejectOpenInterest,
    createOpenInterest,
    closeOpenInterest
  }
}