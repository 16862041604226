import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAcceptFriendRequestMutation, useAssignFriendsToGroupRequestMutation, useCreateFriendGroupRequestMutation, useDeclineFriendRequestMutation, useDeleteFriendGroupRequestMutation, useRemoveFriendRequestMutation, useRenameFriendGroupRequestMutation, useSendFriendRequestMutation } from '../../../services/api'
import { openConfirmDialog, openDialog, useUiStore } from '../../../stores/uiStore'
import { useProcessError } from '../../../hooks/hooks'
import AddFriendToGroupModal from './components/AddFriendToGroupModal'
import EditFriendGroupModal from './components/EditFriendGroup'
import NewFriendGroupModal from './components/NewFriendGroupModal'


export const useFriends = () => {
  const processError = useProcessError()
  const { openSuccessToast, cancelDialog } = useUiStore()
  const [searchQuery, setSearchQuery] = useState('')
  const [acceptFriend, { isLoading: accepting }] = useAcceptFriendRequestMutation()
  const [declineFriend, { isLoading: declining }] = useDeclineFriendRequestMutation()
  const [sendRequest, {isLoading: sendingFriendRequest}] = useSendFriendRequestMutation()
  const [unfriendRequest, { isLoading: unfriending }] = useRemoveFriendRequestMutation()

  const dispatch = useDispatch()


  const sendFriendRequest = async (user: string) => {
    try {
      const response = await sendRequest({sent_to: user}).unwrap()
      openSuccessToast({message: response.message})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const unfriend = (user: string) => {
    dispatch(openConfirmDialog({
      dialogBody: `Are you sure you want to unfriend ${user}?`,
      onConfirmAction: async () => {
        try {
          const response = await unfriendRequest({friend_username: user}).unwrap()
          openSuccessToast({message: response.message})
        } catch (e) {
          processError(e as FetchBaseQueryError)
        }
      }
    }))
  }

  const acceptFriendRequest = async (id: number) => {
    try {
      const response = await acceptFriend({ id }).unwrap()
      openSuccessToast({message: response.message})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const declineFriendRequest = async (id: number) => {
    try { 
      const response = await declineFriend({ id }).unwrap()
      openSuccessToast({message: response.message})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  return {
    searchQuery,
    setSearchQuery,
    cancelDialog,
    acceptFriendRequest,
    sendFriendRequest,
    sendingFriendRequest,
    accepting,
    unfriend,
    unfriending,
    declineFriendRequest,
    declining
  }
}


export const useFriendCategories = () => {
  const processError = useProcessError()
  const dispatch = useDispatch()

  const { openSuccessToast, cancelDialog, showConfirmDialog } = useUiStore()
  const [groupName, setGroupName] = useState('')
  const [groupInputError, setGroupInputError] = useState('')
  const [newFriendGroupRequest, { isLoading: creatingFriendGroup }] = useCreateFriendGroupRequestMutation()
  const [renameFriendGroupRequest, { isLoading: renaming }] = useRenameFriendGroupRequestMutation()
  const [addFriendToGroup, {isLoading: addingFriendToGroup}] = useAssignFriendsToGroupRequestMutation()
  const [deleteFriendGroup, {isLoading: deletingFriendGroup}] = useDeleteFriendGroupRequestMutation()

  const openNewFriendGroupModal = () => {
    dispatch(openDialog({
      dialogBody: <NewFriendGroupModal />
    }))
  }

  const openEditFriendGroupModal = (id: number, name: string) => {
    dispatch(openDialog({
      dialogBody: <EditFriendGroupModal id={id} name={name} />
    }))
  }

  const openAddFriendToGroupModal = (id: number, name: string) => {
    dispatch(openDialog({
      dialogBody: <AddFriendToGroupModal id={id} name={name} />
    }))
  }

  const createNewFriendGroup = async () => {
    if (!groupName.trim()) {
      return setGroupInputError('This field is required')
    }

    try {
      await newFriendGroupRequest({ name: groupName.trim() }).unwrap()
      openSuccessToast({message: 'New friend group created'})
      cancelDialog()
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const editFriendGroup = async (id: number, name: string) => {
    if (!name.trim()) return

    try {
      await renameFriendGroupRequest({ id, name }).unwrap()
      openSuccessToast({message: 'Friend group successfully renamed'})
      cancelDialog()
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const clearGroupInputErrors = () => {
    setGroupInputError('')
  }

  const removeFriendGroup = (id: number) => {
    showConfirmDialog({
      dialogBody: 'Are you sure you want to delete this friend group?',
      onConfirmAction: async () => {
        try {
          const response = await deleteFriendGroup({ id }).unwrap()
          openSuccessToast({ message: response.message })
        } catch (e) {
          processError(e as FetchBaseQueryError)
        }
      }
    })
  }

  const assignFriendToGroup = async (groupId: number, friends: string[]) => {
    try {
      const response = await addFriendToGroup({
        friend_username: friends, 
        category_id: groupId
      }).unwrap()
      openSuccessToast({message: response.message})
      cancelDialog()
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  return {
    createNewFriendGroup,
    clearGroupInputErrors,
    groupInputError,
    creatingFriendGroup,
    groupName,
    setGroupName,
    openNewFriendGroupModal,
    openEditFriendGroupModal,
    editFriendGroup,
    renaming,
    openAddFriendToGroupModal,
    addingFriendToGroup,
    assignFriendToGroup,
    removeFriendGroup,
    deletingFriendGroup
  }
}