import React, { useEffect, useRef, useState } from "react"
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FriendsIcon from '@mui/icons-material/Diversity1Outlined';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PhoneIcon from '@mui/icons-material/LocalPhoneRounded';
import MailIcon from '@mui/icons-material/EmailRounded';
import UsernameIcon from '@mui/icons-material/AlternateEmail';
import Text from "../../../../components/Text"
import Input from "../../../../components/Input"
import validators from "../../../../utils/validators"
import { useUiStore } from "../../../../stores/uiStore"
import Divider from "@mui/material/Divider"
import { FormInputWrapper } from "../../../../components/FormInput/FormInput.styles"
import { colors } from "../../../../styles/_var"
import Button from "../../../../components/Button/Button"
import PhoneNoInput from "../../../../components/PhoneNoInput"
import TextWithIcon from "../../../../components/TextWithIcon";
import { User } from "../../../../utils/types";
import Avatar from "../../../../components/Avatar/Avatar";
import { UsernameResultsWrapper } from "./PlanActivity.styles";
import { UserInfo } from "../../../../components/UserInfo/UserInfo";
import FriendSelector from "../../../../components/FriendSelector/FriendSelector";


export type ParticipantFormValues = {
  name: string
  contact: string
  username?: User
}

export type ParticipantFormErrorValues = {
  name: string
  contact: string
  username: string
}


type AddParticipantModalProps = {
  title?: string,
  storeParticipant: (formValues: ParticipantFormValues[]) => void,
  isLoading?: boolean
}

const useAddParticipantModal = ({
  storeParticipant, 
  isLoading,
}: AddParticipantModalProps) => {
  const { cancelDialog, showDialog } = useUiStore()
  const [payload, setPayload] = useState<ParticipantFormValues>({name: '', contact: '', username: undefined})
  const [errors, setErrors] = useState<ParticipantFormErrorValues>({name: '', contact: '', username: ''})
  const [username, setUsername] = useState('')
  const [inputBCR, setInputBCR] = useState<DOMRect | undefined>(undefined)
  const ref = useRef<HTMLDivElement>(null)

  const USERNAME_TAB = 0
  const PHONE_NUMBER_TAB = 1
  const EMAIL_TAB = 2
  const [tabValue, setTabValue] = useState(USERNAME_TAB)

  const userSelected = payload.username
  const setUserSelected = (user: User | undefined) => {
    setPayload({...payload, username: user})
  }

  const resetErrors = () => {
    setErrors({name: '', contact: '', username: ''})
  }

  const submitForm = () => {
    if (tabValue === USERNAME_TAB && !userSelected) {
      return setErrors({...errors, username: 'Select a user using their username of full name'})
    }

    if (tabValue !== USERNAME_TAB && !payload.name.trim()) {
      return setErrors({...errors, name: 'name is required'})
    }

    if (tabValue === EMAIL_TAB && !!validators.isEmail(payload.contact)) {
      return setErrors({...errors, contact: 'Use a valid email address'})
    }

    if (tabValue === PHONE_NUMBER_TAB && !!validators.isPhoneNo(payload.contact)) {
      return setErrors({...errors, contact: 'Use a valid phone number'})
    }

    if (tabValue === USERNAME_TAB) {
      payload.contact = userSelected?.username || ''
      payload.name = userSelected?.full_name || ''
    }

    storeParticipant([payload])
  }

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const addParticipantViaFriendsList = (users: User[]) => {
    storeParticipant(users.map(user => ({name: user.full_name, contact: user.username})))
  }

  return {
    cancelDialog,
    payload,
    setPayload,
    errors,
    setErrors,
    username,
    setUsername,  
    inputBCR,
    setInputBCR,
    ref,
    userSelected,
    setUserSelected,
    storeParticipant,
    isLoading,
    USERNAME_TAB,
    PHONE_NUMBER_TAB,
    EMAIL_TAB,
    tabValue,
    setTabValue,
    resetErrors,
    submitForm,
    onTabChange,
    showDialog,
    addParticipantViaFriendsList
  }
}

const AddParticipantModal = ({ 
  title = 'Add participant',
  storeParticipant, 
  isLoading = false 
}: AddParticipantModalProps) => {
  const {
    cancelDialog,
    payload,
    setPayload,
    errors,
    username,
    setUsername,  
    inputBCR,
    setInputBCR,
    ref,
    userSelected,
    setUserSelected,
    onTabChange,
    tabValue,
    resetErrors,
    submitForm,
    showDialog,
    addParticipantViaFriendsList
  } = useAddParticipantModal({ storeParticipant, isLoading })

  useEffect(() => {
    setTimeout(() => {
      setInputBCR(ref.current?.getBoundingClientRect())
    }, 300)
  }, [userSelected, ref, setInputBCR])

  const selectFromFriendsList = () => {
    cancelDialog()
    setTimeout(() => {
      showDialog({
        dialogBody: (
          <FriendSelector 
            onFriendsSelected={addParticipantViaFriendsList}
          />
        )
      })
    }, 100)
  }

  const usernameForm = (
    <Box>
      {!userSelected && (
        <>
          <FormInputWrapper style={{marginBottom: 10}}>
            <Input 
              name="potential_username"
              error={!!errors.username}
              helperText={errors.username}
              ref={ref}
              size="small" 
              placeholder="Enter name or username" 
              onFocus={resetErrors}
              value={username} 
              onChange={e => setUsername(e.target.value)}
            />
            {username.length > 2 && (
              <UsernameResultsWrapper 
                style={{top: (inputBCR?.top || 0) + 45, left: inputBCR?.left, width: inputBCR?.width}}
                query={username} 
                onClick={(user) => {
                  setUserSelected(user)
                  setUsername('')
                }} 
                renderBody={user => (
                  <Box display="flex" alignItems="center" my={2}>
                    <Avatar src={user.avatar} sx={{width: 20, height: 20}} />
                    <Box ml={1}>
                      <Text lighten>@{user.username}</Text>
                      <Text mr={1}>{user.full_name}</Text>
                    </Box>
                    <Divider />
                  </Box>
                )}
              />
            )}
          </FormInputWrapper>
          <Button 
            variant="contained" 
            center 
            style={{backgroundColor: colors.secondary}}
            onClick={selectFromFriendsList}
          >
            <TextWithIcon color={colors.white} icon={<FriendsIcon />}>
              Add from friends list
            </TextWithIcon>
          </Button>
        </>
      )}
      {!!userSelected && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <UserInfo user={userSelected} />
          <CloseIcon fontSize="large" onClick={() => setUserSelected(undefined)} />
        </Box>
      )}
    </Box>
  )

  const phoneNumberForm = (
    <Box>
      <FormInputWrapper style={{marginBottom: 25}}>
        <Input 
          size="small" 
          error={!!errors.name}
          helperText={errors.name}
          onFocus={resetErrors}
          placeholder="Enter name" 
          onKeyDown={e => e.key === "Enter" ? submitForm() : undefined}
          value={payload.name} 
          onChange={e => setPayload({...payload, name: e.target.value})}
        />
      </FormInputWrapper>
      <FormInputWrapper style={{marginBottom: 10}}>
        <PhoneNoInput 
          error={!!errors.contact}
          helperText={errors.contact}
          onKeyDown={e => e.key === "Enter" ? submitForm() : undefined}
          onChange={e => setPayload({...payload, contact: e.target.value})} 
          value={payload.contact}
        />
      </FormInputWrapper>
    </Box>
  )

  const emailForm = (
    <Box>
      <FormInputWrapper style={{marginBottom: 25}}>
        <Input 
          size="small" 
          error={!!errors.name}
          helperText={errors.name}
          onFocus={resetErrors}
          placeholder="Enter name" 
          onKeyDown={e => e.key === "Enter" ? submitForm() : undefined}
          value={payload.name} 
          onChange={e => setPayload({...payload, name: e.target.value})}
        />
      </FormInputWrapper>
      <FormInputWrapper style={{marginBottom: 10}}>
        <Input 
          error={!!errors.contact}
          size="small" 
          onFocus={resetErrors}
          placeholder="Enter Email" 
          helperText={errors.contact}
          value={payload.contact}
          onKeyDown={e => e.key === "Enter" ? submitForm() : undefined}
          onChange={e => setPayload({...payload, contact: e.target.value})} 
        />
      </FormInputWrapper>
    </Box>
  )

  const renderTabs = () => {
    switch (tabValue) {
      case 0:
        return usernameForm
      case 1:
        return phoneNumberForm
      case 2:
        return emailForm
      default:
        return null
    }
  }

  return (
    <Box minWidth={300} width={window.innerWidth - 64} maxWidth={450}>
      <Box px={2} py={1.5}>
        <Text weight="semibold" variant="medium">{title}</Text>
      </Box>
      <Divider />
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        <Tab label={<UsernameIcon fontSize="large" />} />
        <Tab label={<PhoneIcon fontSize="large" />} />
        <Tab label={<MailIcon fontSize="large" />} />
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      <Box p={2}>
        {renderTabs()}
      </Box>
      <Divider />
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" mr={1} loading={isLoading} onClick={submitForm}>
          <Text color={colors.white}>Add</Text>
        </Button>
        <Button variant='outlined' onClick={cancelDialog}>
          <Text>Cancel</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default AddParticipantModal
