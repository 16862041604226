import React, { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { routes } from '../../utils/constants'
import { AuthLayoutWrapper } from './AuthIndex/Auth.styles'
import Auth from '../../utils/auth'

const AuthLayout = () => {
  const navigate = useNavigate()
  const [loadAuth, setLoadAuth] = useState(false)

  useEffect(() => {
    Auth.isLoggedIn() ? navigate(routes.DASHBOARD) : setLoadAuth(true)
  }, [navigate])


  if (!loadAuth) return null

  return (
    <AuthLayoutWrapper>
      <Container>
        <Link to={routes.LANDING_PAGE}>
          <Box display="flex" justifyContent="center" p={3}>
            <img 
              src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" 
              alt="gusiberi logo" 
              height={38} />
          </Box>
        </Link>
        <Outlet />
      </Container>
    </AuthLayoutWrapper>
  )
}

export default AuthLayout
