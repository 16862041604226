import styled from "styled-components";


export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`
