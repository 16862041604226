import { useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useAvailabilityQuery, useProfileQuery } from '../../../services/api'
import { DateDetails } from './components/AddDetails'
import { dateIsEqual, generateRandomNumber, timeslotMatchesDate } from '../../../utils/helpers'
import { DateSchedule, DateTimeslot } from '../../../utils/types'


type IUseAddDetails = {
  setCurrentStep: (step: string) => void,
  nextStep: string
}

const fullDayTimeslot = (): DateTimeslot => {
  return {
    id: generateRandomNumber(2),
    recurring: false,
    start_time: DateTime.now().set({hour: 0, minute: 0}).toISO(),
    end_time: DateTime.now().set({hour: 23, minute: 59}).toISO()
  }
}

export const useAddDetails = ({ setCurrentStep, nextStep }: IUseAddDetails) => {
  const [formData, setFormData] = useState<DateDetails>({} as DateDetails)
  const onDetailsAdded = (data: DateDetails) => {
    setFormData(data)
    setCurrentStep(nextStep)
  }
  return { formData, setFormData, onDetailsAdded }
}

export const usePlanDate = () => {
  const DATE = 'DATE'
  const ADD_DETAILS = 'ADD_DETAILS'
  const SEND_REQUEST = 'SEND_REQUEST'
  const CONFIRMATION_PAGE = 'CONFIRMATION_PAGE'
  const STEPS = [DATE, ADD_DETAILS, SEND_REQUEST, CONFIRMATION_PAGE]
  const [currentStep, setCurrentStep] = useState(STEPS[0])

  return {
    currentStep,
    setCurrentStep,
    DATE,
    ADD_DETAILS,
    SEND_REQUEST,
    CONFIRMATION_PAGE 
  }
}


export const useSelectDateAndTime = (currentUsername?: string) => {
  const [selectedTimeslot, setSelectedTimeslot] = useState<null | number>(null)
  const [selectedDate, setSelectedDate] = useState<null| undefined | DateTime>(DateTime.now())

  const params = useParams()
  const username = currentUsername || params.username || ''

  const { data } = useProfileQuery({ username })
  const { data: availability, isLoading } = useAvailabilityQuery({ username })

  const unavailability = availability?.payload?.unavailable
  const schedules = availability?.payload?.schedule

  const { user } = data?.payload || {}
 
  const shouldBeDisabled = (date: DateTime) => {
    const now = DateTime.now()
    const dateInPast = date.year <= now.year && date.month <= now.month && date.day < now.day

    return (!timeSlotsByDate(date).length || dateInPast)
  }

  const timeSlotsByDate = useCallback((date?: DateTime | null) => {
    if (!date) return []

    const noUnavailableSlotsInDay = (date: DateTime) => {
      return !unavailability?.find(u => dateIsEqual(u.start_date, date))
    }

    const filterOutBookedSlotsFromTimeslots = (slotDay?: DateSchedule): DateTimeslot[] => {
      if (!slotDay || !slotDay?.date_schedule_timeslots) return []
      return slotDay.date_schedule_timeslots
        .filter(slot => !unavailability?.find(u => timeslotMatchesDate(u.start_date,  slot.start_time)))
    }

    const slotDay = (schedules || []).find(s => s.day_of_the_week === date.weekday)
    if (!slotDay?.active) return []
    
    if (slotDay.active && !slotDay?.date_schedule_timeslots?.length && noUnavailableSlotsInDay(date)) {
      return [fullDayTimeslot()]
    } else {
      return filterOutBookedSlotsFromTimeslots(slotDay)
    }
  }, [schedules, unavailability])


  const timeSlotsForDate = useMemo(() => {
    return timeSlotsByDate(selectedDate)
  }, [selectedDate, timeSlotsByDate])

  const selectedTimeslotPayload = useMemo(() => {
    const t = timeSlotsForDate.find(e => e.id === selectedTimeslot)
    if (!t) return undefined
    return {
      ...t, 
      start_time: DateTime.fromISO(t.start_time).set({
        year: selectedDate?.year,
        month: selectedDate?.month,
        day: selectedDate?.day
      }).toISO(),
      end_time: DateTime.fromISO(t.end_time).set({
        year: selectedDate?.year,
        month: selectedDate?.month,
        day: selectedDate?.day
      }).toISO()
    }
  }, [timeSlotsForDate, selectedDate, selectedTimeslot])

  const onSelectDate = (date?: DateTime | null) => {
    setSelectedDate(date)
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight)
    }, 400)
  }

  const getAppointmentTimes = () => {
    if (!selectedTimeslotPayload || !selectedDate) return null

    const startTime = DateTime.fromISO(selectedTimeslotPayload.start_time)
    const endTime = DateTime.fromISO(selectedTimeslotPayload.end_time)
  
  
    const appointmentStartDate = selectedDate?.set({
      hour: startTime.hour,
      minute: startTime.minute,
      second: startTime.second 
    }) 
  
    const appointmentEndDate = selectedDate?.set({
      hour: endTime.hour,
      minute: endTime.minute,
      second: endTime.second
    })

    return { appointmentStartDate, appointmentEndDate }
  }

  return {
    selectedTimeslot,
    selectedTimeslotPayload,
    selectedDate,
    setSelectedDate,
    setSelectedTimeslot,
    data,
    availability,
    isLoading,
    shouldBeDisabled,
    timeSlotsForDate,
    user,
    onSelectDate,
    getAppointmentTimes
  }
}