import React from 'react'
import Box from '@mui/material/Box'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { AvailabilityWrapper, Note } from './Availability.styles'
import Text from '../../../components/Text'
import content from './content'
import InfoIcon from '@mui/icons-material/InfoRounded';
import TextWithIcon from '../../../components/TextWithIcon'
import { PageWrapper, colors } from '../../../styles/_var'
import Schedule from './Schedule'
import { animateComponent } from '../../../utils/hoc'

const AnimatedHeader = animateComponent(() => {
  return (
    <Box>
      <Text mt={1}>{content.instructions}</Text>
      <Note mt={3}>
        <TextWithIcon 
          icon={<InfoIcon fontSize="large" />} 
          color={colors.white} 
          textSpacing={{ml: 1}}
          weight="semibold"
        >
          {content.note}
        </TextWithIcon>
      </Note>
    </Box>
  )
}, true)

const AnimatedBody = animateComponent(() => {
  return <Schedule />
})

const Availability = () => {
  useSetHeaderTitle('Availability')

  return (
    <AvailabilityWrapper>
      <MobileHeader />
      <PageWrapper>
        <Box px={2} py={1}>
          <AnimatedHeader />
          <AnimatedBody />
        </Box>
      </PageWrapper>
    </AvailabilityWrapper>
  )
}

export default Availability
