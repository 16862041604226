import styled from "styled-components";
import { colors, device, spacing } from "../../../styles/_var";
import Input from "../../../components/Input";


export const FriendsWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    padding-bottom: ${spacing.xs};
    margin-top: ${spacing.sm};
  }
`

export const SearchInput = styled.div`
  svg {
    color: ${colors.grey};
  }

  input:focus + svg {
    color: ${colors.primary};
  }
`

export const AddByEmailInput = styled(Input)`
  & .MuiInputBase-root {
    height: 45px;
  }
`