import React, { useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddRounded';
import Text from '../../../components/Text';
import { colors } from '../../../styles/_var';
import { DAYS_OF_THE_WEEK, DAY_OF_THE_WEEK, DateScheduleResponse } from '../../../utils/types';
import { ScheduleItem, Times } from './Availability.styles';
import { useSchedule } from './Availability.hooks';
import ScheduleTimeItem from './ScheduleTimeItem';
import Collapse from '@mui/material/Collapse';
import { Chip } from '../DateRequests/components/styles';
import { isEmpty } from '../../../utils/helpers';
import { useAvailabilityTour } from '../../../hooks/apptours';



type ScheduleRowProps = {
  day: DAY_OF_THE_WEEK
  data: DateScheduleResponse
  addSchedule: (day: DAY_OF_THE_WEEK) => JSX.Element
  onCheckBoxClicked: (day: DAY_OF_THE_WEEK) => (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  removeSchedule: (scheduleId: number, day: DAY_OF_THE_WEEK) => () => void
}


const ScheduleRow = ({
  day,
  data,
  addSchedule,
  onCheckBoxClicked,
  removeSchedule
}: ScheduleRowProps) => {
  const [collapsed, setCollapsed] = useState(true)

  if (!data?.[day]) return null

  const labelId = `checkbox-list-label-${day}`
  const slotsCount = data[day]?.date_schedule_timeslots.length
  const hasSlots = !!slotsCount
  const disabled = !data[day]?.active


  return (
    <ScheduleItem key={day}>
      <ListItem
        key={day}
        secondaryAction={data[day].active ? addSchedule(day) : <Text variant='subtext' color={colors.grey}>Deactivated</Text>}
        disablePadding
      >
        <ListItemButton 
          role={undefined} 
          onClick={() => setCollapsed(!collapsed)} 
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={!!data[day]?.active}
              tabIndex={-1}
              disableRipple
              onChange={onCheckBoxClicked(day)}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <Text mt={2} mb={2} useDiv style={{display: "flex", alignItems: "center"}}>
            {day} {hasSlots && <Chip ml={1} $small>{slotsCount}</Chip>}
          </Text>
        </ListItemButton>
      </ListItem>
      {disabled ? (
        <Text center mb={2} color={colors.secondary} weight="semibold">
          You won't receive any date requests on this day.
        </Text>
      ) : hasSlots && (
        <Times disabled={!data[day]?.active} style={{paddingBottom: collapsed ? 0 : undefined}}>
          {data[day].date_schedule_timeslots?.map((schedule, i, arr) => (
            <Collapse in={!collapsed} key={i}>
              <ScheduleTimeItem
                dateSchedule={schedule}
                isLast={i === arr.length - 1}
                onDeleteSchedule={removeSchedule(schedule.id, day)}
              />
            </Collapse>
          ))}
        </Times>
      )}
      <Divider />
    </ScheduleItem>
  );
}


const Schedule = React.memo(() => {
  const { 
    data,
    onAddSchedule,
    onCheckBoxClicked,
    removeSchedule
  } = useSchedule()
  useAvailabilityTour()

  if (isEmpty(data)) return null

  const addSchedule = (day: DAY_OF_THE_WEEK) => (
    <IconButton edge="end" aria-label="add schedule" onClick={onAddSchedule(day)}>
      <AddIcon fontSize="large" sx={{color: colors.primary}} className="jr-add-schedule" />
    </IconButton>
  )

  return (
    <Box>
      <List>
        {DAYS_OF_THE_WEEK.map((day, i) => (
          <ScheduleRow 
            data={data}
            key={i}
            day={day}
            addSchedule={addSchedule}
            removeSchedule={removeSchedule}
            onCheckBoxClicked={onCheckBoxClicked}
          />
          )
        )}
      </List>
    </Box>
  )
})

export default Schedule
