import React, { FunctionComponent } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { PublicUser, User } from '../../utils/types'
import { formatPhoneNumber, isEmail, isPhoneNo, stringAvatar } from '../../utils/helpers'
import Avatar from '../Avatar/Avatar'
import Text, { TextVariant, TextWeight } from '../Text'
import { colors } from '../../styles/_var'


interface UserInfoProps extends BoxProps {
  user: User | PublicUser
  gotoProfileOnClick?: boolean
  onlyShowName?: boolean
  nameFirst?: boolean
  titleTextVariant?: TextVariant,
  titleTextWeight?: TextWeight,
  useSmallAvatar?: boolean,
  avatarOnly?: boolean,
  onlyFirstName?: boolean
}

export const UserInfo: FunctionComponent<UserInfoProps> = ({ 
  user,
  gotoProfileOnClick=false,
  onlyShowName=false,
  nameFirst=false,
  titleTextVariant="medium",
  titleTextWeight="regular",
  useSmallAvatar,
  avatarOnly,
  onlyFirstName,
  ...rest
}) => {
  const name = onlyFirstName ? user?.full_name.split(' ')[0] : user?.full_name
  const title = (user.username && !onlyShowName && !nameFirst) ? `@${user.username}` : name
  const style = {cursor: gotoProfileOnClick ? 'pointer' : undefined, ...(rest.style || {})}
  const avatarStyle = {color: colors.black} as React.CSSProperties

  if (useSmallAvatar) {
    avatarStyle.width = '28px'
    avatarStyle.height = '28px'
  }

  const body = (
    <Box display="flex" alignItems="center" {...rest} style={style}>
      <Avatar 
        style={avatarStyle} 
        src={user.avatar} 
        addBorder={false} 
        {...stringAvatar(user?.full_name)} 
      />
      {!avatarOnly && (
        <Box ml={1}>
          <Text variant={titleTextVariant} weight={titleTextWeight}>
            {title}
          </Text>
          {(!!user.username && !onlyShowName && !nameFirst) && <Text variant="small" lighten left>{name}</Text>}
          {(nameFirst && !!user.username) && <Text variant='small' lighten left>{formatPhoneNumber(user.username)}</Text>}
        </Box>
      )}
    </Box>
  )

  const usernameValid = !isPhoneNo(user.username) && !isEmail(user.username)

  return (gotoProfileOnClick && user.username && usernameValid) ? <Link style={{flex: 1}} to={`/${user.username}`}>{body}</Link> : body
}