import React, { useRef, useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from 'react-router-dom';
import { DashboardHeaderContent, DashboardHeaderWrapper, SearchResults } from './DashboardHeader.styles'
import Text from '../Text'
import TextWithIcon from '../TextWithIcon'
import Input from '../Input'
import Avatar from '../Avatar/Avatar';
import { colors } from '../../styles/_var';
import { useSelector } from 'react-redux';
import { selectHeader, selectShowBackButton } from '../../stores/uiStore';
import { selectCurrentUser } from '../../stores/authStore';
import { routes } from '../../utils/constants';


const DashboardHeader = () => {
  const navigate = useNavigate()
  const header = useSelector(selectHeader)
  const showBackButton = useSelector(selectShowBackButton)
  const currentUser = useSelector(selectCurrentUser)
  const ref = useRef<HTMLDivElement>(null)
  const [query, setQuery] = useState('')
  const menuIsOpen = query.trim().length > 2;

  return (
    <DashboardHeaderWrapper>
      <Container>
        <DashboardHeaderContent>
          {!showBackButton && (
            <Text variant='medium' weight="semibold" mt={2} mb={2}>
              {header}
            </Text>
          )}
          {showBackButton && (
            <TextWithIcon
              style={{width: 'fit-content'}}
              onClick={() => navigate(-1)}
              icon={<LeftIcon />}
              variant='medium'
              weight="semibold"
              mt={2}
              mb={2}
            >
              {header}
            </TextWithIcon>
          )}
          <Box display="flex" alignItems="center" minWidth={300}>
            <Input
              name="find_gusiberi_user"
              value={query}
              onChange={e => setQuery(e.target.value)}
              ref={ref}
              size="small"
              placeholder="Search..."
              mr={2}
              InputProps={{endAdornment: <SearchRoundedIcon />}} 
            />
            <Avatar 
              src={currentUser.avatar} 
              sx={{width: 35, height: 35, cursor: 'pointer'}} 
              onClick={() => navigate(routes.ACCOUNT_SETTINGS)}
            >
              <Text color={colors.white} uppercase>{currentUser?.username?.[0]}</Text>
            </Avatar>
            {menuIsOpen && <SearchResults query={query} />}
          </Box>
        </DashboardHeaderContent>
      </Container>
    </DashboardHeaderWrapper>
  )
}

export default DashboardHeader
