import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Text from '../../../components/Text';
import Divider from '@mui/material/Divider';
import { useIsMobile } from '../../../hooks/hooks';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../utils/constants';
import { CustomLink } from '../../../components/Button/Button';

interface LinkTabProps {
  label?: string | React.ReactNode;
  href?: string;
}


export const LinkTab = (props: LinkTabProps) => {
  return (
    <Tab
      sx={{minWidth: '85px', textTransform: 'capitalize'}}
      LinkComponent={CustomLink}
      {...props}
    />
  );
}

const SettingsNav = React.memo(() => {
  const tabs = [
    {label: 'Profile', route: routes.ACCOUNT_SETTINGS},
    {label: 'Activities', route: routes.ACCOUNT_ACTIVITIES},
    {label: 'Security', route: routes.ACCOUNT_SECURITY},
    // {label: 'Billing', route: routes.ACCOUNT_BILLING},
    {label: 'Support', route: routes.ACCOUNT_SUPPORT},
  ]
  const location = useLocation()
  const isMobile = useIsMobile('sm')
  const [value, setValue] = useState(tabs.findIndex(e => e.route === location.pathname));
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        centered={!isMobile}
        variant={isMobile ? "scrollable" : "fullWidth"}
        onChange={handleChange}
        aria-label="settings nav"
      >
        {tabs.map((tab, i) => (
          <LinkTab 
            label={(
              <Text
                variant="subtext"
                mt={1}
                mb={1}
                weight={value === i ? "semibold" : "regular"}
              >
                {tab?.label}
              </Text>
            )}
            href={tab?.route}
            key={i} 
          />
        ))}
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
    </Box>
  )
})

export default SettingsNav
