import React, { FunctionComponent } from 'react'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import Button from '../Button/Button'
import { InputAndButtonWrapper } from './InputAndButton.styles'
import { ButtonProps } from '@mui/material/Button'

type InputAndButtonProps = {
  inputProps?: InputBaseProps,
  buttonProps?: ButtonProps
}

const InputAndButton: FunctionComponent<InputAndButtonProps> = ({
  inputProps={},
  buttonProps={}
}) => {
  return (
    <InputAndButtonWrapper>
      <InputBase {...inputProps} />
      <Button variant='contained' {...buttonProps} />
    </ InputAndButtonWrapper>
  )
}

export default InputAndButton