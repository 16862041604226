import React, { useEffect } from 'react'
import AboutSection from './AboutSection'
import ComingSoon from './ComingSoon'
import Hero from './Hero'
import HomePageNav from './HomePageNav'
import HowItWorks from './HowItWorks'
import Footer from '../../components/Footer/Footer'
import { HomePageWrapper } from './HomePage.styles'
import { useAuthStore } from '../../stores/authStore'
import Auth from '../../utils/auth'

const HomePage = () => {
  const { loadUser, isLoggedIn, failedToLoadUser } = useAuthStore()
  useEffect(() => {
    Auth.hasToken() 
    && !isLoggedIn 
    && !failedToLoadUser
    && loadUser()
  }, [loadUser, isLoggedIn, failedToLoadUser])

  return (
    <HomePageWrapper>
      <HomePageNav />
      <Hero />
      <AboutSection />
      <HowItWorks />
      <ComingSoon />
      <Footer />
    </HomePageWrapper>
  )
}

export default HomePage
