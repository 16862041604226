import React, { FunctionComponent, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box';
import { useLazySearchUsersQuery } from '../../services/api';
import { debounce } from '@mui/material';
import SearchLoading from './SearchLoading';
import ResultList from './ResultList';
import Drawer from '../Drawer/Drawer';

type SearchDrawerProps = {
  query: string,
  isOpen: boolean,
  onClose: () => void,
}

const SearchDrawer: FunctionComponent<SearchDrawerProps> = React.memo(({
  query,
  isOpen,
  onClose
}) => {
  const [searchUsers, { isFetching, isSuccess, data }] = useLazySearchUsersQuery()
  const runSearch = useMemo(() => debounce(query => {
    searchUsers({ query })
  }, 900), [searchUsers])
  const invalidInput = useMemo(() => query.trim() && query.length < 3, [query])

  useEffect(() => {
    if (invalidInput) return
    runSearch(query)
  }, [runSearch, query, invalidInput])

  const heightOfHeaderMenu = 200
  const drawerHeight = window.innerHeight - heightOfHeaderMenu
  const results = useMemo(() => {
    if (invalidInput || !data?.payload) return []
    return data?.payload
  }, [data, invalidInput])

  return (
    <Drawer
      disableEnforceFocus
      hideBackdrop
      anchor="bottom"
      open={isOpen}
      onOpen={() => {}}
      onClose={onClose}
      header="Search Results"
    >
      <Box>
        <Box height={drawerHeight}>
          {isFetching && <SearchLoading amount={3} />}
          {(isSuccess && data) && <ResultList results={results} emptyResultText='No Results' />}
        </Box>
      </Box>
    </Drawer>
  )
})

export default SearchDrawer
