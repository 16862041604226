import React, { FunctionComponent } from 'react'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DateTime } from 'luxon';

type CalendarProps = {
  shouldBeDisabled?: (date: DateTime) => boolean,
  selectedDate?: DateTime | null,
  onSelectDate?: (date: DateTime | null | undefined) => void
}

const Calendar: FunctionComponent<CalendarProps> = ({
  shouldBeDisabled,
  selectedDate,
  onSelectDate
}) => {
  return (
    <StaticDatePicker
      shouldDisableDate={shouldBeDisabled}
      displayStaticWrapperAs="desktop"
      openTo="day"
      value={selectedDate}
      onChange={onSelectDate} 
    />
  )
}

export default Calendar