import TextField from '@mui/material/TextField'
import styled from "styled-components"
import { size } from '../../styles/_var'

export const InputWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 16px;
`

export const StyledInput = styled(TextField)`
  width: 100%;
  @media (max-width: ${size.md}) {
    & input {
      font-size: 16px;
    }
  
    & input::placeholder {
      font-size: 16px !important;
    }
  }
`
