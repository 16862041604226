import styled from 'styled-components'
import { colors, spacing } from '../../styles/_var'
import HeaderMenu from '../MobileHomeHeader/HeaderMenu'

export const SIDEBAR_WIDTH = '24rem'

export const SideBarLogo = styled.div`
  margin-top: ${spacing.xs};
  display: flex;
  justify-content: center;
`

export const SidebarWrapper = styled.div`
  background-color: ${colors.lightergrey};
  width: ${SIDEBAR_WIDTH};
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
`

export const SidebarContent = styled.div`
  margin-top: ${spacing.sm};
  display: flex;
  flex-direction: column;
`

export const SidebarItem = styled.div<{$selected?: boolean}>`
  padding: ${spacing.xxs} !important;
  display: flex !important;
  width: 100%;
  justify-content: flex-start !important;
  transition: all ease .2s;


  svg {
    font-size: 2.3rem !important;
    height: 3.1rem;
    margin-left: ${spacing.sm};
  }

  p {
    margin-left: ${spacing.xs};
  }

  &:hover {
    background: ${colors.lightGreen};
    p {
      font-family: 'Gilroy-semi-bold';
    }
  }

  ${({ $selected }) => $selected && `
    p {
      font-family: 'Gilroy-semi-bold';
    }

    background: ${colors.lightGreen} !important;
  `}
`

export const SidebarItemFooter = styled(SidebarItem)`
  position: fixed !important;
  bottom: ${spacing.xs};
  width: ${SIDEBAR_WIDTH};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const MoreButton = styled.div`
  justify-content: flex-start !important;
`

export const MoreMenu = styled(HeaderMenu)`
  svg {
    margin-left: 1.5rem;
  }
`