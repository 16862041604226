import { useEffect } from "react"
import { useParams } from "react-router-dom"
import Box from '@mui/material/Box'
import Text from '../../../components/Text'
import { useSetHeaderTitle } from "../../../hooks/hooks"
import { useLazyFetchDateRequestQuery } from "../../../services/api"
import { useUiStore } from "../../../stores/uiStore"
import { LoginButtons } from "../../Auth/AuthIndex/Auth"
import Auth from "../../../utils/auth"

export const useDatePageRequest = (headerTitle?: string) => {
  useSetHeaderTitle(headerTitle || 'Activity Details', true)
  const { date_id } = useParams()
  const { showDialog, cancelDialog, setHeaderTitle } = useUiStore()
  const [fetchDate, { data, isLoading, isError, isSuccess }] = useLazyFetchDateRequestQuery()

  useEffect(() => {
    if (date_id) fetchDate({ request_id: date_id })
  }, [date_id, fetchDate])

  useEffect(() => {
    if (isSuccess && data?.payload.activity) {
      setHeaderTitle(data?.payload.activity)
    }
  }, [
    isSuccess, 
    setHeaderTitle, 
    data?.payload.activity
  ])

  useEffect(() => {
    if (isSuccess && !Auth.isLoggedIn()) {
      showDialog({
        onDialogClose: () => {},
        dialogBody: (
          <Box p={2} minWidth={270}>
            <Text mb={2} weight="semibold" center>
              Please Register or Sign in to continue.
            </Text>
            <LoginButtons onClickButtons={cancelDialog}  />
          </Box>
        )
      })
    }
  }, [isSuccess, cancelDialog, showDialog])

  return {
    data,
    isLoading,
    isError,
    date_id
  }
}
