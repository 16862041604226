import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import content from './content'
import mobileSample from '../../assets/images/mobile-sample.png'
import mobileSampleSmall from '../../assets/images/mobile-sample-small.png'
import appstore from '../../assets/images/appstore.png'
import playstore from '../../assets/images/playstore.png'
import { ComingSoonContent, ComingSoonImage, HowItWorksContainer } from './HomePage.styles'
import Text from '../../components/Text'
import InputAndButton from '../../components/InputAndButton/InputAndButton'
import { colors, spacing } from '../../styles/_var'
import { useIsMobile } from '../../hooks/hooks'

const ComingSoon = () => {
  const isMobile = useIsMobile()


  return (
    <Container style={{marginTop: !isMobile ? '15rem' : undefined}}>
      <HowItWorksContainer>
        <ComingSoonContent>
          {isMobile && <ComingSoonImage src={mobileSampleSmall} alt="coming-soon-mobile" height={300} />}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5}>
              <Text mt={!isMobile ? undefined : 2} left={!isMobile} maxWidth={400} variant="semi-large" weight={400}>
                {content.coming_soon_text}
              </Text>
              <Text mt={3} mb={3}>{content.coming_soon_desc}</Text>
              <InputAndButton 
                buttonProps={{
                  children: <Text color={colors.white}>{content.submit}</Text>
                }}
                inputProps={{
                  placeholder: content.email_placeholder
                }}
              />
              <Text uppercase weight={400} mt={3} mb={1}>
                {content.coming_soon}
              </Text>
              <Box>
                <img src={appstore} alt="app store" height="40px" width="auto" style={{marginRight: spacing.xs}} />
                <img src={playstore} alt="play store" height="40px" width="auto" />
              </Box>
            </Grid>
            {!isMobile && (
              <Grid item xs={7} style={{position: 'relative'}}>
                <ComingSoonImage
                  src={mobileSample}
                  alt="coming-soon-mobile"
                  height={500}
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  }} />
              </Grid>
            )}
          </Grid>
        </ComingSoonContent>
      </HowItWorksContainer>
    </Container>
  ) 
}

export default ComingSoon
