import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useHideFooterOnMount, useProcessError } from '../../../hooks/hooks'
import HomePageNav from '../../HomePage/HomePageNav'
import { ActivateAccountWrapper } from './ActivateAccount.styles'
import Text from '../../../components/Text'
import Card from '@mui/material/Card'
import { colors, spacing } from '../../../styles/_var'
import { animateComponent } from '../../../utils/hoc'
import { useSearchParams } from 'react-router-dom'
import { useActivateUserMutation, useResendActivationMutation } from '../../../services/api'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useUiStore } from '../../../stores/uiStore'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuthStore } from '../../../stores/authStore'
import { routes } from '../../../utils/constants'
import { retrieveErrorMessage } from '../../../utils/helpers'
import SimpleFooter from '../../../components/SimpleFooter/SimpleFooter'

const ActivateAccountBody = animateComponent(() => {
  const processError = useProcessError()
  const { openSuccessToast } = useUiStore()
  const [resendActivationCode, { data, isLoading }] = useResendActivationMutation()
  const resendLink = async () => {
    try {
      await resendActivationCode({}).unwrap()
      openSuccessToast({message: data?.message || ''})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }


  return (
    <Card style={{ 
      width: "80%", 
      maxWidth: 350,
      marginLeft: "auto", 
      marginRight: "auto",
      marginTop: spacing.sm,
      padding: spacing.xs,
    }}>
      <Text center variant="medium" weight="semibold" mb={1}>
        Activate Your Account
      </Text>
      <Text center mb={1}>
        You account has not been activated, check your <strong>inbox</strong> or <strong>spam</strong> folder for your account activation link.
      </Text>
      <Text variant="subtext" center>
        Can't find your link? <strong style={{cursor: 'pointer'}} onClick={resendLink}>{isLoading ? 'Resending' : 'Resend'} Activation Link</strong>
      </Text>
    </Card>
  )
})

const ActivatingAccount = ({code}: {code: string} ) => {
  const { openSuccessToast } = useUiStore()
  const processError = useProcessError()
  const [errorMessage, setErrorMessage] = useState('')
  const [activateUser, { isLoading: isActivating, isSuccess, isError }] = useActivateUserMutation()

  const [resendActivationCode, { data, isLoading }] = useResendActivationMutation()
  const resendLink = async () => {
    try {
      await resendActivationCode({}).unwrap()
      openSuccessToast({message: data?.message || ''})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const performActivation = async () => {
    try {
      const response = await activateUser({activation_code: code}).unwrap()
      openSuccessToast({message: response.message})
      setTimeout(() => {
        window.location.href = routes.DASHBOARD
      }, 2000)
    } catch (e) {
      setErrorMessage(retrieveErrorMessage(e))
    }
  }

  useEffect(() => {
    performActivation()
    // eslint-disable-next-line
  }, [])

  return (
    <Box mt={3}>
      {isActivating && (
        <Box display="flex" mt={4} p={1} flexDirection="column" alignItems="center">
          <CircularProgress />
          <Text center mt={1}>We are activating your account...</Text>
        </Box>
      )}
      {isSuccess && <Text center>Your account has been activated! Redirecting to dashboard...</Text>}
      {isError && (
        <Box display="flex" p={1} flexDirection="column" alignItems="center">
          <Text center mt={1} color={colors.danger} weight="semibold">
            {errorMessage}
          </Text>
          <Text variant="subtext" center onClick={resendLink} underline={!isLoading}>
            {isLoading ? 'Resending' : 'Resend'} Activation Link
          </Text>
        </Box>
      )}
    </Box>
  )
}

const ActivateAccount = () => {
  useHideFooterOnMount()
  const { loadUser, isLoadingUser } = useAuthStore()
  const [searchParams] = useSearchParams()
  const activationCode = searchParams.get('activation_code')
  
  useEffect(() => {
    loadUser().then(({ userDetails }) => {
      if (userDetails?.user?.activated) window.location.href = routes.DASHBOARD
    })
    // eslint-disable-next-line
  }, [])
  
  return (
    <ActivateAccountWrapper>
      <HomePageNav disabled />
      {isLoadingUser && (
        <Box display="flex" mt={4} p={1} flexDirection="column" alignItems="center">
          <CircularProgress />
          <Text center mt={1}>Processing your request...</Text>
        </Box>
      )}
      {!isLoadingUser && (
        <>
          {!activationCode && <ActivateAccountBody />}
          {!!activationCode && <ActivatingAccount code={activationCode} />} 
        </>
      )}
      <SimpleFooter />
    </ActivateAccountWrapper>
  )
}

export default ActivateAccount
