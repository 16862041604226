import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import gusiberiApi from './services/api'
import { authReducer } from './stores/authStore'
import { availabilityReducer } from './stores/availabilityStore'
import { uiReducer } from './stores/uiStore'
import Auth from './utils/auth'
import { routes } from './utils/constants'
import { datesReducer } from './stores/datesStore'
import { probablyBadToken } from './utils/helpers'

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const status = action?.payload?.status
      const data = action?.payload?.data

      if (
        (status === 401 && (data?.message?.includes('token') || data?.message?.includes('Signature')))
        || probablyBadToken(action?.payload)
      ) {
        Auth.logUserOut()
        window.location.href = routes.AUTH
      }
    }

    return next(action)
  }

export const store = configureStore({
  reducer: {
    [gusiberiApi.reducerPath]: gusiberiApi.reducer,
    schedule: availabilityReducer,
    auth: authReducer,
    ui: uiReducer,
    dates: datesReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['ui/openConfirmDialog', 'ui/openDialog'],
      ignoredPaths: [
        'ui.confirmationDialogPayload.onConfirmAction',
        'ui.dialogPayload.dialogBody',
        'ui.dialogPayload.onDialogClose',
        'ui.confirmationDialogPayload.onCancelAction'
      ]
    }
  }).concat([gusiberiApi.middleware, rtkQueryErrorLogger])
})

setupListeners(store.dispatch)


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
