import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PageWrapper, colors, device, spacing } from '../../styles/_var'

export const IconWrapper = styled(Link)`
  background: ${colors.icon};
  padding: ${spacing.xxs};
  border-radius: 50%;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  margin-right: ${spacing.xs};
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: white;
  }
`

export const ActivityWrapper = styled.div`
  border-top: solid 1px ${colors.borderColor};
  padding: ${spacing.xs} 0;
`

export const ProfilePageWrapper = styled(PageWrapper)`
  @media ${device.md} {
    max-width: 60rem;
    padding: ${spacing.sm};
    border-radius: 1rem;
  }
`
