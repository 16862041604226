import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import FrownIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box'
import SimpleForm from '../../../../components/SimpleForm'
import Text from '../../../../components/Text'
import { usePlanActivity, CreateActivityFormValues } from '../CreateActivity/CreateActivity.Hooks'
import { useDatePageRequest } from '../../DateRequests/DateRequestPage.hooks'
import MobileHeader from '../../../../components/MobileHeader/MobileHeader'
import ImageUpload from '../../../../components/ImageUpload'
import { ActivityBackDrop } from '../CreateActivity/CreateActivity.styles'
import { LoadingSkeleton } from '../../DateRequests/DateRequestInfo';
import TextWithIcon from '../../../../components/TextWithIcon';
import { PageWrapper, colors } from '../../../../styles/_var';


const UpdateActivity: FunctionComponent = () => {
  const { data, isLoading, isError, date_id } = useDatePageRequest("Update Activity")
  const {
    formFields, 
    actionButton, 
    setBackdropImage, 
    updateActivityRequest
  } = usePlanActivity({ editMode: true })
  if (!date_id) return null

  if (!data) {
    return (
      <Container>
        <Box mt={3}>
          {isLoading && <LoadingSkeleton />}
          {(isError && !data) && (
            <TextWithIcon icon={<FrownIcon sx={{color: colors.danger}} />} justifyContent="center" color={colors.danger}>
              Failed to load
            </TextWithIcon>
          )}
        </Box>
      </Container>
    )
  }

  const isPrivateDate = data.payload.date_type === "private_date"
  const privateDateFormFields = formFields.filter(field => {
    return field.name !== "scoreboard_enabled" && field.name !== "buck_breaker_enabled"
  })


  return (
    <PageWrapper>
      <MobileHeader />
      {!isPrivateDate && (
          <ImageUpload
            showIcon
            aspectRatio={3/1}
            onImageChange={setBackdropImage}
            contentArea={({ openFileChooser, croppedImage }) => {
              return (
                <ActivityBackDrop backdrop={croppedImage || data.payload?.backdrop_image} onClick={openFileChooser} />
              )
            }}
          />
      )}
      <Box mt={2} pl={3} pr={3}>
        <Text variant="medium" weight="semibold" mb={2}>Update Activity Details</Text>
        <SimpleForm<CreateActivityFormValues>
          animateForm
          initialValues={{
            activity: data.payload.activity,
            location: data.payload.location,
            description: data.payload.description,
            start_date: DateTime.fromISO(data.payload.start_date),
            end_date: DateTime.fromISO(data.payload.end_date),
            scoreboard_enabled: data.payload.scoreboard_enabled,
            buck_breaker_enabled: data.payload.buck_breaker_enabled,
          }}
          formFields={isPrivateDate ? privateDateFormFields : formFields}
          actionButtons={actionButton}
          submitForm={formData => updateActivityRequest(formData, data.payload)}
          buttonWrapperClass='fixed-button-bottom' 
        />
      </Box>
    </PageWrapper>
  )
}

export default UpdateActivity
