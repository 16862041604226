import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import { colors, size, spacing } from '../../../../styles/_var';
import Box from '@mui/material/Box';


export const ProfileInfoItem = styled(ListItem)`
  display: flex;
  &.MuiListItem-root {
    justify-content: space-between;
    padding: ${spacing.xs} 0;
  }
`

export const AvatarEdit= styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    .MuiAvatar-root {
      opacity: 0.4;
    }
  }
  
  .MuiAvatar-root {
    transition: opacity ease .2s;
    opacity: 0.8;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`

export const EditFormWrapper = styled(Box)`
  @media(max-width: ${size.sm}) {
    .simple-form-action-buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      left: 0;
      padding: 20px 16px;
      border-top: solid 1px ${colors.borderColor};
      background: ${colors.white};
      z-index: 2;
    }
  }
`