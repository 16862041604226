import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { spacing } from '../../../../styles/_var'
import Text from '../../../../components/Text'
import Switch from '@mui/material/Switch'
import { useAuthStore } from '../../../../stores/authStore'
import { NotificationSettingType } from '../../../../utils/types'
import { useSetNotificationSettingsMutation } from '../../../../services/api'


const NotificationSetting = React.memo(() => {
  const { notificationSettings } = useAuthStore()
  const [setNotification] = useSetNotificationSettingsMutation()

  const onUpdateNotificationSettings = (notificationType: NotificationSettingType, value: boolean) => {
    setNotification({field: notificationType, value})
  }

  return (
    <Paper sx={{marginTop: spacing.sm, padding: spacing.xs}}>
      <Text center weight="semibold" mb={2}>Notifications</Text>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Text>Notify on new requests</Text>
        <Switch 
          checked={!!notificationSettings.received_requests_notification} 
          onChange={(_, data) => onUpdateNotificationSettings('received_requests_notification', data)} 
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Text>Notify on request updates</Text>
        <Switch 
          checked={!!notificationSettings.request_updates}
          onChange={(_, data) => onUpdateNotificationSettings('request_updates', data)} 
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text>Notify on invite response</Text>
        <Switch 
          checked={!!notificationSettings.invite_response_notification} 
          onChange={(_, data) => onUpdateNotificationSettings('invite_response_notification', data)} 
        />
      </Box>
    </Paper>
  )
})

export default NotificationSetting