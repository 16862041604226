import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import { activities } from '../../../../utils/constants';
import Text from '../../../../components/Text';
import Autocomplete from '@mui/material/Autocomplete';
import { spacing } from '../../../../styles/_var';
import { AddButton } from './Activities.styles';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button/Button';
import Chip from '../../../../components/Chip/Chip';
import Alert from '@mui/material/Alert';
import { FavouriteActivity } from '../../../../utils/types';
import { useAddActivity } from './Activities.hooks';
import { useUiStore } from '../../../../stores/uiStore';

export type AddActivityProps = {
  onAdded?: (activity: FavouriteActivity) => void,
  updateData?: FavouriteActivity
}


const AddActivity: FunctionComponent<AddActivityProps> = React.memo(({
  onAdded,
  updateData
}) => {
  const {
    onKeyDown,
    storeChip,
    removeChip,
    onSubmit,
    activity,
    setActivity,
    errorMessage,
    places,
    chips,
    setPlaces,
    isLoading,
    isUpdate
  } = useAddActivity({ onAdded, updateData })
  const { cancelDialog } = useUiStore()

  return (
    <Box
      sx={{padding: spacing.xs}}
      minWidth={280}
    >
      <Text weight="semibold" mb={2} center uppercase>
        {isUpdate ? 'Update' : 'Add'} Activity
      </Text>
      <Alert variant="standard" icon={false}>
        <Text variant='subtext'>
          Help people plan the perfect date with, add your favourite dating activity i.e "Going to restaurants" and your favourite places for this activity i.e "Cactus Restaurant".
        </Text>
      </Alert>
      <Autocomplete
        value={activity}
        onInputChange={(_, value) => setActivity(value || '')}
        size="small"
        freeSolo
        options={activities}
        renderInput={(params) => (
          <Input 
            mt={2}
            error={!!errorMessage}
            {...params}
            placeholder="Favourite dating activity"
            helperText={errorMessage && errorMessage} />
        )}
      />
      <Input
        mt={2}
        mb={2}
        size="small"
        value={places}
        onKeyDown={onKeyDown}
        onChange={e => setPlaces(e.target.value)}
        placeholder="Add places i.e Cactus Restaurant"
        helperText="Add favourite places by pressing enter or clicking the add button"
        InputProps={{
          endAdornment: <AddButton onClick={storeChip}>Add</AddButton>
        }} />
      <Box>
        {chips.map((chip, i) => (
          <Chip mr={2} mb={2} key={i} onClose={() => removeChip(chip)}>
            {chip}
          </Chip>
        ))}
      </Box>
      <Box display="flex" mt={2} justifyContent="center">
        <Button
          loading={isLoading}
          onClick={onSubmit}
          variant="contained"
        >
          {isUpdate ? 'Update' : 'Save'} Activity
        </Button>
        <Button ml={2} variant="outlined" onClick={cancelDialog}>
          Cancel
        </Button>
      </Box>
    </Box>
  )
})

export default AddActivity