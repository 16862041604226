import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Avatar from '../Avatar/Avatar';
import Text from '../Text';
import { colors } from '../../styles/_var';
import { User } from '../../utils/types';
import { ResultItem, ResultWrapper } from './MobileHomeHeader.styles';
import RightIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useIsMobile } from '../../hooks/hooks';

type ResultListProps = {
  results: User[],
  onClick?: (user: User) => void,
  renderBody?: (user: User) => React.ReactNode,
  emptyResultText?: string,
}

const ResultList: FunctionComponent<ResultListProps> = React.memo(({
  results=[],
  onClick,
  renderBody,
  emptyResultText
}) => {
  const isMobile = useIsMobile()
  const renderBodyDefault = (user: User) => (
    <Container>
      <ResultItem>
        <Avatar src={user.avatar} addBorder={false} />
        <Box ml={2} flex={1} maxWidth={isMobile ? undefined : 150}>
          <Text variant='medium' truncate>@{user.username}</Text>
          <Text color={colors.grey} truncate>{user.full_name}</Text>
        </Box>
        <RightIcon fontSize="large" />
      </ResultItem>
    </Container>
  )
  const renderResultsList = () => {
    return results.map((user, i) => onClick ? (
      <div onClick={() => onClick(user)} key={i}>
        {!!renderBody ? renderBody(user) : renderBodyDefault(user)}
      </div>
    ) : (
      <ResultWrapper 
        to={`/${user.username}`} 
        key={i}
      >
        {!!renderBody ? renderBody(user) : renderBodyDefault(user)}
      </ResultWrapper>
    ))
  }

  const renderEmptyResultList = () => {
    if (emptyResultText) {
      return (
        <Container>
          <Text mt={2}>{emptyResultText}</Text>
        </Container>
      )
    }
    return null
  }

  return (
    <>
      {!results.length ? renderEmptyResultList() : renderResultsList()}
    </>
  )
})

export default ResultList
