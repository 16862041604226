import React, { FunctionComponent } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { DateTime } from 'luxon'
import AddButton from '@mui/icons-material/AddCircleOutlined';
import Divider from '@mui/material/Divider'
import TimeIcon from '@mui/icons-material/AccessTimeRounded';
import KeyboardArrowRightRounded from '@mui/icons-material/KeyboardArrowRightRounded'
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { CreateActivityFormValues } from '../CreateActivity/CreateActivity.Hooks'
import Text from '../../../../components/Text'
import TextWithIcon from '../../../../components/TextWithIcon'
import { colors } from '../../../../styles/_var'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../stores/authStore'
import Button from '../../../../components/Button/Button';
import { useUiStore } from '../../../../stores/uiStore';
import AddParticipantModal, { ParticipantFormValues } from './AddParticipantModal';
import { Participant } from '../../../../services/api';
import DateParticipantView from '../../DateRequests/components/DateParticipants/DateParticipantView';
import { generateRandomNumber } from '../../../../utils/helpers';

type AddActivityParticipantProps = {
  details?: CreateActivityFormValues
  participants: Participant[]
  storeParticipant: (participant: Participant[]) => void,
  removeParticipant: (participantId: number) => void,
  saveParticipants: (participants: Array<{name: string, contact: string}>, redirect: boolean) => void
}


const AddActivityParticipant: FunctionComponent<AddActivityParticipantProps> =  ({ 
  details,
  participants,
  storeParticipant,
  removeParticipant,
  saveParticipants
}) => {
  const currentUser = useSelector(selectCurrentUser)
  const { showDialog, cancelDialog } = useUiStore()
  
  if (!details) return null
  const {end_date, start_date} = details

  const fullDate = start_date.toLocaleString(DateTime.DATE_MED)
  const startTime = start_date.toLocaleString(DateTime.TIME_SIMPLE)
  const endTime = end_date.toLocaleString(DateTime.TIME_SIMPLE)
  const onAddParticipantFormSubmitted = (data: ParticipantFormValues[]) => {
    storeParticipant(data.map(d => ({...d, id: generateRandomNumber()})))
    cancelDialog()
  }

  return (
    <>
      <Paper component={Box} mt={2}>
        <Box padding={2}>
          <Text weight="semibold">{details.activity}</Text>
        </Box>
        <Divider />
        <Box padding={2}>
          <Text mb={1} italic>{details.description}</Text>
          <TextWithIcon icon={<TimeIcon />} weight="semibold" mb={1}>
            {startTime} - {endTime}, {fullDate}
          </TextWithIcon>
          <a href={`https://maps.google.com/?q=${details.location}`} target="_blank" rel="noreferrer">
            <TextWithIcon icon={<LocationIcon />} underline link color={colors.link}>
              {details.location}
            </TextWithIcon>
          </a>
        </Box>
      </Paper>
      <Paper component={Box} mt={2} mb={2}>
        <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
          <Text weight="semibold" variant="medium">Participants</Text>
          <Button 
            startIcon={<AddButton />}
            variant="contained"
            onClick={() => showDialog({
              dialogBody:(
                <AddParticipantModal 
                  storeParticipant={onAddParticipantFormSubmitted} />
              )
            })}
          >
            Add Participant
          </Button>
        </Box>
        <Divider />
        <Box pb={1}>
          <DateParticipantView 
            participant={{user: currentUser} as Participant} 
            isCreator />
          <Divider />
          {!participants.length && (
            <Text center mt={1} mb={1} color={colors.danger}>
              You haven't added any participants.
            </Text>
          )}
          {participants.map(p => (
            <DateParticipantView
              hideResend
              key={p.id}
              participant={p} 
              onRemove={() => removeParticipant(p.id)}
            />
          ))}
        </Box>
      </Paper>
      <div className="simple-form-action-buttons fixed-button-bottom">
        <Button 
          disabled={!participants.length}
          fullWidth
          variant="contained" 
          onClick={() => saveParticipants(
            participants.map(p => ({ name: p.name || '', contact: p.contact || ''})),
            false
          )}
          endIcon={<KeyboardArrowRightRounded />}
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default AddActivityParticipant