import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { colors, device, spacing } from "../../../styles/_var";


export const DateRequestWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    padding-bottom: ${spacing.xs};
    margin-top: ${spacing.sm};
  }
`

export const RequestWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
  }
`

export const DateRequestInfoWrapper = styled.div`
  position: relative;
  top: -93px;

  & .MuiPaper-root {
    background: rgba(255, 255, 255, 0.92);
  }
`

export const RequestCardWrapper = styled(Paper)`
  flex-shrink: 0;
  width: 300px;
  margin-right: ${spacing.xs};
  border: solid 1px ${colors.lightgrey};
  border-radius: 2px;
`

export const RequestLabelWrapper = styled(({ type, ...rest}) => <div {...rest} />)`
  text-align: center;
  padding: 5px 0;
  width: 78px;
  border-radius: 8px;
  p {
    color: #808080;
  }

  ${({ type }) => type === 'canceled' && `background-color: ${colors.red};`}
  ${({ type }) => type === 'pending' && `background-color: rgba(117, 119, 12, 0.15);`}
  ${({ type }) => type === 'declined' && `
    background-color: #808080;
    p {
      color: ${colors.white};
    }
  `}
  ${({ type }) => type === 'expired' && `
    background-color: #8C3650;
    p {
      color: ${colors.white};
    }
  `}
  ${({ type }) => type === 'accepted' && `background-color: ${colors.lightGreen};`}
  ${({ type }) => type === 'completed' && `background-color: ${colors.yellow};`}
`

export const BackdropWrapper = styled.div`
  position: relative;
  button, a {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    color: ${colors.white};
    transition: all ease .2s;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }

    top: 3px;

    &#chat-button {
      right: 3px;
    }

    &#edit-button {
      left: 3px;
    }
  }
`