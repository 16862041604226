import React from 'react'
import { animateComponent } from '../../../../utils/hoc'


const Billing = () => {
  return (
    <p>billing component</p>
  )
}

export default animateComponent(Billing, true)