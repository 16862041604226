import styled from 'styled-components'
import Container from '@mui/material/Container'
import { device } from '../../styles/_var'
import { MobileHomeHeaderWrapper } from '../MobileHomeHeader/MobileHomeHeader.styles'

export const MobileHeaderWrapper = styled(MobileHomeHeaderWrapper)`
  @media ${device.md} {
    display: none;
  }
`


export const MobileHeaderContainer = styled(Container)`
  justify-content: space-between;
  align-items: center;

  & img {
    width: 35px;
    height: auto;
  }
`