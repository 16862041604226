import React, { FunctionComponent } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { ReactComponent as InstagramIcon } from '../../../../assets/images/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../../../assets/images/twitter.svg'
import { ReactComponent as LinkedIn } from '../../../../assets/images/linkedin.svg'
import Text from '../../../../components/Text'
import { User } from '../../../../utils/types'
import Avatar from '../../../../components/Avatar/Avatar'
import { IconWrapper } from '../../Profile.styles'
import { getSMUrl } from '../../../../utils/helpers'

interface ProfileHeaderProps extends BoxProps {
  user: User
}


export const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({
  user,
  ...rest
}) => {
  const hasSocialMedia = !!(user?.instagram || user?.twitter || user?.linkedin)

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Avatar src={user.avatar} sx={{ width: 98, height: 98 }} />
      <Text mt={1} variant="medium" weight="semibold">{user.full_name}</Text>
      {!!user.username && <Text variant="regular">@{user.username}</Text>}
      {hasSocialMedia && <Box display="flex" mt={2}>
        {!!user?.instagram && <IconWrapper to={getSMUrl(user?.instagram, 'instagram')}>
          <InstagramIcon />
        </IconWrapper>}
        {!!user?.twitter && <IconWrapper to={getSMUrl(user?.twitter, 'twitter')}>
          <TwitterIcon />
        </IconWrapper>}
        {!!user?.linkedin && <IconWrapper to={getSMUrl(user?.linkedin, 'linkedin')}>
          <LinkedIn />
        </IconWrapper>}
      </Box>}
    </Box>
  )
}

export default ProfileHeader