import { useState } from 'react';

import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDashboardHeader, setFooterNavVisibility, showToastMessage } from '../stores/uiStore';
import { retrieveErrorMessage } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { routes } from '../utils/constants';


export const useIsMobile = (mobileWidth: Breakpoint = 'md') => {
  const theme = useTheme();
  const aboveSmallScreen = useMediaQuery(theme.breakpoints.up(mobileWidth));

  return !aboveSmallScreen
}

export const useFooterNavVisibility = () => {
  const dispatch = useDispatch()
  const setVisibility = (state: 'hide' | 'show') => {
    dispatch(setFooterNavVisibility(state === 'show'))
  }

  return setVisibility
}

export const useHideFooterOnMount = () => {
  const setVisibility = useFooterNavVisibility()

  useEffect(() => {
    setVisibility('hide')
    return () => setVisibility('show')
  }, [setVisibility])
}

export const useProcessError = () => {
  const dispatch = useDispatch()
  const processError = useCallback((error?: FetchBaseQueryError | SerializedError) => {
    if (error && 'status' in error) {
      dispatch(showToastMessage({
        message: retrieveErrorMessage(error),
        severity: 'error'
      }))
    }
  }, [dispatch])

  return processError
}

export const useSetHeaderTitle = (title: string, showBackButton: boolean = false) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setDashboardHeader({title, showBackButton}))
  }, [dispatch, title, showBackButton])
}

export const useGusiberiRoutes = () => {
  const navigate = useNavigate()

  return {
    gotoActivityRequest: (dateId: string) => navigate(routes.DATE_REQUEST.replace(':date_id', dateId)),
    gotoMessages: () => navigate(routes.MESSAGES)
  }
}

export const useLocalDialog = () => {
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogBody, setDialogBody] = useState<React.ReactNode>(null)
  const [hideModalWrapper, setHideModalWrapper] = useState(false)

  const cancelDialog = () => {
    setOpen(false)
  }

  const showDialog = ({dialogTitle, dialogBody, hideModalWrapper}: {dialogTitle?: string, dialogBody?: React.ReactNode, hideModalWrapper?: boolean}) => {
    setDialogTitle(dialogTitle || '')
    setDialogBody(dialogBody || null)
    setHideModalWrapper(hideModalWrapper || false)
    setOpen(true)
  }

  return {
    actions: {
      showDialog,
      setDialogTitle,
      setDialogBody,
      setHideModalWrapper,
      cancelDialog
    },
    data: {
      open,
      dialogTitle,
      dialogBody,
      hideModalWrapper,
    }
  }
}