import React from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button/Button'
import { useFriendCategories } from '../Friends.hooks'
import { colors } from '../../../../styles/_var'
import { useUiStore } from '../../../../stores/uiStore'

const NewFriendGroupModal = () => {
  const { 
    groupName, 
    setGroupName,
    createNewFriendGroup,
    clearGroupInputErrors,
    groupInputError,
    creatingFriendGroup
  } = useFriendCategories()
  const { cancelDialog } = useUiStore()

  return (
    <Box px={2} py={3} width={280}>
      <Text weight="semibold" mb={1}>Create new friend group</Text>
      <Input
        onKeyDown={e => {
          if (e.key === 'Enter') {
            createNewFriendGroup()
            e.preventDefault()
          }
        }}
        onFocus={clearGroupInputErrors}
        error={!!groupInputError}
        helperText={groupInputError}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        size="small"
        placeholder='Friend group name' />
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant='outlined' onClick={cancelDialog} mr={2}>
          <Text>Cancel</Text>
        </Button>
        <Button variant='contained' onClick={createNewFriendGroup} loading={creatingFriendGroup}>
          <Text color={colors.white}>Create</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default NewFriendGroupModal