import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useFetchOpenDateRequestsQuery } from "../services/api";
import { useAuthStore } from "./authStore";

type StoreState = {
  approvedType: "past" | "upcoming",
  pendingType: "past" | "upcoming"
}

const initialState = {
  approvedType: "upcoming",
  pendingType: "upcoming"
} as StoreState

export const datesStore = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    setApprovedType(state, action: PayloadAction<"upcoming" | "past">) {
      state.approvedType = action.payload
    },
    setPendingType(state, action: PayloadAction<"upcoming" | "past">) {
      state.pendingType = action.payload
    }
  }
})

export const { 
  setApprovedType: storeApprovedType, 
  setPendingType: storePendingType
} = datesStore.actions
export const selectApprovedType = (state: RootState) => state.dates.approvedType
export const selectPendingType = (state: RootState) => state.dates.pendingType
export const datesReducer = datesStore.reducer

export const useDatesStore = () => {
  const dispatch = useDispatch()
  const approvedType = useSelector(selectApprovedType)
  const pendingType = useSelector(selectPendingType)
  const { data: openRequests} = useFetchOpenDateRequestsQuery({})
  const { currentUser } = useAuthStore()
  
  const setApprovedType = (payload: "past" | "upcoming") => {
    dispatch(storeApprovedType(payload))
  }
  const setPendingType = (payload: "past" | "upcoming") => {
    dispatch(storePendingType(payload))
  }

  const showingUpcoming = pendingType === "upcoming"

  return { 
    approvedType, 
    pendingType, 
    setApprovedType, 
    setPendingType,
    showingUpcoming,
    openRequests,
    currentUser
  }
}