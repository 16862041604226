import styled from "styled-components"
import IconButton from '@mui/material/IconButton'
import { colors, spacing } from '../../styles/_var'


export const NavButton = styled(IconButton)`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0px 4px 1px #999;
  background-color: ${colors.white} !important;
  z-index: 2;
  opacity: 1;
`

export const PrevButton = styled(NavButton)`
  left: 5px;
`

export const NextButton = styled(NavButton)`
  right: 5px;
`

export const CardWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding-bottom: ${spacing.xxs};
  position: relative;
`