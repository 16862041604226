import styled from "styled-components";


export const FindUserWrapper = styled.div`
  margin-top: 20px;
  `

export const CardInner = styled.div`
  padding: 10px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgba(200, 200, 200, 0.4);
`

export const SearchBody = styled.div`
  display: flex;
  
  .MuiInputBase-colorPrimary {
    background: transparent;
    box-shadow: 0px 0px 2px 2px rgba(200, 200, 200, 0.4);
    fieldset {
      border: none;
    }
  }

  input {
    box-sizing: border-box;
    height: 40px;
    border-radius: 10px;
  }
`

export const SearchIcon = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  padding 5px;
  box-shadow: 0px 0px 2px 2px rgba(200, 200, 200, 0.4);
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    font-size: 20px;
  }
`

export const ResultsContent = styled.div`
  position: absolute !important;
  top: 11px;
  background: white;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;

  p {
    text-align: left;
  }
`