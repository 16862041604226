import React, { FunctionComponent } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { spacing } from '../../../../styles/_var'
import Paper from '@mui/material/Paper'
import Avatar from '../../../../components/Avatar/Avatar'
import { animateComponent } from '../../../../utils/hoc'
import Text from '../../../../components/Text'
import TextWithIcon from '../../../../components/TextWithIcon'
import { AvatarEdit, EditFormWrapper } from './UserProfile.styles';
import SimpleForm from '../../../../components/SimpleForm';
import { useHideFooterOnMount } from '../../../../hooks/hooks';
import { useProfileEdit } from './UserProfile.hooks';
import ImageUpload from '../../../../components/ImageUpload';

type IProfileEdit = {
  gotoView: () => void,
}

const ProfileEdit: FunctionComponent<IProfileEdit> = React.memo(({ gotoView }) => {
  useHideFooterOnMount()
  const  { 
    formFields, 
    actionButtons, 
    currentUser, 
    onAvatarSelected,
    profileImage,
    submitFormConfirmation 
  } = useProfileEdit(gotoView)

  return (
    <Container>
      <Paper sx={{padding: spacing.xs, marginTop: spacing.sm, maxWidth: 400, marginLeft: 'auto', marginRight: 'auto'}}>
        <TextWithIcon
          onClick={gotoView}
          icon={<LeftIcon />}
          variant="medium"
          mt={2}
          weight="semibold"
          mb={2}
        >
          Edit Profile
        </TextWithIcon>
        <Box mt={2} mb={5} position="relative" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <ImageUpload 
            onImageChange={onAvatarSelected}
            contentArea={({ openFileChooser }) => (
              <AvatarEdit onClick={openFileChooser}>
                <Avatar src={profileImage} sx={{width: 100, height: 100}} />
                <Text variant="small" weight="semibold">Upload new Photo</Text>
              </AvatarEdit>
            )}
          />
        </Box>
        <EditFormWrapper>
          <SimpleForm
            initialValues={currentUser}
            formFields={formFields}
            actionButtons={actionButtons}
            submitForm={submitFormConfirmation} />
        </EditFormWrapper>
      </Paper>
    </Container>
  )
})

export default animateComponent<IProfileEdit>(ProfileEdit, true)
