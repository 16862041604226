import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form'
import { FormInputWrapper } from './FormInput.styles';
import Typography from '@mui/material/Typography'



type ComponentType = 'input' | 'select' | 'textarea'

export interface IFormInputProps {
  validate?: (text: string) => any,
  format?: (text: string) => any,
  parse?: (text: any | any[]) => any,
  onBlur?: (payload: any) => void,
  className?: string;
  style?: any;
  max_length?: number,
  formatOnBlur?: boolean,
  error?: any,
  touched?: boolean,
  name: string,
  label: string,
  type?: string
  component: ComponentType,
  prepend?: string,
  placeholder?: string,
  tooltip?: string,
  children?: any,
  hideErrorText?: boolean
}

const FormInput: FunctionComponent<IFormInputProps> = ({
    validate,
    error,
    touched,
    name,
    label,
    component,
    formatOnBlur,
    max_length,
    parse=undefined,
    format=undefined,
    type="text",
    prepend,
    placeholder,
    onBlur,
    tooltip,
    children,
    className='',
    style={},
    hideErrorText,
    ...rest
}) => {
  const inputProps: any = {
    type,
    placeholder,
    label,
    onBlur,
  }

  const fieldProps: any = {
    name,
    parse,
    format,
    validate,
    component,
    formatOnBlur,
  }

  const isCheckbox = inputProps.type === 'checkbox'
  const hasError = touched && !!error

  if (max_length) fieldProps.maxLength = max_length
  if (isCheckbox) fieldProps.type = type
  

  return (
    <FormInputWrapper
      className={className}
      style={style}
      isCheckbox={isCheckbox}
    >
      {isCheckbox && renderCheckboxLabel(label, !!hasError)}
      {typeof fieldProps.component === 'string' ? (
        <Field 
          {...fieldProps}
          {...inputProps}
          aria-labelledby={`text-label-${inputProps.type}`}
        >
          {children}
        </Field>
      ) : (
        <Field {...fieldProps}>
          {props => {
            const Component = fieldProps.component
            return (
              <Component
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                checked={props.input.checked}
                error={hasError}
                helperText={(hasError && !hideErrorText) ? error : ''}
                {...rest}
                {...inputProps} />
            )
          }}
        </Field>
      )}
    </FormInputWrapper>
  )
}

export default FormInput;

const renderCheckboxLabel = (label: string, error: boolean) => {
  return (
    <Typography variant='subtitle1' color={error ? "red" : undefined}>
      {label}
    </Typography>
  )
}

