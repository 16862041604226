import React from 'react'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SimpleForm from '../../../components/SimpleForm'
import Text from '../../../components/Text'
import { AuthContent } from '../AuthIndex/Auth.styles'
import content from '../content'
import { routes } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import { animateComponent } from '../../../utils/hoc'
import TextWithIcon from '../../../components/TextWithIcon'
import { useLoginForm } from './Login.hooks';
import SimpleFooter from '../../../components/SimpleFooter/SimpleFooter';
import GoogleAuthButton from '../../../components/GoogleAuthButton';
import { spacing } from '../../../styles/_var';


const Login = () => {
  const { submitForm, actionButtons, loginFields } = useLoginForm()

  return (
    <AuthContent>
      <Link to={routes.AUTH}>
        <TextWithIcon variant="semi-large" mb={1} icon={<LeftIcon />}>
          {content.sign_in}
        </TextWithIcon>
      </Link>
      <Text mb={3} variant="subtext">{content.not_your_device}</Text>
      <SimpleForm
        formFields={loginFields}
        submitForm={submitForm}
        actionButtons={actionButtons}
      />
      <GoogleAuthButton style={{marginTop: spacing.xs}} />
      <Text variant="subtext" center mt={1}>Did you forget your password?</Text>
      <Link to={routes.REQUEST_RESET_PASSWORD}>
        <Text variant="subtext" weight="semibold" center>Reset Password Here</Text>
      </Link>
      <Text center variant="subtext" mt={2}>
        {content.no_account} <strong><Link to={routes.REGISTER}>{content.sign_up}</Link></strong>
      </Text>
      <SimpleFooter />
    </AuthContent>
  )
}

export default animateComponent(Login)