import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Text from '../Text'
import { ActionButtonSection, FriendSelectorWrapper, FriendsListWrapper, SCheckBox } from './FriendSelector.styles'
import { Friend, User } from '../../utils/types'
import { useGetFriendsRequestQuery } from '../../services/api'
import Button from '../Button/Button'
import { UserInfo } from '../UserInfo/UserInfo'
import { useUiStore } from '../../stores/uiStore'
import { colors, spacing } from '../../styles/_var'



type FriendSelectorProps = {
  onFriendsSelected: (users: User[]) => void
}

const FriendSelector = ({
  onFriendsSelected
}: FriendSelectorProps) => {
  const [selectedFriends, setSelectedFriends] = React.useState<User[]>([])
  const { data, isLoading } = useGetFriendsRequestQuery({})
  const { cancelDialog } = useUiStore()
  const friends = data?.payload || []

  const checkBoxClicked = (friend: Friend) => () => {
    const index = selectedFriends.findIndex(f => f.username === friend.friend_user.username)
    if (index > -1) {
      setSelectedFriends(selectedFriends.filter(f => f.username !== friend.friend_user.username))
    } else {
      setSelectedFriends([...selectedFriends, friend.friend_user])
    }
  }

  const onComplete = () => {
    onFriendsSelected(selectedFriends)
    cancelDialog()    
  }

  const loadingComponent = null
  const bodyComponent = () => {
    const list = friends.map((friend, i) => {
      const isSelected = selectedFriends.findIndex(f => f.username === friend.friend_user.username) > -1
      return (
        <div key={friend.friend_user.username}>
          <Box 
            py={1.5} 
            px={2}
            display="flex"
            alignItems="center"
            sx={{backgroundColor: isSelected ? colors.veryLightGreen : 'transparent'}}
            onClick={checkBoxClicked(friend)}
          >
            <SCheckBox sx={{marginRight: spacing.xxs}} checked={isSelected} />
            <UserInfo user={friend.friend_user} />
          </Box>
          {(i !== friends.length - 1) && <Divider />}
        </div>
      )}
    )

    return (
      <FriendsListWrapper>{list}</FriendsListWrapper>
    )
  } 

  return (
    <FriendSelectorWrapper>
      <Box px={2} py={1.5}>
        <Text center weight="semibold" variant="medium">
          Select Friends
        </Text>
      </Box>
      <Divider />
      <Box>
        {isLoading ? loadingComponent : bodyComponent()}
      </Box>
      <ActionButtonSection>
        <Divider />
        <Box p={2} justifyContent="flex-end" display="flex">
          <Button variant="outlined" mr={1} onClick={cancelDialog} size="small">
            Cancel
            </Button>
          <Button variant="contained" size="small" onClick={onComplete}>
            Complete
          </Button>
        </Box>
      </ActionButtonSection>
    </FriendSelectorWrapper>
  )
}

export default FriendSelector
