import React, { useState } from "react"
import Box from '@mui/material/Box'
import { Participant } from "../../../../services/api"
import Text from "../../../../components/Text"
import Button from "../../../../components/Button/Button"
import { colors } from "../../../../styles/_var"
import Input from "../../../../components/Input"

type DateResponseModalProps = {
  participant: Participant,
  onAccept: () => void,
  onDecline: () => void
}

const DateResponseModal = ({ participant, onAccept, onDecline }: DateResponseModalProps) => {
  const [note, setNote] = useState('')
  const { response } = participant
  const isPending = response === "pending"
  const isAccepted = response === "accepted"
  const isDeclined = response === "declined"

  const pendingPrompt = "Please respond to your pending invitation."
  const declinePrompt = "Have you changed your mind? Would you like to accept this invitation?"
  const acceptPrompt = "Have you changed your mind? Would you like to decline this invitation?"
  const responsePrompt = isAccepted ? acceptPrompt : (isPending ? pendingPrompt : declinePrompt)


  return (
    <Box p={2} maxWidth={350}>
      <Text weight="semibold" variant="medium" mb={1}>
        Invitation Response
      </Text>
      <Text>{responsePrompt}</Text>
      <Input 
        value={note}
        onChange={e => setNote(e.target.value)}
        placeholder="accept or decline note"
        multiline
        rows={2}
        mt={1} 
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        {(isPending || isAccepted) && (
          <Button
            onClick={onDecline}
            size="small"
            variant={isPending ? "outlined" : "contained"} 
            mr={1}
          >
            <Text useDiv color={isPending ? colors.black : colors.white}>Decline</Text>
          </Button>
        )}
        {(isPending || isDeclined) && (
          <Button size="small" variant="contained" onClick={onAccept}>
            <Text useDiv color={colors.white} weight="semibold">
              Accept
            </Text>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default DateResponseModal
