import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Text from '../../../../components/Text';
import { colors } from '../../../../styles/_var';
import DateParticipants from './DateParticipants/DateParticipants';
import DateMemories from './DateMemories/DateMemories';
import { DateRequest } from '../../../../utils/types';
import BuckBreaker from './BuckBreaker/BuckBreaker';
import { Chip } from './styles';
import { useSearchParams } from 'react-router-dom';


type DateRequestTabsProps = {
  dateInfo: DateRequest
}

const ParticipantsLabel = ({ count }: {count: number}) => {
  return (
    <Text useDiv style={{display: "flex", alignItems: "center"}}>
      Participants <Chip ml={1}>{count}</Chip>
    </Text>
  )
}

const DateRequestTabs = ({ dateInfo }: DateRequestTabsProps) => {
  const participantCount = dateInfo?.date_participants?.length || 0
  const participantCountMinusCreator = participantCount > 0 ? participantCount - 1 : 0
  const [params,] = useSearchParams()
  const currentTab = params.get('tab')
  
  const tabs = [
    {label: <ParticipantsLabel count={participantCountMinusCreator} />, route: null, tabId: 'p'},
    {label: 'Memories', route: null, tabId: 'm'}
  ]
  
  if (dateInfo.buck_breaker_enabled) {
    tabs.push({label: 'Buck Breaker', route: null, tabId: 'bb'})
  }

  const getCurrentlyOpenTabIndex = () => {
    const tabIndex = tabs.findIndex(t => t.tabId === currentTab)
    return tabIndex < 0 ? 0 : tabIndex
  }
  
  const [tabValue, setTabsValue] = useState(getCurrentlyOpenTabIndex())
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };




  const renderTabs = () => {
    switch (tabValue) {
      case 0:
        return <DateParticipants dateInfo={dateInfo} />
      case 1:
        return <DateMemories dateInfo={dateInfo}/>
      case 2:
        return <BuckBreaker dateInfo={dateInfo} />
      default:
        return null
    }
  }

  return (
    <Box>
      <Divider />
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        {tabs.map((tab, i) => (
          <Tab key={i} label={(
            <Text 
              useDiv
              variant="regular" 
              weight="semibold" 
              capitalize
              color={colors.darkgrey}
            >
              {tab.label}
            </Text>
          )} />
        ))}
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      {renderTabs()}
    </Box>
  )
}

export default DateRequestTabs
