import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import PhotoIcon from '@mui/icons-material/InsertPhotoRounded';
import ScoreboardIcon from '@mui/icons-material/ScoreboardRounded';
import MessageIcon from '@mui/icons-material/ThreePRounded';
import { DateRequest } from '../../../../../utils/types'
import DateScoreboard from './ScoreboardView/DateScoreboard';
import DateComments from './DateComments';
import DateMedia from './DateMedia';

const DATE_MEDIA_STEP = 0
const DATE_COMMENTS_STEP = 1
const DATE_SCOREBOARD_STEP = 2


type DateMemoriesProps = {
  dateInfo: DateRequest
}

const DateMemories = ({ dateInfo }: DateMemoriesProps) => {
  const [tabValue, setTabsValue] = useState(DATE_MEDIA_STEP)

  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  const tabs = [
    {label: <PhotoIcon fontSize="large" />, route: null},
    {label: <MessageIcon fontSize="large" />, route: null},
  ]

  if (dateInfo.scoreboard_enabled) {
    tabs.push({label: <ScoreboardIcon fontSize="large" />, route: null})
  }

  const renderTabs = () => {
    switch (tabValue) {
      case DATE_MEDIA_STEP:
        return <DateMedia dateInfo={dateInfo} />
      case DATE_SCOREBOARD_STEP:
        return <DateScoreboard dateInfo={dateInfo} />
      case DATE_COMMENTS_STEP:
        return <DateComments dateInfo={dateInfo} />
      default:
        return null
    }
  }

  return (
    <Box>
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        {tabs.map((tab, i) => (
          <Tab key={i} label={(
            <div>{tab.label}</div>
          )} />
        ))}
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      {renderTabs()}
    </Box>
  )
}



export default DateMemories