import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Badge from '@mui/material/Badge';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { useFetchActivePendingRequestQuery } from '../../../services/api'
import MobileHeader from '../../../components/MobileHeader/MobileHeader'
import { useSetHeaderTitle } from '../../../hooks/hooks'
import { DateRequestWrapper } from './DateRequests.styles'
import Text from '../../../components/Text';
import { Outlet, useLocation } from 'react-router-dom';
import { routes } from '../../../utils/constants';
import { LinkTab } from '../AccountSettings/SettingsNav';
import { animateComponent } from '../../../utils/hoc';

const ComponentBody = animateComponent(() => {
  return (
    <Container>
      <Box mt={3} display="flex" alignItems="center" flexDirection="column">
        <Outlet />
      </Box>
    </Container>
  )
}, true)

const DateRequests = () => {
  useSetHeaderTitle('Invites')
  const { data } = useFetchActivePendingRequestQuery({})

  const tabs = [
    {label: 'Approved', route: routes.UPCOMING_DATES},
    {label: <span>Pending {!!data?.payload.length && <Badge sx={{marginLeft: 1.5}} badgeContent={data.payload.length} color="primary" />}</span>, route: routes.PENDING_REQUESTS},
  ]

  const location = useLocation()
  const [tabValue, setTabsValue] = useState(tabs.findIndex(e => e.route === location.pathname))
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue)
  };

  return (
    <DateRequestWrapper>
      <MobileHeader />
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        {tabs.map((tab, i) => (
          <LinkTab 
            key={i}
            href={tab.route} 
            label={<Text weight={tabValue === i ? "semibold" : "regular"} capitalize>{tab.label}</Text>} />
        ))}
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      <ComponentBody />
    </DateRequestWrapper>
  )
}

export default DateRequests
