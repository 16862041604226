import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IToastMessage } from '../components/Toast/Toast'
import { RootState } from '../store'
import { makeid, setPageTitle } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Step } from 'react-joyride'

export type DialogPayload = {
  dialogTitle?: React.ReactNode | string;
  dialogBody?: React.ReactNode | string;
  onConfirmAction?: () => void;
  onCancelAction?: () => void;
  cancelButtonText?: string,
  confirmButtonText?: string,
  hideModalWrapper?: boolean,
  onDialogClose?: () => void,
  wrapperStyle?: React.CSSProperties
}

export const TOAST_DEFAULTS: IToastMessage = {
  id: '',
  message: '',
  severity: 'success',
  duration: 4000
}

export const uiStore = createSlice({
  name: 'ui',
  initialState: {
    hasShownDashboardDemo: false,
    hasShownScheduleDemo: false,
    showFooterNav: true,
    toastMessages: [] as IToastMessage[],
    dashboardHeader: 'Home',
    showBackButton: false,
    loadingScreenMessage: '',
    showDialog: false,
    dialogPayload: {} as DialogPayload,
    showConfirmationDialog: false,
    confirmationDialogPayload: {} as DialogPayload,
    joyRideSteps: [] as Step[],
    joyRideRunning: false
  },
  reducers: {
    showToastMessage(state, action: PayloadAction<IToastMessage>) {
      const newToastMessage = {...TOAST_DEFAULTS, ...action.payload, id: makeid(5)}
      state.toastMessages = [...state.toastMessages, newToastMessage]
    },
    removeToast(state, action) {
      if (action.payload) {
        state.toastMessages = state.toastMessages.filter(toast => toast.id !== action.payload)
      } else {
        state.toastMessages = []
      }
    },
    setDashboardHeader(state, action: PayloadAction<{title: string, showBackButton: boolean}>) {
      state.dashboardHeader = action.payload.title
      state.showBackButton = action.payload.showBackButton
    },
    setLoadingScreenMessage(state, action) {
      state.loadingScreenMessage = action.payload
    },
    openDialog(state, action: PayloadAction<DialogPayload>) {
      state.showDialog = true
      state.dialogPayload = action.payload
    },
    closeDialog(state) {
      state.showDialog = false
      state.dialogPayload = {}
    },
    openConfirmDialog(state, action: PayloadAction<DialogPayload>) {
      state.confirmationDialogPayload = action.payload
      state.showConfirmationDialog = true
    },
    closeConfirmDialog(state) {
      state.confirmationDialogPayload = {}
      state.showConfirmationDialog = false
    },
    setFooterNavVisibility(state, action: PayloadAction<boolean>) {
      state.showFooterNav = action.payload
    },
    setJoyRideSteps(state, action: PayloadAction<Step[]>) {
      state.joyRideSteps = action.payload as any // couldn't get the types to work
    },
    startJoyRide(state, action: PayloadAction<boolean>) {
      state.joyRideRunning = action.payload
    },
    resetJoyRideState(state, _: PayloadAction<undefined>) {
      state.joyRideSteps = []
      state.joyRideRunning = false
    },
    setDemoHasBeenShownState(state, action: PayloadAction<{demoType: 'hasShownDashboardDemo' | 'hasShownScheduleDemo', hasBeenShown: boolean}>) {
      switch (action.payload.demoType) {
        case 'hasShownDashboardDemo':
          state.hasShownDashboardDemo = action.payload.hasBeenShown
          return
        case 'hasShownScheduleDemo':
          state.hasShownScheduleDemo = action.payload.hasBeenShown
          return
        default:
          // do nothing
      }
    }
  }
})

export const {
  showToastMessage,
  removeToast,
  setDashboardHeader,
  setLoadingScreenMessage,
  openDialog,
  closeDialog,
  openConfirmDialog,
  closeConfirmDialog,
  setFooterNavVisibility,
  startJoyRide,
  setJoyRideSteps,
  resetJoyRideState,
  setDemoHasBeenShownState
} =  uiStore.actions

export const selectToastMessage = (state: RootState) => state.ui.toastMessages
export const selectHeader = (state: RootState) => state.ui.dashboardHeader
export const selectShowBackButton = (state: RootState) => state.ui.showBackButton
export const selectLoadingScreenMessage = (state: RootState) => state.ui.loadingScreenMessage
export const selectShowDialog = (state: RootState) => state.ui.showDialog
export const selectDialogPayload = (state: RootState) => state.ui.dialogPayload
export const selectShowConfirmation = (state: RootState) => state.ui.showConfirmationDialog
export const selectConfirmationPayload = (state: RootState) => state.ui.confirmationDialogPayload
export const selectShowNav = (state: RootState) => state.ui.showFooterNav
export const selectJoyRideSteps = (state: RootState) => state.ui.joyRideSteps
export const selectJoyRideRunning = (state: RootState) => state.ui.joyRideRunning
export const selectHasShowDashboardDemo = (state: RootState) => state.ui.hasShownDashboardDemo
export const selectHasShowScheduleDemo = (state: RootState) => state.ui.hasShownScheduleDemo
export const uiReducer = uiStore.reducer

export const useUiStore = () => {
  const dispatch = useDispatch()

  const openSuccessToast = (payload: IToastMessage) => {
    dispatch(showToastMessage({...payload, severity: "success"}))
  }

  const openErrorToast = (payload: IToastMessage) => {
    dispatch(showToastMessage({...payload, severity: "error"}))
  }

  const openWarningToast = (payload: IToastMessage) => {
    dispatch(showToastMessage({...payload, severity: "warning"}))
  }

  const cancelDialog = () => {
    dispatch(closeDialog())
  }

  const showConfirmDialog = (payload: DialogPayload) => {
    dispatch(openConfirmDialog(payload))
  }

  const showDialog = (payload: DialogPayload) => {
    dispatch(openDialog(payload))
  }

  const cancelConfirmDialog = () => {
    dispatch(closeConfirmDialog())
  }

  const openLoadingScreen = (message: string) => {
    dispatch(setLoadingScreenMessage(message))
  }

  const saveJoyRideSteps = (steps: Step[]) => {
    dispatch(setJoyRideSteps(steps))
  }

  const setJoyRideState = (state: boolean) => {
    dispatch(startJoyRide(state))
  }

  const closeLoadingScreen = () => {
    openLoadingScreen('')
  }

  const resetJoyRide = () => {
    dispatch(resetJoyRideState())
  }

  const setHeaderTitle = (title: string, showBackButton = true) => {
    setPageTitle(title)
    dispatch(setDashboardHeader({ title, showBackButton }))
  }

  const setDemoHasBeenShown = (demoType: 'hasShownDashboardDemo' | 'hasShownScheduleDemo', hasBeenShown: boolean) => {
    dispatch(setDemoHasBeenShownState({ demoType, hasBeenShown }))
  }

  const showConfirmation = useSelector(selectShowConfirmation)
  const confirmationDialogPayload = useSelector(selectConfirmationPayload)
  const joyRideSteps = useSelector(selectJoyRideSteps)
  const joyRideRunning = useSelector(selectJoyRideRunning)
  const hasShownDashboardDemo = useSelector(selectHasShowDashboardDemo)
  const hasShownScheduleDemo = useSelector(selectHasShowScheduleDemo)

  return {
    openSuccessToast,
    openErrorToast,
    openWarningToast,
    cancelDialog,
    showConfirmDialog,
    showDialog,
    cancelConfirmDialog,
    closeLoadingScreen,
    openLoadingScreen,
    saveJoyRideSteps,
    setJoyRideState,
    joyRideSteps,
    joyRideRunning,
    setJoyRideSteps,
    showConfirmation,
    confirmationDialogPayload,
    setHeaderTitle,
    resetJoyRide,
    hasShownDashboardDemo,
    hasShownScheduleDemo,
    setDemoHasBeenShown
  }
}