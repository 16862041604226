import styled from 'styled-components'
import Container  from '@mui/material/Container'
import { colors, device, spacing } from '../../styles/_var'
import Box from '@mui/material/Box'

export const HomePageWrapper = styled.div``

export const PPWrapper = styled(Container)`
  margin-top: ${spacing.sm};
`

export const Logo = styled.img`
  height: 35px;
  width: auto;
`

export const Section = styled.div`
  margin-bottom: ${spacing.xs};
`

export const HomePageNavContainer = styled(Container)`
  height: 7.3rem;
  background-color: ${colors.white};
`

export const HomePageNavWrapper = styled(({ noShadow, ...rest }) => <div {...rest} />)`
  position: relative;
  ${({ noShadow }) => noShadow ? `
    border-bottom: solid 1px ${colors.lightergrey};
  ` : `
    box-shadow: 0px 0px 3px 3px rgba(200, 200, 200, 0.4);
  `}
  box-sizing: border-box;
  z-index: 2;
`

export const HeroWrapper = styled.div`
  padding: ${spacing.lg};
  background-color: ${colors.lightgrey};
  border-image-source: linear-gradient(90deg, rgba(24,45,8,1) 0%, rgba(84,156,58,1) 35%, rgba(189,237,87,1) 68%, rgba(24,45,8,1) 100%);
`

export const HeroImage = styled.img`
  border-radius: 1.5rem;
`

export const HeroSeparator = styled.div`
  background: rgb(24,45,8);
  background: linear-gradient(94.15deg, #182D08 10.53%, #549C3A 40.96%, #BDED57 62.92%, rgba(24, 45, 8, 0.54) 91.87%);
  height: 0.4rem;
`

export const AboutSectionContainer = styled(Container)`
  padding-top: ${spacing.lg};
`

export const AboutIcon = styled.div`
  border-radius: 1rem;
  width: 47px;
  height: 44px;
  margin-right: auto;
  margin-left: auto;
  display: block;
  background: linear-gradient(180deg, rgba(50, 48, 24, 0.064) 0%, rgba(78, 146, 248, 0.092) 100%),
linear-gradient(94.15deg, #FFFFFF 10.53%, rgba(229, 228, 219, 0) 38.09%, rgba(229, 228, 219, 0) 62.92%, rgba(229, 228, 219, 0) 98.59%);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    max-height: 100%;
    max-width: 100%;
  }

`
export const HowItWorksContainer = styled.div`
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  padding: 2px 2px;
  background: linear-gradient(94.15deg, #182D08 10%, #549C3A 30.96%, #BDED57 62.92%, #182D08 100%);
  border-radius: 1rem;
`

export const HIWContent = styled.div`
  background-color: ${colors.lightergrey};
  padding: ${spacing.rg} ${spacing.lg};
  border-radius: 0.8rem;
`

export const HIWStep = styled(Box)`
  background: ${colors.white};
  border: 0.5px solid #352E1A;
  border-radius: 1rem;
  padding: ${spacing.sm};
  text-align: center;
  min-height: 15rem;

  & svg {
    font-size: 3.8rem;
    height: 4rem;
  }
`

export const ComingSoonImage = styled.img``

export const ComingSoonContent = styled(HIWContent)`
  background-color: ${colors.yellow};
  padding: ${spacing.sm};
  text-align: center;

  @media ${device.md} {
    text-align: left;
  }
`