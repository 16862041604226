import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container'
import { animateComponent } from '../../../../utils/hoc'
import Text from '../../../../components/Text';
import ContactUs from './ContactUs';
import PendingIssues from './PendingIssues';
import { SupportWrapper } from './Support.styles';


const Support = React.memo(() => {
  const [tabValue, setTabsValue] = useState(0)
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  return (
    <SupportWrapper>
      <Tabs value={tabValue} variant="fullWidth" onChange={onTabChange}>
        <Tab label={<Text variant="subtext">Contact Us</Text>} />
        <Tab label={<Text variant="subtext">Pending Issues</Text>} />
      </Tabs>
      <Divider sx={{position: 'relative', top: '-1.5px'}} />
      <Container>
        {tabValue === 0 ? <ContactUs /> : <PendingIssues />}
      </Container>
    </SupportWrapper>
  )
})

export default animateComponent(Support, true)