import React from 'react'
import { HIWContent, HIWStep, HowItWorksContainer } from './HomePage.styles'
import Container  from '@mui/material/Container'
import PeopleIcon from '@mui/icons-material/People'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import ActivityIcon from '@mui/icons-material/Rowing'
import ProfileIcon from '@mui/icons-material/Contacts'
import SendIcon from '@mui/icons-material/ScheduleSend'
import MoreIcon from '@mui/icons-material/ReadMore'
import Grid from '@mui/material/Grid'
import content from './content'
import Text from '../../components/Text'

const HowItWorks = () => {
  const steps = [
    {
      title: content.step_1_title,
      desc: content.step_1_description,
      color: "#885F5F",
      Icon: PeopleIcon
    },
    {
      title: content.step_2_title,
      desc: content.step_2_description,
      color: "#887D5F",
      Icon: CalendarIcon
    },
    {
      title: content.step_3_title,
      desc: content.step_3_description,
      color: "#7F5F88",
      Icon: ActivityIcon
    },
    {
      title: content.step_4_title,
      desc: content.step_4_description,
      color: "#8C3650",
      Icon: ProfileIcon
    },
    {
      title: content.step_5_title,
      desc: content.step_5_description,
      color: "#182D08",
      Icon: SendIcon
    },
    {
      title: content.step_6_title,
      desc: content.step_6_description,
      color: "#182D088A",
      Icon: MoreIcon
    },
  ]

  return (
    <Container>
      <HowItWorksContainer>
        <HIWContent>
          <Text variant='semi-large' weight={400} center>
            {content.how_it_works_title}
          </Text>
          <Text center maxWidth={400} mt={2} mb={4}>
            {content.how_it_works_description}
          </Text>
          <Grid container spacing={3}>
            {steps.map((step, i) => (
              <Grid item xs={12} sm={12} md={4} key={i}>
                <HIWStep>
                  <step.Icon style={{ color: step.color }} />
                  <Text weight={400} mb={2}>{step.title}</Text>
                  <Text maxWidth={400}>{step.desc}</Text>
                </HIWStep>
              </Grid>
            ))}
          </Grid>
        </HIWContent>
      </HowItWorksContainer>
    </Container>
  )
}

export default HowItWorks