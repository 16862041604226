import React, { FunctionComponent } from "react";
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import CancelIcon from '@mui/icons-material/ClearRounded';
import Text from "../../../../components/Text";
import { FavouriteActivity } from "../../../../utils/types";
import { spacing } from "../../../../styles/_var";
import Chip from "../../../../components/Chip/Chip";
import { EditButton, RemoveButton } from "./Activities.styles";
import EditIcon from '@mui/icons-material/EditRounded';
import { useActivityList } from "./Activities.hooks";

type ActivityListProps = {
  activities: FavouriteActivity[],
}

const ActivityList: FunctionComponent<ActivityListProps> = React.memo(({
  activities,
}) => {
  const { 
    deleting,
    listToDelete,
    removeActivity, 
    openUpdateActivityModal 
  } = useActivityList()

  return (
    <div>
      {activities.map((activity, i) => (
          <Paper key={i} sx={{marginBottom: spacing.xs, padding: spacing.xs, opacity: deleting && activity.id === listToDelete ? 0.3 : 1}}>
            <Box mb={2} display="flex" justifyContent="space-between">
              <Text>{activity.description}</Text>
              <Box>
                <RemoveButton onClick={() => removeActivity(activity.id)}>
                  <CancelIcon />
                </RemoveButton>
                <EditButton onClick={() => openUpdateActivityModal(activity)}>
                  <EditIcon />
                </EditButton>
              </Box>
            </Box>
            {activity.favourite_places?.split('||').map((fp, i) => <Chip key={i} mr={2} mb={2}>{fp}</Chip>)}
          </Paper>
        )
      )}
    </div>
  )
})

export default ActivityList
