import React, { FunctionComponent } from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import { colors } from '../../../../styles/_var';
import { MessageType } from '../../../../utils/types';
import { getNameInitials, humanize } from '../../../../utils/helpers';
import Avatar from '../../../../components/Avatar/Avatar';

type MessageBubbleProps = {
  chatMessage: MessageType,
  isMe: boolean
}

const MessageBubble: FunctionComponent<MessageBubbleProps> = ({
  chatMessage: {message, created_at, status='', user},
  isMe
}) => {
  const isPending = status?.includes('pending')
  return (
    <Box 
      p={2} 
      display="flex" 
      alignItems={isMe ? "flex-end" : "flex-start"}
      flexDirection="column"
    >
      <Box display="flex" alignItems="center">
        {!isMe && (
          <Avatar src={user.avatar} mr={1} style={{width: 27, height: 27}}>
            <Text variant="small" color={colors.white}>
              {getNameInitials(user.full_name || user.username).toUpperCase()}
            </Text>
          </Avatar>
        )}
        <Box 
          p={2}
          width="fit-content" 
          sx={{
            backgroundColor: isMe ? colors.greenBackground : colors.veryLightGreen,
            borderRadius: 3, 
            opacity: isPending ? 0.6 : 1
          }}
        >
          <Text>{message}</Text>
        </Box>
      </Box>
      {!!created_at && (
        <Box display="flex" alignItems="center">
          <Text variant='small'>{humanize(created_at)}</Text>
        </Box>
      )}
    </Box>
  )
}

export default MessageBubble
