import React, { useState } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import MaterialDialog from '@mui/material/Dialog';
import PlusIcon from '@mui/icons-material/AddRounded';
import { SupplySuggestion } from '../../../../utils/types'
import Text from '../../../../components/Text'
import { colors } from '../../../../styles/_var'
import Button from '../../../../components/Button/Button'
import TextWithIcon from '../../../../components/TextWithIcon'
import SimpleForm, { IActionButton } from '../../../../components/SimpleForm';
import Input from '../../../../components/Input';
import validators from '../../../../utils/validators';
import Checkbox from "../../../../components/Checkbox";
import SupplySuggestionsList from './SupplySuggestionsList';


interface IAddSupplySuggestionsProps {
  supplies: SupplySuggestion[],
  finishActivityCreation: () => void
  addSuggestion: (suggestion: SupplySuggestion) => void
  removeSuggestion: (supplyId: number) => void
}

const formFields = [
  {
    name: 'item',
    placeholder: 'Item Name',
    component: Input,
    validate: validators.required
  },
  {
    name: 'additional_info',
    placeholder: 'Additional information (optional)',
    component: Input,
    multiline: true,
    rows: 2,
  },
  {
    name: 'is_vital',
    label: <Text color={colors.primary}>item is important</Text>,
    component: Checkbox,
    type: 'checkbox'
  }
]


const AddSupplySuggestions = ({
  supplies,
  finishActivityCreation,
  addSuggestion,
  removeSuggestion
}: IAddSupplySuggestionsProps) => {
  const [showModal, setShowModal] = useState(false)
  const actionButtons: IActionButton[] = [
    {
      label: <Text color="white">Add Item</Text>,
      variant: "contained",
      isSubmit: true
    },
    {
      label: <Text>Cancel</Text>,
      variant: "outlined",
      onClick: () => setShowModal(false)
    },
  ]

  const renderModal = () => {
    const add = (values: SupplySuggestion) => {
      addSuggestion({...values, additional_info: values.additional_info || '', is_vital: values.is_vital || false})
      setShowModal(false)
    }

    return (
      <MaterialDialog open={true} onClose={() => setShowModal(false)}>
        <Box py={3} px={2} minWidth={280}>
          <Text variant="medium" weight="semibold" mb={2} center>
            Add Item
          </Text>
          <SimpleForm 
            formFields={formFields}
            submitForm={add}
            actionButtons={actionButtons}
          />
        </Box>
      </MaterialDialog>
    )
  }

  return (
    <Box>
      {showModal && renderModal()}
      <Text variant="medium" weight="semibold" mb={2}>Add Items to Bring</Text>
      <Card sx={{padding: 1, bgcolor: colors.verylightPrimary}} >
        <Text variant="regular">
          Make suggestions to your friends on what to bring. You can also tag them as important or optional.
        </Text>
      </Card>
      <Button variant="outlined" size="small" center mt={3} onClick={() => setShowModal(true)}>
        <TextWithIcon variant='regular' icon={<PlusIcon />}>
          Suggest new Item
        </TextWithIcon>
      </Button>
      <Box mt={3}>
        {supplies.length === 0 && (
          <Text variant="regular" center color={colors.danger}>No items added yet.</Text>
        )}
        <SupplySuggestionsList suggestions={supplies} removeSuggestion={removeSuggestion}/>
      </Box>
      <div className="simple-form-action-buttons fixed-button-bottom">
        <Button variant="contained" size="small" fullWidth onClick={finishActivityCreation}>
          <Text color={colors.white}>Finish</Text>
        </Button>
      </div>
    </Box>
  )
}

export default AddSupplySuggestions