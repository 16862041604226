import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Text from '../../../../../../components/Text'
import trophyImage from '../../../../../../assets/images/trophy.png'
import Avatar from '../../../../../../components/Avatar/Avatar';
import styled from 'styled-components';
import { colors } from '../../../../../../styles/_var';
import { User } from '../../../../../../utils/types'
import { getFirstName } from '../../../../../../utils/helpers'
import UsersAvatarGroup from '../../../../../../components/UsersAvatarGroup/UsersAvatarGroup'

type WinnerCardProps = {
  user: User | User[],
  teamName?: string,
  scores: string[]
}

const WinnerCard = ({ 
  user, 
  scores,
  teamName
}: WinnerCardProps) => {
  return (
    <Box display="flex" alignItems="center">
      <TrophyImage src={trophyImage} alt="trophy"  />
      <Card elevation={4}>
        <Box
          flexDirection="column"
          display="flex" 
          alignItems="center"
          pb={2}
        >
          <WinnerLabel style={{top: "-6px"}} />
          <Box px={2} pt={1} pb={1}>
            {Array.isArray(user) ? (
              <>
                <UsersAvatarGroup users={user} max={3} />
                <Text variant="medium" weight="semibold" center mt={1} lighten>
                  {teamName}
                </Text>
              </>
            ) : (
              <>
                <Avatar 
                  style={{width: 65, height: 65}}
                  src={user.avatar} 
                />
                <Text variant="medium" weight="semibold" center mt={1} lighten>
                  {getFirstName(user.full_name)}
                </Text>
              </>
            )}
          </Box>
          {scores.length === 1 && (
            <ScoreText center weight="semibold" variant="semi-large">
              {scores[0]}
            </ScoreText>
          )}
        </Box>
      </Card>
      <TrophyImage src={trophyImage} alt="trophy" />
    </Box>
  )
}

const WinnerLabel = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <WinnerLabelWrapper style={style}>
      <Text variant="medium" weight="semibold" color={colors.white} center>
        Winner
      </Text>
    </WinnerLabelWrapper>
  )
}

export default WinnerCard


const WinnerLabelWrapper = styled(Box)`
  position: relative;
  width: 60px;
  padding: 10px 15px;
  background: ${colors.primary};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`

const TrophyImage = styled.img`
  width: 40px;
  height: auto;
  margin-left: 23px;
  margin-right: 23px;
`

const ScoreText = styled(Text)`
  color: ${colors.primary};
  background: ${colors.lightGreen};
  width: 100%;
  padding: 5px 0;
` 

const Card = styled(Paper)`
  max-width: 160px;
  width: 160px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;

  &.MuiPaper-root {
    border-radius: 20px;
  }
`
