import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '../../../../../../components/Drawer/Drawer'
import Button from '../../../../../../components/Button/Button';
import Text from '../../../../../../components/Text';
import { colors, dottedBorderTop } from '../../../../../../styles/_var';
import { DateRequest } from '../../../../../../utils/types';
import UsersAvatarGroup from '../../../../../../components/UsersAvatarGroup/UsersAvatarGroup';
import { User } from '../../../../../../utils/types'
import Input from '../../../../../../components/Input';
import Avatar from '../../../../../../components/Avatar/Avatar';
import { Participant } from '../../../../../../services/api';
import { isEmpty, getParticipantFirstName } from '../../../../../../utils/helpers';
import { useUiStore } from '../../../../../../stores/uiStore';


type FormTeamDrawerProps = {
  isOpen: boolean,
  onClose: () => void,
  dateRequest: DateRequest,
  onComplete: (teamsData: Record<string, number[]>) => void
}

type UseFormTeams = {
  dateRequest: DateRequest
}

const useFormTeams = ({dateRequest}: UseFormTeams) => {
  const { showDialog, cancelDialog } = useUiStore()
  const [teamsData, setTeamsData] = useState<Record<string, number[]>>({})
  const hasTeam = (teamId: number) => Object.values(teamsData).some(members => members.includes(teamId))

  const createTeam = (name: string) => {
    beginParticipantsSelection(name)
  }

  const addTeamMembers = (teamName: string, memberId: number | number[]) => {
    setTeamsData({...teamsData, [teamName]: (teamsData[teamName] || []).concat(memberId)})
  }

  const removeTeamMember = (teamName: string, memberId: number) => {
    if (!(teamName in teamsData)) {
      throw new Error('Team name does not exist')
    }

    setTeamsData({...teamsData, [teamName]: teamsData[teamName].filter(id => id !== memberId)})
  }

  const removeTeam = (teamName: string) => {
    if (!(teamName in teamsData)) {
      throw new Error('Team name does not exist')
    }

    const newTeamsData = {...teamsData}
    delete newTeamsData[teamName]
    setTeamsData(newTeamsData)
  }

  const startCreateTeamFlow = () => {
    showDialog({
      dialogBody: <TeamNameView createTeam={createTeam} teams={Object.keys(teamsData)} />,
    })
  }

  const onAddTeamMembers = (teamName: string) => (members: number[]) => {
    addTeamMembers(teamName, members)
    cancelDialog()
  }

  const beginParticipantsSelection = (teamName: string) => {
    showDialog({
      dialogBody: (
        <TeamMemberSelector 
          teamName={teamName} 
          availableParticipants={unassignedParticipants}
          addMembers={onAddTeamMembers(teamName)}
        />
      )
    })
  }

  const unassignedParticipants: Participant[] = dateRequest.date_participants?.filter(p => !hasTeam(p.id))|| []

  return { 
    startCreateTeamFlow,
    unassignedParticipants,
    createTeam,
    addTeamMembers,
    removeTeamMember,
    removeTeam,
    teamsData,
    dateRequest,
  }
}

type TeamNameViewProps = {
  createTeam: (teamName: string) => void,
  teams: string[]
}

const TeamNameView = ({createTeam, teams}: TeamNameViewProps) => {
  const [teamName, setTeamName] = useState<string>('')
  const [teamNameError, setTeamNameError] = useState('')

  const onSubmit = () => {
    if (!teamName.trim()) {
      return setTeamNameError('Team name cannot be blank')
    }

    if (teams.includes(teamName)) {
      return setTeamNameError('Team name already exists')
    }

    createTeam(teamName)
  }

  return (
    <Box p={2}>
      <Text variant="medium" weight="semibold" mb={1}>
        Enter team name
      </Text>
      <Input 
        onFocus={() => setTeamNameError('')}
        onChange={(e) => setTeamName(e.target.value)}
        placeholder="Enter team name"
        size="small" 
        mb={1} 
        helperText={teamNameError}
        error={!!teamNameError} 
      />
      <Button variant="contained" center fullWidth onClick={onSubmit}>
        <Text center color={colors.white}>Next</Text>
      </Button>
    </Box>
  )
}

type TeamMemberSelectorProps = {
  teamName: string,
  availableParticipants: Participant[],
  addMembers: (members: number[]) => void
}

const TeamMemberSelector = ({teamName, availableParticipants, addMembers}: TeamMemberSelectorProps) => {
  const [selectedMembers, setSelectedMembers] = useState<number[]>([])  
  const onToggleMember = (memberId: number) => {
    if (selectedMembers.includes(memberId)) {
      setSelectedMembers(selectedMembers.filter(id => id !== memberId))
    } else {
      setSelectedMembers([...selectedMembers, memberId])
    }
  }

  return (
    <Box p={2}>
      <Text weight="semibold" mb={1}>Select team members for {teamName}</Text>
      <Box display="flex" alignItems="center">
        {availableParticipants.map(p => (
          <Box key={p.id} mr={1}>
            <Avatar 
              removeBorder={!selectedMembers.includes(p.id)}
              addBorder={selectedMembers.includes(p.id)}
              src={p?.user?.avatar} 
              alt={p?.user?.full_name} 
              onClick={() => onToggleMember(p.id)} 
            />  
            <Text center variant="small" maxWords={5}>{getParticipantFirstName(p)}</Text>
          </Box>
        ))}
      </Box>
      <Text 
        variant="subtext" 
        weight="semibold" 
        color={selectedMembers.length === 0 ? colors.danger : colors.primary} 
        my={1}
      >
        {selectedMembers.length} members selected  
      </Text>
      <Button variant="contained" disabled={!selectedMembers.length} center fullWidth mt={1} onClick={() => addMembers(selectedMembers)}>
        <Text center color={colors.white}>Add Members</Text>
      </Button>
    </Box>
  )
}

type CreatedTeamsViewProps = {
  teams: Record<string, number[]>,
  dateRequest: DateRequest,
  removeTeam: (teamName: string) => void
}

const CreatedTeamsView = ({teams, dateRequest, removeTeam}: CreatedTeamsViewProps) => {
  return (
    <Box>
      {Object.entries(teams).map(([teamName, members]) => (
        <Box key={teamName} mb={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex">
              <Text weight="semibold">{teamName}</Text> 
              <Text 
                link 
                ml={1} 
                underline 
                onClick={() => removeTeam(teamName)}
                variant="subtext" 
                textDecorationStyle="dotted"
              >
                remove
              </Text>
            </Box>
            <Text>{members.length} Members</Text>
          </Box>
          <UsersAvatarGroup 
            spacing={8} 
            max={5} 
            users={members.map(id => dateRequest.date_participants?.find(p => p.id === id)?.user).filter(Boolean) as User[]} 
            style={{display: "flex", justifyContent: "flex-start", flexDirection: "row", marginLeft: 6}} 
          />
        </Box>
      ))}
    </Box>
  )
}


const DrawerBody = (data: ReturnType<typeof useFormTeams> & {onComplete: (teamsData: Record<string, number[]>) => void}) => {
  const { 
    unassignedParticipants, 
    startCreateTeamFlow, 
    teamsData,
    dateRequest,
    removeTeam,
    onComplete
  } = data

  return (
    <Box>
      <Box px={2} py={1}>
        {isEmpty(teamsData) && (
          <Text weight="semibold" center color={colors.danger} mb={1}>
            No teams created yet.
          </Text>
        )}
        {!isEmpty(teamsData) && (
          <CreatedTeamsView removeTeam={removeTeam} teams={teamsData} dateRequest={dateRequest} />
        )}
        {unassignedParticipants.length > 0 && (
          <Button variant="outlined" center fullWidth onClick={startCreateTeamFlow}>
            <Text>Create Team</Text>
          </Button>
        )}
      </Box>
      {unassignedParticipants.length > 0 && (
        <Box p={2} style={{...dottedBorderTop}}>
          <Text weight="semibold">Participant without Teams</Text>
          <UsersAvatarGroup 
            spacing={10} 
            max={5} 
            users={unassignedParticipants.map(p => p.user).filter(Boolean) as User[]} 
            style={{display: "flex", justifyContent: "flex-start", flexDirection: "row", marginLeft: 6}} 
          />
        </Box>
      )}
      <Box px={2} py={1} sx={{borderTop: `solid 1px ${colors.lightgrey}`}}>
        <Button 
          variant="contained" 
          center 
          fullWidth 
          disabled={!!unassignedParticipants.length}
          onClick={() => onComplete(teamsData)}
        >
          <Text center color={colors.white}>Complete Teams Setup</Text>
        </Button>
      </Box>
    </Box>
  )
}

const FormTeamDrawer = ({
  isOpen,
  onClose,
  dateRequest,
  onComplete
}: FormTeamDrawerProps) => {
  const dataAndLogic = useFormTeams({dateRequest})

  return (
    <Drawer 
      header="Form Teams"
      open={isOpen} 
      onClose={onClose}
      showCloseIcon
    >
      <DrawerBody {...dataAndLogic} onComplete={onComplete} />
    </Drawer>
  )
}


export default FormTeamDrawer
