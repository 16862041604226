import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Button from '../../../../../components/Button/Button'
import ExpenseIcon from '@mui/icons-material/MonetizationOnRounded';
import { colors } from '../../../../../styles/_var'
import TextWithIcon from '../../../../../components/TextWithIcon'
import { DateRequest } from '../../../../../utils/types';
import { useBuckBreaker } from './BuckBreaker.hooks';
import Text from '../../../../../components/Text';
import { useUiStore } from '../../../../../stores/uiStore';
import AddExpenseModal from './AddExpenseModal';
import ExpensesList from './ExpensesList';
import CostBreakdown from './CostBreakdown';


type BuckBreakerProps = {
  dateInfo: DateRequest
}


export const AllExpensesSkeleton = () => {
  return (
    <Box my={1} display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Box display="flex" alignItems="center" flex={0.8}>
        <Skeleton variant="circular" width={48} height={48} />
        <Box ml={1} width="100%">
          <Skeleton variant="text" height={40} width="100%" />
          <Skeleton variant="text" height={20} width="100%" />
        </Box>
      </Box>
      <Skeleton variant="text" height={60} width={80} />
    </Box>
  )
}

const BuckBreaker = ({ dateInfo }: BuckBreakerProps) => {
  const {
    hasExpenses,
    fetchingDateExpenses,
    expenses,
    userPID,
    saveExpenseRequest,
    expensePayments,
    fetchingDateExpensePayments,
    onPanelSelected,
    participantPanelOpen
  } = useBuckBreaker(dateInfo)
  const { showDialog, cancelDialog } = useUiStore()

  const openAddExpenseModal = () => {
    showDialog({
      dialogBody: (
        <AddExpenseModal 
          dateInfo={dateInfo} 
          onSave={payload => {
            saveExpenseRequest({
              request_id: dateInfo.request_id,
              date_participant_id: userPID || 0,
              ...payload
            })
            cancelDialog()
          }}
        />
      )
    })
  }

  return (
    <Box p={2}>
      <Button variant="contained" center onClick={openAddExpenseModal}>
        <TextWithIcon 
          useDiv 
          icon={<ExpenseIcon />}
          weight="semibold" 
          color={colors.white}
        >
          Add Expense
        </TextWithIcon>
      </Button>

      <Box mt={1}>
        <Text weight='semibold'>All Expenses</Text>
        {(!hasExpenses && !fetchingDateExpenses) &&  (
          <Text center weight="semibold" color={colors.danger} mt={1}>
            There are no expenses for this activity.
          </Text>
        )}
        {(fetchingDateExpenses && !hasExpenses) && Array(3).fill(1).map((_, i) => (
          <AllExpensesSkeleton key={i} />
        ))}
        {hasExpenses && (
          <>
            <ExpensesList dateInfo={dateInfo} expenses={expenses} />
            <CostBreakdown 
              expenses={expenses}
              panelOpen={participantPanelOpen}
              onPanelSelected={onPanelSelected}
              dateInfo={dateInfo} 
              expensePayments={expensePayments} 
              fetchingExpensePayments={fetchingDateExpensePayments}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

export default BuckBreaker
