import styled from "styled-components";
import { device } from '../../../styles/_var'


export const ShareProfileWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
  }
`