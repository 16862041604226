import React, { useState } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import Badge from '@mui/material/Badge';
import HeartIcon from '@mui/icons-material/FavoriteBorderRounded';
import SolidHeartIcon from '@mui/icons-material/Favorite';
import MessageIcon from '@mui/icons-material/MailOutlineRounded';
import SolidMessageIcon from '@mui/icons-material/Email';
import { Link, useLocation } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/HomeOutlined';
import SolidHomeIcon from '@mui/icons-material/Home';
import SolidFriendsIcon from '@mui/icons-material/Diversity1';
import DatesIcon from '@mui/icons-material/FormatListBulletedRounded';
import SolidCalendarIcon from '@mui/icons-material/CalendarToday';
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import FriendsIcon from '@mui/icons-material/Diversity1Outlined';
import MoreIcon from '@mui/icons-material/MenuRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import { routes } from '../../utils/constants'
import { DateRequest } from '../../utils/types';
import { 
  MoreButton,
  MoreMenu,
  SideBarLogo,
  SidebarContent,
  SidebarItem,
  SidebarItemFooter,
  SidebarWrapper
} from './DashboardSidebar.styles'
import TextWithIcon from '../TextWithIcon'
import { animateComponent } from '../../utils/hoc';
import { useFetchNotificationsQuery, useFetchUpcomingDatesQuery } from '../../services/api';
import { useAuthStore } from '../../stores/authStore';

const AnimatedMoreMenu = animateComponent(MoreMenu)


const DashboardSidebar = () => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const { data } = useFetchNotificationsQuery({})
  const { data: upcomingDates } = useFetchUpcomingDatesQuery({})
  const { currentUser } = useAuthStore()

  const checkForUnreadMessage = (date: DateRequest) => date.last_sent_message?.user.username !== currentUser.username && date.last_sent_message?.seen === false
  const hasUnseenNotification = data?.payload.some(e => !e.seen)
  const hasUnreadMessages = upcomingDates?.payload.some(checkForUnreadMessage)

  return (
    <SidebarWrapper>
      <SideBarLogo>
        <Link to={routes.LANDING_PAGE}>
          <img src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" alt="gusiberi logo" height={38} />
        </Link>
      </SideBarLogo>
      <SidebarContent>
        <Link to={routes.DASHBOARD}>
          <ButtonBase 
            component={SidebarItem} 
            $selected={location.pathname.includes(routes.DASHBOARD)}
          >
            <TextWithIcon 
              icon={location.pathname.includes(routes.DASHBOARD)
                ? <SolidHomeIcon />
                : <HomeIcon />
              }
            >
              Home
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <Link to={routes.DATE_REQUESTS}>
          <ButtonBase 
            component={SidebarItem}
            $selected={location.pathname.includes(routes.DATE_REQUESTS)}
          >
            <TextWithIcon icon={<DatesIcon />}>
              Requests
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <Link to={routes.FRIENDS}>
          <ButtonBase 
            component={SidebarItem}
            $selected={location.pathname.includes(routes.FRIENDS)}
          >
            <TextWithIcon 
              icon={location.pathname.includes(routes.FRIENDS)
                ? <SolidFriendsIcon />
                :<FriendsIcon />
              }
            >
              Friends
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <Link to={routes.AVAILABILITY}>
          <ButtonBase 
            component={SidebarItem}
            $selected={location.pathname.includes(routes.AVAILABILITY)}
          >
            <TextWithIcon 
              icon={location.pathname.includes(routes.AVAILABILITY)
                ? <SolidCalendarIcon /> 
                : <CalendarIcon />
              }
            >
              Availability
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <Link to={routes.NOTIFICATIONS}>
          <ButtonBase 
            className="jr-notifications"
            component={SidebarItem}
            $selected={location.pathname.includes(routes.NOTIFICATIONS)}
          >
            <TextWithIcon 
              icon={(
                <Badge 
                  variant={hasUnseenNotification ? 'dot' : undefined} 
                  color="error" 
                  overlap="circular"
                >
                  {location.pathname.includes(routes.NOTIFICATIONS) 
                    ? <SolidHeartIcon />
                    : <HeartIcon />
                  }
                </Badge>
              )}>
              Notifications
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <Link to={routes.MESSAGES}>
          <ButtonBase 
            component={SidebarItem}
            $selected={location.pathname.includes(routes.MESSAGES)}
            className='jr-messages'
          >
            <TextWithIcon 
              icon={(
                <Badge 
                  variant={hasUnreadMessages ? 'dot' : undefined} 
                  color="error" 
                  overlap="circular"
                >
                  {location.pathname.includes(routes.MESSAGES) 
                    ?<SolidMessageIcon />
                    :<MessageIcon />
                  }
                </Badge>
              )}>
              Messages
            </TextWithIcon>
          </ButtonBase>
        </Link>
        <SidebarItemFooter>
          {showMenu && <AnimatedMoreMenu />}
          <ButtonBase component={MoreButton} onClick={() => setShowMenu(!showMenu)}>
            <TextWithIcon icon={showMenu ? <CancelIcon /> : <MoreIcon />}>
              {showMenu ? 'Less' : 'More'}
            </TextWithIcon>
          </ButtonBase>
        </SidebarItemFooter>
      </SidebarContent>
    </SidebarWrapper>
  )
}

export default DashboardSidebar

