import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import Popover from '@mui/material/Popover';
import SmileyIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import Text from '../../../components/Text'
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { 
  ChatMessageHeader, 
  ChatMessageWrapper, 
  ChatMessageBody, 
  ChatInput 
} from './Messages.styles'
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { colors, spacing } from '../../../styles/_var';
import { useHideFooterOnMount, useGusiberiRoutes } from '../../../hooks/hooks';
import Input from '../../../components/Input';
import MessageBubble from './components/MessageBubble';
import { useMessages, useMessagesEffect } from './Messages.hooks';
import UsersAvatarGroup from '../../../components/UsersAvatarGroup/UsersAvatarGroup';


const ChatMessage = () => {
  useHideFooterOnMount()
  const { 
    chatContainerRef, 
    dateInfo, 
    chatMessages,
    isFetching, 
    loadOlder,
    hasOlderMessages,
    dateObject,
    scrollToBottom
  } = useMessagesEffect()
  const { 
    setMessage, 
    message, 
    sendMessage, 
    currentUser,
    participantUsers,
    chatIsActive
  } = useMessages(dateObject)
  const {gotoActivityRequest, gotoMessages} = useGusiberiRoutes()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSendMessage = async () => {
    if (!message) return
    await sendMessage()
    scrollToBottom()
  }

  const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSendMessage()
    }
  }

  const selectEmoji = (emoji: EmojiClickData) => {
    setMessage(`${message} ${emoji.emoji}`)
    handleClose()
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const menuIsOpen = Boolean(anchorEl);

  if (!dateInfo) return null

  return (
    <ChatMessageWrapper>
      <Popover
        sx={{marginTop: spacing.xxs}}
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <EmojiPicker 
          lazyLoadEmojis 
          onEmojiClick={selectEmoji} />
      </Popover>
      <ChatMessageHeader>
        <IconButton 
          onClick={gotoMessages} 
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0.5,
            paddingTop: 0.5,
          }}
        >
          <LeftIcon fontSize="large" style={{cursor: 'pointer'}} />
        </IconButton>
        <Box 
          ml={1} 
          display="flex" 
          alignItems="center" 
          onClick={() => gotoActivityRequest(dateInfo.request_id)}>
          <UsersAvatarGroup 
            users={participantUsers} 
            greyOut={!chatIsActive} 
            max={3} 
          />
          <Text ml={1} weight="semibold">
            {dateInfo.activity}
          </Text>
        </Box>
      </ChatMessageHeader>
      <ChatMessageBody ref={chatContainerRef} id="chat-message-container">
        {isFetching && <CircularProgress size={30} sx={{
          mt: 1,
          position: 'relative',
          left: "calc(50% - 15px)"
        }}/>}
        {hasOlderMessages && (
          <Text center link mt={1} mb={1} underline onClick={loadOlder}>
            Load older messages
          </Text>
        )}
        {!chatMessages.length && (
          <Text center mt={2} lighten variant="subtext">
            Please keep your messages polite and respectful.
          </Text>
        )}
        <Box mb={3} display="flex" flexDirection="column-reverse">
          {chatMessages.map((m, i) => (
            <MessageBubble 
              key={i}
              chatMessage={m} 
              isMe={currentUser.username === m.user.username} 
            />
          ))}
        </Box>
      </ChatMessageBody>
      {chatIsActive && (
        <ChatInput>
          <Box onClick={handleClick}>
            <SmileyIcon 
              fontSize="large" 
              color="primary" 
              style={{marginRight: '5px', cursor: 'pointer'}} />
          </Box>
          <Input 
            fullWidth 
            multiline
            maxRows={2}
            value={message}
            onKeyDown={onKeyDown}
            onChange={e => setMessage(e.target.value)}
            sx={{"& input": { padding: "10px "}}} 
            InputProps={{
              endAdornment: (
                <SendIcon 
                  onClick={onSendMessage}
                  fontSize="large" 
                  sx={{transform: "rotate(-45deg)", color: colors.grey}} />
              )
            }}/>
        </ChatInput>
      )}
      {!chatIsActive && (
        <Text 
          center 
          weight="semibold"
          lighten
          style={{ padding: spacing.xxs, backgroundColor: colors.lightyellow }}
        >
          This chat is no longer active!
        </Text>
      )}
    </ChatMessageWrapper>
  )
}

export default ChatMessage