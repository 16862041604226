import { DateRequest } from "../../../utils/types";
import { useUiStore } from '../../../stores/uiStore';
import { useAcceptDateRequestMutation, useDeclineDateRequestMutation, useSendFriendRequestMutation } from '../../../services/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useProcessError } from '../../../hooks/hooks';
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/constants";


export const useDateRequest = (dateInfo: DateRequest) => {
  const processError = useProcessError()
  const navigate = useNavigate()

  const { openSuccessToast, showConfirmDialog } = useUiStore()
  const [acceptRequest, { isLoading: accepting }] = useAcceptDateRequestMutation()
  const [declineRequest, { isLoading: declining }] = useDeclineDateRequestMutation()
  const [sendFriendRequest, { isLoading: sendingFriendRequest }] = useSendFriendRequestMutation()

  const onAcceptRequest = () => {
    showConfirmDialog({
      dialogBody: 'Are you sure you want to accept this request?',
      onConfirmAction: async () => {
        try {
          const response = await acceptRequest({id: dateInfo.request_id }).unwrap()
          openSuccessToast({message: response.message})
          navigate(routes.UPCOMING_DATES)
        } catch (e) {
          processError(e as FetchBaseQueryError)
        }
      },
    })
  }

  const postDeclineRequest = async (reason: string) => {
    try {
      const response = await declineRequest({id: dateInfo.request_id, message: reason }).unwrap()
      openSuccessToast({message: response.message, severity: 'success'})
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }

  const onSendFriendRequest = async (username?: string) => {
    if (!username) return

    try {
      const response = await sendFriendRequest({ sent_to: username }).unwrap()
      openSuccessToast({message: response.message})
      // await dispatch(gusiberiApi.util.upsertQueryData(
      //   'fetchDateRequest',
      //   { request_id: dateInfo.request_id },
      //   {payload: {
      //     ...dateInfo, 
      //     date_participants: dateInfo.date_participants?.map(p => {
      //       if (p.user.username !== username) return p
      //       return {...p, friendship_status: { request_id: 0, request_status: 'pending' }} as DateParticipant
      //     }),
      //   }}
      // ) as any)
    } catch (e) {
      processError(e as FetchBaseQueryError)
    }
  }


  return { 
    accepting,
    declining,
    onAcceptRequest,
    postDeclineRequest,
    sendingFriendRequest,
    onSendFriendRequest
  }
}