import styled from 'styled-components'
import MCheckbox from '@mui/material/Checkbox'
import { colors } from '../../styles/_var'

export const StyledCheckbox = styled(MCheckbox)`
  ${({ color }) => color === 'secondary' && `
    color: ${colors.red};

    &.MuiCheckbox-root {
      color: ${colors.red};
    }
  `}
`
