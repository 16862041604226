import React, { FunctionComponent } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Text from '../Text'
import { colors } from '../../styles/_var';
import { useSelector } from 'react-redux';
import { selectLoadingScreenMessage } from '../../stores/uiStore';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'

const LoadingScreen: FunctionComponent = () => {
  const loadingScreenMessage = useSelector(selectLoadingScreenMessage)

  if (!loadingScreenMessage.trim()) return null

  return (
    <Backdrop
      sx={{
        zIndex: 2000,
        display: "flex",
        flexDirection: "column"
      }}
      open={true}
      color={colors.white}
    >
      <Paper sx={{maxWidth: "90%"}}>
        <Box p={3} mx={2} display="flex" alignItems="center" flexDirection="column">
          <CircularProgress style={{color: colors.primary}} />  
          <Text mt={1} variant="medium" color={colors.primary} center>
            {loadingScreenMessage}
          </Text>
        </Box>
      </Paper>
    </Backdrop>
  )
}

export default LoadingScreen
