import React from 'react'
import { Outlet } from 'react-router-dom'
import { DashboardContent } from '../Dashboard/DashboardLayout/DashboardLayout.styles'
import MobileHeader from '../../components/MobileHeader/MobileHeader'
import { animateComponent } from '../../utils/hoc'
import HomePageNav from '../HomePage/HomePageNav'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../stores/authStore'
import Auth from '../../utils/auth'


const Profile = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn) || Auth.isLoggedIn()

  return (
    <DashboardContent isMobile>
      {!isLoggedIn && <HomePageNav noShadow />}
      <MobileHeader style={{display: 'block'}} />
      <Outlet />
    </DashboardContent>
  )
}

export default animateComponent(Profile, true)
