import React, { FunctionComponent } from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
// import CreditCardIcon from '@mui/icons-material/CreditCardRounded';
import LinkIcon from '@mui/icons-material/LinkRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import Text from '../Text'
import { Link } from 'react-router-dom';
import { routes } from '../../utils/constants';
import Auth from '../../utils/auth';
import { setLoadingScreenMessage } from '../../stores/uiStore';
import { useDispatch } from 'react-redux'


const HeaderMenu: FunctionComponent<{closeMenu?: () => void, className?: string}> = React.memo(({
  closeMenu,
  className
}) => {
  const dispatch = useDispatch()
  const showLoader = () => dispatch(setLoadingScreenMessage('Logging out, goodbye!'))
  const closeLoader = () => dispatch(setLoadingScreenMessage(''))

  const menuItems = [
    { name: 'Account Settings', Icon: SettingsIcon, route: routes.ACCOUNT_SETTINGS },
    // { name: 'Billing', Icon: CreditCardIcon, route: routes.ACCOUNT_BILLING },
    { name: 'Share your link', Icon: LinkIcon, route: routes.SHARE_PROFILE },
    { name: 'Log Out', Icon: LogoutIcon, route: '/', onClick: (e: any) => {
        e.preventDefault()
        if (closeMenu) closeMenu()
        showLoader()
        Auth.logUserOut()
        setTimeout(() => {
          window.location.href = routes.AUTH
          closeLoader()
        }, 1000)
      }
    }
  ]
  return (
    <List
      className={className}
      sx={{ width: '100%', maxWidth: 360 }}
      component="nav"
    >
      {menuItems.map(({ name, Icon, route, onClick }, i) => (
        <Link to={route} key={i} onClick={ onClick ? onClick : closeMenu}>
          <ListItemButton component="div">
            <ListItemIcon sx={{minWidth: '40px'}}>
              <Icon />
            </ListItemIcon>
            <Text variant='subtext'>{name}</Text>
          </ListItemButton>
        </Link>
      ))}
    </List>
  )
})

export default HeaderMenu