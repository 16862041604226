import styled from "styled-components";

export const colors = {
  primary: '#182D08',
  lightBlack: '#B7B4B4',
  lightgrey: '#EDEFEC',
  lightergrey: 'rgba(237, 239, 236, 1)',
  transparentgrey: 'rgba(237, 239, 236, .4)',
  lightPrimary: 'rgba(14, 177, 119, 0.6)',
  verylightPrimary: 'rgba(215, 122, 97, 0.1)',
  lightGreen: 'rgba(54, 140, 104, 0.1)',
  veryLightGreen: '#eef5ea',
  // greenBackground: 'rgba(147, 173, 134, 0.69)',
  greenBackground: 'rgba(207, 228, 196, 0.2)',
  pink: 'rgba(165, 128, 107, 0.1)',
  white: '#fff',
  red: '#FF3434',
  black: '#000',
  brown: '#381D2A',
  yellow: '#FEFDF2',
  lightyellow: 'rgba(248, 232, 183, 0.25)',
  grey: '#898A8D',
  darkgrey: '#50565E',
  green: '#468317',
  darkGreen: '#26754E',
  mediumgrey: '#DED7C2',
  danger: '#B43F1A',
  borderColor: 'rgba(53, 46, 26, 0.25)',
  icon: 'rgba(24, 45, 8, 0.46)',
  link: '#1976d2',
  secondary: '#ba9ba0',
  tertiary: '#cd8a56',
  info: '#0288d1',
  lightDanger: 'rgb(180, 63, 26, 0.9)',
  darkGreenBorder: '#EDEDED',
}

export const size = {
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1536px',
}


 export const device = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};


export const spacing = {
  xxs: '1rem',
  xs: '1.5rem',
  sm: '3rem',
  rg: '4.5rem',
  lg: '5rem',
  xl: '7rem',
  xxl: '12rem'
}


export const fontSize = {
  xxs: '0.9rem',
  xs: '1rem',
  sm: '1.2rem',
  rg: '1.5rem',
  md: '1.8rem',
  lg: '3.2rem',
  xl: '4rem'
}


export const PageWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 2px 1px lightgrey;
    padding-bottom: ${spacing.xs};
    margin-top: ${spacing.sm};
  }
`

export const dottedBorderTop = {
  borderTop: `1px dotted ${colors.borderColor}`
}