import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/Input';
import { IActionButton } from '../../../components/SimpleForm';
import Text from '../../../components/Text';
import { LoginArgs, useLoginMutation } from '../../../services/api';
import { showToastMessage } from '../../../stores/uiStore';
import Auth from '../../../utils/auth';
import { routes } from '../../../utils/constants';
import { retrieveErrorMessage } from '../../../utils/helpers';
import validators from '../../../utils/validators';


export const useLoginForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loginUser, { isLoading }] = useLoginMutation()

  const loginFields = [
    {
      name: 'username',
      placeholder: 'Username or Email',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.required
    },
    {
      name: 'password',
      placeholder: 'Password',
      type: 'password',
      component: Input,
      max_length: 50,
      fullWidth: true,
      validate: validators.required,
    },
  ]

  const actionButtons: IActionButton = {
    label: <Text color="white">Continue</Text>,
    loading: isLoading,
    isSubmit: true,
    fullWidth: true,
    variant: "contained"
  }

  const completeLogin = (userPayload: any) => {
    Auth.saveUserDetails(userPayload)
    dispatch(showToastMessage({ message: 'You are now logged in' }))
    navigate(routes.DASHBOARD)
  }

  const submitForm = async (data: LoginArgs) => {
    try {
      const response = await loginUser(data).unwrap()
      completeLogin(response)
    } catch (e: any) {
      const errorMessage = retrieveErrorMessage(e)
      dispatch(showToastMessage({ message: errorMessage, severity: 'error' }))
    }
  }

  return { submitForm, isLoading, actionButtons, loginFields, completeLogin }
}
