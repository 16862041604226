import styled from 'styled-components'
import Box from '@mui/material/Box'
import TrashIcon from '@mui/icons-material/DeleteOutlineRounded';
import { colors, spacing, device } from '../../../styles/_var'
import Text from '../../../components/Text'
import Button from '../../../components/Button/Button';

export const AvailabilityWrapper = styled.div`
  @media ${device.md} {
    max-width: 65rem;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Note = styled(Box)`
  padding: ${spacing.xxs} ${spacing.xs};
  border-radius: 5px;
  background-color: ${colors.secondary};
  color: ${colors.white};
`

export const ScheduleItem = styled.div`
  .MuiListItemIcon-root {
    min-width: 30px;
  }
`

export const Times = styled(({ disabled, ...rest }) => <div {...rest} />)`
  padding: 0 ${spacing.xs} ${spacing.sm};
  & input {
    font-size: 16px;
    padding: 8px;
  }
  ${({ disabled }) => disabled && `
    filter: grayscale(1);
    opacity: 0.7;
    pointer-events: none;
  `}
`

export const Spacer = styled.div`
  margin-left: ${spacing.xxs};
  margin-right: ${spacing.xxs};
  &::before {
    content: "-"
  }
`
  
export const TimeBox = styled(Text)`
  padding: ${spacing.xxs} ${spacing.xs};
  border: solid 1px ${colors.borderColor};
  border-radius: 6px;
  width: 55px;
  text-align: center;
`

export const DeleteScheduleIcon = styled(TrashIcon)`
`

export const DeleteButton = styled(Button)`
  padding: 3px !important;
  min-width: 20px !important;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
`

export const AddScheduleWrapper = styled(Box)`
  input.MuiInputBase-input {
    font-size: 16px;
    padding: 10px;
  }
`