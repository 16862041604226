import styled from "styled-components";
import Camera from '@mui/icons-material/CameraAltOutlined';
import { colors } from "../../styles/_var";

export const HiddenFileInput = styled.input`
  position: fixed;
  visibility: hidden;
  display: block;
`

export const ImageUploadWrapper = styled.div`
  position: relative;
`

export const CameraIcon = styled(Camera)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px !important;
  width: 40px !important;
  padding: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  color: ${colors.darkgrey};
  cursor: pointer;
`