import styled from 'styled-components'
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { colors, spacing } from '../../styles/_var'

export const MobileHomeHeaderWrapper = styled.div`
  border-bottom: solid 1px ${colors.lightgrey}; 
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.xs} 0;
  align-items: center;
`

export const SearchBar = styled.div`
  padding-bottom: ${spacing.xs};
`


export const ResultItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xs} 0;
  border-bottom: solid 1px ${colors.lightgrey};

  .MuiAvatar-root.MuiAvatar-circular {
    width: 45px;
    height: 45px;
  }
`

export const ResultWrapper = styled(Link)`
  transition: all ease .2s;
  display: block;

  &:hover {
    background: ${colors.lightGreen};
    padding: 1px;
  }
`
