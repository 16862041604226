import React, { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import Text from '../../../../components/Text'
import { useGetFriendsRequestQuery } from '../../../../services/api'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import ButtonBase from '@mui/material/ButtonBase'
import Button from '../../../../components/Button/Button'
import { colors } from '../../../../styles/_var'
import { useFriendCategories } from '../Friends.hooks'
import { UserInfo } from '../../../../components/UserInfo/UserInfo'

type AddFriendToGroupModalProps = {
  id: number,
  name: string
}

const AddFriendToGroupModal: FunctionComponent<AddFriendToGroupModalProps> = ({
  id,
  name
}) => {
  const [selectedFriends, setSelectedFriends] = useState<any>({})
  const { addingFriendToGroup, assignFriendToGroup } = useFriendCategories()
  const { data: friends } = useGetFriendsRequestQuery({})
  const friendsList = friends?.payload.filter(e => e.category_id !== id)
  const canAdd = Object.values(selectedFriends).some(e => e === true)

  const handleSelected = (friendUsername: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    toggleSelected(friendUsername)
  }

  const toggleSelected = (friendUsername: string) => {
    setSelectedFriends({...selectedFriends, [friendUsername]: !selectedFriends[friendUsername]})
  }

  const onSubmit = () => {
    assignFriendToGroup(id, Object.keys(selectedFriends).filter(friend => selectedFriends[friend] === true))
  }


  return (
    <Box width={300} sx={{boxSizing: "border-box", position: 'relative'}}>
      <Text mt={2} mb={2} ml={2} mr={2} weight="semibold" center variant="medium">
        Add friend to {name}
      </Text>
      <Box maxHeight="80vh" px={2} sx={{overflowY: "scroll"}}>
        {friendsList?.map(({ friend_user }, i) => (
          <ButtonBase 
            component='div'
            key={friend_user.username} 
            onClick={() => toggleSelected(friend_user.username)}
            sx={{display: 'block', width: '100%'}}>
            <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
              <UserInfo user={friend_user} />  
              <Checkbox 
                checked={!!selectedFriends[friend_user.username]} 
                onChange={handleSelected(friend_user.username)} />
            </Box>
            {(i < (friendsList?.length - 1 || 0)) && <Divider />}
          </ButtonBase>
        ))}
      </Box>
      <Divider />
      <Box 
        p={2} 
        sx={{
          background: colors.white, 
          position: 'relative', 
          zIndex: 2
        }}>
        <Button 
          fullWidth
          variant="contained"
          disabled={!canAdd}
          onClick={onSubmit}
          loading={addingFriendToGroup}
        >
          <Text color={colors.white}>Add to friend group</Text>
        </Button>
      </Box>
    </Box>
  )
}

export default AddFriendToGroupModal
