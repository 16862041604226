import React, { FunctionComponent } from 'react'
import { HomePageNavContainer, HomePageNavWrapper } from './HomePage.styles'
import Text from '../../components/Text'
import Box from '@mui/material/Box'
import Button from '../../components/Button/Button'
import content from './content'
import { routes } from '../../utils/constants'
import { useAuthStore } from '../../stores/authStore'
import Avatar from '../../components/Avatar/Avatar'
import { Link } from 'react-router-dom'


type HomePageNavProps = {
  noShadow?: boolean,
  disabled?: boolean,
  onlyLogo?: boolean
}

const HomePageNav: FunctionComponent<HomePageNavProps> = ({ 
  noShadow, 
  disabled,
  onlyLogo
}) => {
  const {isLoggedIn, currentUser} = useAuthStore()

  return (
    <HomePageNavWrapper noShadow={noShadow} style={{opacity: disabled ? 0.6 : 1}}>
      <HomePageNavContainer>
        <Box display="flex" alignItems="center" justifyContent="space-between" height="100%">
          <Link to={routes.LANDING_PAGE}>
            <img 
              src="https://gusiberi-dev.s3.eu-west-1.amazonaws.com/gusiberi-logo.png" 
              alt="gusiberi logo" 
              height={38} />
          </Link>
          {!onlyLogo && (
            <>
              {!isLoggedIn && (
                <Button variant='outlined' to={routes.AUTH} useLink>
                  <Text weight="semibold">{content.signup}</Text>
                </Button>
              )}
              {isLoggedIn && (
                <Link to={disabled ? '' : routes.DASHBOARD}>
                  <Avatar src={currentUser.avatar} sx={{width: 38, height: 38}} />
                </Link>
              )}
            </>
          )}
        </Box>
      </HomePageNavContainer>
    </HomePageNavWrapper>
  )
}

export default HomePageNav