import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { NotificationSettings, NotificationSettingType, User, UserActivityTimestamp } from '../utils/types'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyCurrentUserQuery } from '../services/api'
import { useUiStore } from './uiStore'
import Auth from '../utils/auth'
import { routes } from '../utils/constants'


export const authStore = createSlice({
  name: 'user',
  initialState: {
    username: '',
    token: null,
    currentUser: {} as User,
    notificationSetting: {} as NotificationSettings,
    activityTimestamps: {} as UserActivityTimestamp
  },
  reducers: {
    setCredentials(state, action) {
      state.username = action.payload.username
      state.token = action.payload.token
    },
    setCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload
    },
    setNotificationSettingsState(state, action: PayloadAction<NotificationSettings>) {
      state.notificationSetting = action.payload
    },
    updateNotificationSettings(state, action: PayloadAction<Partial<{[k in NotificationSettingType]: boolean}>>) {
      state.notificationSetting = {...state.notificationSetting, ...action.payload}
    },
    setUserActivityTimestampState(state, action: PayloadAction<UserActivityTimestamp>) {
      state.activityTimestamps = action.payload
    }
  }
})

export type AuthState = ReturnType<typeof authStore.getInitialState>

export const {
  setCredentials, 
  setCurrentUser, 
  setNotificationSettingsState, 
  updateNotificationSettings,
  setUserActivityTimestampState
} = authStore.actions
export const authReducer = authStore.reducer
export const selectUserIsActivated = (state: RootState) => state.auth.currentUser.activated
export const selectToken = (state: RootState) => state.auth.token
export const selectUsername = (state: RootState) => state.auth.currentUser.username
export const selectCurrentUser = (state: RootState) => state.auth.currentUser || {}
export const selectUserFullName = (state: RootState) => state.auth.currentUser.full_name || ''
export const selectNotificationSettings = (state: RootState) => state.auth.notificationSetting || {}
export const selectFirstName = (state: RootState) => selectUserFullName(state).split(' ')[0] || ''
export const selectFirstLetterOfName = (state: RootState) => selectFirstName(state)[0] || ''
export const selectCurrentUserAvatar = (state: RootState) => selectCurrentUser(state).avatar || ''
export const selectCurrentUserLoaded = (state: RootState) => !!Object.keys(state.auth.currentUser).length
export const selectIsLoggedIn = (state: RootState) => !!Object.keys(state.auth.currentUser).length
export const selectActivityTimestamps = (state: RootState) => state.auth.activityTimestamps
export const selectProfileComplete = (state: RootState) => {
  const currentUser = selectCurrentUser(state)
  const fieldsToCheck = ['dob', 'gender', 'avatar', 'instagram', 'linkedin', 'twitter'] as const
  return fieldsToCheck.some(field => !!currentUser[field])
}


export const useAuthStore = () => {
  const dispatch = useDispatch()
  const { closeLoadingScreen, openLoadingScreen } = useUiStore()
  const currentUser = useSelector(selectCurrentUser)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const currentUserLoaded = useSelector(selectCurrentUserLoaded)
  const notificationSettings = useSelector(selectNotificationSettings)
  const userIsActivated = useSelector(selectUserIsActivated)
  const activityTimestamps = useSelector(selectActivityTimestamps)
  const [fetchUserDetails, {isLoading: isLoadingUser, isError: failedToLoadUser }] = useLazyCurrentUserQuery({})

  const loadUser = async () => {
    if (isLoadingUser) return {}
    try {
      const userDetails = await fetchUserDetails({}).unwrap()
      setUser(userDetails.payload?.user)
      setNotificationSettings(userDetails.payload?.notification_settings)
      setUserActivityTimestamps(userDetails.payload?.activities)
      return {userDetails: userDetails.payload}
    } catch {
      return {}
    }
  }

  const logUserOut = () => {
    openLoadingScreen('Logging out!')
    Auth.logUserOut()
    setTimeout(() => {
      closeLoadingScreen()
      window.location.href = routes.AUTH
    }, 1000)
  }

  const setUser = (user: User) => {
    dispatch(setCurrentUser(user))
  }

  const setNotificationSettings = (notification: NotificationSettings) => {
    dispatch(setNotificationSettingsState(notification))
  }

  const setUserActivityTimestamps = (activityTimestamps: UserActivityTimestamp) => {
    dispatch(setUserActivityTimestampState(activityTimestamps))
  }

  return {
    currentUser,
    isLoggedIn,
    currentUserLoaded,
    userIsActivated,
    loadUser,
    logUserOut,
    failedToLoadUser,
    isLoadingUser,
    notificationSettings,
    activityTimestamps,
    setUser,
    setNotificationSettings,
    setUserActivityTimestamps
  }
}