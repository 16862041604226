import React , { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import FrownIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Popover from '@mui/material/Popover';
import MenuDotsIcon from '@mui/icons-material/MoreVertRounded';
import RejectedIcon from '@mui/icons-material/NotInterestedRounded';
import AcceptIcon from '@mui/icons-material/DoneAllRounded';
import Divider from '@mui/material/Divider'
import { colors, spacing } from '../../../styles/_var'
import IconButton from '@mui/material/IconButton';
import TextWithIcon from '../../../components/TextWithIcon'
import { useFetchDeclinedFriendRequestsQuery, useFetchPendingFriendRequestsQuery } from '../../../services/api'
import Text from '../../../components/Text';
import { humanize } from '../../../utils/helpers';
import { FriendRequest } from '../../../utils/types';
import { useFriends } from './Friends.hooks';
import { UserInfo } from '../../../components/UserInfo/UserInfo';

type FriendRequestItemProps = {
  request: FriendRequest,
  acceptFriendRequest: (id: number) => void,
  rejectFriendRequest: (id: number) => void
}

const FriendRequestItem: FunctionComponent<FriendRequestItemProps> = ({
  request,
  acceptFriendRequest,
  rejectFriendRequest
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const accept = () => {
    acceptFriendRequest(request.id)
    handleClose()
  }
  const reject = () => {
    rejectFriendRequest(request.id)
    handleClose()
  }
  const menuIsOpen = Boolean(anchorEl);

  return (
    <>
      <Popover
        sx={{marginTop: spacing.xxs}}
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <ListItemButton sx={{py: 2}} onClick={accept}>
          <ListItemIcon sx={{minWidth: '29px'}}>
            <AcceptIcon />
          </ListItemIcon>
          <Text>Accept</Text>
        </ListItemButton>
        <ListItemButton sx={{py: 2}} onClick={reject}>
          <ListItemIcon sx={{minWidth: '29px'}}>
            <RejectedIcon />
          </ListItemIcon>
          <Text>Decline</Text>
        </ListItemButton>
      </Popover>
      <Box            
        mt={1}
        mb={2}
        display="flex"
        justifyContent="space-between" 
        alignItems="center"
      >
        <UserInfo gotoProfileOnClick user={request.sent_from} flex={1} />
        <Box display="flex" alignItems="center">
          <Text  variant="subtext" mr={1}>{humanize(request.created_at)}</Text>
          <IconButton sx={{height: 'fit-content'}} onClick={handleClick}>
            <MenuDotsIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  ) 
}

const FriendRequests = () => {
  const { data: pendingRequests, isLoading } = useFetchPendingFriendRequestsQuery({}, {refetchOnMountOrArgChange: true})
  const { data: declinedRequests } = useFetchDeclinedFriendRequestsQuery({})
  const { acceptFriendRequest, declineFriendRequest } = useFriends()
  const [requestType, setRequestType] = useState<'pending'|'declined'>('pending')
  const isPending = requestType === 'pending'
  const toggleRequestType = () => {
    setRequestType(isPending ? 'declined' : 'pending')
  }

  const results = (isPending ? pendingRequests?.payload : declinedRequests?.payload) || []

  return (
    <Box width="100%" mb={2} display="flex" flexDirection="column">
      <Text link variant="subtext" right underline mb={1} onClick={toggleRequestType}>
        View {isPending ? 'Declined' : 'Pending'} Requests
      </Text>
      {(!isLoading && !results.length) && (
        <TextWithIcon
          mt={1}
          icon={<FrownIcon fontSize="large" sx={{color: colors.danger}} />} 
          weight="semibold"
          color={colors.danger} 
          justifyContent="center"
        >
          You have no friend requests
        </TextWithIcon>
      )}
      {results.map((request) => (
        <FriendRequestItem 
          key={request.id} 
          request={request}
          acceptFriendRequest={acceptFriendRequest}
          rejectFriendRequest={declineFriendRequest} />
      ))}
    </Box>
  )
}

export default FriendRequests
