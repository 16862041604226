import React from 'react'
import Text from '../Text'
import { SimpleFooterWrapper } from './SimpleFooter.styles'
import { routes } from '../../utils/constants'
import { Link } from 'react-router-dom'


const SimpleFooter = () => {
  return (
    <SimpleFooterWrapper>
      <Text center variant="subtext" weight="semibold">
        &copy; 2023 Gusiberi, Inc.
      </Text>
      <strong style={{marginRight: 5, marginLeft: 5}}>
          &#x2022;
        </strong>
        <Link to={routes.TERMS_OF_USE}>
          <Text variant="subtext" weight="semibold">
            Terms
          </Text>
        </Link>
        <strong style={{marginRight: 5, marginLeft: 5}}>
          &#x2022;
        </strong>
        <Link to={routes.PRIVACY_POLICY}>
          <Text variant="subtext" weight="semibold">
            Privacy
          </Text>
        </Link>
    </SimpleFooterWrapper>
  )
}

export default SimpleFooter